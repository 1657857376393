import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import { APP_BASE_URL, IMAGE_BASE_URL } from '../../constants';

class SubCategoryPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			count: 0,
			modal: false,
		};

	}


	componentDidMount() {
		var cat = this.props.match.params.catId;
		var subcat = '';

		if (cat === 'activities') {
			cat = '611ba6cd14e97e569e41eaac';
			this.setState({ "catName": "Activities" })
		} else if (cat === 'transport_&_travel_services') {
			cat = '61275c3ceaf59f69875e2abd';
			this.setState({ "catName": "Transport & Travel Services" })
		} else if (cat === 'tours') {
			cat = '611b45344fdde75b279725ec';
			this.setState({ "catName": "Tours" })
		} else if (cat === 'staycation') {
			cat = '611b452a4fdde75b279725eb';
			this.setState({ "catName": "Staycation" })
		} else if (cat === 'best_food_&_must_eats') {
			cat = '61275c55eaf59f69875e2abe';
			this.setState({ "catName": "BEST FOOD & MUST EATS" })
		} else {
			cat = '';
			this.setState({ "catName": "Activities" })
		}
		if (this.props.match.params.subCatId != 'All') {
			subcat = this.props.match.params.subCatId;
			ProfileService.getSubCategoryListByCategory(cat).then(response => {

				for (var i = 0; i < response.data.length; i++) {
					if (response.data[i].id === subcat) {
						this.setState({ "subCatName": response.data[i].name })
					}
				}

			})
			ProfileService.getSubCategoryIdByName(subcat).then(response => {

				subcat = response.data[0].id;
				ProfileService.searchOnActivities('', '', cat, '', subcat, '', '', '').then(response => {
					//alert(response.data.length)
					if (response.data.length == 0) {
						this.setState({ "size": true })
					} else {
						this.setState({ "size": false })
					}

					this.setState({ "activities": response.data })
					if (response.data != null) {
						this.setState({ "count": response.data.length })
					}

				})

			})
		} else {
			ProfileService.searchOnActivities('', '', cat, '', '', '', '', '').then(response => {
				//alert(response.data.length)
				if (response.data.length == 0) {
					this.setState({ "size": true })
				} else {
					this.setState({ "size": false })
				}

				this.setState({ "activities": response.data })
				if (response.data != null) {
					this.setState({ "count": response.data.length })
				}

			})
		}




	}

	handleClickActitvity(e, id) {
		this.props.history.push(`/activity/${id}`)
		window.location.reload();
	}

	render() {
		let myMessage = null;
		if (this.state.message != null) {
			myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
		}

		return (
			<>
				<div class="main_all">
					<Header></Header>

					<section class="same-section invite_your_friendsB innerpage_banner destination-banner">
						<div class="container">
							<div class="innpage_banner_inn_side">
								<h1 class="inner_banner_heding text-align">{this.state.catName}</h1>

							</div>
						</div>
					</section>

					<section class="same-section travel_inspiration padding_top padding_bottom bg_white">
						<div class="container">
							<h2 class="section_main_title text-align">{this.state.subCatName}</h2>
							<p class="section_main_subtitle text-align">{this.state.count} Activities Found</p>

							<div class="travel_inspiration_perent_new">
								<div class="row">
									{(this.state.activities != null && this.state.activities.length > 0) ? this.state.activities.map(
										influencer =>
											<div class="col-md-4">
												<div class="popular_activities_box">
													<div class="imagesof_box">
														<img loading="lazy" src={IMAGE_BASE_URL + `` + JSON.parse(influencer.gridImage).imageUrl} alt={JSON.parse(influencer.gridImage).altText} />
														<div class="activities_palcetitle">
															<img loading="lazy" src="/images/map.svg" alt="" /> {influencer.ad[0].name}
														</div>
													</div>
													<div class="popular_activities_content">
														<div class="discount_div">
															<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} />
															<span class="discount"> {influencer.pk.discountPercantage}<sup>%</sup> <sub>OFF</sub></span>
														</div>
														<h3 class="palcebox_title">{influencer.name}</h3>
														<div class="box_review_side">
															<div class="review_side_cell">
																<i class="fas fa-star"></i>
																<span class="rateeside">{influencer.pk.review}</span>
																<span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
																<span class="totalblocked">{influencer.pk.booked} Booked</span>

															</div>
															<h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
															<div class="popular_activities_price">
																<p class="pricesid">₹ {influencer.pk.basePrice}
																	{(influencer.instantConfirmation == true ? (<><span><img loading="lazy" height="17px" width="10px" src="images/lightning.svg" alt="" /></span></>) : (<></>))}
																</p>

															</div>
														</div>
													</div>
													{(influencer.activityCategory_id == '611b452a4fdde75b279725eb' ? (<a href={`${APP_BASE_URL}/staycation/${influencer.url}`} class="onlink"></a>) : (<></>))}
													{(influencer.activityCategory_id == '611b45344fdde75b279725ec' ? (<a href={`${APP_BASE_URL}/tours/${influencer.url}`} class="onlink"></a>) : (<></>))}
													{(influencer.activityCategory_id == '611ba6cd14e97e569e41eaac' ? (<a href={`${APP_BASE_URL}/activities/${influencer.url}`} class="onlink"></a>) : (<></>))}
													{(influencer.activityCategory_id == '61275c3ceaf59f69875e2abd' ? (<a href={`${APP_BASE_URL}/transport/${influencer.url}`} class="onlink"></a>) : (<></>))}
													{(influencer.activityCategory_id == '61275c55eaf59f69875e2abe' ? (<a href={`${APP_BASE_URL}/food/${influencer.url}`} class="onlink"></a>) : (<></>))}
												</div>
											</div>
									)

										: ""}


								</div>

							</div>
						</div>
					</section>






					<HomeFooter></HomeFooter>
				</div>
			</>
		);
	}
}

export default SubCategoryPage;
