import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
// import { EditorState } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import PaginationComponent from "react-reactstrap-pagination";
import { APP_BASE_URL} from '../../constants';
// import Select from 'react-select'
import { IMAGE_BASE_URL} from '../../constants';
import Helmet from 'react-helmet';

class AllBlogs extends React.Component {
  constructor(props) {
    super(props);
	this.handlePageChange = this.handlePageChange.bind(this);
	this.handleClickSearchPage= this.handleClickSearchPage.bind(this);
	this.handleClickSearch = this.handleClickSearch.bind(this);
    this.state = {
    modal: false,
	isClearable: true,
    isDisabled: false,
    isLoading: false,
	activePage:0,
	totalPages: null,
	itemsCountPerPage:10,
	pagesCount: null,
	totalItemsCount:null,
    isRtl: false,
    isSearchable: true,
	searchText : '',
  };
  this.handleChangeSearch = this.handleChangeSearch.bind(this);
  
}


  componentDidMount() {
	this.refreshBrands(this.state.activePage, this.state.itemsCountPerPage);
	ProfileService.retrieveAllBlog(0,10)
	.then(response => { 
		  this.setState({blogListSide:response.data.content})
		}
	);
    ProfileService.getBlogList().then(response=>{
     
	  let tagList = [];
	  let blogSearchList = [];
		for(let i = 0; i < response.data.length; i++){
			let value = response.data[i].url;
			let label = response.data[i].name;
			let skillS = { value : value ,label : label }
			//alert(value);
			//alert(label);
			blogSearchList.push(skillS);
			let tags = JSON.parse(response.data[i].tags);
			for(let t=0;t<tags.length;t++){
				    if(tagList.length<20)
					tagList.push({"tag":tags[t].valueValue,"url":response.data[i].url});	
				
			}
		  }

		  var unique = [];
var distinct = [];
for( let i = 0; i < tagList.length; i++ ){
  if( !unique[tagList[i].tag]){
    distinct.push({"tag":tagList[i].tag,"url":tagList[i].url});
    unique[tagList[i].tag] = 1;
  }
}

		  this.setState({"tagList":distinct.slice(0,11)})
		  this.setState({"blogSearchList":blogSearchList})
    })
  }

  handlePageChange(pageNumber) {
	console.log(this.state.totalItemsCount)

	this.setState({activePage: pageNumber-1})
 
	this.refreshBrands(pageNumber-1, this.state.itemsCountPerPage);
	}

  refreshBrands(page,size){
	ProfileService.retrieveAllBlog(page,size)
	.then(response => { 
		
		const itemsCountPerPage = response.data.size;
		const totalItemsCount = response.data.totalElements;
		
		  this.setState({totalItemsCount: totalItemsCount})
		  this.setState({itemsCountPerPage: size})
		  this.setState({blogList:response.data.content})
		}
	);
  }

  handleClickBlog(e,id){
    this.props.history.push(`/blog/${id}`)
  }

  handleChangeSearch(value){
	if(value!=null){
		var id = value.value;
		this.props.history.push(`/blog/${id}`)
	}
  }


clearSuggestions(){
	this.setState({"suggestions": []});
}

  searchBlog(text){
	let matchers = [];
	var blogSearchList = this.state.blogSearchList;
	if(text.length>0){
		matchers = blogSearchList.filter(act => {
			const regex = new RegExp(`${text}`,"gi");
			return act.label.match(regex);
		})
	}
    console.log(matchers);
	this.setState({"searchText":text,"suggestions":matchers});
  }

  handleClickSearch(e,value){
	e.preventDefault();
	//alert(value);
	this.props.history.push(`/blog/${value}`)

}
  
  handleClickSearchPage(e) {
	e.preventDefault();
	var searchText = this.state.searchText;
	if(searchText.length>0){
		window.location.href=`${APP_BASE_URL}/blogsearch/${searchText}`;
	}
}

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
	<Helmet>
    <script src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v12.0&appId=1206614489817558&autoLogAppEvents=1" 
    type="text/javascript" />
    </Helmet>
    <Header></Header>

   
    <section class="same-section blog_pageB innerpage_banner">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
		    		<p class="top_main_Sheding text-align">Renok Stories</p>
		    		<h1 class="inner_banner_heding text-align">Experiences of Lifetime!</h1>
		    		<div class="blog_baner_search small_padding_top">	    			
		      			<div class="heade_search_fuild">
						  <form  onSubmit={this.handleClickSearchPage}>   
						  <input  type="text" style={{"height": "37px","margin-top": "1px"}} value={this.searchText} className="col-md-10"  onBlur={()=>{
							  setTimeout(()=>{
                                this.clearSuggestions();
							  },1000)
						     }} onChange={e=> this.searchBlog(e.target.value)} placeholder='Search your adventure' />
						 
						  {( this.state.suggestions && this.state.suggestions.length != 0 ? 
						      (<div class="suggestions">
						           {this.state.suggestions && this.state.suggestions.map((suggestion,i)=>
						          <div class="suggestions-item" onClick={e =>this.handleClickSearch(e,suggestion.value)}   key={i} >{suggestion.label}</div>
						        )}
						      </div>) : (<></>)
                          )}
						  <button type="submit" class="searchhead_but"><i class="fa fa-search" aria-hidden="true"></i></button>
						  
						   </form>	  
						  {/* <Select 
						 isDisabled={this.state.isDisabled}
						 isLoading={this.state.isLoading}
						 isClearable={this.state.isClearable}
						 isRtl={this.state.isRtl}
						 isSearchable={this.state.isSearchable} 
						 onChange={ this.handleChangeSearch}
						 options={this.state.blogSearchList} 
						 placeholder={<div>Search your adventure</div>}
						 />
		      			
		      				 <button class="searchhead_but"><i class="fa fa-search" aria-hidden="true"></i></button>  */}
		      			</div>
		    		</div>
		    		<div class="some_list_blog_banner">
		    			<span class="onliban_listline"></span>
		    			<ul class="list_ofround">
						{(this.state.tagList != null && this.state.tagList.length> 0 )? this.state.tagList.map(
                                    hl =><>
		    				<li><a href={`/blog/${hl.url}`} >{hl.tag}</a></li>
							</>
						   ):""}
		    			</ul>
		    			<span class="onliban_listline_two"></span>
		    		</div>
	    		</div>
	    	</div>
	    </section>



   		<section class="same-section blog_page_blogs padding_top">
   			
	    	<div class="container">
	    		<div class="blog_pagemain_flex">
	    			<div class="blog_leftbx_side">
	    				<div class="HRblack_bold"></div>
			    		<h6 class="blog_page_topsmalls small_padding_top">CHECKOUT FOR </h6>
			    		<h3 class="blackblog_title"><span>CHECKOUT OUR </span> LATEST ARTICLES</h3>
			    		{(this.state.blogList != null && this.state.blogList.length> 0 )? this.state.blogList.map(
                                    hl =><>
              <div class="blog_bx_Bpage" style={{padding: "15px", boxShadow: "0px 0px 6px 5px #0000001f", borderRadius: "20px"}}>	
			  <h3 class="blackblog_title">{hl.name}</h3>
			  <ul class="italic_service_label">
							{(JSON.parse(hl.tags) != null && JSON.parse(hl.tags).length> 0 )? JSON.parse(hl.tags).map(
                                    tg =><>
		    					<li><a href="#" class="brn-itiner">{tg.valueValue}</a></li>
		    					</>
						   ):""}
		    				</ul>		    			
		    				<img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(hl.gridImage).imageUrl} alt={JSON.parse(hl.gridImage).altText}/>
		    				
		    				
		    				{/* <div class="cnt-test">
		    				<div class="blog_userimg_bx">
                        <div class="blog_usercont">
                           <div class="image_bbx_user">
                              <img src="/images/guidetip-user.jpg" alt="" />
                           </div>
                        </div>
                        <div class="blog_usercont">
                           <span>Dinkar Kamat</span><span>SEPTEMBER 12, 2019</span>
                        </div>
                     </div>
                     <p>It’s important to understand that tourism activities in Leh-Ladakh are limited to only 5 months in a year. The season to visit Ladakh is from May to October. In fact, the peak tourist season is considered to be from June to September. Though</p>
                     <h5>Read more &nbsp; &nbsp; <i class="fas fa-chevron-down"></i></h5>
                 </div> */}
		    				<a href={`/blog/${hl.url}`}  class="onlink"></a>
				    	</div>
              </>
						   ):""}
			    	
				    	<div class="HRblack_bold small_margin_top border-cnt"></div>
						
            				    	
						<div class="same-section  padding_bottom small_padding_top">	    
						      <div class="common_block pagination_bar align-left">
							  <PaginationComponent
          totalItems={this.state.totalItemsCount}
          pageSize={this.state.itemsCountPerPage}
          onSelect={this.handlePageChange}
        />
						        
						      </div>
						</div>
	    			</div>	    			
	    			<div class="blog_rightbx_side">
	    				<div class="HRblack_bold"></div>
	    				<h3 class="blackblog_title small_padding_top">RECENT POSTS</h3>
	    				<ul class="recent_postlist small_padding_top">
              {(this.state.blogListSide != null && this.state.blogListSide.length> 0 )? this.state.blogListSide.map(
                                    hl =><>
	    					<li><a style={{"cursor":"pointer"}} href={`/blog/${hl.url}`} >{hl.name}</a></li>
	    				
                </>
						   ):""}
	    				</ul>
	    				<div class="HRblack_bold"></div>
	    				<h3 class="blackblog_title small_padding_top">ABOUT RENOK</h3>
	    				<p class="small_padding_top">Renok is an adventure company established in 2011 which specializes in trekking, adventure activities and outbound training. As nature and adventure aficionados we strive to give you an experience where the destination is never a place, but a new way of seeing things.</p>
						<div style={{"width":"100%","margin-top":"20px"}}>

<div class="fb-page" data-href="https://www.facebook.com/renokadventures/" data-width="420"></div>
</div>
					
	    			</div>
	    		</div>
	    	</div>
	    </section>

	    

    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default AllBlogs;
