import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import { APP_BASE_URL} from '../../constants';
import Select from 'react-select'
import { IMAGE_BASE_URL} from '../../constants';

class AllDestination extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickDescInSearch = this.handleClickDescInSearch.bind(this);
    this.state = {
    modal: false,
  };
  
}


  componentDidMount() {
	ProfileService.retrieveTopDestinations().then(response=>{
     // console.log(response.data);
          this.setState({"topDestinations":response.data})
   });
   ProfileService.getAllDestinations().then(response=>{
		//console.log(response.data);
		
		let popularActivities = [];
		for(let i = 0; i < response.data.length; i++){
			let value = response.data[i].url;
			let label = response.data[i].name;
			let skillS = { value : value ,label : label }
			//alert(value);
			//alert(label);
			popularActivities.push(skillS);
			
		  }
		  this.setState({"destinationForSearch":popularActivities})
	})
	
    ProfileService.getStatesListWithDestinations('').then(response=>{
		this.setState({"stateListWithDesEI":response.data})
	})

  

	ProfileService.getSubCategoryListByCategory("611b45344fdde75b279725ec").then(response=>{
		//alert(response.data.length)
		this.setState({"tourSubCatgeoryList":response.data})
	})
	ProfileService.getSubCategoryListByCategory("611ba6cd14e97e569e41eaac").then(response=>{
		//alert(response.data.length)
		this.setState({"experienceSubCatgeoryList":response.data})
	})
	ProfileService.getSubCategoryListByCategory("61275c3ceaf59f69875e2abd").then(response=>{
		//alert(response.data.length)
		this.setState({"travelSubCatgeoryList":response.data})
	})
   ProfileService.getSubCategoryListByCategory("611b452a4fdde75b279725eb").then(response=>{
		//alert(response.data.length)
		this.setState({"attractionSubCatgeoryList":response.data})
	})
   ProfileService.getSubCategoryListByCategory("61275c55eaf59f69875e2abe").then(response=>{
		//alert(response.data.length)
		this.setState({"foodSubCatgeoryList":response.data})
	})

  }

  handleChangeSearchDes(value){
   // alert(value.value)
  if(value!=null){
     var id = value.value;
     //alert(id)
     window.location.href=`${APP_BASE_URL}/destination/${id}`;
      //window.location.reload();
  }
 }

 handleClickDescInSearch(e,id){
  window.location.href=`${APP_BASE_URL}/destination/${id}`;
 }

 handleClickDestination(e,id){
	this.props.history.push(`/destination/${id}`)
	window.location.reload();
  }

  handleClickStateDestination(e,id){
    this.props.history.push(`/statedestinations/${id}`)
    window.location.reload();
    }
  

  handleClickSubCategory(cat,subCat){
	window.location.href=`${APP_BASE_URL}/subactivities/${subCat}/${cat}`;
}

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>
   
    <section class="same-section invite_your_friendsB innerpage_banner destination-banner">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
		    		<h1 class="inner_banner_heding text-align">Destination</h1>
		    		
	    		</div>
	    	</div>
	    </section>

	    <section class="same-section dest-cat-img text-align padding_top padding_bottom">
	    	<div class="container">
		    	
		    <ul class="nav nav-tabs dest-cat-txt" id="myTab" role="tablist">
		  <li class="nav-item" role="presentation">
		    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Destinations</a>
		  </li>
		  <li class="nav-item" role="presentation">
		    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">All Categories</a>
		  </li>
		 
		</ul>
<div class="tab-content sub-cnt-tab" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
<div class="searchbar_side common_block search-box-cnt">
       <div class="inputsearch_perent">
       <Select 
						   className="basic-single"
						   classNamePrefix="Search"
						 isDisabled={this.state.isDisabled}
						 isLoading={this.state.isLoading}
						 isClearable={this.state.isClearable}
						 isRtl={this.state.isRtl}
						 isSearchable={this.state.isSearchable} 
						 onChange={this.handleChangeSearchDes}
						 options={this.state.destinationForSearch}
						 placeholder={<div>Search Destination</div>}
						 />
          <button type="button" class="search_icon_button"><img  loading="lazy" style={{"height":"25px"}} src="images/search-icon.svg" alt="" /></button>
       </div>
    </div>

  	<nav>
  <div class="nav nav-tabs" id="nav-tab" role="tablist">
    <a class="nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">All</a>
    {(this.state.stateListWithDesEI != null && this.state.stateListWithDesEI.length> 0 )? this.state.stateListWithDesEI.map(
                                           st =><>	
    <a onClick={(e)=> this.handleClickStateDestination(e, st.name.split(' ').join('_'))} class="nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">{st.name}</a> </> ):""}
  
  </div>
</nav>

<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
  	<div class="hot-txt">
  	<div class="hot-dest">
       <h2 class="blacbold_main_title">Hot Destinations</h2>
      
   </div>
       <div class="hotd_box">
       {(this.state.topDestinations != null && this.state.topDestinations.length> 0 )? this.state.topDestinations.map(
                                    influencer =>
          <div class="hdimage">
      <a href={`${APP_BASE_URL}/destination/${influencer.url}`}  ><img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(influencer.gridImage).imageUrl} alt={JSON.parse(influencer.gridImage).altText} /></a>
             <h2 class="bold_whitet">{influencer.name}</h2>
             
          </div>
          )
                                    
          : ""}
          
       </div>
   </div>

   {(this.state.stateListWithDesEI != null && this.state.stateListWithDesEI.length> 0 )? this.state.stateListWithDesEI.map(
                                           st =><>	
       <section class="common_block secpaddingT boedr-west">
       <div class="hot-dest">
	       <h2 class="blacbold_main_title">{st.name}</h2>
	      
	   </div>
	       <div class="listofwithout radious common_block">
	          <ul class="list_whthradi">
             {(st.des != null && st.des.length> 0 )? st.des.map(
                                           ds =><>
	             <li><a href="#" onClick={(e)=> this.handleClickDescInSearch(e, ds.url)}>{ds.name}</a></li> </>
					            	):""}
	                                            
	          </ul>
	       </div>                           
	    </section>
       </> ):""}
	    

    

	  


  </div>

</div>
  </div>
  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
  	
  	<div class="acccat_side_main common_block">
     <div class="alllcatelist_perent common_block">
              <h2 class="allact_title_side">
                 <img  loading="lazy" src="images/dest_tab02.svg" alt=""/>
                 <span class="titall_all_cate">Staycation</span>
                 <span><i class="fas fa-chevron-right"></i></span>
              </h2>
              <ul class="circletype_side">
              {(this.state.attractionSubCatgeoryList != null && this.state.attractionSubCatgeoryList.length> 0 )? this.state.attractionSubCatgeoryList.map(
                                           subC =><>
                 <li><a href="#" onClick={() => this.handleClickSubCategory('staycation',subC.name.split(' ').join('_').toLowerCase())}>{subC.name}</a></li>
                 </>
					 ):""}
              </ul>
           </div>
           <div class="alllcatelist_perent common_block">
              <h2 class="allact_title_side">
                 <img  loading="lazy" src="images/activities-icon.svg" alt=""/>
                 <span class="titall_all_cate">Activities</span>
                 <span><i class="fas fa-chevron-right"></i></span>
              </h2>
              <ul class="circletype_side">
              {(this.state.experienceSubCatgeoryList != null && this.state.experienceSubCatgeoryList.length> 0 )? this.state.experienceSubCatgeoryList.map(
                                           subC =><>
                 <li><a href="#" onClick={() => this.handleClickSubCategory('activities',subC.name.split(' ').join('_').toLowerCase())}>{subC.name}</a></li>
                 </>
					 ):""}
              </ul>
           </div>

           <div class="alllcatelist_perent common_block">
              <h2 class="allact_title_side">
                 <img  loading="lazy" src="images/experiences-icon.svg" alt=""/>
                 <span class="titall_all_cate">Tours</span>
                 <span><i class="fas fa-chevron-right"></i></span>
              </h2>
              <ul class="circletype_side">
              {(this.state.tourSubCatgeoryList != null && this.state.tourSubCatgeoryList.length> 0 )? this.state.tourSubCatgeoryList.map(
                                           subC =><>
                 <li><a href="#" onClick={() => this.handleClickSubCategory('tours',subC.name.split(' ').join('_').toLowerCase())}>{subC.name}</a></li>
                 </>
					 ):""}
              </ul>
           </div>

           <div class="alllcatelist_perent common_block">                              
              <h2 class="allact_title_side">
                 <img  loading="lazy" src="images/transportation-icon.svg" alt="" />
                 <span class="titall_all_cate">Transport & Travel Services</span>
                 <span><i class="fas fa-chevron-right"></i></span>
              </h2>
              <ul class="circletype_side">
              {(this.state.travelSubCatgeoryList != null && this.state.travelSubCatgeoryList.length> 0 )? this.state.travelSubCatgeoryList.map(
                                           subC =><>
                 <li><a href="#" onClick={() => this.handleClickSubCategory('Transport & Travel Services'.split(' ').join('_').toLowerCase(),subC.name.split(' ').join('_').toLowerCase())}>{subC.name}</a></li>
                 </>
					 ):""}
              </ul>
           </div>

           <div class="alllcatelist_perent common_block">
              <h2 class="allact_title_side">
                 <img  loading="lazy" src="images/dest_tab06.svg" alt=""/>
                 <span class="titall_all_cate">Best Food & Must Eats</span>
                 <span><i class="fas fa-chevron-right"></i></span>
              </h2>
              <ul class="circletype_side">
              {(this.state.foodSubCatgeoryList != null && this.state.foodSubCatgeoryList.length> 0 )? this.state.foodSubCatgeoryList.map(
                                           subC =><>
                 <li><a href="#" onClick={() => this.handleClickSubCategory('BEST FOOD & MUST EATS'.split(' ').join('_').toLowerCase(),subC.name.split(' ').join('_').toLowerCase())}>{subC.name}</a></li>
                 </>
					 ):""}
              </ul>
           </div>
        </div>
  </div>
 
</div>
</div>
	    </section>


	


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default AllDestination;
