import React from 'react'
import SocialLogin from 'react-social-login'
import { APP_BASE_URL} from '../../constants';

class SocialButtonMobile extends React.Component {
 
    render() {
        return (
            <a class=" logs_fb" style={{"color":"white !important"}}  onClick={this.props.triggerLogin} {...this.props} >
                                   <img  loading="lazy" src={APP_BASE_URL + '/images/facebook.png'} />Continue with Facebook
            </a>
           
        );
    }
}
 
export default SocialLogin(SocialButtonMobile);