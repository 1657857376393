import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import { APP_BASE_URL,IMAGE_BASE_URL} from '../../constants';
import Helmet from 'react-helmet';
import PaginationComponent from "react-reactstrap-pagination";
import Select from 'react-select'
import { Link } from 'react-router-dom';

class Destination extends React.Component {
  constructor(props) {
    super(props);
	this.handlePageChange = this.handlePageChange.bind(this);
	this.handleChangeAsc = this.handleChangeAsc.bind(this);
	this.handleClickActitvitySearch = this.handleClickActitvitySearch.bind(this);
	this.handleChangeSearch = this.handleChangeSearch.bind(this);
	this.handleChangeSubCategory = this.handleChangeSubCategory.bind(this);
	this.handleChangeSearchhead = this.handleChangeSearchhead.bind(this);
	this.handleClickSubCategory = this.handleClickSubCategory.bind(this);
    this.state = {
		isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
	destinationId : '',	
    modal: false,
	activePage:0,
	totalPages: null,
	itemsCountPerPage:6,
	pagesCount: null,
	totalItemsCount:null,
	destinationActivities :[],
	attractionActivities :[],
	tourActivities : [],
	experienceActivities :[],
	travelActivities : [],
	foodActivities :[],
	tourSubCategory:[],
    tourSearch:'',
    tourPriceRange:'',
    tourType:'',
    tourInstantConfirmation:false,
	expSubCategory:'',
expSearch:'',
expPriceRange:'',
expType:'',
expInstantConfirmation:false,
travelSubCategory:'',
travelSearch:'',
travelPriceRange:'',
travelType:'',
travelInstantConfirmation:false,
foodSubCategory:'',
foodSearch:'',
foodPriceRange:'',
foodType:'',
foodInstantConfirmation:false,
searchDestination : this.props.match.params.id,
searchCategory:'',
searchPriceRange:'',
searchInstantConfirmation:false,
searchType:'',
	attractionSubCategory:[],
    attractionSearch:'',
    attractionPriceRange:'',
    attractionType:'',
    attractionInstantConfirmation:false,
	selectedSubCatAsc:'',
	selectedSubCatTour:'',
	selectedSubCatExp:'',
	selectedSubCatTravel:'',
	selectedSubCatFood:'',
	checkedId:''
  };
  
}


  componentDidMount() {

	

	ProfileService.getAllActivities().then(response=>{
		console.log(response.data);
		
		

	});
	ProfileService.getDestinationByUrl(this.props.match.params.id).then(response=>{
		var destinationId = response.data.id;
		if(response.data.id==undefined){
			this.props.history.push(`/pageNotFound`)
			window.location.reload();
		}
          this.setState({
			  "destinationId":response.data.id,
			  "searchDestination":response.data.id,
			  "destinationName":response.data.name,
			  "coverImage":JSON.parse(response.data.coverImage).imageUrl,
			  "coverImageAltText":JSON.parse(response.data.coverImage).altText,
			  "state":response.data.state,
			  "highLight":JSON.parse(response.data.highLight),
			  "title":response.data.title,
			  "metaDesc":response.data.metaDesc
			})
			
			document.title = response.data.title;
			var link = document.createElement('meta'); 
			link.setAttribute('name', 'description');
			link.content = response.data.metaDesc;
			document.getElementsByTagName('head')[0].appendChild(link);

			ProfileService.activityByDestination(destinationId).then(response=>{
				this.setState({"alldestinationActivities":response.data})
				//this.setState({"destinationActivities":response.data})
				let popularActivities = [];
		for(let i = 0; i < response.data.length; i++){
			let value = response.data[i].url;
			let label = response.data[i].name;
			let skillS = { value : value ,label : label }
			//alert(value);
			//alert(label);
			popularActivities.push(skillS);
			
		  }
		  this.setState({"popularActivities":popularActivities})
			})

			ProfileService.searchOnActivities('',destinationId,'','','','','','',this.state.activePage, this.state.itemsCountPerPage).then(response=>{
				this.setState({"destinationActivities":response.data})
				const totalItemsCount = response.data.totalElements;
				this.setState({totalItemsCount: totalItemsCount})
				this.setState({itemsCountPerPage: this.state.itemsCountPerPage})
			})
			
			ProfileService.activitybydestandcategory(destinationId,"611b452a4fdde75b279725eb").then(response=>{
				this.setState({"attractionActivities":response.data})
			})
			
			ProfileService.activitybydestandcategory(destinationId,"611b45344fdde75b279725ec").then(response=>{
				this.setState({"tourActivities":response.data})
			})
			
			ProfileService.activitybydestandcategory(destinationId,"611ba6cd14e97e569e41eaac").then(response=>{
				this.setState({"experienceActivities":response.data})
			})
			
			ProfileService.activitybydestandcategory(destinationId,"61275c3ceaf59f69875e2abd").then(response=>{
				this.setState({"travelActivities":response.data})
			})
			
			ProfileService.activitybydestandcategory(destinationId,"61275c55eaf59f69875e2abe").then(response=>{
				this.setState({"foodActivities":response.data})
			})
			ProfileService.nearbydestbydestid(destinationId).then(response=>{
				this.setState({"nearestDestination":response.data})
			})
			
			this.setState({ description: EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.description))) })
			ProfileService.getStateById(response.data.state).then(response=>{
				this.setState({"state":response.data.name })
			})
			
			var featureguide = JSON.parse(response.data.featureguide);
			var guideList = [];
			for(var i=0;i<featureguide.length;i++){
				//alert(featureguide[i].value)
				ProfileService.getBlog(featureguide[i].value).then(response=>{
					
					if(i=0){
						guideList.push(response.data);
						this.setState({"guideList":guideList})
					}else{
						//var guideListState = this.state.guideList;
						guideList.push(response.data);
						this.setState({"guideList":guideList})
					}
					
				})
			}
			this.setState({"travelPlanning":JSON.parse(response.data.travelPlanning)})
             
			var mapurl = "https://maps.google.com/maps?q="+response.data.lat+","+response.data.lon+"&hl=en&z=14&output=embed";
			//alert(mapurl);
			this.setState({"mapurl":mapurl})

			ProfileService.getWeatherReport(response.data.lat,response.data.lon).then(response=>{
                   //alert(response.data.daily.length);
				   var weatherReportList = [];
				   for(var i=0;i<response.data.daily.length;i++){
					   var fulldate = new Date(response.data.daily[i].dt*1000);
					   var dateN = fulldate.getDate();
					   if(dateN<10){
						dateN = '0'+dateN;
					   }
					   var month = fulldate.getMonth()+1;
					   if(month==1){
						month = 'JAN' ;
					   }else if(month==2){
						month = 'FEB' ;
					   }else if(month==3){
						month = 'MAR' ;
					   }else if(month==4){
						month = 'APR' ;
					   }else if(month==5){
						month = 'MAY' ;
					   }else if(month==6){
						month = 'JUN' ;
					   }else if(month==7){
						month = 'JUL' ;
					   }else if(month==8){
						month = 'AUG' ;
					   }else if(month==9){
						month = 'SEP' ;
					   }else if(month==10){
						month = 'OCT' ;
					   }else if(month==11){
						month = 'NOV' ;
					   }else if(month==12){
						month = 'DEC' ;
					   }  
					   var date = dateN+" "+month;

					   var day = fulldate.getDay();
					   if(day==1){
						day = 'MON' ;
					   }else if(day==2){
						day = 'TUE' ;
					   }else if(day==3){
						day = 'WED' ;
					   }else if(day==4){
						day = 'THU' ;
					   }else if(day==5){
						day = 'FRI' ;
					   }else if(day==6){
						day = 'SAT' ;
					   }else if(day==7){
						day = 'SUN' ;
					   }else if(day==0){
						day = 'SUN' ;
					   }

					   var icon = response.data.daily[i].weather[0].icon;
					   var imageurl = 'https://openweathermap.org/img/wn/'+icon+'@2x.png';
					
					   var maxTemp = response.data.daily[i].temp.max;
					   var minTemp = response.data.daily[i].temp.min;
					  

                       let data = {
						date :date,
						day : day,
						imageurl : imageurl,
						maxTemp : maxTemp,
						minTemp : minTemp,
						main : response.data.daily[i].weather[0].main,
						desc : response.data.daily[i].weather[0].desc
					   }
					   //alert(JSON.stringify(data));
					   if(i==0){
						this.setState({"wrtDate":date});
						this.setState({"wrtDay":day});
						this.setState({"wrtImgurl":imageurl});
						this.setState({"wrtmax":maxTemp});
						this.setState({"wrtmin":minTemp});
					   }else{
						weatherReportList.push(data);
					   }
					   
				   }
				   this.setState({"weatherReportList":weatherReportList});
			})

	})
	
	ProfileService.getCategoryList().then(response=>{
		this.setState({"activitycategory":response.data})
	})
	ProfileService.getSubCategoryListByCategory("611b452a4fdde75b279725eb").then(response=>{
		//alert(response.data.length)
		this.setState({"attractionSubCatgeoryList":response.data})
		this.setState({"attractionSubCategory":new Array(response.data.linght).fill(false)})
	})
	ProfileService.getSubCategoryListByCategory("611b45344fdde75b279725ec").then(response=>{
		//alert(response.data.length)
		this.setState({"tourSubCatgeoryList":response.data})
	})
	ProfileService.getSubCategoryListByCategory("611ba6cd14e97e569e41eaac").then(response=>{
		//alert(response.data.length)
		this.setState({"experienceSubCatgeoryList":response.data})
	})
	ProfileService.getSubCategoryListByCategory("61275c3ceaf59f69875e2abd").then(response=>{
		//alert(response.data.length)
		this.setState({"travelSubCatgeoryList":response.data})
	})
	ProfileService.getSubCategoryListByCategory("61275c55eaf59f69875e2abe").then(response=>{
		//alert(response.data.length)
		this.setState({"foodSubCatgeoryList":response.data})
	})


	
	ProfileService.getAllDestinations().then(response=>{
		
		this.setState({"destinationList":response.data})
	})
  }

  handleClickDestination(e,id){
	this.props.history.push(`/destination/${id}`)
	window.location.reload();
  }

  handleClickActitvity(e,catgeory, id){
	this.props.history.push(`/${catgeory}/${id}`)
	window.location.reload();
  }

  handleChangeSearchhead(value){
	if(value!=null){
		alert(value.value);
		var id = value.value;
		ProfileService.getActivityByUrl(id).then(response=>{
			var activityId = response.data.id;
			if(response.data.activityCategory_id=='611b452a4fdde75b279725eb'){
				window.location.href=`${APP_BASE_URL}/staycation/${id}`;
			}else if(response.data.activityCategory_id=='611b45344fdde75b279725ec'){
				window.location.href=`${APP_BASE_URL}/tours/${id}`;
			}else if(response.data.activityCategory_id=='611ba6cd14e97e569e41eaac'){
				window.location.href=`${APP_BASE_URL}/activities/${id}`;
			}else if(response.data.activityCategory_id=='61275c3ceaf59f69875e2abd'){
				window.location.href=`${APP_BASE_URL}/transport/${id}`;
			}else if(response.data.activityCategory_id=='61275c55eaf59f69875e2abe'){
				window.location.href=`${APP_BASE_URL}/food/${id}`;
			}else {
				window.location.href=`${APP_BASE_URL}/activity/${id}`;
			}
	    })
		
	}
}

  handleClickActitvitySearch(e,id){
	ProfileService.getActivityByUrl(id).then(response=>{
		var activityId = response.data.id;
		if(response.data.activityCategory_id=='611b452a4fdde75b279725eb'){
			window.location.href=`${APP_BASE_URL}/staycation/${id}`;
		}else if(response.data.activityCategory_id=='611b45344fdde75b279725ec'){
			window.location.href=`${APP_BASE_URL}/tours/${id}`;
		}else if(response.data.activityCategory_id=='611ba6cd14e97e569e41eaac'){
			window.location.href=`${APP_BASE_URL}/activities/${id}`;
		}else if(response.data.activityCategory_id=='61275c3ceaf59f69875e2abd'){
			window.location.href=`${APP_BASE_URL}/transport/${id}`;
		}else if(response.data.activityCategory_id=='61275c55eaf59f69875e2abe'){
			window.location.href=`${APP_BASE_URL}/food/${id}`;
		}else {
			window.location.href=`${APP_BASE_URL}/activity/${id}`;
		}
	})
  }
 

  handlePageChange(pageNumber) {
	console.log(this.state.totalItemsCount)

	this.setState({activePage: pageNumber-1})
	ProfileService.searchOnActivities('',this.state.destinationId,this.state.searchCategory,'','',this.state.searchPriceRange,this.state.searchType,this.state.searchInstantConfirmation,pageNumber-1, this.state.itemsCountPerPage).then(response=>{
		this.setState({"destinationActivities":response.data})
		const totalItemsCount = response.data.totalElements;
		this.setState({totalItemsCount: totalItemsCount})
		this.setState({itemsCountPerPage: this.state.itemsCountPerPage})
	})
	
	}


  handleChangeSearch(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if(event.target.type === 'checkbox'){
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      const name = event.target.name;
       this.setState({
      [name]: value
    });
    }
    if(event.target.name === 'searchCategory'){
		//alert(event.target.value)
		ProfileService.searchOnActivities('',this.state.destinationId,event.target.value,'','',this.state.searchPriceRange,this.state.searchType,this.state.searchInstantConfirmation,this.state.activePage, this.state.itemsCountPerPage).then(response=>{
			//alert(response.data.length)
		this.setState({"destinationActivities":response.data})
		const totalItemsCount = response.data.totalElements;
		this.setState({totalItemsCount: totalItemsCount})
		this.setState({itemsCountPerPage: this.state.itemsCountPerPage})
		})
	}else if(event.target.name === 'searchDestination'){
		ProfileService.searchOnActivities('',event.target.value,this.state.searchCategory,'','',this.state.searchPriceRange,this.state.searchType,this.state.searchInstantConfirmation,this.state.activePage, this.state.itemsCountPerPage).then(response=>{
			this.setState({"destinationActivities":response.data})
		const totalItemsCount = response.data.totalElements;
		this.setState({totalItemsCount: totalItemsCount})
		this.setState({itemsCountPerPage: this.state.itemsCountPerPage})
		})
	}else if(event.target.name === 'searchPriceRange'){
		ProfileService.searchOnActivities('',this.state.destinationId,this.state.searchCategory,'','',event.target.value,this.state.searchType,this.state.searchInstantConfirmation,this.state.activePage, this.state.itemsCountPerPage).then(response=>{
			this.setState({"destinationActivities":response.data})
		const totalItemsCount = response.data.totalElements;
		this.setState({totalItemsCount: totalItemsCount})
		this.setState({itemsCountPerPage: this.state.itemsCountPerPage})
		})
	}else if(event.target.name === 'searchType'){
		ProfileService.searchOnActivities('',this.state.destinationId,this.state.searchCategory,'','',this.state.searchPriceRange,event.target.value,this.state.searchInstantConfirmation,this.state.activePage, this.state.itemsCountPerPage).then(response=>{
			this.setState({"destinationActivities":response.data})
		const totalItemsCount = response.data.totalElements;
		this.setState({totalItemsCount: totalItemsCount})
		this.setState({itemsCountPerPage: this.state.itemsCountPerPage})
		})
	}else if(event.target.name === 'searchInstantConfirmation'){
        this.setState({"searchInstantConfirmation":!this.state.searchInstantConfirmation})
		ProfileService.searchOnActivities('',this.state.destinationId,this.state.searchCategory,'','',this.state.searchPriceRange,this.state.searchType,!this.state.searchInstantConfirmation,this.state.activePage, this.state.itemsCountPerPage).then(response=>{
			this.setState({"destinationActivities":response.data})
		const totalItemsCount = response.data.totalElements;
		this.setState({totalItemsCount: totalItemsCount})
		this.setState({itemsCountPerPage: this.state.itemsCountPerPage})
		})
	}else{
		ProfileService.searchOnActivities('',this.state.destinationId,this.state.searchCategory,'','',this.state.searchPriceRange,this.state.searchType,this.state.searchInstantConfirmation,this.state.activePage, this.state.itemsCountPerPage).then(response=>{
			this.setState({"destinationActivities":response.data})
		const totalItemsCount = response.data.totalElements;
		this.setState({totalItemsCount: totalItemsCount})
		this.setState({itemsCountPerPage: this.state.itemsCountPerPage})
		})
	}
	
  }
  
  handleChangeAsc(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if(event.target.type === 'checkbox'){
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      const name = event.target.name;
       this.setState({
      [name]: value
    });
    }
    if(event.target.name === 'attractionSearch'){
		ProfileService.searchOnActivities('',this.state.destinationId,"611b452a4fdde75b279725eb",event.target.value,'',this.state.attractionPriceRange,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"attractionActivities":response.data})
		})
	}else if(event.target.name === 'attractionPriceRange'){
		ProfileService.searchOnActivities('',this.state.destinationId,"611b452a4fdde75b279725eb",this.state.attractionSearch,'',event.target.value,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"attractionActivities":response.data})
		})
	}else if(event.target.name === 'attractionType'){
		ProfileService.searchOnActivities('',this.state.destinationId,"611b452a4fdde75b279725eb",this.state.attractionSearch,'',this.state.attractionPriceRange,event.target.value,this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"attractionActivities":response.data})
		})
	}else if(event.target.name === 'attractionInstantConfirmation'){
        this.setState({"attractionInstantConfirmation":!this.state.attractionInstantConfirmation})
		ProfileService.searchOnActivities('',this.state.destinationId,"611b452a4fdde75b279725eb",this.state.attractionSearch,'',this.state.attractionPriceRange,this.state.attractionType,!this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"attractionActivities":response.data})
		})
	}else{
		ProfileService.searchOnActivities('',this.state.destinationId,"611b452a4fdde75b279725eb",this.state.attractionSearch,'',this.state.attractionPriceRange,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"attractionActivities":response.data})
		})
	}
	
  }

  handleChangeTour(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if(event.target.type === 'checkbox'){
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      const name = event.target.name;
       this.setState({
      [name]: value
    });
    }
    if(event.target.name === 'tourSearch'){
		ProfileService.searchOnActivities('',this.state.destinationId,"611b45344fdde75b279725ec",event.target.value,'',this.state.tourPriceRange,this.state.tourType,this.state.tourInstantConfirmation).then(response=>{
			this.setState({"tourActivities":response.data})
		})
	}else if(event.target.name === 'tourPriceRange'){
		ProfileService.searchOnActivities('',this.state.destinationId,"611b45344fdde75b279725ec",this.state.tourSearch,'',event.target.value,this.state.tourType,this.state.tourInstantConfirmation).then(response=>{
			this.setState({"tourActivities":response.data})
		})
	}else if(event.target.name === 'tourType'){
		ProfileService.searchOnActivities('',this.state.destinationId,"611b45344fdde75b279725ec",this.state.tourSearch,'',this.state.tourPriceRange,event.target.value,this.state.tourInstantConfirmation).then(response=>{
			this.setState({"tourActivities":response.data})
		})
	}else if(event.target.name === 'tourInstantConfirmation'){
		this.setState({"tourInstantConfirmation":!this.state.tourInstantConfirmation})
		ProfileService.searchOnActivities('',this.state.destinationId,"611b45344fdde75b279725ec",this.state.tourSearch,'',this.state.tourPriceRange,this.state.tourType,!this.state.tourInstantConfirmation).then(response=>{
			this.setState({"tourActivities":response.data})
		})
	}else{
		ProfileService.searchOnActivities('',this.state.destinationId,"611b45344fdde75b279725ec",this.state.tourSearch,'',this.state.tourPriceRange,this.state.tourType,this.state.tourInstantConfirmation).then(response=>{
			this.setState({"tourActivities":response.data})
		})
	}
	
  }


  handleChangeExp(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if(event.target.type === 'checkbox'){
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      const name = event.target.name;
       this.setState({
      [name]: value
    });
    }
    if(event.target.name === 'expSearch'){
		ProfileService.searchOnActivities('',this.state.destinationId,"611ba6cd14e97e569e41eaac",event.target.value,'',this.state.expPriceRange,this.state.expType,this.state.expInstantConfirmation).then(response=>{
			this.setState({"experienceActivities":response.data})
		})
	}else if(event.target.name === 'expPriceRange'){
		ProfileService.searchOnActivities('',this.state.destinationId,"611ba6cd14e97e569e41eaac",this.state.expSearch,'',event.target.value,this.state.expType,this.state.expInstantConfirmation).then(response=>{
			this.setState({"experienceActivities":response.data})
		})
	}else if(event.target.name === 'expType'){
		ProfileService.searchOnActivities('',this.state.destinationId,"611ba6cd14e97e569e41eaac",this.state.expSearch,'',this.state.expPriceRange,event.target.value,this.state.expInstantConfirmation).then(response=>{
			this.setState({"experienceActivities":response.data})
		})
	}else if(event.target.name === 'expInstantConfirmation'){
        this.setState({"expInstantConfirmation":!this.state.expInstantConfirmation})
		ProfileService.searchOnActivities('',this.state.destinationId,"611ba6cd14e97e569e41eaac",this.state.expSearch,'',this.state.expPriceRange,this.state.expType,!this.state.expInstantConfirmation).then(response=>{
			this.setState({"experienceActivities":response.data})
		})
	}else{
		ProfileService.searchOnActivities('',this.state.destinationId,"611ba6cd14e97e569e41eaac",this.state.expSearch,'',this.state.expPriceRange,this.state.expType,this.state.expInstantConfirmation).then(response=>{
			this.setState({"experienceActivities":response.data})
		})
	}
	
  }


  handleChangeTravel(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if(event.target.type === 'checkbox'){
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      const name = event.target.name;
       this.setState({
      [name]: value
    });
    }
    if(event.target.name === 'travelSearch'){
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c3ceaf59f69875e2abd",event.target.value,'',this.state.travelPriceRange,this.state.travelType,this.state.travelInstantConfirmation).then(response=>{
			this.setState({"travelActivities":response.data})
		})
	}else if(event.target.name === 'travelPriceRange'){
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c3ceaf59f69875e2abd",this.state.travelSearch,'',event.target.value,this.state.travelType,this.state.travelInstantConfirmation).then(response=>{
			this.setState({"travelActivities":response.data})
		})
	}else if(event.target.name === 'travelType'){
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c3ceaf59f69875e2abd",this.state.travelSearch,'',this.state.travelPriceRange,event.target.value,this.state.travelInstantConfirmation).then(response=>{
			this.setState({"travelActivities":response.data})
		})
	}else if(event.target.name === 'travelInstantConfirmation'){
        this.setState({"travelInstantConfirmation":!this.state.travelInstantConfirmation})
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c3ceaf59f69875e2abd",this.state.travelSearch,'',this.state.travelPriceRange,this.state.travelType,!this.state.travelInstantConfirmation).then(response=>{
			this.setState({"travelActivities":response.data})
		})
	}else{
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c3ceaf59f69875e2abd",this.state.travelSearch,'',this.state.travelPriceRange,this.state.travelType,this.state.travelInstantConfirmation).then(response=>{
			this.setState({"travelActivities":response.data})
		})
	}
	
  }

  handleChangeFood(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
    if(event.target.type === 'checkbox'){
      const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
      const name = event.target.name;
       this.setState({
      [name]: value
    });
    }
    if(event.target.name === 'foodSearch'){
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c55eaf59f69875e2abe",event.target.value,'',this.state.foodPriceRange,this.state.foodType,this.state.foodInstantConfirmation).then(response=>{
			this.setState({"foodActivities":response.data})
		})
	}else if(event.target.name === 'foodPriceRange'){
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c55eaf59f69875e2abe",this.state.foodSearch,'',event.target.value,this.state.foodType,this.state.foodInstantConfirmation).then(response=>{
			this.setState({"foodActivities":response.data})
		})
	}else if(event.target.name === 'foodType'){
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c55eaf59f69875e2abe",this.state.foodSearch,'',this.state.foodPriceRange,event.target.value,this.state.foodInstantConfirmation).then(response=>{
			this.setState({"foodActivities":response.data})
		})
	}else if(event.target.name === 'foodInstantConfirmation'){
		this.setState({"foodInstantConfirmation":!this.state.foodInstantConfirmation})
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c55eaf59f69875e2abe",this.state.foodSearch,'',this.state.foodPriceRange,this.state.foodType,!this.state.foodInstantConfirmation).then(response=>{
			this.setState({"foodActivities":response.data})
		})
	}else{
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c55eaf59f69875e2abe",this.state.foodSearch,'',this.state.foodPriceRange,this.state.foodType,this.state.foodInstantConfirmation).then(response=>{
			this.setState({"foodActivities":response.data})
		})
	}
	
  }
  handleClickSubCategory(cat,subCat){
	window.location.href=`${APP_BASE_URL}/subactivities/${subCat}/${cat}`;
}

handleDestCategory(dest,destId,catId){
	localStorage.setItem('destId', destId);
	localStorage.setItem('catId', catId);
	window.location.href=`${APP_BASE_URL}/destcatactivities/${dest}/tours`;
}
handleDestAllActivities(dest,destId){
	window.location.href=`${APP_BASE_URL}/destallactivities/${dest}/${destId}`;
}

  handleChangeSubCategory(id,subcat,key){
	 this.setState({"checkedId":id});
	 
	  if(key=='asc'){
		this.setState({"selectedSubCatAsc":subcat});
		ProfileService.searchOnActivities('',this.state.destinationId,"611b452a4fdde75b279725eb",this.state.attractionSearch,subcat,this.state.attractionPriceRange,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"attractionActivities":response.data})

			var cbarray = document.getElementsByName("ascSubC");
			for(var i = 0; i < cbarray.length; i++){
				cbarray[i].checked = false;
			}
			  if(document.getElementById(id).checked){
				document.getElementById(id).checked = false;
			  }else{
				document.getElementById(id).checked = true;
			  }
			 
		  })
	  }else if(key=='tour'){
		this.setState({"selectedSubCatTour":subcat});
		ProfileService.searchOnActivities('',this.state.destinationId,"611b45344fdde75b279725ec",this.state.attractionSearch,subcat,this.state.attractionPriceRange,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"tourActivities":response.data})
			var cbarray = document.getElementsByName("tourSubC");
			for(var i = 0; i < cbarray.length; i++){
				cbarray[i].checked = false;
			}
			  if(document.getElementById(id).checked){
				document.getElementById(id).checked = false;
			  }else{
				document.getElementById(id).checked = true;
			  }
		  })
	  }else if(key=='exp'){
		this.setState({"selectedSubCatExp":subcat});
		ProfileService.searchOnActivities('',this.state.destinationId,"611ba6cd14e97e569e41eaac",this.state.attractionSearch,subcat,this.state.attractionPriceRange,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"experienceActivities":response.data})
			var cbarray = document.getElementsByName("expSubC");
			for(var i = 0; i < cbarray.length; i++){
				cbarray[i].checked = false;
			}
			  if(document.getElementById(id).checked){
				document.getElementById(id).checked = false;
			  }else{
				document.getElementById(id).checked = true;
			  }
		  })
	  }else if(key=='travel'){
		this.setState({"selectedSubCatTravel":subcat});
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c3ceaf59f69875e2abd",this.state.attractionSearch,subcat,this.state.attractionPriceRange,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"travelActivities":response.data})
			var cbarray = document.getElementsByName("travelSubC");
			for(var i = 0; i < cbarray.length; i++){
				cbarray[i].checked = false;
			}
			  if(document.getElementById(id).checked){
				document.getElementById(id).checked = false;
			  }else{
				document.getElementById(id).checked = true;
			  }
		  })
	  }else if(key=='food'){
		this.setState({"selectedSubCatFood":subcat});
		ProfileService.searchOnActivities('',this.state.destinationId,"61275c55eaf59f69875e2abe",this.state.attractionSearch,subcat,this.state.attractionPriceRange,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			this.setState({"foodActivities":response.data})
			var cbarray = document.getElementsByName("foodSubC");
			for(var i = 0; i < cbarray.length; i++){
				cbarray[i].checked = false;
			}
			  if(document.getElementById(id).checked){
				document.getElementById(id).checked = false;
			  }else{
				document.getElementById(id).checked = true;
			  }
		  })
	  }
    // this.getFilteredList(id,subcat,key);
	 
  }

  getFilteredList(id,subcat,key){
	if(key=='asc'){
		var exist = false;
		var selected = this.state.selectedSubCatAsc;
			for(var i=0;i<selected.length;i++){
				if(subcat==selected[i]){
				 exist = true;
				}
			}
		if(!exist){
		   selected.push(subcat);
		   this.setState({"selectedSubCatAsc":selected});
		   let sk = "";
		   for(let i = 0; i < selected.length; i++){
				 let value = selected[i];
				 sk=sk+value+",";
			  }
			 sk = sk.slice(0, -1);
		   ProfileService.searchOnActivities('',this.state.destinationId,"611b452a4fdde75b279725eb",this.state.attractionSearch,sk,this.state.attractionPriceRange,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			 this.setState({"attractionActivities":response.data})
			 if(document.getElementById(id).checked){
				document.getElementById(id).checked = false;
			  }else{
				document.getElementById(id).checked = true;
			  }
		   })
		   
		}else{
		   var removedSelected = [];
		   for(var i=0;i<selected.length;i++){
			 if(subcat!=selected[i]){
				 removedSelected.push(selected[i]);
			 }
			this.setState({"selectedSubCatAsc":removedSelected});
			let sk = "";
		   for(let i = 0; i < removedSelected.length; i++){
				 let value = removedSelected[i];
				 sk=sk+value+",";
			  }
			 sk = sk.slice(0, -1);
		   ProfileService.searchOnActivities('',this.state.destinationId,"611b452a4fdde75b279725eb",this.state.attractionSearch,sk,this.state.attractionPriceRange,this.state.attractionType,this.state.attractionInstantConfirmation).then(response=>{
			 this.setState({"attractionActivities":response.data})
			 if(document.getElementById(id).checked){
				document.getElementById(id).checked = false;
			  }else{
				document.getElementById(id).checked = true;
			  }
		   })
		 }
		}
   }
  }

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
	<Helmet>
		
    {/* <title>{this.state.title}</title>
    <meta name='description' content={this.state.metaDesc} /> */}
    </Helmet>
    <div class="main_all">
    <Header></Header>
    
	<section class="bredcrumb_custom same-section padding_top bread-shimla destinationbreadcrum">
	<div class="container">
		<ul class="custbread">
		  <li><a href="/">Home</a></li>
		  <li><i class="fas fa-chevron-right"></i></li>
		  <li><a href="/destination">Destination</a></li>
		  <li><i class="fas fa-chevron-right"></i></li>
		  <li class="active">{this.state.destinationName}</li>
		</ul>
	   </div>
   </section>

	<section class="same-section desination_detailbanner inner_detail--banner">

        	<div class="inner_detail--banner--img same-section ">
			
        		<img loading="lazy" class="destination_banner_image" src={IMAGE_BASE_URL+``+this.state.coverImage} alt={this.state.coverImageAltText} />
        		<div class="title_or_desc">
        			<h1 class="main_banner_title">{this.state.destinationName}</h1>
        		</div>
                <div class="wather_side mobile_weather">
					<div class="crunt_temp">
						<div class="mobile_weather_active"><p style={{"color":"white"}}>
						<img  loading="lazy" src={this.state.wrtImgurl} alt="Icon-Image"/>
						{this.state.wrtmax}<sup>o</sup>C 
						&nbsp;
						{this.state.wrtmin}<sup>o</sup>C
						<span class="downarrow_day"><i class="fas fa-angle-down"></i></span></p>
						<div class="mobile_weather_list">
							<div class="mobile_weather_list_inner">
						{(this.state.weatherReportList != null && this.state.weatherReportList.length> 0 )? this.state.weatherReportList.map(
                                    hl =><>
							<div class="mobile_weather_list_item">
							    
								<span><img loading="lazy"  src={hl.imageurl} title={hl.desc} alt={hl.main}/> </span>
								<p>
								<span>	{hl.date} &nbsp;{hl.day} </span>
									<span>	&nbsp;	{hl.maxTemp}<sup>o</sup> &nbsp;{hl.minTemp}<sup>o</sup></span>
								</p>
								
							</div>
							</>
						   ):""}	
                               </div>
						</div>
						
						</div>
						
						
					</div>
					
						
					
				</div>


        		{/* <div class="dontmiss_desc_perent">
                        <div class="dontmiss_desc">
                           <div class="dontmiss_titles">Don’t miss :</div>
						   {(this.state.highLight != null && this.state.highLight.length> 0 )? this.state.highLight.map(
                                    hl =><>
                           <div class="dontmiss_titles">{hl.valueValue}</div>
                           </>
						   ):""}
                        </div>
                        <h3 class="aboutdetail_title">About {this.state.destinationName}</h3>
                     </div> */}
					 
			<div class="searchbar_side common_block padding_leftang_right top_in_margin search_bar_destination">
               <div class="inputsearch_perent">
			   <Select 
						   className="basic-single"
						   classNamePrefix="Search"
						 isDisabled={this.state.isDisabled}
						 isLoading={this.state.isLoading}
						 isClearable={this.state.isClearable}
						 isRtl={this.state.isRtl}
						 isSearchable={this.state.isSearchable} 
						 onChange={this.handleChangeSearchhead}
						 options={this.state.popularActivities}
						 placeholder={<div>Search activities in {this.state.destinationName}</div>}
						 />
                
                  <button type="button" class="search_icon_button"><img  loading="lazy" src={APP_BASE_URL + '/images/search-icon01.svg'} alt="search"/></button>
                 
               </div>
            </div>

			<section class="aet_section destination_aet_section common_block secpaddingT padding_leftang_right activity-sec">
			  <div class="aet_flex ">
				 <div class="aet_box">
					   <img  loading="lazy" src={APP_BASE_URL + '/images/activities-icon.svg'} alt="activity" />
					  <a href="#" onClick={() => this.handleDestCategory(this.state.destinationName,this.state.destinationId,'611ba6cd14e97e569e41eaac')}> <p>Activities  <i class="fas fa-chevron-right"></i></p> </a>
					<a href="#"  onClick={() => this.handleDestCategory(this.state.destinationName,this.state.destinationId,'611ba6cd14e97e569e41eaac')} class="onlink"></a>
				 </div>

				 <div class="aet_box">
					   <img  loading="lazy" src={APP_BASE_URL + '/images/experiences-icon.svg'} alt="experience" />
					 <a href="#" onClick={() => this.handleDestCategory(this.state.destinationName,this.state.destinationId,'611b45344fdde75b279725ec')}>  <p>Tours <i class="fas fa-chevron-right"></i></p> </a>
					<a href="#" onClick={() => this.handleDestCategory(this.state.destinationName,this.state.destinationId,'611b45344fdde75b279725ec')} class="onlink"></a>
				 </div>

				 <div class="aet_box">
					   <img  loading="lazy" src={APP_BASE_URL + '/images/transportation-icon.svg'} alt="transport" />
					 <a href="#" onClick={() => this.handleDestCategory(this.state.destinationName,this.state.destinationId,'61275c3ceaf59f69875e2abd')}>   <p>Transport <i class="fas fa-chevron-right"></i></p> </a>
					<a href="#"  onClick={() => this.handleDestCategory(this.state.destinationName,this.state.destinationId,'61275c3ceaf59f69875e2abd')} class="onlink"></a>
				 </div>
				 <div class="aet_box">
					 <button class="aet_button"  onClick={() => this.handleDestAllActivities(this.state.destinationName,this.state.destinationId)}>Browse All Categories</button>
				 </div>
			  </div>
		   </section>

			
			
        	</div>
        </section>
     
		<section class="same-section taside_destion bg-gray">
        	<div class="container">
        		<div class="tab_menulist">
        			<ul class="nav nav-pills">
					    <li>
					    	<a data-toggle="pill" href="#home" class="active">
					    		<p><img  loading="lazy" src={APP_BASE_URL + '/images/dest_tab01.svg'} alt="Icon-Image"/></p>
					    		<h6>Home page</h6>
					    	</a>
					    </li>
					    <li>
					    	<a data-toggle="pill" href="#menu1">
					    		<p><img  loading="lazy" src={APP_BASE_URL + '/images/dest_tab02.svg'} alt="Icon-Image"/></p>
					    		<h6>Staycation</h6>
					    	</a>
					    </li>
					    <li>
					    	<a data-toggle="pill" href="#menu2">						    	
					    		<p><img  loading="lazy" src={APP_BASE_URL + '/images/dest_tab03.svg'} alt="Icon-Image"/></p>
					    		<h6>Tours</h6>
					    	</a>
					    </li>
					    <li>
					    	<a data-toggle="pill" href="#menu3">						    						    	
					    		<p><img  loading="lazy" src={APP_BASE_URL + '/images/dest_tab04.svg'} alt="Icon-Image"/></p>
					    		<h6>Activities</h6>
					    	</a>
					    </li>
					    <li>
					    	<a data-toggle="pill" href="#menu4">						    						    	
					    		<p><img  loading="lazy" src={APP_BASE_URL + '/images/dest_tab05.svg'} alt="Icon-Image"/></p>
					    		<h6>Transport & Travel Services</h6>
					    	</a>
					    </li>
					    <li>
					    	<a data-toggle="pill" href="#menu5">						    						    	
					    		<p><img  loading="lazy" src={APP_BASE_URL + '/images/dest_tab06.svg'} alt="Icon-Image"/></p>
					    		<h6>Best Food & Must Eats</h6>
					    	</a>
					    </li>
					</ul>
        		</div>
        	</div>
        </section>

<div class="tabside_desc_perent same-section">
<div class="tab-content">
<div id="home" class="tab-pane fade in active">

<section class="bredcrumb_custom same-section padding_top bg-gray bread-shimla destination_web_bread">
	<div class="container">
		<ul class="custbread">
		  <li><a href="/">Home</a></li>
		  <li><i class="fas fa-chevron-right"></i></li>
		  <li><a href="/destination">Destination</a></li>
		  <li><i class="fas fa-chevron-right"></i></li>
		  <li class="active">{this.state.destinationName}</li>
		</ul>
	   </div>
</section>

<section class="same-section bg-gray padding_top whatwe_love_dontmiss_temp">
	<div class="container">						      
		<h2 class="section_main_title align-left">What We love about {this.state.destinationName}</h2>
		<p class="section_main_subtitle align-left"><Editor toolbarHidden={true} editorState={this.state.description} readOnly={true} /></p>
		<div class="row">
			<div class="col-md-8">
				<div class="dontmiss_sec">
					<ul>
						<li><strong>Don’t miss:</strong></li>
						{(this.state.highLight != null && this.state.highLight.length> 0 )? this.state.highLight.map(
                                    hl =><>
									<li><a href="#">{hl.valueValue}</a></li>
                         
                           </>
						   ):""}
						
						
					</ul>
				</div>
				<div class="viewmore_custom">
					<a href="#treavel_viewmore" class="custom_more">View More <i class="fas fa-angle-down"></i></a>
				</div>
			</div>
			<div class="col-md-4">
				<div class="wather_side">
					<div class="crunt_temp">
						<div class="temp_img">
						
							<img  loading="lazy" src={this.state.wrtImgurl} alt="Icon-Image"/>
						</div>
						<div class="temp_degree">
							<h6>{this.state.wrtmax}<sup>o</sup>C</h6>
							<p>{this.state.wrtDay}, {this.state.wrtDate} (Local Time)</p>
						</div>
					</div>
					<div class="waithr_next_dayes">
						<select class="drop_next_days">
							<option>View weather conditions for the next 7 days</option>
						</select>
						<span class="downarrow_day"><i class="fas fa-angle-down"></i></span>
						<div class="wather_ohoverlist">
						{(this.state.weatherReportList != null && this.state.weatherReportList.length> 0 )? this.state.weatherReportList.map(
                                    hl =><>
							<div class="wather_ohoverlist_flex">
								<div class="weatherflex_bx">
									<p>{hl.date} &nbsp; &nbsp;<span>{hl.day}</span></p>
								</div>
								<div class="weatherflex_bx cloud_rain_image">
									<h5><img  loading="lazy" src={hl.imageurl} title={hl.desc} alt={hl.main}/></h5>
								</div>
								<div class="weatherflex_bx">
									<p>{hl.maxTemp}<sup>o</sup> &nbsp;  &nbsp;<span>{hl.minTemp}<sup>o</sup></span></p>
								</div>
							</div>
							</>
						   ):""}	

						</div>
					</div>
				</div>
			</div>
		</div>
	  </div>
  </section>


<section class="same-section popular_activities padding_top padding_bottom bg-gray">
	<div class="container">
		<h2 class="section_main_title align-left">Popular Activities in {this.state.destinationName}</h2>
		<div class="popularacti_bx_perent">
			<div class="row">
				<div id="renok_carousel_02" class="owl-carousel common_col--width common_arrow--slider">
				{(this.state.alldestinationActivities != null && this.state.alldestinationActivities.length> 0 )? this.state.alldestinationActivities.map(
                                    influencer =>
					<div class="item">
						<div class="col-md-3">
							<div class="popular_activities_box">
								 
							<div class="imagesof_box">
									<img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(influencer.gridImage).imageUrl} alt={JSON.parse(influencer.gridImage).altText}/>
								 </div>
								 <div class="popular_activities_content">
								 <div class="discount_div">
									 		
										 {Math.ceil(influencer.pk.discountPercantage)!==0 && <>
											<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />
										<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}
										
										</div>
									<h3 class="palcebox_title"> {influencer.name}</h3>
									<div class="box_review_side">
									   <div class="review_side_cell">
										  <i class="fas fa-star"></i>
										  <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												
									   </div>
									   <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img  height="17px" width="10px"   loading="lazy" src="/images/lightning.svg" alt="instant"/></span></>):(<></>))}
												</p>
												
											  </div>
									   

									   
									</div>
								 </div>
								 {( influencer.activityCategory_id=='611b452a4fdde75b279725eb' ?  (<a  href={`${APP_BASE_URL}/staycation/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611b45344fdde75b279725ec' ?  (<a  href={`${APP_BASE_URL}/tours/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611ba6cd14e97e569e41eaac' ?  (<a  href={`${APP_BASE_URL}/activities/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c3ceaf59f69875e2abd' ?  (<a  href={`${APP_BASE_URL}/transport/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c55eaf59f69875e2abe' ?  (<a  href={`${APP_BASE_URL}/food/${influencer.url}`}  class="onlink"></a>):(<></>) )}
								 {/* <a onClick={(e)=> this.handleClickActitvitySearch(e, influencer.url)}class="onlink"></a> */}
							</div>
						</div>
					</div>): ""}

				</div>
			</div>
		</div>
	</div>
</section>

<section class="same-section bg-gray guid-trv-cnt">
	<div class="container">
		<h2 class="section_main_title align-left">{this.state.destinationName} TRAVEL GUIDES & TIPS</h2>
		<div class="traveller_guide">
			<div class="row">
				<div id="renok_carousel_03" class="owl-carousel common_col--width common_arrow--slider">
					
				{(this.state.guideList != null && this.state.guideList.length> 0 )? this.state.guideList.map(
                                                        guide =>
					<div class="item">
						<div class="col-md-3">	    						
							<div class="guidetipsbox">
								 <div class="guide_tip_image">
									<img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(guide.coverImage).imageUrl} alt={JSON.parse(guide.coverImage).altText}/>
								 </div>
								 <div class="guide_title">
									<h3 class="guidetiptit">{guide.name}</h3>
								 </div>
								 <div class="guide_tipuser_side">
									<div class="guidetipusr_left">
									   {/* <div class="images_od_user">
										  <img  loading="lazy" src={JSON.parse(guide.profileImage).imageUrl} alt="profile"/>
									   </div> */}
									   <span class="usertitleonly">{guide.userName}</span>
									</div>
									<div class="guidetipusr_left">
									   <p>{guide.blogdate}</p>
									</div>
								 </div>
								 <a  href={`${APP_BASE_URL}/blog/${guide.url}`} class="onlink"></a>
								
							</div>
						</div>
					</div>): ""}

					
				</div>
			</div>
		</div>
	</div>
</section>




<section class="same-section bg-gray palnning_and_preparation padding_top pre-cnt-img">
	<div class="container">
		<h2 class="section_main_title align-left">Travel Planning & Preparations</h2>
		<div class="planning_four_bx">
			<div class="row">
			{(this.state.travelPlanning != null && this.state.travelPlanning.length> 0 )? this.state.travelPlanning.map(
                                                        travel =>
				<div class="col-md-3">
					<div class="planning_inner--image box_side_four">
						
					<a href={"//"+travel.valueValue}> 
						
						<img  loading="lazy" src={travel.keyValue} alt="palnning"  /> 
					</a>
					 </div>
				</div>
			): ""}
				
			</div>
		</div>
	</div>
</section>


<section class="same-section topthinks_palces padding_top padding_bottom bg-gray">
	<div class="container">
		<h2 class="section_main_title align-left">TOP Things to do in {this.state.destinationName} </h2>
		<div class="top_thinks_doin">
			<div class="top_think_doin_flex">
				<div  class="labelare_title_perent">
					<div class="topthink_one_inner">
						<div class="labelside_one_inner">
							<label class="labelare_title">Destination</label>
						</div>
					</div>
					<div class="topthink_two_inner">
						<select class="filter_fulid" name="searchDestination" value={this.state.searchDestination} onChange={(event) => this.handleChangeSearch(event)}>
						{(this.state.destinationList != null && this.state.destinationList.length> 0 )? this.state.destinationList.map(
                                    dest =>
                                    <option value={dest.id} >{dest.name}</option>
                                    )
            
                                : ""}
							
						</select>
						<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					</div>
				</div>
				<div  class="labelare_title_perent">
					<div class="topthink_one_inner">
						<div class="labelside_one_inner">
							<label class="labelare_title">Categories</label>
						</div>
					</div>
					<div class="topthink_two_inner">
						<select class="filter_fulid" name="searchCategory" value={this.state.searchCategory}  onChange={(event) => this.handleChangeSearch(event)}>
						<option value="">Select Category</option>
														<option value="611b452a4fdde75b279725eb">STAYCATION</option>
														<option value="611b45344fdde75b279725ec">TOURS</option>
														<option value="611ba6cd14e97e569e41eaac">ACTIVITIES</option>
														<option value="61275c3ceaf59f69875e2abd">TRANSPORT & TRAVEL SERVICES</option>
														<option value="61275c55eaf59f69875e2abe">BEST FOOD & MUST EATS</option>
						</select>
						<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					</div>
				</div>
			</div>

			<div class="top_think_doin_flex_second">
				{/* <div  class="labelare_title_perent">
					<div class="topthink_two_inner">
						<select class="filter_fulid">
							<option>Availability</option>
						</select>
						<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					</div>
				</div> */}
				<div  class="labelare_title_perent">
				<div class="topthink_one_inner">
						<div class="labelside_one_inner">
							<label class="labelare_title">Price</label>
						</div>
					</div>
					<div class="topthink_two_inner">
						<select class="filter_fulid" name="searchPriceRange" value={this.state.searchPriceRange}  onChange={(event) => this.handleChangeSearch(event)}>
						<option value="">Select Range</option>
														  <option value="0-10000">0-10000</option>
														<option value="10000-20000">10000-20000</option>
														<option value="20000-30000">20000-30000</option>
														<option value="30000-50000">30000-50000</option>
														<option value="50000-1000000">Above 50000</option>
						</select>
						<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					</div>
				</div>
				<div  class="labelare_title_perent instant_image_search_bar">
					<div class="topthink_one_inner">
						<div class="labelside_one_inner">
							<label class="labelare_title"><img  loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/>Instant Confirmation</label>
						</div>
					</div>
					<div class="topthink_two_inner">
						<label class="switch">
							<input class="switch-input" type="checkbox" name="searchInstantConfirmation" checked={this.searchInstantConfirmation} value={this.state.searchInstantConfirmation}  onChange={(event) => this.handleChangeSearch(event)} />
							<span class="switch-label" data-on="On" data-off="Off"></span> 
							<span class="switch-handle"></span> 
						</label>
					</div>
				</div>
				<div  class="labelare_title_perent">
					<div class="topthink_one_inner">
						<div class="labelside_one_inner">
							<label class="labelare_title">Type:</label>
						</div>
					</div>
					<div class="topthink_two_inner">
						<select class="filter_fulid" name="searchType" value={this.state.searchType}  onChange={(event) => this.handleChangeSearch(event)}>
						<option value="">Select Type</option>
			
			<option value="Inspirational">Inspirational</option>
			<option value="Popular">Popular</option>
			<option value="Recommended">Recommended</option>
						</select>
						<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					</div>
				</div>
			</div>
		</div>
		<div class="popularacti_bx_perent">
			<div class="row">
			{(this.state.destinationActivities != null && this.state.destinationActivities.length> 0 )? this.state.destinationActivities.map(
                                    influencer =>
				<div class="col-md-3">
					<div class="popular_activities_box">
						 <div class="imagesof_box">
							<img  loading="lazy" style={{"height":"160px"}}  src={IMAGE_BASE_URL+``+JSON.parse(influencer.coverImage).imageUrl} alt={JSON.parse(influencer.coverImage).altText}/>
							<div class="activities_palcetitle">
							   <img  loading="lazy" src="/images/map.svg" alt="map"/>{influencer.ad[0].name}
							</div>
						 </div>
						 <div class="popular_activities_content">
						 <div class="discount_div">
						 {Math.ceil(influencer.pk.discountPercantage)!==0 && <>
											<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />
										<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}
										
										</div>
							<h3 class="palcebox_title">{influencer.name}</h3>
							<div class="box_review_side">
							   <div class="review_side_cell">
								  <i class="fas fa-star"></i>
								  <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												
							   </div>
							   <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/></span></>):(<></>))}
												</p>
												
											  </div>
							</div>
						 </div>
						 {( influencer.activityCategory_id=='611b452a4fdde75b279725eb' ?  (<a  href={`${APP_BASE_URL}/staycation/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611b45344fdde75b279725ec' ?  (<a  href={`${APP_BASE_URL}/tours/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611ba6cd14e97e569e41eaac' ?  (<a  href={`${APP_BASE_URL}/activities/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c3ceaf59f69875e2abd' ?  (<a  href={`${APP_BASE_URL}/transport/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c55eaf59f69875e2abe' ?  (<a  href={`${APP_BASE_URL}/food/${influencer.url}`}  class="onlink"></a>):(<></>) )}
					</div>
				</div>): ""}

			
			</div>
		</div>
	</div>
</section>

<section class="same-section topthinks_palces padding_bottom bg-gray">
	<div class="container">	    
					      <div class="common_block pagination_bar text-align">
					        
							 <PaginationComponent
          totalItems={this.state.totalItemsCount}
          pageSize={this.state.itemsCountPerPage}
          onSelect={this.handlePageChange}
        />
					        
					      </div></div>
					</section>


{/* <section class="same-section topthinks_palces padding_bottom bg-gray">	    
	  <div class="common_block pagination_bar text-align">
		 <ul class="agination_custom">
			<li><a href="#"><i class="fas fa-chevron-left"></i></a></li>
			<li class="active"><a href="#">1</a></li>
			<li ><a href="#">2</a></li>
			<li><a href="#">3</a></li>
			<li><a href="#">...</a></li>
			<li><a href="#">7</a></li>
			<li><a href="#"><i class="fas fa-chevron-right"></i></a></li>
		 </ul>
	  </div>
</section> */}


<section id="treavel_viewmore" class="same-section palces--travlinginfo padding_top bg-gray">
	<div class="container">
		<h2 class="section_main_title align-left">{this.state.destinationName} Travel Information</h2>
		<div class="travlinginfo_inner">
			<div class="place_infoperent">
				<h6 class="place_info_titles">About {this.state.destinationName}</h6>
				<div class="row">
					<div class="col-md-8">
						<div class="shim--placedec">
							<p><Editor toolbarHidden={true} editorState={this.state.description} readOnly={true} /></p>
						</div>
					</div>
					<div class="col-md-4">
						<div class="shim--placemap">
							<iframe src={this.state.mapurl} width="" height="" frameborder="0" allowfullscreen=""></iframe>
							<div  class="moremap">
								<a href="#">View popular activities on the map</a>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div class="place_infoperent">
				<h6 class="place_info_titles">Local Weather <span><img src={APP_BASE_URL + '/images/temp.png'} alt="Image-Icon"/> </span></h6>
				<div class="row">
					<div class="col-md-3">
						<div class="wetiher_sec">
							<p>Dec - Feb</p>
							<h4>20° &nbsp;13°</h4>
						</div>
					</div>
					<div class="col-md-3">
						<div class="wetiher_sec">
							<p>Mar - May</p>
							<h4>29° &nbsp;17°</h4>
						</div>
					</div>
					<div class="col-md-3">
						<div class="wetiher_sec">
							<p>JUN - AUG</p>
							<h4>31° &nbsp;26°</h4>
						</div>
					</div>
					<div class="col-md-3">
						<div class="wetiher_sec">
							<p>SEP - NOV</p>
							<h4>29° &nbsp;19°</h4>
						</div>
					</div>
				</div>
			</div> */}
			<div class="place_infoperent geninfo_time">
				<h6 class="place_info_titles">General Information</h6>
				<div class="row">
					<div class="col-md-2">
						<span>Time Zone</span>
						<h3>Time Zone</h3>
						<p>5 hours ahead</p>
					</div>

					<div class="col-md-2">
						<span>Currency</span>
						<h3>INR</h3>
						<p>1 INR = 70 USD</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>

<section class="same-section bg-gray palnning_and_preparation padding_top padding_bottom near-dest-shim">
	<div class="container">
		<h2 class="section_main_title align-left">Nearby Destinations to {this.state.destinationName}</h2>	    		
		<div class="row">
		{(this.state.nearestDestination != null && this.state.nearestDestination.length> 0 )? this.state.nearestDestination.map(
                                    influencer =>
			<div class="col-md-3 col-sm-4">
				<div class="earn_friend_bx">
					<img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(influencer.gridImage).imageUrl} alt={JSON.parse(influencer.gridImage).altText}/>
					<div class="earn_friend_content">
						<h2 class="earn_friend_title">{influencer.name}</h2>
						
					</div>
					<a href={`${APP_BASE_URL}/destination/${influencer.url}`}   class="onlink"></a>
				</div>
			</div>):""}

			

			
		</div>
		<div class="explore_more_perent">
			<a href="/destination" class="common_readmore">Explore All Destinations</a>
		</div>
	</div>
</section>
</div>
                <div id="menu1" class="tab-pane fade">			      
				    <section class="same-section attractions-shows_perent padding_top">
				      	<div class="container">
				      		<h2 class="section_main_title align-left padding_bottom">STAYCATION</h2>
				      		<div class="filterlist_main_perent padding_bottom">
				      			<div class="filterlist_main_perent_inner">
				      				<div class="filter_list_left_main small_padding_top small_padding_bottom">
				      					<h3 class="categories_list_heading small_padding_bottom"> Categories</h3>
				      					<div class="categories_checklist">
										  <ul class="list">  
										  <li class="list__item">
		                                        <div class="list-check-box">
		                                          <input type="checkbox" id='asc_all' name="ascSubC" value="" checked={true}    />
		                                          <label for="all" class="label"  onClick={() => this.handleChangeSubCategory('asc_all',"",'asc')}>All</label>
		                                        </div>
		                                      </li>
										  {(this.state.attractionSubCatgeoryList != null && this.state.attractionSubCatgeoryList.length> 0 )? this.state.attractionSubCatgeoryList.map(
                                           (subC,index) =><>
									                          
		                                      <li class="list__item">
		                                        <div class="list-check-box">
		                                          <input type="checkbox" id={'asc_'+index} name="ascSubC" value={subC.id} checked={false}    />
		                                          <label for={subC.name} class="label"  onClick={() => this.handleChangeSubCategory('asc_'+index,subC.id,'asc')}>{subC.name}</label>
		                                        </div>
		                                      </li>
		                                   
                         
                           </>
						   ):""}
				      					 </ul>	
				      						
				      					</div>
				      					{/* <div class="viewall_button--filter small_padding_top">
				      						<a href="#" class="common_readmore">View All</a>
				      					</div> */}
				      				</div>

				      				<div class="filter_list_right_main small_padding_top small_padding_bottom">
				      					<h4 class="title_meduim_size">{this.state.attractionActivities.length} activities found</h4>
				      					<div class="filter_search_side small_padding_top">
				      						<div class="filter_fuild_bx">
				      							<input type="text" value={this.state.attractionSearch}  onChange={(event) => this.handleChangeAsc(event)} class="filter_fulid" name="attractionSearch" placeholder="Search by destination, activity" />
				      							<button type="button" class="down_arrow_filter"><i class="fas fa-search"></i></button>
				      						</div>
				      					</div>
				      					<div class="filter_shortby_flex small_padding_top">
				      						<div class="filter_shortby_left">				      							
					      						{/* <div class="filter_fuild_bx">
					      							<select class="filter_fulid">
					      								<option>Availability</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div> */}

					      						<div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="attractionPriceRange" value={this.state.attractionPriceRange}  onChange={(event) => this.handleChangeAsc(event)}>
					      								<option value="">Select Price Range</option>
														  <option value="0-10000">0-10000</option>
														<option value="10000-20000">10000-20000</option>
														<option value="20000-30000">20000-30000</option>
														<option value="30000-50000">30000-50000</option>
														<option value="50000Plus">Above 50000</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
												  <div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="attractionType" value={this.state.attractionType}  onChange={(event) => this.handleChangeAsc(event)}>
													  <option value="">Select Type</option>
			
														  <option value="Inspirational">Inspirational</option>
														  <option value="Popular">Popular</option>
														  <option value="Recommended">Recommended</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
					      						<div class="filter_fuild_bx">
											
												  <div class="topthink_one_inner">
						                            <div class="labelside_one_inner">
													<label ><img  loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/>Instant Confirmation</label></div>
											          <div class="topthink_two_inner">
						                             <label class="switch">
						                              	<input class="switch-input" type="checkbox" name="attractionInstantConfirmation" value={this.state.attractionInstantConfirmation} checked={this.state.attractionInstantConfirmation}  onChange={(event) => this.handleChangeAsc(event)} />
						                            	<span class="switch-label" data-on="On" data-off="Off"></span> 
						                              	<span class="switch-handle"></span> 
						                                </label>
				                                	</div>
						                                  
					                                  </div>
				
				
		                                         
					      						</div>
												 
				      						</div>
				      						
				      					</div>
				      					<div class="list_boxes_filter">
				      						<div class="list_boxes_filter_listing">
												<div class="row">
												{(this.state.attractionActivities != null && this.state.attractionActivities.length> 0 )? this.state.attractionActivities.map(
                                                        influencer =>
									    			  <div class="col-md-3">
										    			<div class="popular_activities_box">
										                     <div class="imagesof_box">
										                        <img  loading="lazy" style={{"height":"160px"}} src={IMAGE_BASE_URL+``+JSON.parse(influencer.coverImage).imageUrl} alt={JSON.parse(influencer.coverImage).altText}/>
										                        <div class="activities_palcetitle">
										                           <img  loading="lazy" src="/images/map.svg" alt="map"/> {influencer.ad[0].name}
										                        </div>
										                     </div>
										                     <div class="popular_activities_content">
															 <div class="discount_div">
															 {Math.ceil(influencer.pk.discountPercantage)!==0 && <>
											<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />
										<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}
										
										</div>
										                        <h3 class="palcebox_title">{influencer.name}</h3>
										                        <div class="box_review_side">
										                           <div class="review_side_cell">
										                              <i class="fas fa-star"></i>
																	  <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												
										                           </div>
																   <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img  loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/></span></>):(<></>))}
												</p>
												
											  </div>
										                        </div>
										                     </div>
										                     <a onClick={(e)=> this.handleClickActitvity(e,'staycation',influencer.url)} class="onlink"></a>
										                </div>
									    			</div>): ""}


								    			</div>
				      						</div>

											{/* <div class="same-section topthinks_palces padding_bottom small_padding_top">	    
											      <div class="common_block pagination_bar text-align">
											         <ul class="agination_custom">
											            <li><a href="#"><i class="fas fa-chevron-left"></i></a></li>
											            <li><a href="#">1</a></li>
											            <li class="active"><a href="#">2</a></li>
											            <li><a href="#">3</a></li>
											            <li><a href="#">...</a></li>
											            <li><a href="#">7</a></li>
											            <li><a href="#"><i class="fas fa-chevron-right"></i></a></li>
											         </ul>
											      </div>
											</div> */}

										    <div class="same-section palnning_and_preparation padding_top ">
									    		<h2 class="section_main_title align-left">Travel Planning & Preparations</h2>
									    		<div class="planning_four_bx">
									    			<div class="row">
													{(this.state.travelPlanning != null && this.state.travelPlanning.length> 0 )? this.state.travelPlanning.map(
                                                        travel =>
				<div class="col-md-3">
					<div class="planning_inner--image box_side_four">
					<a href={"//"+travel.valueValue}> <img  loading="lazy" src={travel.keyValue} alt="planning"  /> </a>
					 </div>
				</div>
			): ""}
									    			</div>
									    		</div>
										    </div>

										    <div class="same-section traveller_guide padding_top">										    	
									    		<h2 class="section_main_title align-left">Featured Guides</h2>
				    							<div class="row">
												{(this.state.guideList != null && this.state.guideList.length> 0 )? this.state.guideList.map(
                                                        guide =>
												
				    								<div class="col-md-4">	    						
										                <div class="guidetipsbox">
										                     <div class="guide_tip_image">
										                        <img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(guide.coverImage).imageUrl} alt={JSON.parse(guide.coverImage).altText}/>
										                     </div>
										                     <div class="guide_title">
										                        <h3 class="guidetiptit">{guide.name}</h3>
										                     </div>
										                     <div class="guide_tipuser_side">
										                        <div class="guidetipusr_left">
										                           {/* <div class="images_od_user">
										                              <img  loading="lazy" src={JSON.parse(guide.profileImage).imageUrl} alt={JSON.parse(guide.profileImage).altText}/>
										                           </div> */}
										                            <span class="usertitleonly">{guide.userName}</span> 
										                        </div>
										                        <div class="guidetipusr_left">
										                           <p>{guide.blogdate}</p>
										                        </div>
										                     </div>
										                     <a href={`${APP_BASE_URL}/blog/${guide.url}`} class="onlink"></a>
										                </div>
									            	</div>
				    								): ""}
				    								
				    							</div>
										    </div>
				      					</div>
				      				</div>
				      			</div>
				      		</div>
				      	</div>
				    </section>
			    </div>


				<div id="menu2" class="tab-pane fade">			      
				<section class="same-section attractions-shows_perent padding_top">
				      	<div class="container">
				      		<h2 class="section_main_title align-left padding_bottom">TOURS</h2>
				      		<div class="filterlist_main_perent padding_bottom">
				      			<div class="filterlist_main_perent_inner">
				      				<div class="filter_list_left_main small_padding_top small_padding_bottom">
				      					<h3 class="categories_list_heading small_padding_bottom"> Categories</h3>
				      					<div class="categories_checklist">
										  <ul class="list">  
										  <li class="list__item">
		                                        <div class="list-check-box">
		                                          <input type="checkbox" id='tour_all' name="tourSubC" value="" checked={true}    />
		                                          <label for="all" class="label"  onClick={() => this.handleChangeSubCategory('tour_all',"",'tour')}>All</label>
		                                        </div>
		                                      </li>
										  {(this.state.tourSubCatgeoryList != null && this.state.tourSubCatgeoryList.length> 0 )? this.state.tourSubCatgeoryList.map(
                                           (subC,index) =><>
									                          
		                                      <li class="list__item">
		                                        <div class="list-check-box">
												<input type="checkbox" id={'tour_'+index} name="tourSubC" value={subC.id} checked={false}    />
		                                          <label for={subC.name} class="label"  onClick={() => this.handleChangeSubCategory('tour_'+index,subC.id,'tour')}>{subC.name}</label>
		                                        </div>
		                                      </li>
		                                   
                         
                           </>
						   ):""}
				      					 </ul>	
				      						
				      					</div>
				      					{/* <div class="viewall_button--filter small_padding_top">
				      						<a href="#" class="common_readmore">View All</a>
				      					</div> */}
				      				</div>

				      				<div class="filter_list_right_main small_padding_top small_padding_bottom">
				      					<h4 class="title_meduim_size">{this.state.tourActivities.length} activities found</h4>
				      					<div class="filter_search_side small_padding_top">
				      						<div class="filter_fuild_bx">
				      							<input type="text" value={this.state.tourSearch}  onChange={(event) => this.handleChangeTour(event)} class="filter_fulid" name="tourSearch" placeholder="Search by destination, activity" />
				      							<button type="button" class="down_arrow_filter"><i class="fas fa-search"></i></button>
				      						</div>
				      					</div>
				      					<div class="filter_shortby_flex small_padding_top">
				      						<div class="filter_shortby_left">				      							
					      						{/* <div class="filter_fuild_bx">
					      							<select class="filter_fulid">
					      								<option>Availability</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div> */}

					      						<div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="tourPriceRange" value={this.state.tourPriceRange}  onChange={(event) => this.handleChangeTour(event)}>
					      								<option value="">Select Price Range</option>
														  <option value="0-10000">0-10000</option>
														<option value="10000-20000">10000-20000</option>
														<option value="20000-30000">20000-30000</option>
														<option value="30000-50000">30000-50000</option>
														<option value="50000Plus">Above 50000</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
												  <div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="tourType" value={this.state.tourType}  onChange={(event) => this.handleChangeTour(event)}>
													  <option value="">Select Type</option>
			
														  <option value="Inspirational">Inspirational</option>
														  <option value="Popular">Popular</option>
														  <option value="Recommended">Recommended</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
					      						<div class="filter_fuild_bx">
											
												  <div class="topthink_one_inner">
						                            <div class="labelside_one_inner">
													<label ><img  loading="lazy" src="/images/lightning.svg"  height="17px" width="10px"  alt="instant"/>Instant Confirmation</label></div>
											          <div class="topthink_two_inner">
						                             <label class="switch">
						                              	<input class="switch-input" type="checkbox" name="tourInstantConfirmation" value={this.state.tourInstantConfirmation} checked={this.state.tourInstantConfirmation}  onChange={(event) => this.handleChangeTour(event)} />
						                            	<span class="switch-label" data-on="On" data-off="Off"></span> 
						                              	<span class="switch-handle"></span> 
						                                </label>
				                                	</div>
						                                  
					                                  </div>
				
				
		                                         
					      						</div>
												 
				      						</div>
				      						
				      					</div>
				      					<div class="list_boxes_filter">
				      						<div class="list_boxes_filter_listing">
												<div class="row">
												{(this.state.tourActivities != null && this.state.tourActivities.length> 0 )? this.state.tourActivities.map(
                                                        influencer =>
									    			  <div class="col-md-3">
										    			<div class="popular_activities_box">
										                     <div class="imagesof_box">
										                        <img  loading="lazy" style={{"height":"160px"}}  src={IMAGE_BASE_URL+``+JSON.parse(influencer.coverImage).imageUrl} alt={JSON.parse(influencer.coverImage).altText} />
										                        <div class="activities_palcetitle">
										                           <img  loading="lazy" src="/images/map.svg" alt="map"/> {influencer.ad[0].name}
										                        </div>
										                     </div>
										                     <div class="popular_activities_content">
															 <div class="discount_div">
															 {Math.ceil(influencer.pk.discountPercantage)!==0 && <>
											<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />
										<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}
										
										</div>
										                        <h3 class="palcebox_title">{influencer.name}</h3>
										                        <div class="box_review_side">
										                           <div class="review_side_cell">
										                              <i class="fas fa-star"></i>
																	  <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												 
										                           </div>
										                           <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img  loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/></span></>):(<></>))}
												</p>
												
											  </div>
										                        </div>
										                     </div>
										                     <a onClick={(e)=> this.handleClickActitvity(e,'tours', influencer.url)} class="onlink"></a>
										                </div>
									    			</div>): ""}


								    			</div>
				      						</div>

											{/* <div class="same-section topthinks_palces padding_bottom small_padding_top">	    
											      <div class="common_block pagination_bar text-align">
											         <ul class="agination_custom">
											            <li><a href="#"><i class="fas fa-chevron-left"></i></a></li>
											            <li><a href="#">1</a></li>
											            <li class="active"><a href="#">2</a></li>
											            <li><a href="#">3</a></li>
											            <li><a href="#">...</a></li>
											            <li><a href="#">7</a></li>
											            <li><a href="#"><i class="fas fa-chevron-right"></i></a></li>
											         </ul>
											      </div>
											</div> */}

										    <div class="same-section palnning_and_preparation padding_top ">
									    		<h2 class="section_main_title align-left">Travel Planning & Preparations</h2>
									    		<div class="planning_four_bx">
									    			<div class="row">
													{(this.state.travelPlanning != null && this.state.travelPlanning.length> 0 )? this.state.travelPlanning.map(
                                                        travel =>
				<div class="col-md-3">
					<div class="planning_inner--image box_side_four">
					<a href={"//"+travel.valueValue}> <img  loading="lazy" src={travel.keyValue} alt="planning"  /> </a>
					 </div>
				</div>
			): ""}
									    			</div>
									    		</div>
										    </div>

										    <div class="same-section traveller_guide padding_top">										    	
									    		<h2 class="section_main_title align-left">Featured Guides</h2>
				    							<div class="row">
												{(this.state.guideList != null && this.state.guideList.length> 0 )? this.state.guideList.map(
                                                        guide =>
												
				    								<div class="col-md-4">	    						
										                <div class="guidetipsbox">
										                     <div class="guide_tip_image">
										                        <img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(guide.coverImage).imageUrl} alt={JSON.parse(guide.coverImage).altText}/>
										                     </div>
										                     <div class="guide_title">
										                        <h3 class="guidetiptit">{guide.name}</h3>
										                     </div>
										                     <div class="guide_tipuser_side">
										                        <div class="guidetipusr_left">
										                           {/* <div class="images_od_user">
										                              <img loading="lazy" src={JSON.parse(guide.profileImage).imageUrl} alt={JSON.parse(guide.profileImage).altText}/>
										                           </div> */}
										                           <span class="usertitleonly">{guide.userName}</span>
										                        </div>
										                        <div class="guidetipusr_left">
										                           <p> <p>{guide.blogdate}</p></p>
										                        </div>
										                     </div>
										                     <a href={`${APP_BASE_URL}/blog/${guide.url}`} class="onlink"></a>
										                </div>
									            	</div>
				    								): ""}
				    								
				    							</div>
										    </div>
				      					</div>
				      				</div>
				      			</div>
				      		</div>
				      	</div>
				    </section>
			    </div>


				<div id="menu3" class="tab-pane fade">			      
				<section class="same-section attractions-shows_perent padding_top">
				      	<div class="container">
				      		<h2 class="section_main_title align-left padding_bottom">ACTIVITIES</h2>
				      		<div class="filterlist_main_perent padding_bottom">
				      			<div class="filterlist_main_perent_inner">
				      				<div class="filter_list_left_main small_padding_top small_padding_bottom">
				      					<h3 class="categories_list_heading small_padding_bottom"> Categories</h3>
				      					<div class="categories_checklist">
										  <ul class="list">  
										  <li class="list__item">
		                                        <div class="list-check-box">
		                                          <input type="checkbox" id='exp_all' name="expSubC" value="" checked={true}    />
		                                          <label for="all" class="label"  onClick={() => this.handleChangeSubCategory('exp_all',"",'exp')}>All</label>
		                                        </div>
		                                      </li>
										  {(this.state.experienceSubCatgeoryList != null && this.state.experienceSubCatgeoryList.length> 0 )? this.state.experienceSubCatgeoryList.map(
                                           (subC,index) =><>
									                          
		                                      <li class="list__item">
		                                        <div class="list-check-box">
												<input type="checkbox" id={'exp_'+index} name="expSubC" value={subC.id} checked={false}    />
		                                          <label for={subC.name} class="label"  onClick={() => this.handleChangeSubCategory('exp_'+index,subC.id,'exp')}>{subC.name}</label>
		                                        </div>
		                                      </li>
		                                   
                         
                           </>
						   ):""}
				      					 </ul>	
				      						
				      					</div>
				      					{/* <div class="viewall_button--filter small_padding_top">
				      						<a href="#" class="common_readmore">View All</a>
				      					</div> */}
				      				</div>

				      				<div class="filter_list_right_main small_padding_top small_padding_bottom">
				      					<h4 class="title_meduim_size">{this.state.experienceActivities.length} activities found</h4>
				      					<div class="filter_search_side small_padding_top">
				      						<div class="filter_fuild_bx">
				      							<input type="text" value={this.state.expSearch}  onChange={(event) => this.handleChangeExp(event)} class="filter_fulid" name="expSearch" placeholder="Search by destination, activity" />
				      							<button type="button" class="down_arrow_filter"><i class="fas fa-search"></i></button>
				      						</div>
				      					</div>
				      					<div class="filter_shortby_flex small_padding_top">
				      						<div class="filter_shortby_left">				      							
					      						{/* <div class="filter_fuild_bx">
					      							<select class="filter_fulid">
					      								<option>Availability</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div> */}

					      						<div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="expPriceRange" value={this.state.expPriceRange}  onChange={(event) => this.handleChangeExp(event)}>
					      								<option value="">Select Price Range</option>
														  <option value="0-10000">0-10000</option>
														<option value="10000-20000">10000-20000</option>
														<option value="20000-30000">20000-30000</option>
														<option value="30000-50000">30000-50000</option>
														<option value="50000Plus">Above 50000</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
												  <div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="expType" value={this.state.expType}  onChange={(event) => this.handleChangeExp(event)}>
													  <option value="">Select Type</option>
			
														  <option value="Inspirational">Inspirational</option>
														  <option value="Popular">Popular</option>
														  <option value="Recommended">Recommended</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
					      						<div class="filter_fuild_bx">
											
												  <div class="topthink_one_inner">
						                            <div class="labelside_one_inner">
													<label ><img  loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/>Instant Confirmation</label></div>
											          <div class="topthink_two_inner">
						                             <label class="switch">
						                              	<input class="switch-input" type="checkbox" name="expInstantConfirmation" value={this.state.expInstantConfirmation} checked={this.state.expInstantConfirmation}  onChange={(event) => this.handleChangeExp(event)} />
						                            	<span class="switch-label" data-on="On" data-off="Off"></span> 
						                              	<span class="switch-handle"></span> 
						                                </label>
				                                	</div>
						                                  
					                                  </div>
				
				
		                                         
					      						</div>
												 
				      						</div>
				      						
				      					</div>
				      					<div class="list_boxes_filter">
				      						<div class="list_boxes_filter_listing">
												<div class="row">
												{(this.state.experienceActivities != null && this.state.experienceActivities.length> 0 )? this.state.experienceActivities.map(
                                                        influencer =>
									    			  <div class="col-md-3">
										    			<div class="popular_activities_box">
										                     <div class="imagesof_box">
										                        <img   loading="lazy" style={{"height":"160px"}}  src={IMAGE_BASE_URL+``+JSON.parse(influencer.coverImage).imageUrl} alt={JSON.parse(influencer.coverImage).imageUrl}/>
										                        <div class="activities_palcetitle">
										                           <img  loading="lazy" src="/images/map.svg" alt="map"/> {influencer.ad[0].name}
										                        </div>
										                     </div>
										                     <div class="popular_activities_content">
															 <div class="discount_div">
															 {Math.ceil(influencer.pk.discountPercantage)!==0 && <>
											<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />
										<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}
										
										</div>
										                        <h3 class="palcebox_title">{influencer.name}</h3>
										                        <div class="box_review_side">
										                           <div class="review_side_cell">
										                              <i class="fas fa-star"></i>
																	  <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												
										                           </div>
										                           <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img  loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/></span></>):(<></>))}
												</p>
											
											  </div>
										                        </div>
										                     </div>
										                     <a onClick={(e)=> this.handleClickActitvity(e,'activities', influencer.url)} class="onlink"></a>
										                </div>
									    			</div>): ""}


								    			</div>
				      						</div>

											{/* <div class="same-section topthinks_palces padding_bottom small_padding_top">	    
											      <div class="common_block pagination_bar text-align">
											         <ul class="agination_custom">
											            <li><a href="#"><i class="fas fa-chevron-left"></i></a></li>
											            <li><a href="#">1</a></li>
											            <li class="active"><a href="#">2</a></li>
											            <li><a href="#">3</a></li>
											            <li><a href="#">...</a></li>
											            <li><a href="#">7</a></li>
											            <li><a href="#"><i class="fas fa-chevron-right"></i></a></li>
											         </ul>
											      </div>
											</div> */}

										    <div class="same-section palnning_and_preparation padding_top ">
									    		<h2 class="section_main_title align-left">Travel Planning & Preparations</h2>
									    		<div class="planning_four_bx">
									    			<div class="row">
													{(this.state.travelPlanning != null && this.state.travelPlanning.length> 0 )? this.state.travelPlanning.map(
                                                        travel =>
				<div class="col-md-3">
					<div class="planning_inner--image box_side_four">
					<a href={"//"+travel.valueValue}> <img  loading="lazy" src={travel.keyValue} alt="planning"  /> </a>
					 </div>
				</div>
			): ""}
									    			</div>
									    		</div>
										    </div>

										    <div class="same-section traveller_guide padding_top">										    	
									    		<h2 class="section_main_title align-left">Featured Guides</h2>
				    							<div class="row">
												{(this.state.guideList != null && this.state.guideList.length> 0 )? this.state.guideList.map(
                                                        guide =>
												
				    								<div class="col-md-4">	    						
										                <div class="guidetipsbox">
										                     <div class="guide_tip_image">
										                        <img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(guide.coverImage).imageUrl} alt={JSON.parse(guide.coverImage).altText} />
										                     </div>
										                     <div class="guide_title">
										                        <h3 class="guidetiptit">{guide.name}</h3>
										                     </div>
										                     <div class="guide_tipuser_side">
										                        <div class="guidetipusr_left">
										                           {/* <div class="images_od_user">
										                              <img  loading="lazy" src={JSON.parse(guide.profileImage).imageUrl} alt={JSON.parse(guide.profileImage).altText}/>
										                           </div> */}
										                           <span class="usertitleonly">{guide.userName}</span>
										                        </div>
										                        <div class="guidetipusr_left">
										                           <p>{guide.blogDate}</p>
										                        </div>
										                     </div>
										                     <a href={`${APP_BASE_URL}/blog/${guide.url}`} class="onlink"></a>
										                </div>
									            	</div>
				    								): ""}
													
				    								
				    							</div>
										    </div>
				      					</div>
				      				</div>
				      			</div>
				      		</div>
				      	</div>
				    </section>
			    </div>



			    <div id="menu4" class="tab-pane fade">			      
				<section class="same-section attractions-shows_perent padding_top">
				      	<div class="container">
				      		<h2 class="section_main_title align-left padding_bottom">TRANSPORT & TRAVEL SERVICES</h2>
				      		<div class="filterlist_main_perent padding_bottom">
				      			<div class="filterlist_main_perent_inner">
				      				<div class="filter_list_left_main small_padding_top small_padding_bottom">
				      					<h3 class="categories_list_heading small_padding_bottom"> Categories</h3>
				      					<div class="categories_checklist">
										  <ul class="list">  
										  <li class="list__item">
		                                        <div class="list-check-box">
		                                          <input type="checkbox" id='travel_all' name="travelSubC" value="" checked={true}    />
		                                          <label for="all" class="label"  onClick={() => this.handleChangeSubCategory('travel_all',"",'travel')}>All</label>
		                                        </div>
		                                      </li>
										  {(this.state.travelSubCatgeoryList != null && this.state.travelSubCatgeoryList.length> 0 )? this.state.travelSubCatgeoryList.map(
                                           (subC,index) =><>
									                          
		                                      <li class="list__item">
		                                        <div class="list-check-box">
												<input type="checkbox" id={'travel_'+index} name="travelSubC" value={subC.id} checked={false}    />
		                                          <label for={subC.name} class="label"  onClick={() => this.handleChangeSubCategory('travel_'+index,subC.id,'travel')}>{subC.name}</label>
		                                        </div>
		                                      </li>
		                                   
                         
                           </>
						   ):""}
				      					 </ul>	
				      						
				      					</div>
				      					{/* <div class="viewall_button--filter small_padding_top">
				      						<a href="#" class="common_readmore">View All</a>
				      					</div> */}
				      				</div>

				      				<div class="filter_list_right_main small_padding_top small_padding_bottom">
				      					<h4 class="title_meduim_size">{this.state.travelActivities.length} activities found</h4>
				      					<div class="filter_search_side small_padding_top">
				      						<div class="filter_fuild_bx">
				      							<input type="text" value={this.state.tourSearch}  onChange={(event) => this.handleChangeTour(event)} class="filter_fulid" name="tourSearch" placeholder="Search by destination, activity" />
				      							<button type="button" class="down_arrow_filter"><i class="fas fa-search"></i></button>
				      						</div>
				      					</div>
				      					<div class="filter_shortby_flex small_padding_top">
				      						<div class="filter_shortby_left">				      							
					      						{/* <div class="filter_fuild_bx">
					      							<select class="filter_fulid">
					      								<option>Availability</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div> */}

					      						<div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="tourPriceRange" value={this.state.tourPriceRange}  onChange={(event) => this.handleChangeTour(event)}>
					      								<option value="">Select Price Range</option>
														  <option value="0-10000">0-10000</option>
														<option value="10000-20000">10000-20000</option>
														<option value="20000-30000">20000-30000</option>
														<option value="30000-50000">30000-50000</option>
														<option value="50000Plus">Above 50000</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
												  <div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="tourType" value={this.state.tourType}  onChange={(event) => this.handleChangeTour(event)}>
													  <option value="">Select Type</option>
			
														  <option value="Inspirational">Inspirational</option>
														  <option value="Popular">Popular</option>
														  <option value="Recommended">Recommended</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
					      						<div class="filter_fuild_bx">
											
												  <div class="topthink_one_inner">
						                            <div class="labelside_one_inner">
													<label ><img  loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/>Instant Confirmation</label> </div>
											          <div class="topthink_two_inner">
						                             <label class="switch">
						                              	<input class="switch-input" type="checkbox" name="tourInstantConfirmation" value={this.state.tourInstantConfirmation} checked={this.state.tourInstantConfirmation}  onChange={(event) => this.handleChangeTour(event)} />
						                            	<span class="switch-label" data-on="On" data-off="Off"></span> 
						                              	<span class="switch-handle"></span> 
						                                </label>
				                                	</div>
						                                 
					                                  </div>
				
				
		                                         
					      						</div>
												 
				      						</div>
				      						 
				      					</div>
				      					<div class="list_boxes_filter">
				      						<div class="list_boxes_filter_listing">
												<div class="row">
												{(this.state.travelActivities != null && this.state.travelActivities.length> 0 )? this.state.travelActivities.map(
                                                        influencer =>
									    			  <div class="col-md-3">
										    			<div class="popular_activities_box">
										                     <div class="imagesof_box">
										                        <img  loading="lazy" style={{"height":"160px"}}  src={IMAGE_BASE_URL+``+JSON.parse(influencer.coverImage).imageUrl} alt={JSON.parse(influencer.coverImage).altText}/>
										                        <div class="activities_palcetitle">
										                           <img  loading="lazy" src="/images/map.svg" alt="map"/> {influencer.ad[0].name}
										                        </div>
										                     </div>
										                     <div class="popular_activities_content">
															 <div class="discount_div">
															 {Math.ceil(influencer.pk.discountPercantage)!==0 && <>
											<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />
										<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}
										
										</div>
										                        <h3 class="palcebox_title">{influencer.name}</h3>
										                        <div class="box_review_side">
										                           <div class="review_side_cell">
										                              <i class="fas fa-star"></i>
										                              <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												
										                           </div>
										                           <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img  loading="lazy" src="/images/lightning.svg"  height="17px" width="10px"  alt="instant"/></span></>):(<></>))}
												</p>
												
											  </div>
										                        </div>
										                     </div>
										                     <a onClick={(e)=> this.handleClickActitvity(e,'transport', influencer.url)} class="onlink"></a>
										                </div>
									    			</div>): ""}


								    			</div>
				      						</div>

											{/* <div class="same-section topthinks_palces padding_bottom small_padding_top">	    
											      <div class="common_block pagination_bar text-align">
											         <ul class="agination_custom">
											            <li><a href="#"><i class="fas fa-chevron-left"></i></a></li>
											            <li><a href="#">1</a></li>
											            <li class="active"><a href="#">2</a></li>
											            <li><a href="#">3</a></li>
											            <li><a href="#">...</a></li>
											            <li><a href="#">7</a></li>
											            <li><a href="#"><i class="fas fa-chevron-right"></i></a></li>
											         </ul>
											      </div>
											</div> */}

										    <div class="same-section palnning_and_preparation padding_top ">
									    		<h2 class="section_main_title align-left">Travel Planning & Preparations</h2>
									    		<div class="planning_four_bx">
									    			<div class="row">
													{(this.state.travelPlanning != null && this.state.travelPlanning.length> 0 )? this.state.travelPlanning.map(
                                                        travel =>
				<div class="col-md-3">
					<div class="planning_inner--image box_side_four">
					<a href={"//"+travel.valueValue}> <img  loading="lazy" src={travel.keyValue} alt="planning"  /> </a>
					 </div>
				</div>
			): ""}
									    			</div>
									    		</div>
										    </div>

										    <div class="same-section traveller_guide padding_top">										    	
									    		<h2 class="section_main_title align-left">Featured Guides</h2>
				    							<div class="row">
												{(this.state.guideList != null && this.state.guideList.length> 0 )? this.state.guideList.map(
                                                        guide =>
												
				    								<div class="col-md-4">	    						
										                <div class="guidetipsbox">
										                     <div class="guide_tip_image">
										                        <img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(guide.coverImage).imageUrl} alt={JSON.parse(guide.coverImage).altText}/>
										                     </div>
										                     <div class="guide_title">
										                        <h3 class="guidetiptit">{guide.name}</h3>
										                     </div>
										                     <div class="guide_tipuser_side">
										                        <div class="guidetipusr_left">
										                           {/* <div class="images_od_user">
										                              <img  loading="lazy" src={JSON.parse(guide.profileImage).imageUrl} alt={JSON.parse(guide.profileImage).altText}/>
										                           </div> */}
										                           <span class="usertitleonly">{guide.userName}</span>
										                        </div>
										                        <div class="guidetipusr_left">
										                           <p>{guide.blogDate}</p>
										                        </div>
										                     </div>
										                     <a href={`${APP_BASE_URL}/blog/${guide.url}`} class="onlink"></a>
										                </div>
									            	</div>
				    								): ""}
				    								
				    							</div>
										    </div>
				      					</div>
				      				</div>
				      			</div>
				      		</div>
				      	</div>
				    </section>
			    </div>

			    <div id="menu5" class="tab-pane fade">			      
				<section class="same-section attractions-shows_perent padding_top">
				      	<div class="container">
				      		<h2 class="section_main_title align-left padding_bottom">BEST FOOD & MUST EATS</h2>
				      		<div class="filterlist_main_perent padding_bottom">
				      			<div class="filterlist_main_perent_inner">
				      				<div class="filter_list_left_main small_padding_top small_padding_bottom">
				      					<h3 class="categories_list_heading small_padding_bottom"> Categories</h3>
				      					<div class="categories_checklist">
										  <ul class="list">  
										  <li class="list__item">
		                                        <div class="list-check-box">
		                                          <input type="checkbox" id='food_all' name="foodSubC" value="" checked={true}    />
		                                          <label for="all" class="label"  onClick={() => this.handleChangeSubCategory('food_all',"",'food')}>All</label>
		                                        </div>
		                                      </li>
										  {(this.state.foodSubCatgeoryList != null && this.state.foodSubCatgeoryList.length> 0 )? this.state.foodSubCatgeoryList.map(
                                           (subC,index) =><>
									                          
		                                      <li class="list__item">
		                                        <div class="list-check-box">
												<input type="checkbox" id={'food_'+index} name="foodSubC" value={subC.id} checked={false}    />
		                                          <label for={subC.name} class="label"  onClick={() => this.handleChangeSubCategory('food_'+index,subC.id,'food')}>{subC.name}</label>
		                                        </div>
		                                      </li>
		                                   
                         
                           </>
						   ):""}
				      					 </ul>	
				      						
				      					</div>
				      					{/* <div class="viewall_button--filter small_padding_top">
				      						<a href="#" class="common_readmore">View All</a>
				      					</div> */}
				      				</div>

				      				<div class="filter_list_right_main small_padding_top small_padding_bottom">
				      					<h4 class="title_meduim_size">{this.state.foodActivities.length} activities found</h4>
				      					<div class="filter_search_side small_padding_top">
				      						<div class="filter_fuild_bx">
				      							<input type="text" value={this.state.foodSearch}  onChange={(event) => this.handleChangeFood(event)} class="filter_fulid" name="foodSearch" placeholder="Search by destination, activity" />
				      							<button type="button" class="down_arrow_filter"><i class="fas fa-search"></i></button>
				      						</div>
				      					</div>
				      					<div class="filter_shortby_flex small_padding_top">
				      						<div class="filter_shortby_left">				      							
					      						{/* <div class="filter_fuild_bx">
					      							<select class="filter_fulid">
					      								<option>Availability</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div> */}

					      						<div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="foodPriceRange" value={this.state.foodPriceRange}  onChange={(event) => this.handleChangeFood(event)}>
					      								<option value="">Select Price Range</option>
														  <option value="0-10000">0-10000</option>
														<option value="10000-20000">10000-20000</option>
														<option value="20000-30000">20000-30000</option>
														<option value="30000-50000">30000-50000</option>
														<option value="50000-1000000">Above 50000</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
												  <div class="filter_fuild_bx">
					      							<select class="filter_fulid" name="foodType" value={this.state.foodType}  onChange={(event) => this.handleChangeFood(event)}>
													  <option value="">Select Type</option>
			
														  <option value="Inspirational">Inspirational</option>
														  <option value="Popular">Popular</option>
														  <option value="Recommended">Recommended</option>
					      							</select>
					      							<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
					      						</div>
					      						<div class="filter_fuild_bx">
											
												  <div class="topthink_one_inner">
						                            <div class="labelside_one_inner">
													<label ><img  loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/>Instant Confirmation</label></div>
											          <div class="topthink_two_inner">
						                             <label class="switch">
						                              	<input class="switch-input" type="checkbox" name="foodInstantConfirmation" value={this.state.foodInstantConfirmation} checked={this.state.foodInstantConfirmation}  onChange={(event) => this.handleChangeFood(event)} />
						                            	<span class="switch-label" data-on="On" data-off="Off"></span> 
						                              	<span class="switch-handle"></span> 
						                                </label>
				                                	</div>
						                                  
					                                  </div>
				
		                                         
					      						</div>
												 
				      						</div>
				      						{/* <div class="filter_shortby_right shortby_title_perent">
				      							<label >Instant Confirmation</label>
											   <div class="topthink_two_inner">
						                             <label class="switch">
						                              	<input class="switch-input" type="checkbox" name="foodInstantConfirmation" value={this.state.foodInstantConfirmation} checked={this.state.foodInstantConfirmation}  onChange={(event) => this.handleChangeFood(event)} />
						                            	<span class="switch-label" data-on="On" data-off="Off"></span> 
						                              	<span class="switch-handle"></span> 
						                                </label>
				                                	</div>
				      						</div>  */}
				      					</div>
				      					<div class="list_boxes_filter">
				      						<div class="list_boxes_filter_listing">
												<div class="row">
												{(this.state.foodActivities != null && this.state.foodActivities.length> 0 )? this.state.foodActivities.map(
                                                        influencer =>
									    			  <div class="col-md-3">
										    			<div class="popular_activities_box">
										                     <div class="imagesof_box">
										                        <img  loading="lazy" style={{"height":"160px"}}  src={IMAGE_BASE_URL+``+JSON.parse(influencer.coverImage).imageUrl} alt={JSON.parse(influencer.coverImage).altText} />
										                        <div class="activities_palcetitle">
										                           <img  loading="lazy" src="/images/map.svg" alt="map"/> {influencer.ad[0].name}
										                        </div>
										                     </div>
										                     <div class="popular_activities_content">
															 <div class="discount_div">
															 {Math.ceil(influencer.pk.discountPercantage)!==0 && <>
											<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />
										<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}
										
										</div>
										                        <h3 class="palcebox_title">{influencer.name}</h3>
										                        <div class="box_review_side">
										                           <div class="review_side_cell">
										                              <i class="fas fa-star"></i>
										                              <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												 
										                           </div>
										                           <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img  loading="lazy" src="/images/lightning.svg" height="17px" width="10px"   alt="instant"/></span></>):(<></>))}
												</p>
												
											  </div>
										                        </div>
										                     </div>
										                     <a onClick={(e)=> this.handleClickActitvity(e,'food', influencer.url)} class="onlink"></a>
										                </div>
									    			</div>): ""}


								    			</div>
				      						</div>


										    <div class="same-section palnning_and_preparation padding_top ">
									    		<h2 class="section_main_title align-left">Travel Planning & Preparations</h2>
									    		<div class="planning_four_bx">
									    			<div class="row">
													{(this.state.travelPlanning != null && this.state.travelPlanning.length> 0 )? this.state.travelPlanning.map(
                                                        travel =>
				<div class="col-md-3">
					<div class="planning_inner--image box_side_four">
					<a href={"//"+travel.valueValue}> <img  loading="lazy" src={travel.keyValue} alt="planning" /> </a>
					 </div>
				</div>
			): ""}
									    			</div>
									    		</div>
										    </div>

										    <div class="same-section traveller_guide padding_top">										    	
									    		<h2 class="section_main_title align-left">Featured Guides</h2>
				    							<div class="row">
												{(this.state.guideList != null && this.state.guideList.length> 0 )? this.state.guideList.map(
                                                        guide =>
												
				    								<div class="col-md-4">	    						
										                <div class="guidetipsbox">
										                     <div class="guide_tip_image">
										                        <img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(guide.coverImage).imageUrl} alt={JSON.parse(guide.coverImage).altText}/>
										                     </div>
										                     <div class="guide_title">
										                        <h3 class="guidetiptit">{guide.name}</h3>
										                     </div>
										                     <div class="guide_tipuser_side">
										                        <div class="guidetipusr_left">
										                           {/* <div class="images_od_user">
										                              <img  loading="lazy" src={JSON.parse(guide.profileImage).imageUrl} alt={JSON.parse(guide.profileImage).altText}/>
										                           </div> */}
										                           <span class="usertitleonly">{guide.userName}</span>
										                        </div>
										                        <div class="guidetipusr_left">
										                           <p>{guide.blogDate}</p>
										                        </div>
										                     </div>
										                     <a href={`${APP_BASE_URL}/blog/${guide.url}`} class="onlink"></a>
										                </div>
									            	</div>
				    								): ""}
				    								
				    							</div>
										    </div>
				      					</div>
				      				</div>
				      			</div>
				      		</div>
				      	</div>
				    </section>
			    </div>

			 



		</div>
		</div>



    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default Destination;
