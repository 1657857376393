import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import AuthService from '../../services/AuthService';
import { APP_BASE_URL} from '../../constants';
import Helmet from 'react-helmet';
import Form from "react-validation/build/form";
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { IMAGE_BASE_URL} from '../../constants';

class Activity extends React.Component {
  constructor(props) {

    super(props);
	this.handleClickPackageOptions = this.handleClickPackageOptions.bind(this);
	this.handleClickAddtoWishlist = this.handleClickAddtoWishlist.bind(this);
	this.handleDayClick = this.handleDayClick.bind(this);
	this.addReview = this.addReview.bind(this);
	this.onChange = this.onChange.bind(this);
	this.generateCaptcha = this.generateCaptcha.bind(this);
	this.removeSpaces = this.removeSpaces.bind(this);
	this.ValidCaptcha = this.ValidCaptcha.bind(this);
    this.state = {
	id : this.props.match.params.id,	
	userLoggedIn :  AuthService.isUserLoggedIn(),
	userId : AuthService.getCurrentUserId(),
	reviewPackageId : '',
	rating : 0,
	review : '',
	reviewMessage : false,
    modal: false,
  };
  
}


  componentDidMount() {
	this.generateCaptcha();
	console.log('here')
	// ProfileService.getActivityUrl(this.props.match.params.id).then(response=>{
	// 	if(response.data.new_url!=undefined){
	// 		// let newurl = response.data.new_url.split('/')
	// 		// console.log(newurl[newurl.length-2]);
	// 		window.location.href = response.data.new_url;
	// 	}
	// })
	console.log(this.props.match.params.id);
	ProfileService.getActivityByUrl(this.props.match.params.id).then(response=>{
		   if(response.data.id==undefined){
			   this.props.history.push(`/pageNotFound`)
			   window.location.reload();
		   }
		  var activityId = response.data.id;
          this.setState({
			  "activityId":response.data.id,
			  "activityIdToWish":response.data.id,
			  "name":response.data.name,
			  "coverImage":JSON.parse(response.data.coverImage).imageUrl,
			  "coverImageAltText":JSON.parse(response.data.coverImage).altText,
			  "gridImage":response.data.gridImage,
			  "activityCategory":response.data.activityCategory,
			  "destination" :response.data.destination,
			  "title":response.data.title,
			  "metaDesc":response.data.metaDesc
			})
			ProfileService.getStateById(response.data.state).then(response=>{
				this.setState({"state":response.data.name })
			})
			

			ProfileService.getDestination(response.data.destination).then(response=>{
				this.setState({"destination":response.data.name })
				this.setState({"destinationUrl":response.data.url })
			})
			
			ProfileService.getNonDefaultPackageListByActivityId(activityId).then(response=>{
		        
				this.setState({"otherPackageCount":response.data.length-1})
				this.setState({"otherPackageList":response.data})
			})

			ProfileService.getDefaultPackageByActivity(activityId).then(response=>{
				
				this.setState({"packageId":response.data[0].id})
				
				ProfileService.getVendorById(response.data[0].vendor_id).then(response=>{
				this.setState({"vendorName":response.data.name })
				this.setState({"vendorCompanyName":response.data.companyName })
				this.setState({"vendorTagLine":response.data.tagLine })
				
				})
                
				this.packageDetailsByActivity(response.data[0].id);

			})	

			

              ProfileService.similarActivities(activityId).then(response=>{
	              this.setState({"similarActivities":response.data})
              })

			  ProfileService.getActivityReviews(activityId).then(response=>{
				this.setState({"activityReviews":response.data})
			  })

			  
			this.setState({ activityDescription: EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.activityDescription))) })
	})


   

  }


  packageDetailsByActivity(packageId){
	   ProfileService.getPackageDetailsById(packageId).then(response=>{
		  ProfileService.getVendorById(response.data.vendor_id).then(response=>{
			this.setState({"vendorName":response.data.name })
			this.setState({"vendorCompanyName":response.data.companyName })
			this.setState({"vendorTagLine":response.data.tagLine })
			
		  })

		  this.setState({"packageId":response.data.id})
	      this.setState({"packageName":response.data.name})
	      this.setState({"booked":response.data.booked})
	      this.setState({"total_reviews":response.data.total_reviews})
	      this.setState({"pkreview":response.data.review})
	  if(response.data.dateRangeTypeFlag!=null && response.data.dateRangeTypeFlag === 'EXCLUDE'){
		//alert("dateExcludeFlag "+true)
	   this.setState({"dateExcludeFlag":true })
	   let excludeDateRange = JSON.parse(response.data.excludeDateRange);
	   var datesToFormat = [];
	   for(var i =0;i<excludeDateRange.length;i++){
		var st = excludeDateRange[i].keyValue;
		var pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
		var dt = new Date(st.replace(pattern,'$1-$2-$3'));
		datesToFormat.push(dt);
	   }
	   this.setState({"dateExclude":datesToFormat}) 
	   datesToFormat.push({before: new Date()})
	   this.setState({disabledDays:datesToFormat});
	  } else{
	  // alert("dateExcludeFlag "+false)
	   this.setState({"dateExcludeFlag":false })
	   this.setState({"dateRange":JSON.parse(response.data.dateRange) })
	   this.setState({"dateSelected":JSON.parse(response.data.dateRange)[0].keyValue})
	  }   
	 
      

	  //this.setState({"activityId":response.data.activity})
	  this.setState({"noOfAdults":1 })
	  this.setState({"noOfChilds":0 })
	  this.setState({"basePrice":response.data.basePrice })
	  this.setState({"pkreview":response.data.review })
	  this.setState({"childPrice":response.data.childPrice })
	  //alert(response.data.childPrice)
	  var totalPriceAdult =  response.data.basePrice * 1;
	  var totalPriceChild =  response.data.childPrice * 0;
	  var totalPaymentAmount = totalPriceAdult + totalPriceChild;
	  this.setState({"totalPaymentAmount":totalPaymentAmount})
	  this.setState({"updatedPrice":response.data.basePrice })
	  this.setState({"updatedChildPrice":response.data.childPrice })
	  this.setState({"unitprice":response.data.basePrice })
	  this.setState({"unitpriceChild":response.data.childPrice })
	  this.setState({"totalPrice":response.data.basePrice })
	  this.setState({"optionLable1":response.data.optionLable1 })
	  this.setState({"oV1":JSON.parse(response.data.optionValue1)})
	  this.setState({"oV2":JSON.parse(response.data.optionValue2)})
	  this.setState({"oV3":JSON.parse(response.data.optionValue3)})
	  var optionValue1 = JSON.parse(response.data.optionValue1);
	  for(var i=0;i<optionValue1.length;i++){
		  var value = optionValue1[i];
		  if(value.valueValue==0){
			 value.selectedValue = true;
		  }
	  }
	  this.setState({"optionValue1":optionValue1 })
	  this.setState({"optionLable2":response.data.optionLable2 })
	  var optionValue2 = JSON.parse(response.data.optionValue2);
	  for(var i=0;i<optionValue2.length;i++){
		  var value = optionValue2[i];
		  if(value.valueValue==0){
			 value.selectedValue = true;
		  }
	  }
	  this.setState({"optionValue2":optionValue2 })
	  this.setState({"optionLable3":response.data.optionLable3 })
	  var optionValue3 = JSON.parse(response.data.optionValue3);
	  for(var i=0;i<optionValue3.length;i++){
		  var value = optionValue3[i];
		  if(value.valueValue==0){
			 value.selectedValue = true;
		  }
	  }
	  this.setState({"optionValue3":optionValue3 })
	  
	  this.setState({"packageDescription":EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.description))) })
	  this.setState({"packageTerms":EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.terms))) })
	   })
		
		
	   
  }

  handleClickClearOptionValue(){
	 this.setState({
		selectedDay:  undefined ,dateSelected:''
	  });
	var optionValue1 = this.state.oV1;
	for(var i=0;i<optionValue1.length;i++){
		var value = optionValue1[i];
		if(value.valueValue==0){
		   value.selectedValue = true;
		}else{
			value.selectedValue = false;
		}
	}
	this.setState({"optionValue1":optionValue1 })
	var optionValue2 = this.state.oV2;
	for(var i=0;i<optionValue2.length;i++){
		var value = optionValue2[i];
		if(value.valueValue==0){
		   value.selectedValue = true;
		}else{
			value.selectedValue = false;
		}
	}
	this.setState({"optionValue2":optionValue2 })
	var optionValue3 = this.state.oV3;
	for(var i=0;i<optionValue3.length;i++){
		var value = optionValue3[i];
		if(value.valueValue==0){
		   value.selectedValue = true;
		}else{
			value.selectedValue = false;
		}
	}
	this.setState({"optionValue3":optionValue3 })
	this.setState({"noOfAdults":1 })
	this.setState({"noOfChilds":0 })
	this.updatePrice(1,0);
  }

  handleClickOptionValue(valueId,keyId){
  // alert(valueId);
	if(valueId==1){
		var optionValue = this.state.optionValue1;
		for(var i=0;i<optionValue.length;i++){
			var value = optionValue[i];
			if(value.id==keyId){
				value.selectedValue = true;
			}else{
				value.selectedValue = false;
			}
		}
		this.setState({"optionValue1":optionValue })
	}else if(valueId==2){
		var optionValue = this.state.optionValue2;
		for(var i=0;i<optionValue.length;i++){
			var value = optionValue[i];
			if(value.id==keyId){
				value.selectedValue = true;
			}else{
				value.selectedValue = false;
			}
		}
		this.setState({"optionValue2":optionValue })
	}else if(valueId==3){
		var optionValue = this.state.optionValue3;
		for(var i=0;i<optionValue.length;i++){
			var value = optionValue[i];
			if(value.id==keyId){
				value.selectedValue = true;
			}else{
				value.selectedValue = false;
			}
		}
		this.setState({"optionValue3":optionValue })
	}
	this.updatePrice(this.state.noOfAdults,this.state.noOfChilds);
//	alert(keyId);
  }
  
  decrementAdult(){
	  var adult = this.state.noOfAdults;
	  if(adult>1){
		  adult = adult-1;
		  this.setState({"noOfAdults":adult});
		  this.updatePrice(adult,this.state.noOfChilds);
	  }
  }

  decrementChild(){
	var child = this.state.noOfChilds;
	if(child>0){
		child = child-1;
		this.setState({"noOfChilds":child});
		this.updatePrice(this.state.noOfAdults,child);
	}
}

incrementAdult(){
	    var adult = this.state.noOfAdults;
		 adult = adult+1;
		this.setState({"noOfAdults":adult});
		this.updatePrice(adult,this.state.noOfChilds);
	
}

incrementChild(){
      var child = this.state.noOfChilds;
	   child = child+1;
	  this.setState({"noOfChilds":child});
	  this.updatePrice(this.state.noOfAdults,child);

}

  updatePrice(adult,child){
	var basePrice = this.state.basePrice; 
	var childPrice = this.state.childPrice; 
	var addOnOv1 = 0;
	var addOnOv2 = 0;
	var addOnOv3 = 0;
	var optionValue = this.state.optionValue1;
	for(var i=0;i<optionValue.length;i++){
		var value = optionValue[i];
		if(value.selectedValue==true){
			addOnOv1 = parseInt(value.valueValue);
		}
	}
	var optionValue2 = this.state.optionValue2;
	for(var i=0;i<optionValue2.length;i++){
		var value = optionValue2[i];
		if(value.selectedValue==true){
			addOnOv2 = parseInt(value.valueValue);
		}
	}
	var optionValue3 = this.state.optionValue3;
	for(var i=0;i<optionValue3.length;i++){
		var value = optionValue3[i];
		if(value.selectedValue==true){
			addOnOv3 = parseInt(value.valueValue);
		}
	}

	var totalAddOn = addOnOv1 + addOnOv2 + addOnOv3;

	var unitprice = parseInt(basePrice) + totalAddOn;

	var unitpriceChild = parseInt(childPrice) + totalAddOn;
	
    var totalPriceAdult =  unitprice * adult;
	
	var totalPriceChild =  unitpriceChild * child;

	var totalPaymentAmount = totalPriceAdult + totalPriceChild;
	this.setState({"totalAddOn":totalAddOn})
	this.setState({"unitprice":unitprice})
	this.setState({"unitpriceChild":unitpriceChild})
	this.setState({"totalPriceAdult":totalPriceAdult})
	this.setState({"totalPriceChild":totalPriceChild})

	this.setState({"totalPaymentAmount":totalPaymentAmount})
  }

  bookNow(){
	
	var optionValue = this.state.optionValue1;
	var selectedOption1 = "";
	for(var i=0;i<optionValue.length;i++){
		var value = optionValue[i];
		if(value.selectedValue==true){
			selectedOption1 = value.keyValue;
			break;
		}
	}
	var optionValue2 = this.state.optionValue2;
	var selectedOption2 = "";
	for(var i=0;i<optionValue2.length;i++){
		var value = optionValue2[i];
		if(value.selectedValue==true){
			selectedOption2 = value.keyValue;
			break;
		}
	}
	var optionValue3 = this.state.optionValue3;
	var selectedOption3 = "";
	for(var i=0;i<optionValue3.length;i++){
		var value = optionValue3[i];
		if(value.selectedValue==true){
			selectedOption3 = value.keyValue;
			break;
		}
	}

	let packageOptions = {"optionLabel1": this.state.optionLable1,
	"optionLabel2": this.state.optionLable2,
	 "optionLabel3": this.state.optionLable3,
	 "option1":selectedOption1,
	 "option2":selectedOption2,
	 "option3":selectedOption3
	};

	//packageOptions.push(this.state.optionValue1);
	//packageOptions.push(this.state.optionValue2);
	//packageOptions.push(this.state.optionValue3);
	var sd = this.state.dateSelected;
	//alert(sd);
	if(this.state.dateExcludeFlag){
		sd = this.state.selectedDay;
	}
	if(sd!= undefined){
	//alert(sd);
	let bookingDetails = {
		// userId : this.state.userId,
         packageId : this.state.packageId,
		 packageName : this.state.packageName,
		 dateSelected : sd,
		 dateExcludeFlag : this.state.dateExcludeFlag,
		 activityId : this.state.activityId,
		 totalPaymentAmount : this.state.totalPaymentAmount,
		 noOfAdults:this.state.noOfAdults,
         noOfChilds:this.state.noOfChilds,
         packageOptions:packageOptions,
		 basePrice : this.state.basePrice,
		 childPrice : this.state.childPrice,
		 totalAddOn : this.state.totalAddOn,
		 unitprice : this.state.unitprice,
		 unitpriceChild : this.state.unitpriceChild,
		 totalPriceAdult : this.state.totalPriceAdult,
		 totalPriceChild : this.state.totalPriceChild,
         payementStatus:'PENDING',
         bookingStatus:'PENDING'
	}
	localStorage.setItem("bkd"+this.state.packageId, JSON.stringify(bookingDetails));
	var id = this.state.packageId;
	this.props.history.push(`/travelInfo/${id}`)
     }else{
		 alert("Please select Date.")
	 }
    

  }


  handleDayClick(day, { selected }) {
  //alert("inn"+day)
  this.setState({
	selectedDay: selected ? undefined : day,
  });

}
 

  handleClickActitvity(e, id){
	this.props.history.push(`/activity/${id}`)
	window.location.reload();
  }

generateCaptcha() {  
	
	var chr1 = Math.ceil(Math.random() * 10) + '';  
	var chr2 = Math.ceil(Math.random() * 10) + '';  
	var chr3 = Math.ceil(Math.random() * 10) + '';  

	var str = new Array(4).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); });  
	var captchaCode = str + chr1 + ' ' + chr2 + ' ' + chr3;  
	document.getElementById("txtCaptcha").value = captchaCode  
}  

/* Validating Captcha Function */  
 ValidCaptcha() {  
	var str1 = this.removeSpaces(document.getElementById('txtCaptcha').value);  
	var str2 = this.removeSpaces(document.getElementById('txtCompare').value);  

	if (str1 == str2) return true;  
	return false;  
}  

/* Remove spaces from Captcha Code */  
 removeSpaces(string) {  
	return string.split(' ').join('');  
}  

  addReview(e){
	 e.preventDefault();
	 if(this.state.rating==0){
           this.setState({"ratingError":true})
	 }else{
		this.setState({"ratingError":false})
		 if(this.state.review==''){
			this.setState({"reviewError":true})
		 }else{
			this.setState({"reviewError":false})
			if(this.ValidCaptcha()){
				var userId = null;
				if(this.state.userLoggedIn){
					userId = this.state.userId;
				}
				var packageId = this.state.reviewPackageId;
				if(this.state.reviewPackageId==''){
					packageId = this.state.packageId;
				}
				let dto = {
					userId : userId,
					packageId : packageId,
					activityId : this.state.activityId,
					noOfStar : this.state.rating,
					review : this.state.review
				}
				ProfileService.addReview(dto).then(response=>{
					this.setState({"rating":0,"review":'',"reviewMessage":true});
				})
				this.generateCaptcha();
				this.setState({"captchError":false})
			}else{
				this.setState({"captchError":true})
			}
			
		 }
	 }
	
  }

  onChange(e){
	this.setState({
		[e.target.name]: e.target.value
	  });
  }

  handleClickAddtoWishlist(e,id){
    if(this.state.userLoggedIn){
		let dto = {
			userid : this.state.userId,
			activity : this.state.activityIdToWish,
			packageId : this.state.packageId
		}
		ProfileService.addToWishlist(dto).then(response=>{
			alert("Activity Added to wishlist")
		})
	}else{
       alert("Please login first.");
	}
  }

  handleClickPackageOptions(e, id){
	
	//	this.props.history.push(`/packageOptions/${id}`)
	//	window.location.reload();
	this.packageDetailsByActivity(id);

  }



  handleClickDestination(e,id){
	this.props.history.push(`/destination/${id}`)
	window.location.reload();
  }



  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
	<Helmet>
    <title>{this.state.title}</title>
    <meta name='description' content={this.state.metaDesc} />
    </Helmet>
    <div class="main_all">
    <Header></Header>
   
	<section class="same-section destinationpage_slider">
		  	<div class="same-section topside_banner--slider">
		  		<div class="activity_banner" >
		  			<div class="item"><img  loading="lazy" src={IMAGE_BASE_URL+``+this.state.coverImage} alt={this.state.coverImageAltText}/></div>
		  			
		  		</div>
		  	</div>
		</section>

		<section class="same-section desination_perent--details">
			<div class="container">
				<div class="row onlydetail_main_row">
					<div class="col-md-8 desti_detail_mainleft">
						<div class="desination_perent--details--Linn">
					        <div class="bredcrumb_custom same-section small_padding_top">
					            <ul class="custbread">
					              <li><a href="/">Home</a></li>
					              <li><i class="fas fa-chevron-right"></i></li>
					              <li><a href="/destination">Destinations</a></li>
					              <li><i class="fas fa-chevron-right"></i></li>
					              <li><a onClick={(e)=> this.handleClickDestination(e, this.state.destinationUrl)}>{this.state.destination}</a></li>
								  <li><i class="fas fa-chevron-right"></i></li>
								  <li class="active">{this.state.name}</li>
					            </ul>
					        </div>

							<div class="same-section heading_side">
								<h3 class="palcebox_title get-sky">{this.state.name}</h3>
								<h2 class="detail_in_title forecast">{this.state.name}</h2>
								<h3 class="totlaprice--details activity_base_price_mobile">₹ {this.state.basePrice}</h3>
								<div class="rating_detail_bx">
									<div class="rating_detail_bx--left">
										<span><i class="fas fa-star"></i>&nbsp;{this.state.pkreview}</span><span>({this.state.total_reviews} reviews)</span><span>{this.state.booked} Booked</span>
									</div>
									<div class="rating_detail_bx--right">
										<a onClick={(e)=> this.handleClickAddtoWishlist(e, this.state.activityId)}><i class="far fa-heart"></i> Wishlist</a>
									</div>
								</div>
								
								{( this.state.otherPackageCount == 0 ? (<><p style={{"margin-top":"15px;"}}>Sold by <a href="#" data-toggle="modal" data-target="#myModal01">{this.state.vendorName}</a></p></>):(<><p style={{"margin-top":"15px;"}}>Sold by <a href="#" data-toggle="modal" data-target="#myModal01">{this.state.vendorName}</a> and {this.state.otherPackageCount} similar <a href="#" data-toggle="modal" data-target="#myModal">offers</a> Starting from {this.state.basePrice}</p></>) )}
								

								
								
								
								<div class="popular_activities_price  act_mobile_price_none">
                           			<p class="pricesid">₹ {this.state.basePrice} </p>
                        		</div>
								
								<div class="service_list_wIcon">
									<ul class="listwith_icon">
										<li><span class="service_typeic"><img  loading="lazy" height="16px" width="16px" src="/images/ruees.svg" alt="rupee" /></span><span class="service_type">Conditional Cancelation*</span></li>
										<li><span class="service_typeic"><img  loading="lazy" height="16px" width="16px" src="/images/printer.svg" alt="print" /></span><span class="service_type">Print Voucher</span></li>
										<li><span class="service_typeic"><img  loading="lazy" height="16px" width="16px" src="/images/calender.svg" alt="calender" /></span><span class="service_type">Collect Physical Ticket</span></li>
										<li><span class="service_typeic"><img  loading="lazy" height="16px" width="16px" src="/images/ticket.svg" alt="ticket" /></span><span class="service_type">Open Date Ticket</span></li>
									</ul>
								</div>
								

                            

								
							</div>

							





							
						
							

						</div>
					</div>

					<div class="col-md-4 desti_detail_mainright">
						<div class="select_option--details small_padding_top">
							<div class="select_option--price">
								<h3 class="totlaprice--details">₹ {this.state.basePrice}</h3>
								<ul class="listside_icon small_padding_top small_margin_bottom">
						
									
								</ul>
								<div class="select_op_button">
									<a onClick={(e)=> this.bookNow()}  class="green_button" >Book Now</a>
								</div>
							</div>
							
							{/* <div class="same-section downloadapp--details small_margin_top">
								<div class="download_flex">
									<div class="icondwnload">
										<img src="/images/download-mobile.svg" align="Image-Icon"/>
									</div>
									<div class="contentdownload">
										Get ₹100 off on your first Mobile App booking with promo code <span>mob10</span>
									</div>
								</div>
								<div class="downloadlick--app">
									<a href="#"><img src="/images/download-app.svg" alt="Image-Icon"/> Download App</a>
								</div>
							</div> */}
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="same-section desination_perent--details">
 <div class="sticky-top-bar" id="sticky-variant-table-bar"><div class="container" id="sticky-top-bar-container"><span class="top-bar-item top-bar-item-active"><a href="#section-itr" class="js-anchor-link"><button type="button" data-section="itinerary">Itinerary</button></a></span><span class="top-bar-item "><a href="#section-pkd" class="js-anchor-link"><button type="button" data-section="package-options">Package Options</button></a></span><span class="top-bar-item "><a href="#section-rw" class="js-anchor-link"><button type="button" data-section="map">Reviews</button></a></span><span id="policy-menu" class="top-bar-item "><a href="#section-sa" class="js-anchor-link"><button type="button" data-section="policies">Similar Activities</button></a></span></div></div>
			<div class="container">
				<div  class="row onlydetail_main_row">
					<div class="col-md-8 desti_detail_mainleft">
                              <div id="section-itr" class="simple_list">
								<Editor toolbarHidden={true} editorState={this.state.activityDescription} readOnly={true} />
								</div>
								<div id="section-pkd" class="same-section package_optionD--details padding_top">
								<h3 class="title--leftborder">Package Options</h3>
								<div class="detail_packag--descs">
									<div class="pack_ttile--clearall">
										<div class="row">
											<div class="col-md-8">
												 <span class="package_subsT">Select date and package options</span> 
											</div>
											<div class="col-md-4">
												<a class="clear_butD" onClick={() => this.handleClickClearOptionValue()}><i class="fas fa-redo"></i>&nbsp; Clear All</a>
											</div>
										</div>
									</div>
								   <div class="label--withdetail">
										<h6 class="label--titleS">Please select date of departure</h6>
										<div class="label-desc--bx">
										{(this.state.dateExcludeFlag == false ? (<><select class="filter_fulid_date" required name="dateSelected" value={this.state.dateSelected}   onChange={this.onChange} >
			                    						

										{( this.state.dateRange != null && this.state.dateRange.length> 0 )? this.state.dateRange.map(
                                    hl =><>
									<option value={hl.keyValue}>{hl.keyValue}</option>
                         
                           </>
						   ):""}
										</select>	</>):(<>
										 
											<DayPicker  selectedDays={this.state.selectedDay}
     
      disabledDays={this.state.disabledDays}
	  onDayClick={this.handleDayClick}
    />
										</>) )}
										</div>
									</div>								

									<div class="label--withdetail">
										<h6 class="label--titleS">{this.state.optionLable1}</h6>
										<div class="label-desc--bx">
											<ul class="label_typeL">
											{(this.state.optionValue1 != null && this.state.optionValue1.length> 0 )? this.state.optionValue1.map(
												 hl =><>
                                              {( hl.selectedValue === true  ? (<><li class="active" onClick={() => this.handleClickOptionValue(1,hl.id)}>{hl.keyValue}</li></>) :(<><li onClick={() => this.handleClickOptionValue(1,hl.id)}>{hl.keyValue}</li></>) )}
												
												
												</>):""}
											</ul>
										</div>
									</div>							
									<div class="label--withdetail">
										<h6 class="label--titleS">{this.state.optionLable2}</h6>
										<div class="label-desc--bx">
											<ul class="label_typeL">
											{(this.state.optionValue2 != null && this.state.optionValue2.length> 0 )? this.state.optionValue2.map(
												 hl =><>
                                              {( hl.selectedValue === true ? (<><li class="active" onClick={() => this.handleClickOptionValue(2,hl.id)}>{hl.keyValue}</li></>) :(<><li onClick={() => this.handleClickOptionValue(2,hl.id)}>{hl.keyValue}</li></>) )}
												
												
												</>):""}
											</ul>
										</div>
									</div>							
									<div class="label--withdetail">
										<h6 class="label--titleS">{this.state.optionLable3}</h6>
										<div class="label-desc--bx">
											<ul class="label_typeL non_inline">
											{(this.state.optionValue3 != null && this.state.optionValue3.length> 0 )? this.state.optionValue3.map(
												 hl =><>
                                              {( hl.selectedValue === true  ? (<><li class="active" onClick={() => this.handleClickOptionValue(3,hl.id)}>{hl.keyValue}</li></>) :(<><li onClick={() => this.handleClickOptionValue(3,hl.id)}>{hl.keyValue}</li></>) )}
												
												
												</>):""}
											</ul>
										</div>
									</div>
									<div class="label--withdetail">
									<div class="manage_row_flex padding_leftang_right">
                                      <div class="manage_row_bx">
                                       <div class="titile_content_log_method">
                                       <span class="qty_print">Adult</span>
									   <span class="qty_print">₹ {this.state.unitprice}</span>
                                      </div>
                                      </div>
									  
                                          <div class="manage_edit_row_bx">
                     	                    <div class="add_lase_bx" >
						                  
											 <span class="qty_print">{this.state.noOfAdults}</span>
	                                             <a  onClick={() => this.incrementAdult()} class="tabplus_minus"><i class="fas fa-plus"></i></a>
	                                             <a onClick={() => this.decrementAdult()}  class="tabplus_minus"><i class="fas fa-minus"></i></a>
                    	                    </div>
                                          </div>
                                     </div>		
									 </div>	
									 <div class="label--withdetail">
									<div class="manage_row_flex padding_leftang_right">
                                      <div class="manage_row_bx">
                                       <div class="titile_content_log_method">
                                       <span class="qty_print">Child(6-11)</span>
									   <span class="qty_print">₹ {this.state.unitpriceChild}</span>
                                      </div>
                                      </div>
									  
                                          <div class="manage_edit_row_bx">
                     	                    <div class="add_lase_bx" >
						                  
	                     	                 <span class="qty_print">{this.state.noOfChilds}</span>
	                                             <a onClick={() => this.incrementChild()} class="tabplus_minus"><i class="fas fa-plus"></i></a>
	                                             <a onClick={() => this.decrementChild()}  class="tabplus_minus"><i class="fas fa-minus"></i></a>
                    	                    </div>
                                          </div>
                                     </div>		
									 </div>										
								</div>
							</div>
							<div class="same-section Itnery_optionD--details padding_top">
							<Editor toolbarHidden={true} editorState={this.state.packageDescription} readOnly={true} />
							</div>
							<div class="same-section what_expect padding_top what-exp-type">
							<Editor toolbarHidden={true} editorState={this.state.packageTerms} readOnly={true} />
							</div>
							
							<div id="section-rw" class="same-section guest_review padding_top">
								<h3 class="title--leftborder small_margin_bottom">Guest Review : {(this.state.activityReviews != null  ? (<>{this.state.activityReviews.length}</>):(<>0</>))}</h3>
								{(this.state.activityReviews != null && this.state.activityReviews.length> 0 )? this.state.activityReviews.map(
												 hl =><>
								<div class="reviews_flex_bx">
									<div class="reviews_user_img">
										<div class="user_imgbx">
											<img  loading="lazy" src="/images/guidetip-user.jpg" alt="Image-Icon"/>
										</div>
									</div>
									<div class="user_name_rate">
										<h4>{hl.userName}</h4>
										<ul class="starrate_sides">
										{( hl.noOfStar == 5?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( hl.noOfStar == 4?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( hl.noOfStar == 3?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( hl.noOfStar == 2?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( hl.noOfStar == 1?  (<><li><i class="fas fa-star"></i></li></>):(<></>) )}
										
										</ul>
									</div>
								</div>
								<div class="rate_desc_conmtent">
									<p>{hl.review}</p>
								</div>
								<br></br>
								</>):""}
								
								
							</div>
							
							<div class="add_review">
							<h3 class="small_margin_bottom">Add Review</h3>
							<Form onSubmit={this.addReview} ref={c => {this.form = c;}}>
							<fieldset class="rating">
    <input type="radio" id="star5" name="rating" value="5" onChange={this.onChange} /><label class = "full" for="star5" title="Awesome - 5 stars"></label>
    {/* <input type="radio" id="star4half" name="rating" value="4 and a half" onChange={this.onChange}  /><label class="half" for="star4half" title="Pretty good - 4.5 stars"></label> */}
    <input type="radio" id="star4" name="rating" value="4" onChange={this.onChange}  /><label class = "full" for="star4" title="Pretty good - 4 stars"></label>
    {/* <input type="radio" id="star3half" name="rating" value="3 and a half" onChange={this.onChange}  /><label class="half" for="star3half" title="Meh - 3.5 stars"></label> */}
    <input type="radio" id="star3" name="rating" value="3" /><label class = "full" for="star3" title="Meh - 3 stars"></label>
    {/* <input type="radio" id="star2half" name="rating" value="2 and a half" onChange={this.onChange}  /><label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label> */}
    <input type="radio" id="star2" name="rating" value="2" onChange={this.onChange}  /><label class = "full" for="star2" title="Kinda bad - 2 stars"></label>
   {/*  <input type="radio" id="star1half" name="rating" value="1 and a half" onChange={this.onChange}  /><label class="half" for="star1half" title="Meh - 1.5 stars"></label> */}
    <input type="radio" id="star1" name="rating" value="1" onChange={this.onChange}  /><label class = "full" for="star1" title="Sucks big time - 1 star"></label>
   {/*  <input type="radio" id="starhalf" name="rating" value="half" onChange={this.onChange}  /><label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label> */}
</fieldset>
{( this.state.ratingError == true ?  (<div style={{"color":"#55aeb3"}}><p>Please provide rating.</p></div>):(<></>) )}
<select style={{"margin-top":"10px","margin-bottom":"10px"}} class="filter_fulid" required name="reviewPackageId" value={this.state.reviewPackageId}  onChange={this.onChange}>
{(this.state.otherPackageList != null && this.state.otherPackageList.length> 0 )? this.state.otherPackageList.map(
												 hl =><>			
<option value={hl._id}>{hl.vd[0].name} ( {hl.name} )	</option>								
													
  </>):""}
  </select>
  <textarea id="w3review" name="review" rows="4" cols="50" value={this.state.review} onChange={this.onChange}>
</textarea>
{( this.state.reviewError == true ?  (<div  style={{"color":"#55aeb3"}}><p>Please enter your review.</p></div>):(<></>) )}
<div class="btnrefresh">
<input  class="filter_fulid captchaMy" type="text" disabled  id="txtCaptcha"  />  
<img id="btnrefresh"  onClick={e =>this.generateCaptcha()} loading="lazy" src="/images/refresh-icon.png" height="30px" width="30px"   alt="refresh"/>
</div>
<input class="filter_fulid row" style={{"width":"170px","margin-left":"0px"}} type="text" name="captchatext" id="txtCompare" placeholder="Enter Captcha" value={this.state.captchatext}  onChange={this.onChange} />  
{( this.state.captchError == true ?  (<div  style={{"color":"#55aeb3"}}><p>Invalid Captcha.</p></div>):(<></>) )}



<button type="submit" class="btn btn-primary" >Save</button>
{( this.state.reviewMessage == true ?  (<div  style={{"color":"#55aeb3","margin-top":"10px"}}><p>Thank you for Review Submission. It will be displayed after approval.</p></div>):(<></>) )}
</Form>
							</div>
							
							<div id="section-sa" class="same-section import_faqs--details padding_top padding_bottom">
								<h3 class="title--leftborder">Other similar experiences</h3>
								<div class="same-section other_similar--details small_padding_top">
									<div class="row">
									{(this.state.similarActivities != null && this.state.similarActivities.length> 0 )? this.state.similarActivities.map(
                                    influencer =>
						    			<div class="col-md-4 col-sm-12">
							    			<div class="popular_activities_box">
							                     <div class="imagesof_box">
												 <h3 class="similar_act_name">{influencer.name}</h3>
							                        <img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(influencer.gridImage).imageUrl} alt={JSON.parse(influencer.gridImage).altText}/>
							                        <div class="activities_palcetitle">
							                           {/* <img src="/images/map.svg" alt=""/> Lucknow */}
							                        </div>
							                        <div class="desti_km">
						                           {/* <span>100</span> Km away */}
						                        </div>
							                     </div>
							                     <div class="popular_activities_content">
												 <div class="discount_div">
									 	<img  loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />	
										<span class="discount"> {influencer.pk.discountPercantage}<sup>%</sup> <sub>OFF</sub></span>
										</div>
							                        <h3 class="palcebox_title">{influencer.name}</h3>
							                        <div class="box_review_side">
							                           <div class="review_side_cell">
							                              <i class="fas fa-star"></i>
							                              <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												 
							                           </div>
													   <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img  loading="lazy" height="17px" width="10px"   src="/images/lightning.svg" alt="instant"/></span></>):(<></>))}
												</p>
												
											  </div>

							                           
							                        </div>
							                     </div>

							                     {( influencer.activityCategory_id=='611b452a4fdde75b279725eb' ?  (<a  href={`${APP_BASE_URL}/staycation/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611b45344fdde75b279725ec' ?  (<a  href={`${APP_BASE_URL}/tours/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611ba6cd14e97e569e41eaac' ?  (<a  href={`${APP_BASE_URL}/activities/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c3ceaf59f69875e2abd' ?  (<a  href={`${APP_BASE_URL}/transport/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c55eaf59f69875e2abe' ?  (<a  href={`${APP_BASE_URL}/food/${influencer.url}`}  class="onlink"></a>):(<></>) )}

												 
							                </div>
						    			</div>):""}

						    			
					    			</div>
								</div>
							</div>
							
							
							
							
					</div>		
                 </div>
            </div>		
			</section>


		<section class="bottobfix--booknow">
			<div class="container">
				<div class="bottobfix--booknow_flex">
				  
					<div class="complate_allrequerd">
					<div class="rupeeqts">
            			<span>₹ {this.state.totalPaymentAmount}</span>
            		</div>
						 
					</div>
					<div class="bottom_side--fixbutton wishmobile">
						<a onClick={(e)=> this.handleClickAddtoWishlist(e, this.state.activityId)} class="wishlist_button"><i class="far fa-heart"></i></a>
					</div>
					<div class="bottom_side--fixbutton booknowmobile">
						<a onClick={() => this.bookNow()}   class="green_button">Book Now</a>
					</div>
					{/* <div class="editside_bookfix">
						<a href="#"><i class="far fa-edit"></i> &nbsp;Edit Selected Options</a>
					</div> */}
				</div>
			</div>
			</section>


    <HomeFooter></HomeFooter>

	<div class="modal" id="myModal">
    <div class="modal-dialog modal-lg mycustom_model_postion">
      <div class="modal-content">        
        
        <div class="modal-body seller_info--model">
          <div class="seller_info_inner">
          	<div class="seller_model_head">
          		<div class="seller_head_bx align-left">
          			<h5>Price + Delivery</h5>
          		</div>
          		<div class="seller_head_bx align-left">
          			<h5>Type</h5>
          		</div>
          		<div class="seller_head_bx align-left">
          			<h5>Seller Information</h5>
          		</div>
          	</div>
          	<div class="seller_info_flex_perent">
			  {(this.state.otherPackageList != null && this.state.otherPackageList.length> 0 )? this.state.otherPackageList.map(
												 hl =><>
	          	<div class="seller_info_flex">
	          		<div class="seller_model_bx align-left">
	          		<a data-dismiss="modal" onClick={(e)=> this.handleClickPackageOptions(e, hl._id)}  >	<h5>₹  {hl.basePrice}</h5> <p>{hl.packageUSP}</p></a>
	          			
	          		</div>
	          		<div class="seller_model_bx align-left">
	          		<a data-dismiss="modal" onClick={(e)=> this.handleClickPackageOptions(e, hl._id)}  >		<h4>{hl.type}</h4></a>
	          		</div>
	          		<div class="seller_model_bx align-left">
	          		<a data-dismiss="modal" onClick={(e)=> this.handleClickPackageOptions(e, hl._id)}  >		<h4>{hl.vd[0].name}</h4></a>
	          			<div class="sallerstar_rate">
	          				<ul>

							  {( Math.ceil(hl.review) == 5?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(hl.review) == 4?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(hl.review) == 3?  (<><li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(hl.review) == 2?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(hl.review) == 1?  (<><li class="active"><i class="fas fa-star"></i></li>
														<li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
								  {(  Math.ceil(hl.review) == 0?  (<><li><i class="fas fa-star"></i></li>
														<li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
							
	          					
	          					<li><strong>{hl.review} Rating</strong></li>
	          				</ul>
	          			</div>
	          			<p>in the last 12 months ({hl.total_reviews} total ratings)</p>
	          		</div>
	          	</div>
				  </>):""}

	          	
	          
	          	
          	</div>
          </div>
        </div>   
      </div>
      <button type="button" class="close closecustom--css" data-dismiss="modal">&times;</button>
    </div>
    </div>
     

	 <div class="modal" id="myModal01">
    <div class="modal-dialog modal-lg mycustom_model_postion">
      <div class="modal-content">        
      
        <div class="modal-body seller_info--model">
          <div class="seller_info_inner">
          	<div class="seller_info_flex_perent  small_padding_top small_margin_bottom">
	          	<div class="seller_namedetail_flex">
	          		<div class="seller_namedetail_left">
	          			<h2>{this.state.vendorName}</h2>
	          			<h3>{this.state.vendorCompanyName}</h3>
	          		</div>
	          		<div class="seller_namedetail_right">
		          		<div class="seller_model_bx align-left">
		          			<div class="sallerstar_rate">
		          				<ul>
								  {( Math.ceil(this.state.pkreview) == 5?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(this.state.pkreview) == 4?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(this.state.pkreview) == 3?  (<><li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(this.state.pkreview) == 2?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(this.state.pkreview) == 1?  (<><li class="active"><i class="fas fa-star"></i></li>
														<li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
								  {(  Math.ceil(this.state.pkreview) == 0?  (<><li><i class="fas fa-star"></i></li>
														<li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
		          					<li><strong>{this.state.pkreview} Rating</strong></li>
		          				</ul>
		          			</div>
		          			<p>in the last 12 months</p>
		          		</div>
	          		</div>
	          	</div>
	          	<p class="content_of_saller_name_datils">{this.state.vendorTagLine}</p>
          	</div>
          </div>
        </div>   
      </div>
      <button type="button" class="close closecustom--css" data-dismiss="modal">&times;</button>
    </div>
     </div>
 

	
    </div>
    </>
  );
  }
}

export default Activity;
