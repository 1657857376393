import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import ProfileService from '../../services/ProfileService'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
	this.handleSubmit = this.handleSubmit.bind(this);
	this.handleChangeSubEmail = this.handleChangeSubEmail.bind(this);
    this.state = {
	email:'',	
    modal: false,
	success: false,
  };
  
}


  componentDidMount() {
    
  }
  handleSubmit(e){
    e.preventDefault();
	ProfileService.submitForgotPasswordRequest(this.state.email).then(response=>{
	//	alert(response.data.message);
		if(response.data.responseCode==='FAILURE'){
			this.setState({"email":"","success":false,"message":response.data.message});
		}else{
			this.setState({"email":"","success":true});
		}
		

	})
  }

  handleChangeSubEmail(e){
	this.setState({"email":e.target.value})
}

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>

	<section class="same-section invite_your_friendsB innerpage_banner update-pass">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
		    		<h1 class="inner_banner_heding text-align">Forgot Password</h1>
		    		
	    		</div>
	    	</div>
	    </section>

	    <section class="same-section what_are_renok text-align padding_top padding_bottom bg-gray referal-code">
	    	<div class="container">
	    		<div class="forgot-password">
	    			<h3 class="form_label_heading padding_leftang_right">Enter Registered Email to Receive Reset Password Link</h3>
	    			<form autocomplete="off" onSubmit={this.handleSubmit}>
					  <div class="form-row">
					    <div class="form-group col-md-12">
					      <label>Email</label>
					      <input type="email" class="form-control" required name="email" onChange={(event) => this.handleChangeSubEmail(event)} value={this.state.email} placeholder="Please enter email address" autocomplete="off" />
					    </div>
				
					  </div>
					  <div class="confirms">
					  {( this.state.success == true ?  (<p>Request Accepted. Please check your all mails or spam mail, in case the mail does not arrive in your inbox in 1-2 mins.</p>):(<><p>{this.state.message}</p></>) )}
					  	
					  	 <button type="submit" class="btn btn-primary con-btn">Submit</button>
					  </div>
 
		</form>
	    		</div>
	    		
	    	</div>
	    </section>


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default ForgotPassword;
