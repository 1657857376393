import React from 'react';
import ProfileService from '../../services/ProfileService';
import Select from 'react-select'
import { APP_BASE_URL} from '../../constants';
import AuthService from '../../services/AuthService'

class HomeFooter extends React.Component {
  constructor(props) {
    super(props);
	this.handleClickDescInSearch = this.handleClickDescInSearch.bind(this);
	this.handleSubmitSubs = this.handleSubmitSubs.bind(this);
	this.handleChangeSubEmail = this.handleChangeSubEmail.bind(this);
    this.state = {
    modal: false,
	userLoggedIn :  AuthService.isUserLoggedIn(),
	userName : AuthService.getCurrentUserName(),
	userId : AuthService.getCurrentUserId(),
	profileImageUrl : "images/user-account.jpg",
	isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
  };
  
}


  componentDidMount() {
	ProfileService.getAllDestinations().then(response=>{
		//console.log(response.data);
		
		let popularActivities = [];
		for(let i = 0; i < response.data.length; i++){
			let value = response.data[i].url;
			let label = response.data[i].name;
			let skillS = { value : value ,label : label }
			//alert(value);
			//alert(label);
			popularActivities.push(skillS);
			
		  }
		  this.setState({"destinationForSearch":popularActivities})
	})
	
    ProfileService.getStatesListWithDestinations('East_India').then(response=>{
		this.setState({"stateListWithDesEI":response.data})
	})

	ProfileService.getStatesListWithDestinations('North_India').then(response=>{
		this.setState({"stateListWithDesNI":response.data})
	})
	ProfileService.getStatesListWithDestinations('South_India').then(response=>{
		this.setState({"stateListWithDesSI":response.data})
	})
	ProfileService.getStatesListWithDestinations('Western_India').then(response=>{
		this.setState({"stateListWithDesWG":response.data})
	})
	ProfileService.getStatesListWithDestinations('Central_India').then(response=>{
		this.setState({"stateListWithDesCI":response.data})
	})
  }
  handleSubmitSubs(e){
    e.preventDefault();
	if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.subscribeEmail)){
	ProfileService.subscribeEmail(this.state.subscribeEmail).then(response=>{
		//alert(response.data.message)
		if(response.data.message=='Subscription Added!') {
			this.setState({"subscribeEmail":"","subscribeEmailSuccess":true,"subSuccessMessage":"Thank you for Subscription."})
		} else{
			this.setState({"subscribeEmail":"","subscribeEmailSuccess":true,"subSuccessMessage":"Email Id already subscribed."})
		}

	})
   }else{
	this.setState({"subscribeEmailSuccess":true,"subSuccessMessage":"Invalid Email Address."})
   }
  }
  handleChangeSubEmail(e){
	this.setState({"subscribeEmail":e.target.value})
}

  handleChangeSearchDes(value){
	 // alert(value.value)
	if(value!=null){
		var id = value.value;
		//alert(id)
		window.location.href=`${APP_BASE_URL}/destination/${id}`;
	    //window.location.reload();
	}
  }

  handleClickDescInSearch(e,id){
	window.location.href=`${APP_BASE_URL}/destination/${id}`;
  }
  logout = () => {
    AuthService.logout();
    window.location.href=`${APP_BASE_URL}/slogin`;
  }


  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>


			
		
		   <footer class="footer">
		   <img loading="lazy" class="cloudimage" src={APP_BASE_URL + '/images/cloudwhite.png'} alt="cloud"/>
		   <img loading="lazy" class="cloudimage2" src={APP_BASE_URL + '/images/cloudwhite.png'} alt="cloud"/>
		   <img loading="lazy" class="cloudimage3" src={APP_BASE_URL + '/images/cloudwhite.png'} alt="cloud"/>
		   <img loading="lazy" class="cloudimage4" src={APP_BASE_URL + '/images/cloudwhite.png'} alt="cloud"/>
		   <img loading="lazy" class="cloudimage5" src={APP_BASE_URL + '/images/cloudwhite.png'} alt="cloud"/>
		   <img loading="lazy" class="cloudimage6" src={APP_BASE_URL + '/images/cloudwhite.png'} alt="cloud"/>

		   <img loading="lazy" class="factoryimage" src={APP_BASE_URL + '/images/factorywithplane.png'} alt="factory"/>
		   <img loading="lazy" class="monsterimage" src={APP_BASE_URL + '/images/monster.png'} alt="monster"/>
		   <img loading="lazy" class="windmillimage" src={APP_BASE_URL + '/images/windmill.png'} alt="windmill"/>
		   {/* <img class="plane1image" src={APP_BASE_URL + '/images/plain1.png'} alt="plane"/> */}
		   <img loading="lazy" class="plane2image" src={APP_BASE_URL + '/images/plain2.png'} alt="plane"/>
		   {/*<img class="plane3image" src={APP_BASE_URL + '/images/plain3.png'} alt="plane"/>
		    <img class="plane4image" src={APP_BASE_URL + '/images/plain4.png'} alt="plane"/> */}
	    	<div class="container">
	    		<div class="row">
	    			<div class="col-md-4 left_part_footer footer_common_padding">
	    				<div class="footerlogo_side_flex">
		    				<div class="footer_logo_bx">
		    					<a href="#">
		    						<img loading="lazy" src={APP_BASE_URL + '/images/logo.svg'} alt="logo"/>
		    					</a>
		    				</div>
		    				<div class="footer_logo_quote">
		    						<p>We believe there is a difference between a tourist & a traveller. We cater to travellers.
		    						<img loading="lazy" src={APP_BASE_URL + '/images/quote-footer.svg'} alt="quote-footer"/></p>
		    				</div>
    					</div>
    					<div class="footer_subs_bx">
	    					<div class="contentsubs_side">
	    						<p>Travel discounts up to 60% off sent straight to your inbox</p>
	    						<p>By clicking Subscribe, you have agreed to our Terms & Conditions and Privacy Policy</p>
	    					</div>
	    					<div class="subs_fuildside">
							<form onSubmit={this.handleSubmitSubs} >
							   <input type="email" class="subsfuil" required name="subscribeEmail" onChange={(event) => this.handleChangeSubEmail(event)} value={this.state.subscribeEmail} placeholder="Enter your email address" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your email address'" />
							   <button class="subs_but" type="submit">Subscribe</button>
							   {( this.state.subscribeEmailSuccess == true ?  (<div class="contentsubs_side"><p>{this.state.subSuccessMessage}</p></div>):(<></>) )}
							   </form>
	    					</div>
	    				</div>
    					
	    			</div>
	    			<div class="col-md-8 right_part_footer footer_common_padding">
	    				<div class="row">
	    					<div class="col-sm-3">
	    						<div class="footer_link_bx">
	    							<h3 class="footerlink_title">About Renok</h3>
	    							<ul class="footerlink_list">
	    								<li><a href="/about-us"><span>About Us </span></a></li>
	    								
	    								<li><a href="/blog"><span>Renok Blog </span></a></li>
	    								
	    								<li><a href="/career"><span>Careers </span></a></li>	    								
	    							</ul>
	    						</div>
	    					</div>
	    					    					
	    					<div class="col-sm-3">
	    						<div class="footer_link_bx">
	    							<h3 class="footerlink_title">Terms of Use</h3>
	    							<ul class="footerlink_list">
	    								<li><a href="/privacy-policy"><span>Terms And Conditions </span></a></li>
	    								<li><a href="/privacy-policy"><span>Privacy Policy </span></a></li>
	    								<li><a href="/privacy-policy"><span>Cookie Policy </span></a></li>   								
	    							</ul>
	    						</div>
	    					</div>
	    					<div class="col-md-6">
	    						<div class="row">
			    					<div class="col-md-12">
			    						<div class="askrenok_footer">
			    							<a href="/ask-renok">Ask Renok</a>
			    						</div>
			    						<div class="footer_link_bx ft-address">
			    							<h6>Delhi Address:</h6>
			    							<p>R294, Jal Vayu Vihar, Sector 21, Noida, PIN 201301</p><br></br>
			    							<h6>Phone:</h6>
			    							<p>+918750551168</p>
			    						</div>
			    					</div>

		    					</div>
	    					</div>
	    					
	    					<div class="col-md-12">
	    						<div class="footer_copy_right">
	    							<p>© 2011 - Present Renok Adventures Pvt Ltd. All Rights Reserved </p>
	    						</div>
	    					</div>
	    				</div>
	    			</div>
	    		</div>
	    	</div>
	    </footer>
		{/* <div id="homevideo--model" class="modal fade homevideo--model" tabindex="99" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
		  <div class="modal-dialog">
		    <div class="modal-content">
		      <iframe width="" height="" src="https://www.youtube.com/embed/DHJnKlGlAWU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		    </div>
		  </div>
		  	<button type="button" class="closevideo_button" data-dismiss="modal"><i class="fas fa-times"></i></button>
		</div> */}


		<div id="homesearch--model" class="modal fade homevideo--modell" tabindex="99" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
		  <div class="modal-dialog modal-lg">
		    <div class="modal-content">
		    	<div class="hover_search_inSide--search">
		    		<div class="search_filtr">
		    			<div class="filter_fuild_bx">
						<Select 
						   className="basic-single"
						   classNamePrefix="Search"
						 isDisabled={this.state.isDisabled}
						 isLoading={this.state.isLoading}
						 isClearable={this.state.isClearable}
						 isRtl={this.state.isRtl}
						 isSearchable={this.state.isSearchable} 
						 onChange={this.handleChangeSearchDes}
						 options={this.state.destinationForSearch}
						 placeholder={<div>Search Destination</div>}
						 />
  							<button type="button" class="down_arrow_filter"><i class="fas fa-search"></i></button>
  						</div>
		    		</div>
		    	</div>
		    	<div class="hover_search_list">
		    		<div class="contenttab_side">
				    	<div class="tab">
				    		<ul class="tablistside">
				    			<li><button class="tablinks" title="dsSearchEI"><h4>East India</h4><p><span>India</span><span>New Delhi</span></p></button></li>
							  	<li><button class="tablinks active"  title="dsSearchNI"><h4>North India</h4><p><span>India</span><span>New Delhi</span></p></button></li>
							  	<li><button class="tablinks"  title="dsSearchSI"><h4>South India</h4><p><span>India</span><span>New Delhi</span></p></button></li>
							  	<li><button class="tablinks"  title="dsSearchWG"><h4>Western India</h4><p><span>India</span><span>New Delhi</span></p></button></li>
							  	<li><button class="tablinks"  title="dsSearchCI"><h4>Central India</h4><p><span>India</span><span>New Delhi</span></p></button></li>
							  </ul>
						</div>
					</div>
					<div class="contentprient_side">
						<div id="dsSearchEI" class="tabcontent">

						{(this.state.stateListWithDesEI != null && this.state.stateListWithDesEI.length> 0 )? this.state.stateListWithDesEI.map(
                                           st =><>	
						 <div class="easeindi_search--list">
						 	<div class="easeindi_search--title">
						 		{st.name}
						 	</div>
						 	<div class="easeindi_search--alltitles">
						 		<ul class="listof_cityes">
								 {(st.des != null && st.des.length> 0 )? st.des.map(
                                           ds =><>	
						 			<li><a href="#"  onClick={(e)=> this.handleClickDescInSearch(e, ds.url)}>{ds.name}</a></li>
									 </>
					            	):""}
						 		</ul>
						 	</div>
						 </div>
						 </>
						):""}
						 
						 
						</div>

						<div id="dsSearchNI" class="tabcontent active">
						{(this.state.stateListWithDesNI != null && this.state.stateListWithDesNI.length> 0 )? this.state.stateListWithDesNI.map(
                                           st =><>	
						 <div class="easeindi_search--list">
						 	<div class="easeindi_search--title">
						 		{st.name}
						 	</div>
						 	<div class="easeindi_search--alltitles">
						 		<ul class="listof_cityes">
								 {(st.des != null && st.des.length> 0 )? st.des.map(
                                           ds =><>	
						 			<li><a href="#"  onClick={(e)=> this.handleClickDescInSearch(e, ds.url)}>{ds.name}</a></li>
									 </>
					            	):""}
						 		</ul>
						 	</div>
						 </div>
						 </>
						):""} 
						</div>

						<div id="dsSearchSI" class="tabcontent">
						{(this.state.stateListWithDesSI != null && this.state.stateListWithDesSI.length> 0 )? this.state.stateListWithDesSI.map(
                                           st =><>	
						 <div class="easeindi_search--list">
						 	<div class="easeindi_search--title">
						 		{st.name}
						 	</div>
						 	<div class="easeindi_search--alltitles">
						 		<ul class="listof_cityes">
								 {(st.des != null && st.des.length> 0 )? st.des.map(
                                           ds =><>	
						 			<li><a href="#"  onClick={(e)=> this.handleClickDescInSearch(e, ds.url)}>{ds.name}</a></li>
									 </>
					            	):""}
						 		</ul>
						 	</div>
						 </div>
						 </>
						):""}
						</div>
						<div id="dsSearchWG" class="tabcontent">
						{(this.state.stateListWithDesWG != null && this.state.stateListWithDesWG.length> 0 )? this.state.stateListWithDesWG.map(
                                           st =><>	
						 <div class="easeindi_search--list">
						 	<div class="easeindi_search--title">
						 		{st.name}
						 	</div>
						 	<div class="easeindi_search--alltitles">
						 		<ul class="listof_cityes">
								 {(st.des != null && st.des.length> 0 )? st.des.map(
                                           ds =><>	
						 			<li><a href="#"  onClick={(e)=> this.handleClickDescInSearch(e, ds.url)}>{ds.name}</a></li>
									 </>
					            	):""}
						 		</ul>
						 	</div>
						 </div>
						 </>
						):""}
						</div>
						<div id="dsSearchCI" class="tabcontent">
						{(this.state.stateListWithDesCI != null && this.state.stateListWithDesCI.length> 0 )? this.state.stateListWithDesCI.map(
                                           st =><>	
						 <div class="easeindi_search--list">
						 	<div class="easeindi_search--title">
						 		{st.name}
						 	</div>
						 	<div class="easeindi_search--alltitles">
						 		<ul class="listof_cityes">
								 {(st.des != null && st.des.length> 0 )? st.des.map(
                                           ds =><>	
						 			<li><a href="#"  onClick={(e)=> this.handleClickDescInSearch(e, ds.url)}>{ds.name}</a></li>
									 </>
					            	):""}
						 		</ul>
						 	</div>
						 </div>
						 </>
						):""}
						</div>
					</div>
				</div>
		    </div>
		  	<button type="button" class="closevideo_button" data-dismiss="modal"><i class="fas fa-times"></i></button>
		  </div>
		</div>


            <div class="footerpart common_block padding_leftang_right secpaddingT secmarginT">
               
               <div class="askrenok_button">
                  <a href="/ask-renok" class="renokbutton">Ask Renok</a>
               </div>
               <div class="footermenu_links secpaddingT">
                  <ul class="footer_linklist">
                     <li><a href="/about-us">About us</a></li>
                     <li><a href="/career">Careers</a></li>
					 <li><a href="/blog">Blogs</a></li>
                     <li><a href="/privacy-policy#side_tab1">Terms And Conditions</a></li>
					 <li><a href="/privacy-policy#side_tab2">Privacy Policy</a></li>
					 <li><a href="/privacy-policy#side_tab3">Cookie Policy</a></li>
                    
                  </ul>
               </div>
               <div class="footersocials secpaddingT">                  
                  <ul class="footer_soclist">
                     <li><a href="https://www.facebook.com/renokadventures/"><i class="fab fa-facebook-f"></i></a></li>
                     <li><a href="https://www.instagram.com/renok.adventures/?hl=en"><i class="fab fa-instagram"></i></a></li>
                     <li><a href="https://twitter.com/renokadventures?lang=en"><i class="fab fa-twitter"></i></a></li>
                     <li><a href="https://www.youtube.com/channel/UCkuiirm9sBWaLt11OYsgDQg"><i class="fab fa-youtube"></i></a></li>
                  </ul>
               </div>
               
               <div class="footerbootom_bar secpaddingT">
                  © 2011 - Present | Renok Adventures. All Rights Reserved.
               </div>
            </div>

            <div class="footer_barmenu">
            <div class="footer_barmenu_flex">
               <div class="footer_barmenu_box">
                  <a href="/destination"><span><img loading="lazy" src="/images/explore-icon.svg" height="100%" width="100%" alt="explore"/></span><p>Explore</p></a>
               </div>

               <div class="footer_barmenu_box">
                  <a href="/myWishlist"><span><img loading="lazy" src="/images/wishlist-icon.svg" height="100%" width="100%" alt="whishlist"/></span><p>Wishlist</p></a>
               </div>

               <div class="footer_barmenu_box">
                  <a href="/myProfile"><span><img loading="lazy" src="/images/profile-icon.svg" height="100%" width="100%" alt="profile"/></span><p>Profile</p></a>
               </div>
            </div>
         </div>

          <div class="side_toggle_menu">
		  <div class="sidenav">
         		<a class="menu_toggle_green">
                <i class="fas fa-times"></i>
                 </a>
         		

         		<ul class="leftmenu_list">
				 {(this.state.userLoggedIn === true ? 
							(
							<>
							<li class="line_link"><a href="/destination"><span>Search by Destination</span></a></li>
							<li class="line_link"><a href="/subactivities/All/activities"><span>Browse Activities</span></a></li>
							<li class="line_link"><a href="/subactivities/All/tours"><span>Browse Tours</span></a></li>
							<li class="line_link"><a href="/subactivities/All/staycation"><span>Browse Staycations</span></a></li>
							<li class="line_link"><a href="/subactivities/All/transport_&_travel_services"><span>Browse Transport</span></a></li>
							<li class="line_link"><a href="/privacy-policy#side_tab1"><span>Terms And Conditions</span></a></li>
							<li class="line_link"><a href="/privacy-policy#side_tab2"><span>Privacy Policy</span></a></li>

							 <li class="line_link"><a onClick={this.logout}><span>Log Out</span></a></li></>):( <>
							 <li class="line_link"><a href="/destination"><span>Search by Destination</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/activities"><span>Browse Activities</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/tours"><span>Browse Tours</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/staycation"><span>Browse Staycations</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/transport_&_travel_services"><span>Browse Transport</span></a></li>
							 <li class="line_link"><a href="/privacy-policy#side_tab1"><span>Terms And Conditions</span></a></li>
							 <li class="line_link"><a href="/privacy-policy#side_tab2"><span>Privacy Policy</span></a></li>
		                     <li class="line_link"><a href="/slogin"><span>Log In</span></a></li>
		                     </>) )}
         		</ul>

         		

         		

			</div>
		  </div>
    </>
  );
  }
}

export default HomeFooter;
