import React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './App.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';
import 'react-s-alert/dist/s-alert-css-effects/scale.css';
import 'react-s-alert/dist/s-alert-css-effects/flip.css';
import 'react-s-alert/dist/s-alert-css-effects/jelly.css';
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css';
import 'react-s-alert/dist/s-alert-css-effects/genie.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import SignUp from './component/pages/SignUp';
import AboutUs from './component/pages/AboutUs';
import Careers from './component/pages/Careers';
import Terms from './component/pages/Terms';
import Login from './component/pages/Login';
import Home from './component/pages/Home';
import Destination from './component/pages/Destination';
import AllDestination from './component/pages/AllDestination';
import AllDestinationState from './component/pages/AllDestinationState';
import Activity from './component/pages/Activity';
import AskRenok from './component/pages/AskRenok';
import SpecialRequirement from './component/pages/SpecialRequirement';
import InviteFriend from './component/pages/InviteFriend';
import FreeCredits from './component/pages/FreeCredits';
import AllBlogs from './component/pages/AllBlogs';
import Blog from './component/pages/Blog';
import ForgotPassword from './component/pages/ForgotPassword';
import MyBookings from './component/pages/MyBookings';
import PackageOptions from './component/pages/PackageOptions';
import TravelInfo from './component/pages/TravelInfo';
import SubCategoryPage from './component/pages/SubCategoryPage';
import MyReviews from './component/pages/MyReviews';
import MyWishlist from './component/pages/MyWishlist';
import MyProfile from './component/pages/MyProfile';
import LoginMobile from './component/pages/LoginMobile'
import ResetPassword from './component/pages/ResetPassword'
import VerifyEmail from './component/pages/VerifyEmail'
import DestCategoryPage from './component/pages/DestCategoryPage';
import DestActivities from './component/pages/DestActivities';
import Unsubscribe from './component/pages/Unsubscribe';
import NotFound from './NotFound';
import SearchActivity from './component/pages/SearchActivity';
import BlogSearch from './component/pages/BlogSearch';

function App() {
  return (
    <div className="App">
     <Router>
       <>
       <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/destination' exact component={AllDestination} />
          <Route path='/statedestinations/:id/' exact  component={AllDestinationState} />
          <Route path='/destination/:id/' exact component={Destination} />
          <Route path='/activity/:id/' exact component={Activity} />
          <Route path='/staycation/:id/' exact component={Activity} />
          <Route path='/tours/:id/' exact component={Activity} />
          <Route path='/activities/:id/' exact component={Activity} />
          <Route path='/transport/:id/' exact component={Activity} />
          <Route path='/food/:id/' exact component={Activity} />
          <Route path='/packageOptions/:id/' exact component={PackageOptions} />
          <Route path='/travelInfo/:id' exact component={TravelInfo} />
          <Route path='/blog' exact component={AllBlogs} />
          <Route path='/blog/:id/' exact component={Blog} />
          <Route path='/slogin' component={LoginMobile} />
          <Route path='/login' component={Login} />
          <Route path='/myBookings' component={MyBookings} />
          <Route path='/myReviews' component={MyReviews} />
          <Route path='/myWishlist' component={MyWishlist} />
          <Route path='/myProfile' component={MyProfile} />
          
          <Route path='/signup' component={SignUp} />
          <Route path='/forgot-password' component={ForgotPassword} />
          <Route path='/resetpassword/:id' component={ResetPassword} />
          <Route path='/verifyemail/:id' component={VerifyEmail} />
          <Route path='/privacy-policy' exact component={Terms} />
          <Route path='/career' exact component={Careers} />
          <Route path='/about-us' exact component={AboutUs} />
          <Route path='/ask-renok' exact component={AskRenok} />
          <Route path='/get-free-credits' exact component={FreeCredits} />
          <Route path='/invite-your-friends' exact component={InviteFriend} />
          <Route path='/special-requirements' exact component={SpecialRequirement} />
          <Route path='/subactivities/:subCatId/:catId' component={SubCategoryPage} />
          <Route path='/destcatactivities/:dest/tours' component={DestCategoryPage} />
          <Route path='/destallactivities/:dest/:destId' component={DestActivities} />
          <Route path='/unsubscribe/:id' component={Unsubscribe} />
          <Route path='/search/:id' component={SearchActivity} />
          <Route path='/blogsearch/:id' component={BlogSearch} />
          <Route path='/pageNotFound' component={NotFound} />

          {/* <Route path='/:id/' component={Blog} /> */}
          <Route path='/blog/:id/' exact component={Blog} />

          <Route component={NotFound} />
         
       </Switch>
       </>
     </Router>
    </div>
  );
}

export default App;
