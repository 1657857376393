import axios from "axios";
import {API_BASE_URL} from "../constants/index"
import {twitterAuthHeader} from "./AuthHeader"
const API_URL = `${API_BASE_URL}/api/user/`;

class AuthService {
  login(loginRequest) {
  //  console.log(loginRequest);
    return axios
      .post(API_URL + "login",loginRequest)
      .then(response => {
        console.log(response)
        // if (response.data && response.data.isEmailVerified && response.data.status==='ACTIVE') {
          localStorage.setItem("userRenokWeb", JSON.stringify(response.data));
       // }

        return response.data;
      });
  }

  resetPasswordRequest(resetPasswordRequest) {
    return axios.post(API_URL + `resetPasswordRequest/${resetPasswordRequest.email}`)
  }

  resetPassword(resetPassword) {
    return axios.post(API_URL + `resetPassword/${resetPassword.password}/${resetPassword.token}`)
  }

  verifyEmail(token){
    return axios.post(API_URL + `verifyEmail/${token}`)

  }

  logout() {
    localStorage.removeItem("userRenokWeb");
  }

  socialSignIn(loginRequest){
    return axios
      .post(API_URL + "signin", loginRequest)
      .then(response => {
        if (response.data.accessToken) {
          
          localStorage.setItem("userRenokWeb", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  gmailSignIn(loginRequest){
    return axios
    .post(API_URL + "gmaillogin", loginRequest)
    .then(response => {
      //alert(JSON.stringify(response.data));
      // console.log("hii",JSON.stringify(response.data))
      localStorage.setItem("userRenokWeb", JSON.stringify(response.data.data));
      return response.data;
    });
  }

  faceBookSignIn(loginRequest){
    return axios
    .post(API_URL + "facebooklogin", loginRequest)
    .then(response => {
     // alert(JSON.stringify(response.data));
      localStorage.setItem("userRenokWeb", JSON.stringify(response.data.data));
      return response.data;
    });
  }

  userSignupEmail(dto){
    return axios
      .post(API_URL + "userSignupEmail",dto)
      .then(response => {
        console.log(response)
        return response;
      });
  }

  checkOrCreatUserByEmail(dto){
    return axios
    .post(API_URL + "checkOrCreatUserByEmail",dto)
    .then(response => {
      console.log(response)
      return response;
    });
  }

  userSignupPhone(dto){
    return axios
      .post(API_URL + "userSignupPhone",dto)
      .then(response => {
        console.log(response)
        return response;
      });
  }

  sendOtp(phone){
    let dto = {
      mobile : phone
    }
    return axios
    .post(API_BASE_URL + "/api/generateotp",dto)
    .then(response => {
      console.log(response)
      return response;
    });
  }

  verifyOtp(dto){
    return axios
    .post(API_BASE_URL + "/api/verifyotp",dto)
    .then(response => {
      console.log(response)
      return response;
    });
    
  } 

  logout(){
    localStorage.removeItem('userRenokWeb')
    return true
}
isUserLoggedIn(){
    let user = localStorage.getItem('userRenokWeb')
    if(user===null) return false 
        return true
}

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('userRenokWeb'));
  }

  getCurrentUserId() {
    let user = JSON.parse(localStorage.getItem('userRenokWeb'));
    if(user===null) return '' 
    return user.id;
  }

  getCurrentUserName() {
    let user = JSON.parse(localStorage.getItem('userRenokWeb'));
    if(user===null) return "" 
    return user.firstName;
  }

  twitterLogin(data){
  return axios.post(API_URL + "twitterAuthLogin", data).then(response => {
    if (response.data.accessToken) {
      localStorage.setItem("userRenokWeb", JSON.stringify(response.data));
    }

    return response.data;
  });

  }

  twitterRegister(data){
    return axios.post(API_URL + "twitterAuthSignUp", data).then(response => {
      if (response.data.accessToken) {
        localStorage.setItem("userRenokWeb", JSON.stringify(response.data));
      }
  
      return response.data;
    });
  
    }
}

export default new AuthService();
