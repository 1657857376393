import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import AuthService from '../../services/AuthService'
import ProfileService from '../../services/ProfileService';
import Form from "react-validation/build/form";
import SocialButtonVerify from './SocialButtonVerify'
import { GoogleLogin } from 'react-google-login';
import qs from 'qs';
import {GOOGLE_CLIENT_ID, FB_APP_ID,IMAGE_BASE_URL,PROFILE_IMAGE_BASE_URL} from '../../constants'

class MyWishlist extends React.Component {
  constructor(props) {
    super(props);
	this.handleRemoveWishList = this.handleRemoveWishList.bind(this);
	this.updateDetails = this.updateDetails.bind(this);
	this.updatePassword  = this.updatePassword.bind(this);
	this.onChange = this.onChange.bind(this);
	this.onChangeProfilePic = this.onChangeProfilePic.bind(this);
    this.state = {
	profileImageUrl : "images/user-account.jpg",	
	userLoggedIn :  AuthService.isUserLoggedIn(),
	userName : AuthService.getCurrentUserName(),
	userId : AuthService.getCurrentUserId(),
	noBookings : false,
	noWishList : false,
	noReview : false,
	gmailVerified : false,
	facebookVerified : false,
	isEmailVerified : false,
	isPhoneVerified : false,
	passwordExist : false,
	updated : false,
	updatedPassword : false,
    modal: false,
  };
  
}


  componentDidMount() {
    if(this.state.userLoggedIn){
		
        //alert(this.state.userId);
		ProfileService.getWishList(this.state.userId).then(response=>{
		//alert(response.data.data.length)
			//this.setState({"wishListlength":response.data.data.length})
			//alert(response.data.data.length)
			if(response.data.data.length==0){
				this.setState({"noWishList":true})
			  }else{
				this.setState({"noWishList":false})
			  }
			  this.setState({"wishList":response.data.data})
			//console.log(response.data.data)
		  })

		 

		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			if(response.data.profileImageUrl!=null && response.data.profileImageUrl!==''){
				this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
			}else{
				if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
					this.setState({"profileImageUrl":response.data.gmailImageUrl});
				}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
					this.setState({"profileImageUrl":response.data.facebookImageUrl});
				}
			}
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			this.setState({"title":response.data.title,
			"firstName":response.data.firstName,
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
			//console.log(response.data)
		  })
	}else{
		this.props.history.push(`/login`)
		window.location.reload();
	}
  }

  handleRemoveWishList(e, id){
	e.preventDefault();
	ProfileService.removeFromWishlist(id,this.state.userId).then(response=>{
		ProfileService.getWishList(this.state.userId).then(response=>{
		    this.setState({"wishList":response.data.data})
			if(response.data.data.length==0){
				this.setState({"noWishList":true})
			  }else{
				this.setState({"noWishList":false})
			  }
			//console.log(response.data.data)
		  })
	})
  }
  onChange(e){
	this.setState({
		[e.target.name]: e.target.value
	  });
  }

  updateDetails(e){
     e.preventDefault();
	 let dto = {
		id : this.state.userId,
		title : this.state.title, 
		firstName : this.state.firstName,
		lastName : this.state.lastName,
		code : this.state.code,
		country : this.state.country,
		email :  this.state.email,
		phone : this.state.phone
	 }
	 ProfileService.updateUserDetails(dto).then(response=>{
      this.setState({"updated":true});
	 })
  }

  updatePassword(e){
	e.preventDefault();
	if(this.state.newPassword == this.state.repeatPassword){
		let dto = {
			id : this.state.userId,
			email :  this.state.email,
			phone : this.state.phone,
			passwordExist : this.state.passwordExist,
			currentPassword : this.state.currentPassword,
			newPassword : this.state.newPassword
		 }
		ProfileService.updatePassword(dto).then(response=>{
			if(response.data.message=='User Password updated'){
				this.setState({"updatedPassword":true,"currentPassword":"","newPassword":"","repeatPassword":""});
			}else{
				alert(response.data.message);
			}
			
		})
	}else{
      alert("Password and Confirm Password are not same.")
	}
	
  }

  handleGoogleLogin(response){
	//console.log(response)
	if(response!=null){
	const signInRequest = {
	  email : this.state.email,
	  accessToken : response.accessToken,
	  lastName : response.profileObj.familyName,
	  firstName : response.profileObj.givenName,
	  imageUrl : response.profileObj.imageUrl,
	  googleId : response.profileObj.googleId
	}
	AuthService.gmailSignIn(signInRequest).then(
	  () => {
		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
				this.setState({"profileImageUrl":response.data.gmailImageUrl});
			}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
				this.setState({"profileImageUrl":response.data.facebookImageUrl});
			}
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			this.setState({"title":response.data.title,
			"firstName":response.data.firstName,
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
			//console.log(response.data)
		  })
	  },
	  error => {
		const resMessage =
		  (error.response &&
		  error.response.data &&
		  error.response.data.message) ||
		  error.message ||
		  error.toString();

		this.setState({
		  loading: false,
		  message: resMessage
		});
	  }
	)
  }
  }

  handleFBLogin(response){

	//console.log(response)
	const signInRequest = {
	  fbId : response.profile.id,
	  email : this.state.email,
	  firstName : response.profile.firstName,
	  lastName : response.profile.lastName,
	  imageUrl : response.profile.profilePicURL,
	  accessToken : response._token.accessToken
	}
	AuthService.faceBookSignIn(signInRequest).then(
	  () => {
		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
				this.setState({"profileImageUrl":response.data.gmailImageUrl});
			}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
				this.setState({"profileImageUrl":response.data.facebookImageUrl});
			}
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			this.setState({"title":response.data.title,
			"firstName":response.data.firstName,
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
			//console.log(response.data)
		  })
	  },
	  error => {
		const resMessage =
		  (error.response &&
			error.response.data &&
			error.response.data.message) ||
		  error.message ||
		  error.toString();

		this.setState({
		  loading: false,
		  message: resMessage
		});
	  }
	)

  }

  onChangeProfilePic(event){
	this.setState({"profileUploading":true})
	 const formData = new FormData();
	 formData.append('id',this.state.userId);
	 formData.append('profileImage',event.target.files[0]);
	 formData.append('profileImageName',event.target.files[0].name);
	 ProfileService.updateProfile(formData).then(
	   response => {
		   if (response.data.responseCode == 'SUCCESS') {
			   alert("Profile Image Updated.");
			   ProfileService.getUserDetails(this.state.userId).then(response=>{
				   //alert(response.data.email)
				   if(response.data.profileImageUrl !=null && response.data.profileImageUrl!==''){
					   this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
				   }
				   this.setState({"profileUploading":false})
			   });
		   
		   }else{
			   alert(response.data.message);
		   }

	   });
 }

  render() {
	const responseGoogle = (response) => {
        this.handleGoogleLogin(response)
      }
      const handleSocialLogin = (user) => {
        this.handleFBLogin(user)
      }

      const handleSocialLoginFailure = (err) => {
        console.error(err)
      }
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>

	<section class="same-section user_accountP padding_top padding_bottom">
	    	<div class="container">
	    		<div class="user_accountP_perent">
	    			<div class="user_accountP_left">
	    				<div class="userac_tabsimage_bx_main">
		    				<div class="user_image_side">
		    					<div class="user_image_account">
								<img src={this.state.profileImageUrl} style={{"border-radius":"50%"}}  alt="Image_Icon"/>
									<div class="profilePicEdit">
									           <i class="fas fa-camera"></i>
									</div>
									<input type="file" id="imgupload" name="profilePic" style={{"display":"none"}} onChange={this.onChangeProfilePic}  /> 
									{( this.state.profileUploading == true ?  (<div style={{"color":"#55aeb3"}}><p>Uploading....</p></div>):(<></>) )}
		    					</div>
		    					<h5>{this.state.userName}</h5>
		    					<h6><a class="nav-link"  href="/myProfile" class="greenlink_text">Edit User</a></h6> 
		    				</div>
		    				
		    				<div class="user_account_table_menu">
		    					<ul class="userac_tabs nav nav-tabs" role="tablist">
		          					<li class="nav-item">
				                        <a class="nav-link"  href="/myBookings">
				                        	<img src="images/usericon01.svg" alt="Image-Icon"/><span class="boktitle"> Bookings</span> 
				                        	
				                        </a>
				                    </li>
				                    <li class="nav-item">
				                      <a class="nav-link"  href="/myReviews">
				                      	<img src="images/usericon02.svg" alt="Image-Icon"/><span class="boktitle"> Reviews</span> 
				                      
				                      </a>
				                  	</li>
				                    <li class="nav-item">
				                      <a class="nav-link active" href="/myWishlist">
				                      	<img src="images/usericon03.svg" alt="Image-Icon"/><span class="boktitle"> Wishlist</span> 
				                      	<i class="fas fa-chevron-right"></i>
				                      </a>
				                  </li>
				                    <li class="nav-item">
				                      <a class="nav-link" href="/myProfile">
				                      	<img src="images/usericon04.svg" alt="Image-Icon"/><span class="boktitle"> Settings</span> 
				                      	
				                      </a>
				                  	</li>
		    					</ul>
		    				</div>
	    				</div>
	    				{/* <div class="bestfood_account">	    					
				    		<div class="planning_four_bx">
		    					<div class="planning_inner box_side_one background_grees">
		    						<h6>Best Food & Must Eats</h6>
		    						<p>Discover Local Cuisines</p>
		    						<a href="#" class="white_text_border explor_more_butt">View More</a>
		    					</div>
	    					</div>
	    				</div> */}
	    			</div>
	    			<div class="user_accountP_right">
		                <div class="tab-content">
		                   
		                   

<div id="menu2" class="tab-pane active">
<div class="user_accountP_right_inner">
<h2 class="userac_right_title">My Wishlist {this.state.noWishList}</h2>
<div class="mywishlist_ac">
<div class="row">	
{(this.state.wishList != null && this.state.wishList.length> 0 )? this.state.wishList.map(
                                    dd =><>		    							
	<div class="col-md-4">
		<div class="popular_activities_box">
             <div class="imagesof_box">
                <img  src={IMAGE_BASE_URL+``+JSON.parse(dd.ad.gridImage).imageUrl} alt={JSON.parse(dd.ad.gridImage).altText}/>
                <div class="activities_palcetitle">
                  
                </div>
             </div>
             <div class="popular_activities_content">
                <h3 class="palcebox_title">{dd.ad.name}</h3>
               
             </div>
             <div class="removewishlist_bx">
             	<a href="#" onClick={(e)=> this.handleRemoveWishList(e, dd._id)}><i class="fas fa-heart"></i> Remove from Wishlist</a>
             </div>
        </div>
	</div>	

		</>
						   ):""}		
	
	{( this.state.noWishList == true ?  (<div class="contentsubs_side"><p>No Whislist Items Found</p></div>):(<></>) )}
</div>
</div>
</div>
</div>


			    		</div>
	    			</div>
	    		</div>
	    	</div>
	    </section>


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default MyWishlist;
