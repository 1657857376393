
import React from 'react';
import { Route, Redirect} from 'react-router-dom'
import AuthService from '../../services/AuthService'
import { APP_BASE_URL} from '../../constants';
import Select from 'react-select'
import ProfileService from '../../services/ProfileService'
import {GOOGLE_CLIENT_ID, FB_APP_ID,IMAGE_BASE_URL,PROFILE_IMAGE_BASE_URL} from '../../constants'

class HomeHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
	userLoggedIn :  AuthService.isUserLoggedIn(),
	userName : AuthService.getCurrentUserName(),
	userId : AuthService.getCurrentUserId(),
	profileImageUrl : "images/user-account.jpg",
    modal: false,
	isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
	searchText : '',
  };
  this.handleChangeSearch = this.handleChangeSearch.bind(this);
  this.handleClickSubCategory = this.handleClickSubCategory.bind(this);
  this.searchActivity = this.searchActivity.bind(this);
  this.handleClickSearch = this.handleClickSearch.bind(this);
  this.handleClickSearchPage = this.handleClickSearchPage.bind(this);
  this.handleClickSearchHead = this.handleClickSearchHead.bind(this);
  this.handleClickSearchPageHead = this.handleClickSearchPageHead.bind(this);
 
  
}


  componentDidMount() {
    ProfileService.getAllActivities().then(response=>{
		//console.log(response.data);
		
		let popularActivities = [];
		for(let i = 0; i < response.data.length; i++){
			let value = response.data[i].url;
			let label = response.data[i].name;
			let skillS = { value : value ,label : label }
			//alert(value);
			//alert(label);
			popularActivities.push(skillS);
			
		  }
		  this.setState({"popularActivities":popularActivities})

	});

	ProfileService.getSubCategoryListByCategory("611b45344fdde75b279725ec").then(response=>{
		//alert(response.data.length)
		this.setState({"tourSubCatgeoryList":response.data})
	})
	ProfileService.getSubCategoryListByCategory("611ba6cd14e97e569e41eaac").then(response=>{
		//alert(response.data.length)
		this.setState({"experienceSubCatgeoryList":response.data})
	})
	ProfileService.getSubCategoryListByCategory("61275c3ceaf59f69875e2abd").then(response=>{
		//alert(response.data.length)
		this.setState({"travelSubCatgeoryList":response.data})
	})

	if(this.state.userLoggedIn){
		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			if(response.data.profileImageUrl !=null && response.data.profileImageUrl!==''){
				this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
			}else{
				if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
					this.setState({"profileImageUrl":response.data.gmailImageUrl});
				}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
					this.setState({"profileImageUrl":response.data.facebookImageUrl});
				}
			}
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			this.setState({"title":response.data.title,
			"firstName":response.data.firstName,
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
			//console.log(response.data)
		  })
	}
	
  }



handleChangeSearch(value){
	if(value!=null){
		var id = value.value;
		ProfileService.getActivityByUrl(id).then(response=>{
			var activityId = response.data.id;
			if(response.data.activityCategory_id=='611b452a4fdde75b279725eb'){
				window.location.href=`${APP_BASE_URL}/staycation/${id}`;
			}else if(response.data.activityCategory_id=='611b45344fdde75b279725ec'){
				window.location.href=`${APP_BASE_URL}/tours/${id}`;
			}else if(response.data.activityCategory_id=='611ba6cd14e97e569e41eaac'){
				window.location.href=`${APP_BASE_URL}/activities/${id}`;
			}else if(response.data.activityCategory_id=='61275c3ceaf59f69875e2abd'){
				window.location.href=`${APP_BASE_URL}/transport/${id}`;
			}else if(response.data.activityCategory_id=='61275c55eaf59f69875e2abe'){
				window.location.href=`${APP_BASE_URL}/food/${id}`;
			}else {
				window.location.href=`${APP_BASE_URL}/activity/${id}`;
			}
	    })
		
	}
}

handleClickSubCategory(cat,subCat){
	window.location.href=`${APP_BASE_URL}/subactivities/${subCat}/${cat}`;
}

searchActivity(text){
	let matchers = [];
	var popularActivities = this.state.popularActivities;
	if(text.length>0){
		matchers = popularActivities.filter(act => {
			const regex = new RegExp(`${text}`,"gi");
			return act.label.match(regex);
		})
	}
   // console.log(matchers);
	this.setState({"searchText":text,"suggestions":matchers});
}

searchActivityHead(text){
	let matchers = [];
	var popularActivities = this.state.popularActivities;
	if(text.length>0){
		matchers = popularActivities.filter(act => {
			const regex = new RegExp(`${text}`,"gi");
			return act.label.match(regex);
		})
	}
  //  console.log(matchers);
	this.setState({"searchTextHead":text,"suggestionsHead":matchers});
}

clearSuggestions(){
	this.setState({"suggestions": []});
}
clearSuggestionsHead(){
	this.setState({"suggestionsHead": []});
}

handleClickSearch(e,value){
	e.preventDefault();
	//alert(value);
	if(value!=null){
		var id = value;
		ProfileService.getActivityByUrl(id).then(response=>{
			var activityId = response.data.id;
			if(response.data.activityCategory_id=='611b452a4fdde75b279725eb'){
				window.location.href=`${APP_BASE_URL}/staycation/${id}`;
			}else if(response.data.activityCategory_id=='611b45344fdde75b279725ec'){
				window.location.href=`${APP_BASE_URL}/tours/${id}`;
			}else if(response.data.activityCategory_id=='611ba6cd14e97e569e41eaac'){
				window.location.href=`${APP_BASE_URL}/activities/${id}`;
			}else if(response.data.activityCategory_id=='61275c3ceaf59f69875e2abd'){
				window.location.href=`${APP_BASE_URL}/transport/${id}`;
			}else if(response.data.activityCategory_id=='61275c55eaf59f69875e2abe'){
				window.location.href=`${APP_BASE_URL}/food/${id}`;
			}else {
				window.location.href=`${APP_BASE_URL}/activity/${id}`;
			}
	    })
		
	}
}

handleClickSearchHead(e,value){
	e.preventDefault();
	//alert(value);
	if(value!=null){
		var id = value;
		ProfileService.getActivityByUrl(id).then(response=>{
			var activityId = response.data.id;
			if(response.data.activityCategory_id=='611b452a4fdde75b279725eb'){
				window.location.href=`${APP_BASE_URL}/staycation/${id}`;
			}else if(response.data.activityCategory_id=='611b45344fdde75b279725ec'){
				window.location.href=`${APP_BASE_URL}/tours/${id}`;
			}else if(response.data.activityCategory_id=='611ba6cd14e97e569e41eaac'){
				window.location.href=`${APP_BASE_URL}/activities/${id}`;
			}else if(response.data.activityCategory_id=='61275c3ceaf59f69875e2abd'){
				window.location.href=`${APP_BASE_URL}/transport/${id}`;
			}else if(response.data.activityCategory_id=='61275c55eaf59f69875e2abe'){
				window.location.href=`${APP_BASE_URL}/food/${id}`;
			}else {
				window.location.href=`${APP_BASE_URL}/activity/${id}`;
			}
	    })
		
	}
}


handleClickSearchPage(e) {
	e.preventDefault();
	var searchText = this.state.searchText;
	if(searchText.length>0){
		window.location.href=`${APP_BASE_URL}/search/${searchText}`;
	}
}

handleClickSearchPageHead(e) {
	e.preventDefault();
	var searchText = this.state.searchTextHead;
	if(searchText.length>0){
		window.location.href=`${APP_BASE_URL}/search/${searchText}`;
	}
}

  logout = () => {
    AuthService.logout();
    window.location.href=`${APP_BASE_URL}/login`;
  }

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
  

{(this.state.userLoggedIn === true ? 
							(<>


<header class="with-login">
	        <div class="container">
	            <div class="logo-bx float-left">
	            	<a href="/">
	               <img loading="lazy" src="images/logo.svg" height="100%" width="100%"   alt="Logo"/>
	               </a>
	            	<div class="hder_menu_box">
                  <a class="header_toggle"><img loading="lazy" src="images/menu-toggle.svg" height="100%" width="100%"  alt="toggle"/> </a>
               	</div>
	            </div>
				<div class="mobile_header_button"><img loading="lazy" src="images/search-icon01.svg" height="100%" width="100%"  alt="search"/>  </div>
	            <div class="search-bar header_search_bar">
				<form  class="searchbar" onSubmit={this.handleClickSearchPageHead}>  
			   
				<div class="mobile_header_search"> <input  type="text" value={this.searchTextHead} onBlur={()=>{
							  setTimeout(()=>{
                                this.clearSuggestionsHead();
							  },1000)
						     }}  onChange={e=> this.searchActivityHead(e.target.value)} placeholder='Search your activity' />
						
						{( this.state.suggestionsHead && this.state.suggestionsHead.length != 0 ? 
						      (<div class="suggestions">
						           {this.state.suggestionsHead && this.state.suggestionsHead.map((suggestion,i)=>
						          <div class="suggestions-item" onClick={e =>this.handleClickSearchHead(e,suggestion.value)}   key={i} >{suggestion.label}</div>
						        )}
						      </div>) : (<></>)
                          )}
						 <button type="submit" class=""><img loading="lazy" src="images/search-icon01.svg" height="100%" width="100%"  alt="search"/></button> 	 
				</div>
						 
						   </form>
	          
	               </div>

	              <div class="login-dp">
	              	<span>{this.state.userName}</span>

	              	<div class="user-dp">
					  <img  loading="lazy" src={this.state.profileImageUrl} height="40px" width="40px"   alt="profile" />

	            	<div class="user-option">
	            		<ul>
						<li><a href="/myBookings"><img loading="lazy" src={APP_BASE_URL + '/images/usericon01.svg'}  height="100%" width="100%"   alt="booking" /> Bookings</a></li>
	            		<li><a href="/myReviews"><img loading="lazy" src={APP_BASE_URL + '/images/usericon02.svg'} height="100%" width="100%"   alt="review" />Reviews</a></li>
	            		<li><a href="/myWishlist"><img loading="lazy" src={APP_BASE_URL + '/images/usericon03.svg'} height="100%" width="100%"   alt="wishlist"  />Wishlist</a></li>
	            		<li><a href="/myProfile"><img loading="lazy" src={APP_BASE_URL + '/images/usericon04.svg'}  height="100%" width="100%"  alt="settings" />Settings</a></li>
	            		<li><a href="#" onClick={this.logout} ><i class="fa fa-power-off" aria-hidden="true" style={{"color":"#00acb2", "width": "36px","font-size":"20px"}} ></i>Log out</a></li>
	            		</ul>
	            	</div>
	            	</div>
	            	
	            	</div>

	            <div class="clearfix"></div>
	        </div>
        </header>				
							
							</>):( <>

							

		<header class="without-login">
	        <div class="container">
	            <div class="logo-bx float-left">
	            	<div class="hder_menu_box">
                  <a class="header_toggle"> <img loading="lazy" src="images/menu-toggle.svg" height="100%" width="100%"   alt="toggle"/></a>
                   
               </div>
	               <a href="/">
	               <img loading="lazy" src="images/logo.svg"  height="100%" width="100%"  alt="Logo"/>
	               </a>
	            </div>
				
				<div class="mobile_header_button"><img loading="lazy" src="images/search-icon01.svg" height="100%" width="100%"  alt="search"/>  </div>
	            <div class="search-bar header_search_bar">
				<form  class="searchbar" onSubmit={this.handleClickSearchPageHead}>  
			   
				<div class="mobile_header_search"> <input  type="text" value={this.searchTextHead} onBlur={()=>{
							  setTimeout(()=>{
                                this.clearSuggestionsHead();
							  },1000)
						     }}  onChange={e=> this.searchActivityHead(e.target.value)} placeholder='Search your activity' />
						
						{( this.state.suggestionsHead && this.state.suggestionsHead.length != 0 ? 
						      (<div class="suggestions">
						           {this.state.suggestionsHead && this.state.suggestionsHead.map((suggestion,i)=>
						          <div class="suggestions-item" onClick={e =>this.handleClickSearchHead(e,suggestion.value)}   key={i} >{suggestion.label}</div>
						        )}
						      </div>) : (<></>)
                          )}
						 <button type="submit" class=""><img loading="lazy" src="images/search-icon01.svg" height="100%" width="100%"  alt="search"/></button> 	 
				</div>
						 
						   </form>
	          
	               </div>
	            
				<div class="login-dp">
					<ul>
				<li class="line_link"><a href="/login" class="signup-btn" ><span>Log In</span></a></li>
				
				</ul>
				</div>
	            <div class="clearfix"></div>
	        </div>
        </header>
		                


							 
							 </>) )}

		



		<section class="same-section homebanner">
		  	<div class="container">
		  		<div class="homebanner_content">
		      		<h1 class="top_main_Bheding text-align">Search for your kind of adventure</h1>
		      		<p class="top_main_Sheding text-align">Book amazing adventures/experiences just on a click of a button </p>
		      		<div class="header_searchbar">
		      			<div class="heade_search_fuild">
						<form  onSubmit={this.handleClickSearchPage}>   
						  <input  type="text" value={this.searchText} className="col-md-10"  onBlur={()=>{
							  setTimeout(()=>{
                                this.clearSuggestions();
							  },1000)
						     }} onChange={e=> this.searchActivity(e.target.value)} placeholder='Search your activity' />
						 
						  {( this.state.suggestions && this.state.suggestions.length != 0 ? 
						      (<div class="suggestions">
						           {this.state.suggestions && this.state.suggestions.map((suggestion,i)=>
						          <div class="suggestions-item" onClick={e =>this.handleClickSearch(e,suggestion.value)}   key={i} >{suggestion.label}</div>
						        )}
						      </div>) : (<></>)
                          )}
						  
						   <button type="submit" class="searchhead_but"><img loading="lazy" src="images/white.png" height="70%" width="100%"  alt="search"/></button> 
						   </form>	
		      			</div>
						 
						  {/* <div class="heade_search_fuild">
						  <Select 
						   className="basic-single"
						   classNamePrefix="Search"
						 isDisabled={this.state.isDisabled}
						 isLoading={this.state.isLoading}
						 isClearable={this.state.isClearable}
						 isRtl={this.state.isRtl}
						 isSearchable={this.state.isSearchable} 
						 onChange={this.handleChangeSearch}
						 options={this.state.popularActivities}
						 placeholder={<div>Search your activity</div>}
						 />
						  
		      					 <button class="searchhead_but"><img src="images/white.png" height="" width="" alt="search"/></button> 
		      				
		      			</div> */}
		      			<div class="header_dropdoen_fuilds">
		      				<div class="dropdowns_bxleft">
			      				<div class="dropdowns_bx iconacti onlyforposti">
			      					<div class="dropdown_sec_bx">
			      						<a class="image_dropdown transport_img--dropdown">
			      							<span>Activities</span>
			      						<span class="downarrow_common"><i class="fas fa-chevron-down"></i></span>		      						
			      						</a>
		      							<ul class="listdrop_hover">
										  {(this.state.experienceSubCatgeoryList != null && this.state.experienceSubCatgeoryList.length> 0 )? this.state.experienceSubCatgeoryList.map(
                                           subC =><>
		      								<li><a href="#" onClick={() => this.handleClickSubCategory('Activities'.split(' ').join('_').toLowerCase(),subC.name.split(' ').join('_').toLowerCase())}  >{subC.name}</a></li>
											  </>
						                   ):""}
		      							
		      								<li><a href="#" onClick={() => this.handleClickSubCategory('Activities'.split(' ').join('_').toLowerCase(),'All')} ><span>Show all</span>&nbsp;&nbsp;<i class="fas fa-chevron-right"></i></a></li>
		      							</ul>
			      					</div>
			      				</div>
			      				<div class="dropdowns_bx icontransport onlyforposti">
			      					<div class="dropdown_sec_bx">
			      						<a class="image_dropdown transport_img--dropdowntwo">
			      							<span>Transport</span>
			      						<span class="downarrow_common"><i class="fas fa-chevron-down"></i></span>
			      						</a>
		      							<ul class="listdrop_hovertwo">
										  
										  {(this.state.travelSubCatgeoryList != null && this.state.travelSubCatgeoryList.length> 0 )? this.state.travelSubCatgeoryList.map(
                                           subC =><>
		      								<li><a href="#" onClick={() => this.handleClickSubCategory('Transport & Travel Services'.split(' ').join('_').toLowerCase(),subC.name.split(' ').join('_').toLowerCase())}  >{subC.name}</a></li>
											  </>
						                   ):""}
		      							
		      								<li><a href="#" onClick={() => this.handleClickSubCategory('Transport & Travel Services'.split(' ').join('_').toLowerCase(),'All')} ><span>Show all</span>&nbsp;&nbsp;<i class="fas fa-chevron-right"></i></a></li>
		      							</ul>
			      					</div>
			      				</div>
			      				<div class="dropdowns_bx iconexpexp onlyforposti">
			      					<div class="dropdown_sec_bx">
			      						<a class="image_dropdown transport_img--dropdownthree">
			      							<span>Tours</span>
			      						<span class="downarrow_common"><i class="fas fa-chevron-down"></i></span>			      						
			      						</a>
		      							<ul class="listdrop_hoverthree">
										  {(this.state.tourSubCatgeoryList != null && this.state.tourSubCatgeoryList.length> 0 )? this.state.tourSubCatgeoryList.map(
                                           subC =><>
		      								<li><a href="#" onClick={() => this.handleClickSubCategory('Tours'.split(' ').join('_').toLowerCase(),subC.name.split(' ').join('_').toLowerCase())}  >{subC.name}</a></li>
											  </>
						                   ):""}
		      							
		      								<li><a href="#" onClick={() => this.handleClickSubCategory('Tours'.split(' ').join('_').toLowerCase(),'All')} ><span>Show all</span>&nbsp;&nbsp;<i class="fas fa-chevron-right"></i></a></li>
		      							</ul>
			      					</div>
			      				</div>
		      				</div>
		      				<div class="dropdowns_bxright">
		      					<div class="threedot_tog">
		      						<a href="/destination">
			      						<span class="dotwhite"></span>
			      						<span class="dotwhite"></span>
			      						<span class="dotwhite"></span>
		      						</a>
		      					</div>
		      				</div>
		      			</div>
		      		</div>
		  		</div>
		  	</div>
		</section>

		<div class="searchbar_side common_block padding_leftang_right top_in_margin serach-menu-bar">
              <form class="mobile_home_search" onSubmit={this.handleClickSearchPage}>   
						  <input  type="text" value={this.searchText} className="col-md-10"  onBlur={()=>{
							  setTimeout(()=>{
                                this.clearSuggestions();
							  },1000)
						     }} onChange={e=> this.searchActivity(e.target.value)} placeholder='Search your activity' />
						 
						  {( this.state.suggestions && this.state.suggestions.length != 0 ? 
						      (<div class="suggestions">
						           {this.state.suggestions && this.state.suggestions.map((suggestion,i)=>
						          <div class="suggestions-item" onClick={e =>this.handleClickSearch(e,suggestion.value)}   key={i} >{suggestion.label}</div>
						        )}
						      </div>) : (<></>)
                          )}
						   <button type="submit" class=""><img loading="lazy" src="images/search-icon01.svg" height="100%" width="100%"   alt="search"/></button>
						 
						   </form>
            </div>

		{/* <div class="searchbar_side common_block padding_leftang_right top_in_margin serach-menu-bar">
               <div class="inputsearch_perent">
			   <Select 
						   className="basic-single"
						   classNamePrefix="Search"
						 isDisabled={this.state.isDisabled}
						 isLoading={this.state.isLoading}
						 isClearable={this.state.isClearable}
						 isRtl={this.state.isRtl}
						 isSearchable={this.state.isSearchable} 
						 onChange={this.handleChangeSearch}
						 options={this.state.popularActivities}
						 placeholder={<div>Search your activity</div>}
						 />
                
                  <button type="button" class="search_icon_button"><img src="images/search-icon01.svg" height="" width=""  alt="search"/></button>
               </div>
            </div> */}

	    <section class="same-section header_fiture">
	      	<div class="container">
	      		<div class="header_fiture_flex">
	      			<div class="header_fiture_bx">
	      				<div class="header_fiture_icon">
	      					<img loading="lazy" src="images/afterB-icon01.svg" height="100%" width="100%"   alt="after"/>
	      				</div>
	      				<div class="header_fiture_desc">
	      					<h5>Handpicked Experiences</h5>
	      					<p>Read real user reviews</p>
	      				</div>
	      			</div>
	      			<div class="header_fiture_bx">
	      				<div class="header_fiture_icon">
	      					<img loading="lazy" src="images/afterB-icon02.svg" height="100%" width="100%"   alt="after"/>
	      				</div>
	      				<div class="header_fiture_desc">
	      					<h5>Seamless & Safe Booking</h5>
	      					<p>Hassle-free e-ticket entry</p>
	      				</div>
	      			</div>
	      			<div class="header_fiture_bx">
	      				<div class="header_fiture_icon">
	      					<img loading="lazy" src="images/afterB-icon03.svg" height="100%" width="100%"   alt="after"/>
	      				</div>
	      				<div class="header_fiture_desc">
	      					<h5>Best Price</h5>
	      					<p>Discounts upto 60% off</p>
	      				</div>
	      			</div>
	      		</div>
	      	</div>
	    </section>

		<section class="aet_section common_block secpaddingT padding_leftang_right activity-sec">
			  <div class="aet_flex">
				 <div class="aet_box">
					   <img loading="lazy" src="images/activities-icon.svg" height="100%" width="100%"   alt="activity" />
					  <a href="#" onClick={() => this.handleClickSubCategory('Activities'.split(' ').join('_').toLowerCase(),'All')}> <p>Activities</p> </a>
					<a href="#"  onClick={() => this.handleClickSubCategory('Activities'.split(' ').join('_').toLowerCase(),'All')} class="onlink"></a>
				 </div>

				 <div class="aet_box">
					   <img loading="lazy" src="images/experiences-icon.svg"  height="100%" width="100%"  alt="experience" />
					 <a href="#" onClick={() => this.handleClickSubCategory('Tours'.split(' ').join('_').toLowerCase(),'All')}>  <p>Tours</p> </a>
					<a href="#" onClick={() => this.handleClickSubCategory('Tours'.split(' ').join('_').toLowerCase(),'All')} class="onlink"></a>
				 </div>

				 <div class="aet_box">
					   <img loading="lazy" src="images/transportation-icon.svg" height="100%" width="100%"   alt="transportation" />
					 <a href="#" onClick={() => this.handleClickSubCategory('Transport & Travel Services'.split(' ').join('_').toLowerCase(),'All')}>   <p>Transport</p> </a>
					<a href="#"  onClick={() => this.handleClickSubCategory('Transport & Travel Services'.split(' ').join('_').toLowerCase(),'All')} class="onlink"></a>
				 </div>
			  </div>
		   </section>
    </>
  );
  }
}

export default HomeHeader;
