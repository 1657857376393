import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import ProfileService from '../../services/ProfileService'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
	this.onChange = this.onChange.bind(this);
	this.handleSubmit = this.handleSubmit.bind(this);
    this.state = {
    modal: false,
	message : 'Verifying Email Please Wait...'
  };
  
}


  componentDidMount() {
    if(this.props.match.params.id!=null){
		ProfileService.verifyEmail(this.props.match.params.id).then(response=>{
			//	alert(response.data.message);
				if(response.data.responseCode==='FAILURE'){
					this.setState({"success":false,"message":"Invalid Email Verification Link"});
				}else{
					this.setState({"password":"","repeatpassword":"","success":true,"message":"Email Verification successful. Please Login to continue.."});
				}
				
		
			})
	}else{
		this.setState({"success":false,"message":"Invalid Email Verification Link"});
	}
  }
  onChange(e) {
	this.setState({
	  [e.target.name]: e.target.value
	});
  }

  handleSubmit(e){
    e.preventDefault();
	if(this.state.password===this.state.repeatpassword){
		const regPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
		// alert(x);
		if (this.state.password.match(regPass)==null || this.state.password.match(regPass) === false) {
		 this.setState({ "success":false,message: "The password should be min 8 characters long , and should include one Capital alphabet, one Small alphabet, one special character and numbers." });
	   } else{
		ProfileService.resetPasswordRequest(this.props.match.params.id,this.state.password).then(response=>{
			//	alert(response.data.message);
				if(response.data.responseCode==='FAILURE'){
					this.setState({"success":false,"message":"Invalid Password Reset Request"});
				}else{
					this.setState({"password":"","repeatpassword":"","success":true,"message":"Password Updated. Please Login to continue.."});
				}
				
		
			})
	   }
	}else{
		this.setState({"success":false,"message":"Password & Repeat Password does not match"});
	}
	
  }

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>

	<section class="same-section invite_your_friendsB innerpage_banner update-pass">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
		    		<h1 class="inner_banner_heding text-align">Email Verification</h1>
		    		
	    		</div>
	    	</div>
	    </section>

	    <section class="same-section what_are_renok text-align padding_top padding_bottom bg-gray referal-code">
	    	<div class="container">
	    		<div class="forgot-password">
	    			
	    			
					  
					  <div class="confirms">
					  	  {( this.state.message === '' ?  (<></>):(<><p>{this.state.message}</p></>) )}
					  	 
					  </div>
 
	
	    		</div>
	    		
	    	</div>
	    </section>


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default ForgotPassword;
