import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import AuthService from '../../services/AuthService'
import ProfileService from '../../services/ProfileService';
import Form from "react-validation/build/form";
import SocialButtonVerify from './SocialButtonVerify'
import { GoogleLogin } from 'react-google-login';
import qs from 'qs';
import {GOOGLE_CLIENT_ID, FB_APP_ID,IMAGE_BASE_URL,PROFILE_IMAGE_BASE_URL} from '../../constants'

class MyBookings extends React.Component {
  constructor(props) {
    super(props);
	this.handleRemoveWishList = this.handleRemoveWishList.bind(this);
	this.updateDetails = this.updateDetails.bind(this);
	this.updatePassword  = this.updatePassword.bind(this);
	this.onChange = this.onChange.bind(this);
	this.onChangeProfilePic = this.onChangeProfilePic.bind(this);
    this.state = {	
		profileImageUrl : "images/user-account.jpg",
	userLoggedIn :  AuthService.isUserLoggedIn(),
	userName : AuthService.getCurrentUserName(),
	userId : AuthService.getCurrentUserId(),
	noBookings : false,
	noWishList : false,
	noReview : false,
	gmailVerified : false,
	facebookVerified : false,
	isEmailVerified : false,
	isPhoneVerified : false,
	passwordExist : false,
	updated : false,
	updatedPassword : false,
    modal: false,
  };
  
}


  componentDidMount() {
    if(this.state.userLoggedIn){
		//alert(this.state.userId)
		ProfileService.getBookingList(this.state.userId).then(response=>{
          this.setState({"bookingList":response.data.data})
		  if(response.data.data.length==0){
			this.setState({"noBookings":true})
		  }else{
			this.setState({"noBookings":false})
		  }
		 // console.log(response.data)
		})
       
		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			if(response.data.profileImageUrl!=null && response.data.profileImageUrl!==''){
				this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
			}else{
				if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
					this.setState({"profileImageUrl":response.data.gmailImageUrl});
				}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
					this.setState({"profileImageUrl":response.data.facebookImageUrl});
				}
			}
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			this.setState({"title":response.data.title,
			"firstName":response.data.firstName,
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
		//	console.log(response.data)
		  })
	}else{
		this.props.history.push(`/login`)
		window.location.reload();
	}
  }

  handleRemoveWishList(e, id){
	e.preventDefault();
	ProfileService.removeFromWishlist(id,this.state.userId).then(response=>{
		ProfileService.getWishList(this.state.userId).then(response=>{
		    this.setState({"wishList":response.data.data})
			if(response.data.data.length==0){
				this.setState({"noWishList":true})
			  }else{
				this.setState({"noWishList":false})
			  }
		//	console.log(response.data.data)
		  })
	})
  }
  onChange(e){
	this.setState({
		[e.target.name]: e.target.value
	  });
  }

  updateDetails(e){
     e.preventDefault();
	 let dto = {
		id : this.state.userId,
		title : this.state.title, 
		firstName : this.state.firstName,
		lastName : this.state.lastName,
		code : this.state.code,
		country : this.state.country,
		email :  this.state.email,
		phone : this.state.phone
	 }
	 ProfileService.updateUserDetails(dto).then(response=>{
      this.setState({"updated":true});
	 })
  }

  updatePassword(e){
	e.preventDefault();
	if(this.state.newPassword == this.state.repeatPassword){
		let dto = {
			id : this.state.userId,
			email :  this.state.email,
			phone : this.state.phone,
			passwordExist : this.state.passwordExist,
			currentPassword : this.state.currentPassword,
			newPassword : this.state.newPassword
		 }
		ProfileService.updatePassword(dto).then(response=>{
			if(response.data.message=='User Password updated'){
				this.setState({"updatedPassword":true,"currentPassword":"","newPassword":"","repeatPassword":""});
			}else{
				alert(response.data.message);
			}
			
		})
	}else{
      alert("Password and Confirm Password are not same.")
	}
	
  }

  handleGoogleLogin(response){
	//console.log(response)
	if(response!=null){
	const signInRequest = {
	  email : this.state.email,
	  accessToken : response.accessToken,
	  lastName : response.profileObj.familyName,
	  firstName : response.profileObj.givenName,
	  imageUrl : response.profileObj.imageUrl,
	  googleId : response.profileObj.googleId
	}
	AuthService.gmailSignIn(signInRequest).then(
	  () => {
		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
				this.setState({"profileImageUrl":response.data.gmailImageUrl});
			}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
				this.setState({"profileImageUrl":response.data.facebookImageUrl});
			}
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			this.setState({"title":response.data.title,
			"firstName":response.data.firstName,
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
		//	console.log(response.data)
		  })
	  },
	  error => {
		const resMessage =
		  (error.response &&
		  error.response.data &&
		  error.response.data.message) ||
		  error.message ||
		  error.toString();

		this.setState({
		  loading: false,
		  message: resMessage
		});
	  }
	)
  }
  }

  handleFBLogin(response){

	//console.log(response)
	const signInRequest = {
	  fbId : response.profile.id,
	  email : this.state.email,
	  firstName : response.profile.firstName,
	  lastName : response.profile.lastName,
	  imageUrl : response.profile.profilePicURL,
	  accessToken : response._token.accessToken
	}
	AuthService.faceBookSignIn(signInRequest).then(
	  () => {
		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
				this.setState({"profileImageUrl":response.data.gmailImageUrl});
			}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
				this.setState({"profileImageUrl":response.data.facebookImageUrl});
			}
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			this.setState({"title":response.data.title,
			"firstName":response.data.firstName,
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
			//console.log(response.data)
		  })
	  },
	  error => {
		const resMessage =
		  (error.response &&
			error.response.data &&
			error.response.data.message) ||
		  error.message ||
		  error.toString();

		this.setState({
		  loading: false,
		  message: resMessage
		});
	  }
	)

  }

  onChangeProfilePic(event){
	this.setState({"profileUploading":true})
	 const formData = new FormData();
	 formData.append('id',this.state.userId);
	 formData.append('profileImage',event.target.files[0]);
	 formData.append('profileImageName',event.target.files[0].name);
	 ProfileService.updateProfile(formData).then(
	   response => {
		   if (response.data.responseCode == 'SUCCESS') {
			   alert("Profile Image Updated.");
			   ProfileService.getUserDetails(this.state.userId).then(response=>{
				   //alert(response.data.email)
				   if(response.data.profileImageUrl !=null && response.data.profileImageUrl!==''){
					   this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
				   }
				   this.setState({"profileUploading":false})
			   });
		   
		   }else{
			   alert(response.data.message);
		   }

	   });
 }

  render() {
	const responseGoogle = (response) => {
        this.handleGoogleLogin(response)
      }
      const handleSocialLogin = (user) => {
        this.handleFBLogin(user)
      }

      const handleSocialLoginFailure = (err) => {
        console.error(err)
      }
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>

	<section class="same-section user_accountP padding_top padding_bottom">
	    	<div class="container">
	    		<div class="user_accountP_perent">
	    			<div class="user_accountP_left">
	    				<div class="userac_tabsimage_bx_main">
		    				<div class="user_image_side">
		    					<div class="user_image_account">
								<img src={this.state.profileImageUrl} style={{"border-radius":"50%"}} alt="Image_Icon"/>
									<div class="profilePicEdit">
									           <i class="fas fa-camera"></i>
									</div>
									<input type="file" id="imgupload" name="profilePic" style={{"display":"none"}} onChange={this.onChangeProfilePic}  /> 
									{( this.state.profileUploading == true ?  (<div style={{"color":"#55aeb3"}}><p>Uploading....</p></div>):(<></>) )} 

		    					</div>
		    					<h5>{this.state.userName}</h5>
		    					<h6><a class="nav-link"  href="/myProfile" class="greenlink_text">Edit User</a></h6> 
		    				</div>
		    				
		    				<div class="user_account_table_menu">
		    					<ul class="userac_tabs nav nav-tabs" role="tablist">
		          					<li class="nav-item">
				                        <a class="nav-link active" href="#home">
				                        	<img src="images/usericon01.svg" alt="Image-Icon"/><span class="boktitle"> Bookings</span> 
				                        	<i class="fas fa-chevron-right"></i>
				                        </a>
				                    </li>
				                    <li class="nav-item">
				                      <a class="nav-link"  href="/myReviews">
				                      	<img src="images/usericon02.svg" alt="Image-Icon"/><span class="boktitle"> Reviews</span> 
				                      
				                      </a>
				                  	</li>
				                    <li class="nav-item">
				                      <a class="nav-link"  href="/myWishlist">
				                      	<img src="images/usericon03.svg" alt="Image-Icon"/><span class="boktitle"> Wishlist</span> 
				                      	
				                      </a>
				                  </li>
				                    <li class="nav-item">
				                      <a class="nav-link"  href="/myProfile">
				                      	<img src="images/usericon04.svg" alt="Image-Icon"/><span class="boktitle"> Settings</span> 
				                      	
				                      </a>
				                  	</li>
		    					</ul>
		    				</div>
	    				</div>
	    				{/* <div class="bestfood_account">	    					
				    		<div class="planning_four_bx">
		    					<div class="planning_inner box_side_one background_grees">
		    						<h6>Best Food & Must Eats</h6>
		    						<p>Discover Local Cuisines</p>
		    						<a href="#" class="white_text_border explor_more_butt">View More</a>
		    					</div>
	    					</div>
	    				</div> */}
	    			</div>
	    			<div class="user_accountP_right">
		                <div class="tab-content">
		                    <div id="home" class="tab-pane active">
			    				<div class="user_accountP_right_inner">
			    					<h2 class="userac_right_title">My Bookings</h2>
									{(this.state.bookingList != null && this.state.bookingList.length> 0 )? this.state.bookingList.map(
                                    dd =><>
			    					<div class="booking_order_bx">
			    						<div class="booking_orderbx_paid">
										    <div class="orderDetails">
			    							<h6>Order No - {dd.orderId}  </h6>
											
			    							<h6>Actual Payment -  <strong>₹  {dd.bookingAmount} </strong></h6>
											</div>
			    							<div class="paidclick_link">
											<h6>Booking Date - {dd.participationDate}  </h6>
			    								Payment Status : <a href="#" class="greenlink_text"> {dd.payementStatus}</a>
			    							</div>
			    						</div>
			    						<div class="order_confurmbx">
			    							<div class="order_confurmbx_flex">
			    								<div class="ordeeflex_left">
			    									<div class="ordeeflex_left_image">
			    										<img src={IMAGE_BASE_URL+``+JSON.parse(dd.ad.gridImage).imageUrl} alt={JSON.parse(dd.ad.gridImage).altText}/>
			    									</div>
			    								</div>
			    								<div class="ordeeflex_right">
			    									<h2>{dd.ad.name}</h2>
			    									{/* <p>Participation Date - 28 OCTOBER 2019 (Local Time) | <span>MP</span></p> */}
			    									<p>Package Details - <span> {dd.noOfAdults} Adult</span> |<span> {dd.noOfChilds} Child</span></p>
			    								</div>
			    							</div>
			    							<div class="confurmedlink">
											Booking Status : <a href="#" class="greenlink_text">{dd.bookingStatus}</a>
			    							</div>
			    						</div>
			    					</div>
									</>
						   ):""}

{( this.state.noBookings == true ?  (<div class="contentsubs_side"><p>No Bookings Found</p></div>):(<></>) )}
			    					{/* <div class="booking_order_bx">
			    						<div class="booking_orderbx_paid">
			    							<h6>Order No - AU1922313123121   Booking Date - <span>15 Oct 2019</span> | <span>20:01 Hours</span></h6>
			    							<h6>Actual Payment -  <strong>₹ 30,000</strong></h6>
			    							<div class="paidclick_link">
			    								<a href="#" class="greenlink_text">Paid</a>
			    							</div>
			    						</div>
			    						<div class="order_confurmbx">
			    							<div class="order_confurmbx_flex">
			    								<div class="ordeeflex_left">
			    									<div class="ordeeflex_left_image">
			    										<img src="images/book-img.jpg" alt="Image-Icon"/>
			    									</div>
			    								</div>
			    								<div class="ordeeflex_right">
			    									<h2>Nandanvan Forest Trails & more (7 Days)</h2>
			    									<p>Participation Date - 28 OCTOBER 2019 (Local Time) | <span>MP</span></p>
			    									<p>Package Details - Pickup from Panjim Bus Stand | <span> 2 Adult</span> |<span> 1 Child</span></p>
			    								</div>
			    							</div>
			    							<div class="confurmedlink">
			    								<a href="#" class="greenlink_text">Confirmed</a>
			    							</div>
			    						</div>
			    					</div> */}
			    				</div>
			    			</div>

		                    <div id="menu1" class="tab-pane">
		                    	<div class="user_accountP_right_inner">
			    					<h2 class="userac_right_title">My Reviews</h2>
			    					<div class="myreview_head_flex">
			    						<div class="reviewhead_left">
			    							Activities
			    						</div>
			    						<div class="reviewhead_left">
			    							Reviews
			    						</div>
			    					</div>
			    					<div class="review_ac_list">
									{(this.state.reviewList != null && this.state.reviewList.length> 0 )? this.state.reviewList.map(
                                    dd =><>
			    						<div class="review_ac_lflex">
			    							<div class="review_ac_lflex_left">			    								
		    									<div class="review_ac_left_one">
		    										<div class="ordeeflex_left_image">
			    										<img src={IMAGE_BASE_URL+``+JSON.parse(dd.ad.gridImage).imageUrl} alt={JSON.parse(dd.ad.gridImage).altText}/>
			    									</div>
		    									</div>
		    									<div class="review_ac_left_two">		    										
				    								<h2>{dd.ad.name}</h2>
				    								
				    								<ul class="star_ac_list">
													{( dd.noOfStar == 5?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( dd.noOfStar == 4?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( dd.noOfStar == 3?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( dd.noOfStar == 2?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( dd.noOfStar == 1?  (<><li><i class="fas fa-star"></i></li></>):(<></>) )}
				    								</ul>
		    									</div>
			    							</div>
			    							<div class="review_ac_lflex_right">	
			    								<p>{dd.review}</p>
			    								<h6>12 August 2019</h6>
			    							</div>
			    						</div>
										</>
						            ):""}
									{( this.state.noReview == true ?  (<div class="contentsubs_side"><p>No Reviews Found</p></div>):(<></>) )}
			    					</div>
			    				</div>
		                    </div>

<div id="menu2" class="tab-pane">
<div class="user_accountP_right_inner">
<h2 class="userac_right_title">My Wishlist</h2>
<div class="mywishlist_ac">
<div class="row">	
{(this.state.wishList != null && this.state.wishList.length> 0 )? this.state.wishList.map(
                                    dd =><>		    							
	<div class="col-md-4">
		<div class="popular_activities_box">
             <div class="imagesof_box">
                <img  src={IMAGE_BASE_URL+``+JSON.parse(dd.ad.gridImage).imageUrl} alt={JSON.parse(dd.ad.gridImage).altText}/>
                <div class="activities_palcetitle">
                  
                </div>
             </div>
             <div class="popular_activities_content">
                <h3 class="palcebox_title">{dd.ad.name}</h3>
               
             </div>
             <div class="removewishlist_bx">
             	<a href="#" onClick={(e)=> this.handleRemoveWishList(e, dd._id)}><i class="fas fa-heart"></i> Remove from Wishlist</a>
             </div>
        </div>
	</div>	

		</>
						   ):""}		
	
	{( this.state.noWishList == true ?  (<div class="contentsubs_side"><p>No Whislist Items Found</p></div>):(<></>) )}
</div>
</div>
</div>
</div>

<div id="menu3" class="tab-pane">
<div class="ac_info_userside">
<div class="panel-group accordion_Ppolicy" id="accordion_Ppolicy_one" role="tablist" aria-multiselectable="true">

<div class="panel panel-default">
<div class="panel-heading" role="tab" id="heading-2">
<h4 class="panel-title">
<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_Ppolicy_one" href="#collapse-2" aria-controls="collapse-2">							                     
<h2 class="userac_right_title">Account Information</h2>
</a>
</h4>
</div>
<Form class="login-form" onSubmit={this.updateDetails} ref={c => {this.form = c;}}>
<div id="collapse-2" class="panel-collapse collapse show in" role="tabpanel" aria-labelledby="heading-2">
<div class="panel-body">
<div class="account_info_form_main">
<div class="acdetail_title">
<p>This information is used to autofill your details to make it quicker for you to book. Your details will be stored securely and won’t be shared publicly.</p>
<div class="formfuild_perent small_padding_top">
	
<div class="form_fulidrow">
<div class="row">
<div class="col-lg-2 pb-4">
<label class="form_fuild_label">Title</label>
<div class="form_icon_postion_fuild">
<select class="filter_fulid" required name="title" value={this.state.title}  onChange={this.onChange}>
			                    						<option value="">select</option>
														<option value="Mr">Mr</option>
														<option value="Mrs">Miss</option>
</select>
<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
</div>
</div>

<div class="col-lg-5 pb-4">
<label class="form_fuild_label">First Name (as on Travel Document)</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" required name="firstName" value={this.state.firstName}  onChange={this.onChange} placeholder="First Name"/>
</div>
</div>

<div class="col-lg-5 pb-4">
<label class="form_fuild_label">Family Name (as on Travel Document)</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" required name="lastName" value={this.state.lastName}  onChange={this.onChange} placeholder="Family Name"/>
</div>
</div>
</div>
</div>

<div class="form_fulidrow">
<div class="row">
<div class="col-lg-12 pb-4">
<label class="form_fuild_label">Country/Region of Travel Document</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" required name="country" value={this.state.country}  onChange={this.onChange} placeholder="Country Name"/>

</div>
</div>
</div>
</div>

<div class="form_fulidrow">
<div class="row">
<div class="col-lg-2 pb-4">
<label class="form_fuild_label">Country Code</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" required name="code" value={this.state.code}  onChange={this.onChange} placeholder="Code"/>

</div>
</div>

<div class="col-lg-6 pb-4">
<label class="form_fuild_label">Phone Number (Incase of Emergency)</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" required name="phone" value={this.state.phone}  onChange={this.onChange} placeholder="Phone Number"/>

</div>
</div>
</div>
</div>

<div class="form_fulidrow">
<div class="row">								                    				
<div class="col-lg-12 pb-4">
<label class="form_fuild_label">Email (To Receive Coupon)</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" required name="email" value={this.state.email}  onChange={this.onChange} placeholder="mopasa@king.com"/>
</div>
</div>
</div>
</div>

<div class="form_fulidrow">
<div class="row">								                    				
<div class="col-lg-12 pb-4">
<div class="ac_savebutton">
<button type="submit" class="btn btn-primary" >Save</button>
{( this.state.updated == true ?  (<div class="contentsubs_side"><p>Account Details Updated</p></div>):(<></>) )}
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</Form>
</div>

<Form class="login-form" onSubmit={this.updatePassword} ref={c => {this.form = c;}}>
<div class="panel panel-default">
<div class="panel-heading" role="tab" id="heading-3">
<h4 class="panel-title">
<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_Ppolicy_one" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
<h2 class="userac_right_title">Change Password</h2>
</a>
</h4>
</div>
<div id="collapse-3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-3">
<div class="panel-body">							                    
<div class="account_info_form_main">
<div class="acdetail_title">
<p>Password must be 8-20 characters long and contain at least 1 letter and 1 number</p>
<div class="formfuild_perent small_padding_top">

{(this.state.passwordExist == false ? (<></>):(
<>	
<div class="form_fulidrow">
<div class="row">
<div class="col-lg-5 pb-4">
<label class="form_fuild_label">Current Password</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" name="currentPassword" value={this.state.currentPassword} onChange={this.onChange} placeholder="Enter your current password"/>
</div>
</div>
</div>
</div>
</>) )}




<div class="form_fulidrow">
<div class="row">
<div class="col-lg-12">
<label class="form_fuild_label">New Password (password must be 8 - 20 characters long and contain at least 1 letter and 1 number)</label>
</div>
<div class="col-lg-5 pb-4">
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" name="newPassword" value={this.state.newPassword} onChange={this.onChange} placeholder="Enter new password"/>
</div>
</div>								                    				
<div class="col-lg-5 pb-4">
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" name="repeatPassword" value={this.state.repeatPassword} onChange={this.onChange} placeholder="Confirm password"/>
</div>
</div>
</div>
</div>
<div class="form_fulidrow small_padding_bottom">
<div class="row">								                    				
<div class="col-md-2">
<div class="ac_savebutton">
<button type="submit" class="btn btn-primary" >Save</button>
{( this.state.updatedPassword == true ?  (<div class="contentsubs_side"><p>Password Updated</p></div>):(<></>) )}
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</Form>
<div class="panel panel-default">
<div class="panel-heading" role="tab" id="heading-4">
<h4 class="panel-title">
<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_Ppolicy_one" href="#collapse-4" aria-expanded="false" aria-controls="collapse-3">
<h2 class="userac_right_title">Manage Login Methods</h2>
</a>
</h4>
</div>
<div id="collapse-4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-4">
<div class="panel-body">						                  								                    
<div class="account_info_form_main">
<div class="acdetail_title">
<p>Log into Renok with your email address, phone number, or social media accounts</p>
<div class="formfuild_perent small_padding_top">
<div class="form_fulidrow">
<div class="row">
<div class="col-lg-6 pb-4">
<label class="form_fuild_label">Email & Phone Number</label>
<div class="form_icon_postion_fuild_flex">
<div class="form_icon_postion_flex_left">
<span class="email_ad_title">Email address</span>
<span class="email_ad_address">{this.state.email}</span>
</div>


{(this.state.isEmailVerified == true ? (<><div class="form_icon_postion_flex_right align-right" style={{"color":"green"}}>Verified</div></>):(<><div class="form_icon_postion_flex_right align-right" ><a href="#" class="greenlink_text">Verify</a></div></>) )}	

</div>
</div>
<div class="col-lg-6 pb-4">
<label class="form_fuild_label">Email & Phone Number</label>
<div class="form_icon_postion_fuild_flex span_not_padding">
<div class="form_icon_postion_flex_left">
<span class="email_ad_title">Phone Number</span>
<span class="email_ad_address">{this.state.phone}</span>
</div>

{(this.state.isPhoneVerified == true ? (<><div class="form_icon_postion_flex_right align-right" style={{"color":"green"}}>Verified</div></>):(<><div class="form_icon_postion_flex_right align-right" ><a href="#" class="greenlink_text">Verify</a></div></>) )}	

</div>
</div>
</div>
</div>
<div class="form_fulidrow">
<div class="row">
<div class="col-lg-6 pb-4">
<label class="form_fuild_label">Social Media</label>
<div class="form_icon_postion_fuild_flex span_not_padding">
<div class="form_icon_postion_flex_left">
<span class="email_ad_title">Google</span>
</div>


{(this.state.gmailVerified == true ? (<><div class="form_icon_postion_flex_right align-right" style={{"color":"green"}}>Verified</div></>):(<><div class="form_icon_postion_flex_right align-right" >
<GoogleLogin 
                                        clientId={GOOGLE_CLIENT_ID}
                                        render={renderProps => (
											<a href="#" class="greenlink_text" onClick={renderProps.onClick} disabled={renderProps.disabled} >Verify</a>
    
                                        )}
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                   />
	
	</div></>) )}	


</div>
</div>
<div class="col-lg-6 pb-4">
<label class="form_fuild_label"></label>
<div class="form_icon_postion_fuild_flex span_not_padding">
<div class="form_icon_postion_flex_left">
<span class="email_ad_title">Facebook</span>
</div>


{(this.state.facebookVerified == true ? (<><div class="form_icon_postion_flex_right align-right" style={{"color":"green"}}>Verified</div></>):(<><div class="form_icon_postion_flex_right align-right" ><SocialButtonVerify
                                        provider='facebook'
                                        appId={FB_APP_ID}
                                        onLoginSuccess={handleSocialLogin}
                                        onLoginFailure={handleSocialLoginFailure}
                                      >
                                        Verify
                                      </SocialButtonVerify> 	
                                  </div></>) )}	

</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

</div> 
</div>
</div>
			    		</div>
	    			</div>
	    		</div>
	    	</div>
	    </section>


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default MyBookings;
