import React from 'react';
import AuthService from '../../services/AuthService'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import SocialButton from './SocialButton'
import { GoogleLogin } from 'react-google-login';
import GoogleButton from '../Login/googleLoginButton';
import qs from 'qs';
import {GOOGLE_CLIENT_ID, FB_APP_ID} from '../../constants'
import Helmet from 'react-helmet';
import Header from './Header';

class Login extends React.Component {
  constructor(props) {
    super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleLoginP = this.handleLoginP.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
        this.handleFBLogin = this.handleFBLogin.bind(this)
        
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this)
        this.state = {
          userLoggedIn :  AuthService.isUserLoggedIn(),
          token : qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).token,
          first_name: "",
          last_name: "",
          image_url: "",
    
          phone: localStorage.phone,
          password: localStorage.password,
          isChecked : false,
          isCheckedEmail : localStorage.isCheckedEmail,
          isCheckedMobile:localStorage.isCheckedMobile,
          
          loading: false,
          message: "",
          successful: false,
          email: localStorage.email,
        };
      }

      componentDidMount() {
        if(this.state.token !== null && this.state.token !== undefined){
          AuthService.verifyEmail(this.state.token).then(
            response => {
              this.setState({
                message: response.data.message,
                successful: true
              })
            })
        }
        
       
    }

 
    onChangeCheckbox (e,id) {
      alert(id)

      // document.getElementById(id).checked = true;
        e.preventDefault();
        if(id=='isCheckedEmail'){
          if(this.state.isCheckedEmail==true){
            this.setState({isCheckedEmail: false});
          }else{
            this.setState({isCheckedEmail: true});
          }
         
        }else if(id="isCheckedMobile"){
          if(this.state.isCheckedMobile==true){
            this.setState({isCheckedMobile: false});
          }else{
            this.setState({isCheckedMobile: true});
          }
        }
    }

      handleGoogleLogin(response){
        if(response!=null){
        const signInRequest = {
          email : response.profileObj.email,
          accessToken : response.accessToken,
          lastName : response.profileObj.familyName,
          firstName : response.profileObj.givenName,
          imageUrl : response.profileObj.imageUrl,
          googleId : response.profileObj.googleId
        }
        AuthService.gmailSignIn(signInRequest).then(
          (data) => {
            
            if(data.status==undefined || data.status === 'ACTIVE'){
              
              const user = JSON.parse(localStorage.getItem('userRenokWeb'));
              this.props.history.push("/myProfile");
              window.location.reload();
            }else{
              localStorage.removeItem('userRenokWeb')
              this.setState({
                loading: false,
                message: "Login Blocked By Administrator."
              });
            }
          },
          error => {
            const resMessage =
              (error.response &&
              error.response.data &&
              error.response.data.message) ||
              error.message ||
              error.toString();
  
            this.setState({
              loading: false,
              message: resMessage
            });
          }
        )
      }
      }

      handleFBLogin(response){

        //console.log(response)
        const signInRequest = {
          fbId : response.profile.id,
          email : response.profile.email,
          firstName : response.profile.firstName,
          lastName : response.profile.lastName,
          imageUrl : response.profile.profilePicURL,
          accessToken : response._token.accessToken
        }
        AuthService.faceBookSignIn(signInRequest).then(
          (data) => {

            if(data.status==undefined || data.status === 'ACTIVE'){
              
              const user = JSON.parse(localStorage.getItem('userRenokWeb'));
              this.props.history.push("/myProfile");
              window.location.reload();
            }else{
              localStorage.removeItem('userRenokWeb')
              this.setState({
                loading: false,
                message: "Login Blocked By Administrator."
              });
            }
            
          },
          error => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
  
            this.setState({
              loading: false,
              message: resMessage
            });
          }
        )

      }

      onChange(e) {
        this.setState({
          [e.target.name]: e.target.value
        });
      }

    
      handleLogin(e) {
        e.preventDefault();
       
        
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();
        if (document.getElementById("checkEmail").checked) {
          //alert("checkedEmail")
          localStorage.email = this.state.email
          localStorage.password = this.state.password
          localStorage.isCheckedEmail = this.state.isCheckedEmail
        }else{
         // alert("Not checkedEmail")
        }
    
       
          const loginRequest = {
            email : this.state.email,
            password : this.state.password
          }
          AuthService.login(loginRequest).then(
            (data) => {
              
              if(data.isEmailVerified){
               // alert(data.status);
                if(data.status==undefined || data.status === 'ACTIVE'){
                  
                  const user = JSON.parse(localStorage.getItem('userRenokWeb'));
                  this.props.history.push("/myProfile");
                  window.location.reload();
                }else{
                  localStorage.removeItem('userRenokWeb')
                  this.setState({
                    loading: false,
                    message: "Login Blocked By Administrator."
                  });
                }
                
              }else{
                localStorage.removeItem('userRenokWeb')
                this.setState({
                  loading: false,
                  message: "Please Verify your Email first."
                });
              }
             
            
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
                // message: resMessage
              });
            }
          );
        
      }

      handleLoginP(e) {
        e.preventDefault();
    
        this.setState({
          message: "",
          loading: true
        });
    
        this.form.validateAll();
        if (document.getElementById("checkMobile").checked) {
         // alert("checkedMobile")
          localStorage.phone = this.state.phone
          localStorage.password = this.state.password
          localStorage.isCheckedMobile = this.state.isCheckedMobile
        }else{
         // alert("not checkedMobile")
        }
    
        var phoneno = /^\d{10}$/;
        if(this.state.phone!=null && this.state.phone.length==10 && this.state.phone.match(phoneno)){
        
          const loginRequest = {
            phone : this.state.phone,
            password : this.state.password
          }
          AuthService.login(loginRequest).then(
            (data) => {
              
                if(data.status==undefined || data.status === 'ACTIVE'){
                  //alert("inn")
                  const user = JSON.parse(localStorage.getItem('userRenokWeb'));
                  this.props.history.push("/myProfile");
                  window.location.reload();
                }else{
                  localStorage.removeItem('userRenokWeb')
                  this.setState({
                    loading: false,
                    message: "Login Blocked By Administrator."
                  });
                }
              
            
            },
            error => {
              const resMessage =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
    
              this.setState({
                loading: false,
                message: resMessage
              });
            }
          );

      }else{
        this.setState({
          loading: false,
          message: "Please Enter 10 Digit Mobile Number"
        });
      }
        
      }

    render(){
      const responseGoogle = (response) => {
        this.handleGoogleLogin(response)
      }
      const handleSocialLogin = (user) => {
        this.handleFBLogin(user)
      }

      const handleSocialLoginFailure = (err) => {
        console.error(err)
      }
   
  return (
	<body class="darkHeader_inner">
    <Helmet>
    <script src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v12.0&appId=1206614489817558&autoLogAppEvents=1" 
    type="text/javascript" />
    </Helmet>
    <div class="main_all">
    <Header></Header>
	<section class="login_main_perent">
          <div class="login_main_perent_overflow">
          	<div class="user_login_page_bx">
          		<div class="user_login_page_bx_inner">
             
          			<div class="login_title_fuild_bx">
          				<h2 class="login_side_ttile">Login</h2>
                  {this.state.message && (
              <div className="form-group">
                <div className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  } role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
          				<ul class="listtabs_click nav nav-tabs" role="tablist">
          					<li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#home">Email address</a>
                      </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#menu1">Phone Number</a>
                    </li>
          				</ul>                
                </div>
                  <div class="tab-content">
                    <div id="home" class="tab-pane active">
                      <div class="login_tab_content">
                        <div class="formside_perent">
                        <Form class="login-form" onSubmit={this.handleLogin} ref={c => {this.form = c;}}>
                          <div class="fuild_form_row">                 
                            <div class="classform_fuild_icon">
                                <input type="email" name="email" required  onChange={this.onChange} value={this.state.email} class="form_inputfuild" placeholder="Email address" />
                            </div>
                          </div>                  
                          <div class="fuild_form_row">
                            <div class="classform_fuild_icon">
                              <input id="password-field" required type="password" name="password"  onChange={this.onChange} value={this.state.password} class="form_inputfuild" placeholder="Password" />
                                {/* <span class="formon_icon visibal_and_not"><i class="far fa-eye"></i></span> */}
                                <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                            </div>
                          </div>
                          
                         

                          <div class="fuild_form_row">
                            <div class="rememberlist_chck">
                              <div class="log_formfuild"> 
                              <label class="containerRememberMe">Remember Me<input type="checkbox" id="checkEmail" value="checkedEmailValue" name="checkEmail"  />
                              <span class="checkmark"></span>
                               </label>           
                                  {/* <ul class="list">                            
                                    <li class="list__item">
                                      <div class="list-check-box">
                                     
                                        <input type="checkbox"   name="lsRememberMeEmail"  />
                                        <label for="test134" class="label">Remember Me</label>
                                      </div>
                                    </li> 
                                  </ul> */}
                              </div>                  
                              <div class="log_formfuild align-right">
                              
                                <a href="/forgot-password" class="forgotpass_link">Forgot Password?</a>
                              </div>                    
                            </div>
                          </div>

                          <div class="fuild_form_row">
                          <button type="submit" class="green_button login-page" >Login</button>
                         
                          </div>
                          </Form>
                            <div class="log_formfuild text-center">
                                <a href="/forgot-password" class="forgot-pass">Forgot Password?</a>
                              </div>     

                          <div class="fuild_form_row">
                            <div class="orlog_with text-align">
                              <span>or log in with</span>
                            </div>
                          </div>
                          <div class="fuild_form_row">
                            {/* <GoogleButton /> */}
                          <GoogleLogin 
                                        clientId={GOOGLE_CLIENT_ID}
                                        render={renderProps => (
                                          <button  class="green_button logs_google" style={{"color":"white !important"}} onClick={renderProps.onClick} disabled={renderProps.disabled} >
                                          <i class="fab fa-google"></i>&nbsp; &nbsp;&nbsp; Login with Google</button >
                                        )}
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                   />
                                 </div>
                                 <div class="fuild_form_row forno_padd_bottom"> 
                                <SocialButton
                                        provider='facebook'
                                        appId={FB_APP_ID}
                                        onLoginSuccess={handleSocialLogin}
                                        onLoginFailure={handleSocialLoginFailure}
                                      >
                                        Login with Facebook
                                      </SocialButton> 	
                                  </div>
                          
                        </div>

                        <div class="fuild_form_row">
                          <div class="noaccount_yetside">
                            <div class="noaccount_yetside_left">
                              No Account yet? Sign Up now
                            </div>
                            <div class="noaccount_yetside_right">
                              <a href="/signup" class="signuo_butS">Sign up</a>
                            </div>
                          </div>
                        </div>
                        <div class="fuild_form_row">
                          <div class="lastlog_trens_condition">
                            <p>By joining Renok through Facebook, Google, Phone Number or Email Sign up, you agree to <a href="/privacy-policy">Renok’s General Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a></p>
                          </div>
                        </div>
                      </div>                      
                    </div>

                    <div id="menu1" class="tab-pane">
                      <div class="formside_perent">
                        
          				      <div class="login_tab_content">
                        <Form class="login-form" onSubmit={this.handleLoginP} ref={c => {this.form = c;}}>
                          <div class="fuild_form_row formob_num_row">
                            <div class="log_formfuild">
                              <div class="classform_fuild_icon">
                                <select class="form_inputfuild">
                                  <option>+91</option>
                                </select>
                                <span class="formon_icon"><i class="fas fa-angle-down"></i></span>
                              </div>
                            </div>                  
                            <div class="log_formfuild">
                              <div class="classform_fuild_icon">
                                <input type="tel" name="phone" required  onChange={this.onChange} value={this.state.phone} class="form_inputfuild" placeholder="Phone Number" />
                              </div>
                            </div>
                          </div>                  
                          <div class="fuild_form_row">
                            <div class="classform_fuild_icon">
                            <input id="password-field2" required type="password" name="password"  onChange={this.onChange} value={this.state.password} class="form_inputfuild" placeholder="Password" />
                                {/* <span class="formon_icon visibal_and_not"><i class="far fa-eye"></i></span> */}
                                <span toggle="#password-field2" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                            </div>
                          </div>

                          <div class="fuild_form_row">
                            <div class="rememberlist_chck">
                            <div class="log_formfuild"> 
                              <label class="containerRememberMe">Remember Me<input type="checkbox" id="checkMobile" value="checkedMobileValue" name="checkMobile"  />
                              <span class="checkmark"></span>
                               </label>           
                                
                              </div>  
                                             
                              <div class="log_formfuild align-right">
                                <a href="/forgot-password" class="forgotpass_link">Forgot Password?</a>
                              </div>                    
                            </div>
                          </div>

                          <div class="fuild_form_row">
                            <button type="submit"  class="green_button" >Login</button>
                          </div>
                         </Form>

                          

                          <div class="fuild_form_row">
                            <div class="orlog_with text-align">
                              <span>or log in with</span>
                            </div>
                          </div>

                          <div class="fuild_form_row">
                          <GoogleLogin 
                                        clientId={GOOGLE_CLIENT_ID}
                                        render={renderProps => (
                                          <a class="green_button logs_google" style={{"color":"white !important"}} onClick={renderProps.onClick} disabled={renderProps.disabled} >
                                          <i class="fab fa-google"></i>&nbsp; &nbsp;&nbsp; Login with Google</a>
                                        )}
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                   />
                          </div>
                          <div class="fuild_form_row forno_padd_bottom">
                          <SocialButton
                                        provider='facebook'
                                        appId={FB_APP_ID}
                                        onLoginSuccess={handleSocialLogin}
                                        onLoginFailure={handleSocialLoginFailure}
                                      >
                                        Login with Facebook
                                      </SocialButton> 	
                          </div>

                        </div> 

                        </div>                    
                          <div class="fuild_form_row">
                            <div class="noaccount_yetside">
                              <div class="noaccount_yetside_left">
                                No Account yet? Sign Up now
                              </div>
                              <div class="noaccount_yetside_right">
                                <a href="/signup" class="signuo_butS">Sign up</a>
                              </div>
                            </div>
                          </div>
                          <div class="fuild_form_row">
                            <div class="lastlog_trens_condition">
                              <p>By joining Renok through Facebook, Google, Phone Number or Email Sign up, you agree to <a href="/privacy-policy">Renok’s General Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a></p>
                            </div>
                          </div>
                		</div>                
                  </div>
          	 </div>
            </div>
          </div>
        </section>

       <div class="footerpart common_block padding_leftang_right secpaddingT secmarginT">
              
               <div class="askrenok_button">
                  <a href="/ask-renok" class="renokbutton">Ask Renok</a>
               </div>
               <div class="footermenu_links secpaddingT">
                  <ul class="footer_linklist">
                     <li><a href="/about-us">About us</a></li>
                     <li><a href="/career">Careers</a></li>
                     <li><a href="/privacy-policy">Terms of Use</a></li>
                    
                  </ul>
               </div>
               <div class="footersocials secpaddingT">                  
                  <ul class="footer_soclist">
                     <li><a href="https://www.facebook.com/renokadventures/"><i class="fab fa-facebook-f"></i></a></li>
                     <li><a href="https://www.instagram.com/renok.adventures/?hl=en"><i class="fab fa-instagram"></i></a></li>
                     <li><a href="https://twitter.com/renokadventures?lang=en"><i class="fab fa-twitter"></i></a></li>
                     <li><a href="https://www.youtube.com/channel/UCkuiirm9sBWaLt11OYsgDQg"><i class="fab fa-youtube"></i></a></li>
                  </ul>
               </div>
               
               <div class="footerbootom_bar secpaddingT">
                  © 2011 - Present | Renok Adventures. All Rights Reserved.
               </div>
            </div>

        <div class="side_toggle_menu">
          <div class="sidenav">
            <a class="menu_toggle_green">
                <i class="fas fa-times"></i>
                 </a>
                 <ul class="leftmenu_list">
                 {(this.state.userLoggedIn === true ? 
							(<>
							<li class="line_link"><a href="/destination"><span>Search by Destination</span></a></li>
							<li class="line_link"><a href="/subactivities/All/activities"><span>Browse Activities</span></a></li>
							<li class="line_link"><a href="/subactivities/All/tours"><span>Browse Tours</span></a></li>
							<li class="line_link"><a href="/subactivities/All/staycation"><span>Browse Staycations</span></a></li>
							<li class="line_link"><a href="/subactivities/All/transport_&_travel_services"><span>Browse Transport</span></a></li>
							<li class="line_link"><a href="/privacy-policy#side_tab1"><span>Terms And Conditions</span></a></li>
							<li class="line_link"><a href="/privacy-policy#side_tab2"><span>Privacy Policy</span></a></li>

							 <li class="line_link"><a onClick={this.logout}><span>Log Out</span></a></li></>):( <>
							 <li class="line_link"><a href="/destination"><span>Search by Destination</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/activities"><span>Browse Activities</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/tours"><span>Browse Tours</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/staycation"><span>Browse Staycations</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/transport_&_travel_services"><span>Browse Transport</span></a></li>
							 <li class="line_link"><a href="/privacy-policy#side_tab1"><span>Terms And Conditions</span></a></li>
							 <li class="line_link"><a href="/privacy-policy#side_tab2"><span>Privacy Policy</span></a></li>
		                     <li class="line_link"><a href="/slogin"><span>Log In</span></a></li>
		                     </>) )}
         		</ul>
          
      </div>
         </div>

    </div>
	</body>
  );
  }
}

export default Login;
