import React from 'react'
import SocialLogin from 'react-social-login'

class SocialButtonVerify extends React.Component {
 
    render() {
        return (
            <a href="#" class="greenlink_text"  onClick={this.props.triggerLogin} {...this.props} >Verify</a>
           
        );
    }
}
 
export default SocialLogin(SocialButtonVerify);