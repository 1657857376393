import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import ProfileService from '../../services/ProfileService'

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
	this.onChange = this.onChange.bind(this);

    this.state = {
    modal: false,
	message : 'Email Unsubscribtion successful...'
  };
  
}


  componentDidMount() {
    if(this.props.match.params.id!=null){
		ProfileService.unsubscribe(this.props.match.params.id).then(response=>{
			//	alert(response.data.message);

				//	this.setState({"password":"","repeatpassword":"","success":true,"message":"Email Unsubscribtion successful."});

				
		
			})
	}else{
		this.setState({"success":false,"message":"Invalid Email Unsubscribe Link"});
	}
  }
  onChange(e) {
	this.setState({
	  [e.target.name]: e.target.value
	});
  }


  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>

	<section class="same-section invite_your_friendsB innerpage_banner update-pass">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
		    		<h1 class="inner_banner_heding text-align">Email Unsubscribtion</h1>
		    		
	    		</div>
	    	</div>
	    </section>

	    <section class="same-section what_are_renok text-align padding_top padding_bottom bg-gray referal-code">
	    	<div class="container">
	    		<div class="forgot-password">
	    			
	    			
					  
					  <div class="confirms">
					  	  {( this.state.message === '' ?  (<></>):(<><p>{this.state.message}</p></>) )}
					  	 
					  </div>
 
	
	    		</div>
	    		
	    	</div>
	    </section>


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default ForgotPassword;
