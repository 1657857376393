import React from 'react';
import Header from '../pages/Header';
import HomeFooter from '../pages/HomeFooter';

class AboutUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    modal: false,
  };
  
}


  componentDidMount() {
    
  }


  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>
    <section class="same-section new_renok_video">
	    	<div class="container">
	    		<h2 class="top_main_Bheding text-align">Welcome</h2>
	    		<p class="top_main_Sheding text-align">Book your next adventure with Renok</p>
	    		<div class="videoplay_ic">
	    			<a data-toggle="modal" data-target="#homevideo--model">
	    				<img  loading="lazy" src="images/video-playicon.svg" alt=""/>
	    			</a>
	    		</div>
	    	</div>
	    </section>
	    <section class="same-section tabof_about ">
	    	<div class="container">
	    		<div class="about_tab">
	    			<ul class="nav nav-tabs text-align" role="tablist">
					 <li class="nav-item"><a class="nav-link active" data-toggle="tab" href="#home">About Renok</a></li>
					  
					</ul>
	    		</div>
	    	</div>
	    </section>


		<div class="tab-content">
		    <div id="home" class="tab-pane active"><br></br>

			    <section class="same-section aboutafter_banner bg-gray padding_top padding_bottom">
			    	<div class="container">
			    		<h2 class="mainGreen_title text-align"><span>About Us</span></h2>
			    		<p class="text-align">Renok is an adventure company established in 2011 which specializes in trekking, adventure activities and outbound training. As nature and adventure aficionados we strive to give you an experience where the destination is never a place, but a new way of seeing things.</p>
			    	</div>
			    </section>

			    <section class="same-section ourvalue_about padding_top padding_bottom">
			    	<div class="container">
			    		<h2 class="mainGreen_title text-align">Our Values</h2>
			    		<p class="subtitle_about">We believe there is a difference between a tourist and a traveller. We cater to travellers.</p>
			    		<div class="row">
			    			<div class="col-md-6 col-lg-4">
			    				<div class="ourvalu_bx text-align">
			    					<div class="valubx_image">
			    						<img  loading="lazy" src="images/disconnect.svg" alt="Image-Icon"/>
			    					</div>
			    					<h5 class="about_icontitle">Disconnect to Connect</h5>
			    					<p>We at Renok Adventures believe the only way we can connect with nature and ourselves is if we disconnect from our phones and other electronic devices. Only when we leave all our problems, worries and attachments back home do we truly connect with ourselves and our surroundings.</p>
			    				</div>
			    			</div>

			    			<div class="col-md-6 col-lg-4">
			    				<div class="ourvalu_bx text-align">
			    					<div class="valubx_image">
			    						<img  loading="lazy" src="images/planet-earth.svg" alt="Image-Icon"/>
			    					</div>
			    					<h5 class="about_icontitle">Sustainable Tourism</h5>
			    					<p>We have a deep belief in sustainable tourism. We generally operate in far flung rural areas when development is at a minimal. It is our company aim to empower people in these villages so that these people see themselves as stakeholders in our success and see themselves as part of our company.</p>
			    				</div>
			    			</div>

			    			<div class="col-md-6 col-lg-4">
			    				<div class="ourvalu_bx text-align">
			    					<div class="valubx_image">
			    						<img  loading="lazy" src="images/fixes-departure.svg" alt="Image-Icon"/>
			    					</div>
			    					<h5 class="about_icontitle">Fixed Departures</h5>
			    					<p>To make adventure travel affordable we have something called fixed departures. Fixed Departure is a commitment we at Renok Adventures makes with our customers that come what may we will not cancel your trek unless due to ‘Force Majeure’.</p>
			    				</div>
			    			</div>

			    			<div class="col-md-6 col-lg-4">
			    				<div class="ourvalu_bx text-align">
			    					<div class="valubx_image">
			    						<img  loading="lazy" src="images/small-group.svg" alt="Image-Icon"/>
			    					</div>
			    					<h5 class="about_icontitle">Eco Tourism</h5>
			    					<p>We have signed the Eco-Tourism pledge which signifies that we believe that responsible travel to natural areas that conserves the environment and improves the welfare of local people is of the utmost importance and is of the Renok Adventures Ethos.</p>
			    				</div>
			    			</div>

			    			<div class="col-md-6 col-lg-4">
			    				<div class="ourvalu_bx text-align">
			    					<div class="valubx_image">
			    						<img  loading="lazy" src="images/eco-tourism.svg" alt="Image-Icon"/>
			    					</div>
			    					<h5 class="about_icontitle">Values- Small groups</h5>
			    					<p>We believe the best way to experience the outdoors is in small groups. We don’t run away from our city dwellings to come to another fish market.</p>
			    				</div>
			    			</div>
			    			
			    			<div class="col-md-6 col-lg-4">
			    				<div class="ourvalu_bx text-align">
			    					<div class="valubx_image">
			    						<img  loading="lazy" src="images/a-track.svg" alt="Image-Icon"/>
			    					</div>
			    					<h5 class="about_icontitle">Leave No Trace</h5>
			    					<p>Future trekkers should find these pristine environs as we experience them today. To ensure this we follow a policy of leave no trace(LNT).We make sure that we dispose off all our trash in our campsites so that we literally leave no trace of our camp. This is a core principle at Renok Adventures.</p>
			    				</div>
			    			</div>
			    		</div>
			    	</div>
			    </section>

	        </div>
		    <div id="menu1" class="tab-pane fade"><br></br>
		      <h3>Menu 1</h3>
		      <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
		    </div>
		    <div id="menu2" class="tab-pane fade"><br></br>
		      <h3>Menu 2</h3>
		      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
		    </div>
		    <div id="menu3" class="tab-pane fade"><br></br>
		      <h3>Menu 3</h3>
		      <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam.</p>
		    </div>
		</div>


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default AboutUs;
