// export const API_BASE_URL = 'https://api.renokadventures.com'
export const API_BASE_URL = "https://api.renokadventures.com";
export const APP_BASE_URL = "https://renokadventures.com";
// export const APP_BASE_URL = 'http://localhost:3000'

export const IMAGE_BASE_URL = "https://api.renokadventures.com/uploads/images/";

export const PROFILE_IMAGE_BASE_URL =
  "https://api.renokadventures.com/uploads/images/profileimage/";

//Google Credentials
export const GOOGLE_CLIENT_ID =
  "811356634083-4tr5td9nbpdr64c79hlht48p856p0an8.apps.googleusercontent.com";
export const GOOGLE_CLIENT_SECRET = "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";

// export const GOOGLE_CLIENT_ID = "628869251294-qafb7ei72gbiu49atj7vncrl7bdtskd6.apps.googleusercontent.com"
// export const GOOGLE_CLIENT_SECRET = "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"

//Facebook Credentials
export const FB_APP_ID = "1206614489817558";
// export const FB_APP_ID = "670214440956886"

export const WEATHER_API_KEY = "32dba427fb32444692d714b9b1a89ede";
