export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
  
    if (user && user.accessToken) {
      // return { Authorization: 'Bearer ' + user.accessToken }; // for Spring Boot back-end
      return { 'Authorization' : "Bearer "+user.accessToken};       // for Node.js Express back-end
    } else {
      return {};
    }
  }


  export  function youtubeAuthHeader() {
    const youtubeAuth = localStorage.getItem('youtubeAuth')
    console.log(youtubeAuth)
    
      return {'Authorization' : "Bearer ya29.a0AfH6SMBfEmslgBVg9It2LhEKeu-IsbbornUJdTcFqS_2e9RCRecMWdoX4TFdWJikjcuiUdDdjFXh8Ics3hbNei9RN0CUACUVwe6l8wIQMxYVa8msQMf9qNwmv_7jiO3Rf9lb-6x6xEVU77zE_1jvLmdm4LgP5JzbskKp"}
    
  }
