import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import { APP_BASE_URL} from '../../constants';
import Select from 'react-select'
import { IMAGE_BASE_URL} from '../../constants';

class AllDestinationState extends React.Component {
  constructor(props) {
    super(props);
    this.handleClickDescInSearch = this.handleClickDescInSearch.bind(this);
    this.state = {
    modal: false,
    stateName:this.props.match.params.id.split('_').join(' '),
  };
  
}


  componentDidMount() {
    
    ProfileService.getStateIdByName(this.props.match.params.id.split('_').join(' ')).then(response=>{
      var stateId = response.data.id;
      //alert(stateId);
		   if(response.data.id==undefined){
		     	this.props.history.push(`/pageNotFound`)
		     	window.location.reload();
		   }
       ProfileService.getDestinationByState(stateId).then(response=>{
       // console.log(response.data);
            this.setState({"topDestinations":response.data})
       });
    })
	  
   


 

  }

  handleChangeSearchDes(value){
   // alert(value.value)
  if(value!=null){
     var id = value.value;
     //alert(id)
     window.location.href=`${APP_BASE_URL}/destination/${id}`;
      //window.location.reload();
  }
 }

 handleClickDescInSearch(e,id){
  window.location.href=`${APP_BASE_URL}/destination/${id}`;
 }

 handleClickDestination(e,id){
	this.props.history.push(`/destination/${id}`)
	window.location.reload();
  }

  handleClickSubCategory(cat,subCat){
	window.location.href=`${APP_BASE_URL}/subactivities/${subCat}/${cat}`;
}

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>
   
    <section class="same-section invite_your_friendsB innerpage_banner destination-banner">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
		    		<h1 class="inner_banner_heding text-align">{this.state.stateName} </h1>
		    		
	    		</div>
	    	</div>
	    </section>

	    <section class="same-section dest-cat-img text-align padding_top padding_bottom">
	    	<div class="container">
		    	
		    
<div class="tab-content sub-cnt-tab" id="myTabContent">
  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
     


<div class="tab-content" id="nav-tabContent">
  <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
  	<div class="hot-txt">
  	<div class="hot-dest">
       <h2 class="blacbold_main_title">All Destinations</h2>
      
   </div>
       <div class="hotd_box">
       {(this.state.topDestinations != null && this.state.topDestinations.length> 0 )? this.state.topDestinations.map(
                                    influencer =>
          <div class="hdimage">
             <a onClick={(e)=> this.handleClickDestination(e, influencer.url)} ><img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(influencer.gridImage).imageUrl} alt={JSON.parse(influencer.gridImage).altText} /></a>
             <h2 class="bold_whitet">{influencer.name}</h2>
             
          </div>
          )
                                    
          : ""}
          
       </div>
   </div>


  </div>

</div>
  </div>
 
 
</div>
</div>
	    </section>


	


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default AllDestinationState;
