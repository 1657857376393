import React from 'react';
import { Link } from 'react-router-dom';
import Header from './component/pages/Header';
import HomeFooter from './component/pages/HomeFooter';
import ProfileService from './services/ProfileService'

const NotFound = ()=>{
  const [modal,setModal] = React.useState(false);
  const [isLoad,setIsLoad] = React.useState(false);

  React.useEffect(()=>{
    ProfileService.getActivityUrl(window.location.href).then(response=>{
      if(response.data.new_url!=undefined){
        window.location.href = response.data.new_url;
      }
    })
    setIsLoad(true)

    
    setTimeout(function() {
      window.location.href = '/';
    }, 5000);
  },[])

  return (
    <>

    {isLoad? <>

    <div class="main_all">
      <Header></Header>
        <section class="same-section innerpage_banner update-pass">
          <div class="container">
            <div class="innpage_banner_inn_side">
              <h1 class="inner_banner_heding text-align"><img src="/images/404.png" alt="404"/></h1>
              
            </div>
          </div>
        </section>
        <section class="same-section what_are_renok text-align padding_top padding_bottom bg-gray referal-code">
          <div class="container">
            <div class="forgot-password">
              
              
              
              <div class="confirms">
                  
                        <h1>404 Error - the requested page does not exist.
                        You'll go back to the homepage in few seconds! or</h1>
                        <Link to="/">(click here)</Link>
              </div>
  
    
            </div>
            
          </div>
        </section>
      <HomeFooter></HomeFooter>
    </div>
    </> :""}
    </>
  )
} 
export default NotFound;


