import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import PaginationComponent from "react-reactstrap-pagination";
import { APP_BASE_URL} from '../../constants';
import { IMAGE_BASE_URL} from '../../constants';
import Helmet from 'react-helmet';

import Select from 'react-select'
class BlogSearch extends React.Component {
  constructor(props) {
    super(props);
	this.handlePageChange = this.handlePageChange.bind(this);
	this.handleClickSearchPage= this.handleClickSearchPage.bind(this);
	this.handleClickSearch = this.handleClickSearch.bind(this);
    this.state = {
    modal: false,
	isClearable: true,
    isDisabled: false,
    isLoading: false,
	activePage:0,
	totalPages: null,
	itemsCountPerPage:10,
	pagesCount: null,
	totalItemsCount:null,
    isRtl: false,
    isSearchable: true,
	id: this.props.match.params.id,
	searchText : this.props.match.params.id
  };
  this.handleChangeSearch = this.handleChangeSearch.bind(this);
  
}


  componentDidMount() {
	this.refreshBrands(this.props.match.params.id,this.state.activePage, this.state.itemsCountPerPage);
	
  }

  handlePageChange(pageNumber) {
	//console.log(this.state.totalItemsCount)

	this.setState({activePage: pageNumber-1})
 
	this.refreshBrands(this.state.searchText,pageNumber-1, this.state.itemsCountPerPage);
	}

  refreshBrands(search,page,size){
	ProfileService.retrieveAllBlogSearch(search,page,size)
	.then(response => { 
		
		const itemsCountPerPage = response.data.size;
		const totalItemsCount = response.data.totalElements;
		
		  this.setState({totalItemsCount: totalItemsCount})
		  this.setState({itemsCountPerPage: size})
		  this.setState({blogList:response.data.content})
		}
	);
  }

  handleClickBlog(e,id){
    this.props.history.push(`/blog/${id}`)
  }

  handleChangeSearch(value){
	if(value!=null){
		var id = value.value;
		this.props.history.push(`/blog/${id}`)
	}
  }


clearSuggestions(){
	this.setState({"suggestions": []});
}

  searchBlog(text){
	let matchers = [];
	var blogSearchList = this.state.blogSearchList;
	if(text.length>0){
		matchers = blogSearchList.filter(act => {
			const regex = new RegExp(`${text}`,"gi");
			return act.label.match(regex);
		})
	}
   // console.log(matchers);
	this.setState({"searchText":text,"suggestions":matchers});
  }

  handleClickSearch(e,value){
	e.preventDefault();
	//alert(value);
	this.props.history.push(`/blog/${value}`)

}
  
  handleClickSearchPage(e) {
	e.preventDefault();
	var searchText = this.state.searchText;
	if(searchText.length>0){
		window.location.href=`${APP_BASE_URL}/blogsearch/${searchText}`;
	}
}

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
	<Helmet>
    <script src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v12.0&appId=1206614489817558&autoLogAppEvents=1" 
    type="text/javascript" />
    </Helmet>
    <Header></Header>

   



   		<section class="same-section blog_page_blogs padding_top">
   			
	    	<div class="container">
	    		<div class="blog_pagemain_flex">
	    			<div class="blog_leftbx_side">
	    				<div class="HRblack_bold"></div>
			    		<h6 class="blog_page_topsmalls small_padding_top"> </h6>
			    		<h3 class="blackblog_title">Search Results for : {this.state.searchText}</h3>
			    		{(this.state.blogList != null && this.state.blogList.length> 0 )? this.state.blogList.map(
                                    hl =><>
									<a href={"/blog/" +hl.url}>
						<div class="blog_bx_Bpage">
							<h3 class="blackblog_title">{hl.name}</h3>	
							<ul class="italic_service_label">
							{(JSON.parse(hl.tags) != null && JSON.parse(hl.tags).length> 0 )? JSON.parse(hl.tags).map(
                                    tg =><>
		    					<li><a href="#" class="brn-itiner">{tg.valueValue}</a></li>
		    					</>
						   ):""}
		    				</ul>		    			
		    				<img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(hl.gridImage).imageUrl} alt={JSON.parse(hl.gridImage).altText}/>
		    				
		    			
		    				{/* <div class="cnt-test">
		    				<div class="blog_userimg_bx">
                        <div class="blog_usercont">
                           <div class="image_bbx_user">
                              <img src="/images/guidetip-user.jpg" alt="" />
                           </div>
                        </div>
                        <div class="blog_usercont">
                           <span>Dinkar Kamat</span><span>SEPTEMBER 12, 2019</span>
                        </div>
                     </div>
                     <p>It’s important to understand that tourism activities in Leh-Ladakh are limited to only 5 months in a year. The season to visit Ladakh is from May to October. In fact, the peak tourist season is considered to be from June to September. Though</p>
                     <h5>Read more &nbsp; &nbsp; <i class="fas fa-chevron-down"></i></h5>
                 </div> */}
		    				<a onClick={(e)=> this.handleClickBlog(e, hl.url)} class="onlink"></a>
				    	</div>
						</a>
              </>
						   ):""}
			    	
				    	<div class="HRblack_bold small_margin_top border-cnt"></div>
						
            			<div class="same-section  padding_bottom small_padding_top">	    
						      <div class="common_block pagination_bar align-left">
							  <PaginationComponent
          totalItems={this.state.totalItemsCount}
          pageSize={this.state.itemsCountPerPage}
          onSelect={this.handlePageChange}
        />
						        
						      </div>
						</div>	    	
						
	    			</div>	    			
	    			<div class="blog_rightbx_side">
	    				<div class="HRblack_bold"></div>
	    				<h3 class="blackblog_title small_padding_top">RECENT POSTS</h3>
	    				<ul class="recent_postlist small_padding_top">
	    				{(this.state.blogList != null && this.state.blogList.length> 0 )? this.state.blogList.map(
                                    hl =><>
	    					<li><a style={{"cursor":"pointer"}}  href={`/blog/${hl.url}`} >{hl.name}</a></li>
	    				
                </>
						   ):""}
	    				</ul>
	    				<div class="HRblack_bold"></div>
	    				<h3 class="blackblog_title small_padding_top">ABOUT RENOK</h3>
	    				<p class="small_padding_top">Renok is an adventure company established in 2011 which specializes in trekking, adventure activities and outbound training. As nature and adventure aficionados we strive to give you an experience where the destination is never a place, but a new way of seeing things.</p>
						<div style={{"width":"100%","margin-top":"20px"}}>

<div class="fb-page" data-href="https://www.facebook.com/renokadventures/" data-width="420"></div>
</div>
	    			</div>
	    		</div>
	    	</div>
	    </section>

	   

    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default BlogSearch;
