import React,{createRef} from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import Select from 'react-select'
import ProfileService from '../../services/ProfileService'
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import Example from "./Faq";
import AccordionExampleNested from './Faq';
import { Accordion } from 'semantic-ui-react'

const styleLink = document.createElement("link");
styleLink.rel = "stylesheet";
styleLink.href = "https://cdn.jsdelivr.net/npm/semantic-ui/dist/semantic.min.css";
document.head.appendChild(styleLink);


class AskRenok extends React.Component {
  constructor(props) {
    super(props);
    this.handleChangeSearch = this.handleChangeSearch.bind(this);
    this.getFaqByType = this.getFaqByType.bind(this);
    this.state = {
    modal: false,
    isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
    faqTypeList : [],
    faqQueryList:[],
    activeIndex:0,
    scrollDiv : createRef(),
    activeTab: 0


  };
  
}


  componentDidMount() {
    ProfileService.getAllfaqs().then(response=>{
      
      // console.log('here we are : ',response)
	  // let faqSearchList = [];
		// for(let i = 0; i < response.data.length; i++){
		// 	let value   = response.data[i].id;
		// 	let label   = response.data[i].query;
		// 	let skillS  = { value : value ,label : label }

		// 	faqSearchList.push(skillS);
			
		//   }
		//   this.setState({"faqSearchList":faqSearchList})


    })

    ProfileService.getFaqTypeList().then(response=>{
        this.setState({"faqTypeList":response.data})
      // console.log(response.data)
        let faqSearchList = [];
        for(let i = 0; i < response.data.length; i++){
          let value   = response.data[i].id;
          let label   = response.data[i].name;
          let skillS  = { value : value ,label : label }
    
          faqSearchList.push(skillS);
          
          }
          this.setState({"faqSearchList":faqSearchList})
    

          
        ProfileService.getFaqByType(response.data[0].id).then(response=>{
          this.setState({"faqQueryList":response.data})
        })
      })
  }
  handleChangeSearch(value){
    if(value!=null){
      let id        = value.label.replace(/\s/g, '-')
      let select_id = value.id;
      // this.getFaqByType(select_id)
      this.state.activeTab = 1;
      this.state.scrollDiv.current.scrollIntoView({ behavior: 'smooth' });
	  	this.props.history.push(`/ask-renok/#`+id)
    }
  }

  getFaqByType(id){
    ProfileService.getFaqByType(id).then(response=>{
      this.setState({"faqQueryList":response.data})
    })
  }


  handleClick = (e, titleProps) => {
    const { index }        = titleProps
    const { activeIndex }  = this.state
    const newIndex         = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }


  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }

    const rootPanels = [];
    let SecondData    = '';



    if(this.state.faqTypeList && this.state.faqTypeList.length > 0){
 


     // console.log('Demo Gautam')
      

      const secAccordians = this.state.faqQueryList; 
      if(secAccordians && secAccordians.length > 0){
          for (let index = 0; index < secAccordians.length; index++) {
            let id = secAccordians[index].query.replace(/\s/g, '-')
             SecondData = <>
            <div className='inner-data' >
                <p className="qus" id={id}  >{secAccordians[index].query}</p>
                <p>
                <p class="typ_qu_title">
                  <Editor toolbarHidden={true} editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(secAccordians[index].answers)))}  readOnly={true} editorStyle={{lineHeight: '150%', letterSpacing: '0.5px'}} /></p>
                </p>
                </div>
            </>
            // SecondData.push(myQUsANs)
          }
      }


      const Level2Content = (
        <div>
          {SecondData}
        </div>
      )
      

      // console.log(SecondData,secAccordians)
      this.state.faqTypeList.map((item)=>{
        // console.log(item)
          rootPanels.push(
              { key: 'panel-'+item.id, title: item.name, content: { content: Level2Content } }
          )    
      })
  }   



   
  return (
    <>
    <div class="main_all">
    <Header></Header>

	<section class="same-section ask_renok innerpage_banner">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
		    		<h1 class="inner_banner_heding text-align"><span>Hello.</span> How can we help you?</h1>
		    		<div class="blog_baner_search small_padding_top">	    			
		      			<div class="heade_search_fuild">
                <Select 
                  isDisabled={this.state.isDisabled}
                  isLoading={this.state.isLoading}
                  isClearable={this.state.isClearable}
                  isRtl={this.state.isRtl}
                  isSearchable={this.state.isSearchable} 
                  onChange={ this.handleChangeSearch}
                  options={this.state.faqSearchList} 
                  placeholder={<div>Enter a keyword or search term here</div>}
                />
		      			
		      		
		      				<button class="searchhead_but"><i class="fa fa-search" aria-hidden="true"></i></button>
		      			</div>
		    		</div>
	    		</div>
	    	</div>
	    </section>

	   
<section class="same-section ask_renok_tabs padding_top">

<div class="container">
  <div className="row" style={{marginBottom: '30px'}}>
    
    <div className="col-md-12">
    <h2 style={{textAlign:"center"}}>FAQ's</h2>

    {/* <Accordion defaultActiveIndex={0} panels={rootPanels} styled active={this.state.activeTab} /> */}
    
      
    </div>
  </div>
	<div class="askrenok_tabs_flex padding_bottom">
		<h1 class="blacbold_main_title">Your Question</h1>
	<div class="row">
    <div class="col-md-3" ref={this.state.scrollDiv}>
    	<div class="askrenok_tableft_main">
        <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
        {(this.state.faqTypeList != null && this.state.faqTypeList.length> 0 )? this.state.faqTypeList.map(
                                      type =><>
          <a class="nav-link" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"  onClick={() => this.getFaqByType(type.id)} >{type.name} <span class="doentab_ask"><i class="fas fa-angle-right"></i></span></a>
        
          </>
        ):""}
        </div>
        <div class="askrenokbut_side small_padding_top">
    
        </div>
      </div>
    </div>
		<div class="col-md-9">
      <div class="askrenok_tabright_main">
        <div class="tab-content" id="v-pills-tabContent">
          <div class="card tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab">
            
            <div id="v-collapse-home" class="collapse show" role="tabpanel" aria-labelledby="v-collapse-heading-home" data-parent="#v-pills-tabContent">
              <div class="card-body">
              <h2 class="green_innermain_heading">Ask Renok</h2>
          <p>Please reach out via our online chat or through email using the inquiry form below. Service is available 24 hours, 7 days a week</p>
          <div class="type_of_quest">
            <p class="small_padding_top typ_qu_title">Type of Question </p>
            <ul class="label_sidetype_list small_padding_top">
            {(this.state.faqQueryList != null && this.state.faqQueryList.length> 0 )? this.state.faqQueryList.map(
                type =><>
              <li><a href={'mailto:info@renokadventures.com?subject='+type.query} class="labels_type_list">{type.query}</a></li>
              <p class="small_padding_top typ_qu_title"><Editor toolbarHidden={true} editorState={EditorState.createWithContent(convertFromRaw(JSON.parse(type.answers)))}  readOnly={true} /></p>
              </>
        ):""}
            </ul>
          </div>
              </div>
            </div>
          </div>
        
          <div class="card tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab">
            <div class="card-header" role="tab" id="v-collapse-heading-profile">
              <a data-toggle="collapse" href="#v-collapse-profile" data-parent="#v-pills-tabContent" aria-expanded="false" aria-controls="v-collapse-profile"> <span class="accordian_help_icon"><img  loading="lazy" src="images/help-iocn04.svg" alt="" /></span>
                  Indian Rail 
              </a>

            </div>
            <div id="v-collapse-profile" class="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-profile" data-parent="#v-pills-tabContent">
              <div class="card-body">
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
          cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
          proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
              </div>
            </div>
          </div>
          

          <div class="card tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab">
            <div class="card-header" role="tab" id="v-collapse-heading-messages">
              <a data-toggle="collapse" href="#v-collapse-messages" aria-expanded="false" aria-controls="v-collapse-messages"> <span class="accordian_help_icon"><img  loading="lazy" src="images/help-iocn03.svg" alt="" /></span>
                  Renok Upgrades 
              </a>
            </div>
            <div id="v-collapse-messages" class="collapse" role="tabpanel" aria-labelledby="v-collapse-heading-messages" data-parent="#v-pills-tabContent">
              <div class="card-body">
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>

        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
        consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
        cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
        proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>
              </div>
            </div>
          </div>
          

      

      </div>
      </div>
    </div>

    

			
    </div>
		
  </div>
	
</div>

</section>

{/* <div class="faqaccor_main">
 <h1 class="blacbold_main_title">Common Questions</h1>
   <div class="panel-group accordion_Ppolicy" id="accordion_Ppolicy_three" role="tablist" aria-multiselectable="true">

     <div class="panel panel-default">
       <div class="panel-heading" role="tab" id="heading-21">
         <h4 class="panel-title">
           <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_Ppolicy_three" href="#collapse-21" aria-controls="collapse-21" aria-expanded="false">
             Chat with Us
           </a>
         </h4>
       </div>
       <div id="collapse-21" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-21" aria-expanded="false" style={{"height":"0px"}}>
         <div class="panel-body">
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
         </div>
       </div>
     </div>

     <div class="panel panel-default">
       <div class="panel-heading" role="tab" id="heading-22">
         <h4 class="panel-title">
           <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_Ppolicy_three" href="#collapse-22" aria-expanded="false" aria-controls="collapse-22">
            General Inquiry
           </a>
         </h4>
       </div>
       <div id="collapse-22" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-22" aria-expanded="false" style={{"height":"0px"}}>
         <div class="panel-body">
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
          <p> Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
         </div>
       </div>
     </div>

     <div class="panel panel-default">
       <div class="panel-heading" role="tab" id="heading-23">
         <h4 class="panel-title">
           <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_Ppolicy_three" href="#collapse-23" aria-expanded="false" aria-controls="collapse-3">
            Report a Mobile Web Problem
           </a>
         </h4>
       </div>
       <div id="collapse-23" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-23" aria-expanded="false">
         <div class="panel-body">                                   
          <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod  tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, Quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
         </div>
       </div>
     </div>
   </div> 
</div> */}


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default AskRenok;
