import React from 'react';
import Header from '../pages/Header';
import HomeFooter from '../pages/HomeFooter';
import ProfileService from '../../services/ProfileService'

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    modal: false,
  };
  
}


  componentDidMount() {
	ProfileService.getJobList().then(response=>{
		this.setState({"jobList":response.data})
	  })
  }


  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <Header></Header>
    <section class="same-section join_the_teamB innerpage_banner">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
	    			<h6 class="smalltitle_side_banner text-align">CAREERS</h6>
		    		<h1 class="inner_banner_heding text-align">Join the team</h1>
		    		<p class="top_main_Sheding text-align">BE THE PART OF FUN AND EXCITING TEAM</p>
	    		</div>
	    	</div>
	    </section>

	    <section class="same-section join_theteam_after_bannertag text-align padding_top bg-gray">
	    	<div class="container">
		    	<p class="padding_bottom"><strong>At Renok Adventures you will find best atmosphere for all staff members. As the number of employees increases, it is essential that each one is clear about the personnel policies, programs and schemes. We keep relation good with our staff as well client.</strong></p>
	    	</div>
	    </section>

	    <section class="same-section join_open_postion text-align padding_top padding_bottom bg-gray">
	    	<div class="container">
	    		<h2 class="mainGreen_title small_padding_bottom">Open Positions</h2>
	    		<p>We are a looking for highly energetic people passionate about what they’re doing and dedicated to deliver a world class customer experience.</p>
	    		<div class="positions_bx_flex small_padding_top">
	    			<div class="row">
					{(this.state.jobList != null && this.state.jobList.length> 0 )? this.state.jobList.map(
                                    job =><>
	    				<div class="col-md-4">
	    					<div class="postion_bx_inner align-left">
	    						<h4>{job.noOfVacancy}</h4>
	    						<h6>{job.jobProfile}</h6>
	    						<p>{job.jobDescription}</p>
	    					</div>
	    				</div>
						</>
						   ):""}
	    				
	    			
	    				
	    				
	    			</div>
	    		</div>
	    	</div>
	    </section>
	    <section class="same-section meetrenok_Vsec text-align padding_bottom bg-gray">
	    	<div class="container">
	    		<h3 class="mainGreen_title small_padding_bottom">Meet Renok</h3>
	    		<div class="meet_renok_video small_margin_top">
	    			<iframe width="" height="" src="https://www.youtube.com/embed/eNfTSMFcH4w" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
	    		</div>
	    	</div>
	    </section>


    <HomeFooter></HomeFooter>
    </>
  );
  }
}

export default Careers;
