import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';

class SpecialRequirement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    modal: false,
  };
  
}


  componentDidMount() {
    
  }


  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>

    <section class="same-section invite_your_friendsB innerpage_banner update-pass">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
		    		<h1 class="inner_banner_heding text-align">Special Requirements</h1>
		    		
	    		</div>
	    	</div>
	    </section>

	    <section class="same-section what_are_renok text-align padding_top padding_bottom bg-gray referal-code">
	    	<div class="container">
	    		<div class="forgot-password special-request">
	    			<form autocomplete="off">
					  <div class="form-row">
					    <div class="form-group col-md-12">
					      <label>Pick up must be at least 3 business days from today</label>
					       <select id="inputState" class="form-control">
					        <option selected>Please Confirm</option>
					       
					      </select>
					    </div>
					    <div class="form-group col-md-12">
					      <label>Choose Pick Up Location</label>
					       <select id="inputState" class="form-control">
					        <option selected>Please Select</option>
					        
					      </select>
					    </div>
					     <div class="form-group col-md-12">
					      <label>Voucher Pick Up Time</label>
					       <select id="inputState" class="form-control">
					        <option selected>e.g 10:00am</option>
					        
					      </select>
					    </div>
					     <div class="form-group col-md-12">
					      <label>Exchange Order must be redeemed within 90 days from booking confirmed</label>
					       <select id="inputState" class="form-control">
					        <option selected>Please Confirm</option>
					        
					      </select>
					    </div>
					  </div>
					
 
		</form>
	    		</div>
	    		
	    	</div>
	    </section>

	    <div class="travel-form forgot-password special-request">
	    	<div class="container">
	    <div class="row">
	    	<div class="col-lg-6">
	    		<div class="travel1">
	    			<h4>Traveller 1</h4>
	    		<form autocomplete="off">
					  <div class="form-row">
					    <div class="form-group col-md-12">
					      <label>Confirm Ticket Type</label>
					       <select id="inputState" class="form-control">
					        <option selected>Please Confirm</option>
					       
					      </select>
					    </div>
					    <div class="form-group col-md-12">
					      <label>Full Name</label>
					       <input type="text" class="form-control" placeholder="Must include first, last & middle names" />
					    </div>
					     <div class="form-group col-md-12">
					      <label>Name is. entered exactly as it appears on passport</label>
					        <input type="text" class="form-control" placeholder="Please enter" />
					    </div>
					     <div class="form-group col-md-12">
					      <label>Gender</label>
					       <select id="inputState" class="form-control">
					        <option selected>Please Confirm</option>
					        
					      </select>
					    </div>
					      <div class="form-group col-md-12">
					      <label>Date of Birth</label>
					      <input type="text" class="form-control" placeholder="Please Enter" />
					    </div>
					  </div>
					
 
		</form>
	    		</div>
	    	</div>
	    	<div class="col-lg-6">
	    		<div class="travel1">
	    			<h4>Traveller 2</h4>
	    		<form autocomplete="off">
					  <div class="form-row">
					    <div class="form-group col-md-12">
					      <label>Confirm Ticket Type</label>
					       <select id="inputState" class="form-control">
					        <option selected>Please Confirm</option>
					       
					      </select>
					    </div>
					    <div class="form-group col-md-12">
					      <label>Full Name</label>
					       <input type="text" class="form-control" placeholder="Must include first, last & middle names" />
					    </div>
					     <div class="form-group col-md-12">
					      <label>Name is. entered exactly as it appears on passport</label>
					        <input type="text" class="form-control" placeholder="Please enter" />
					    </div>
					     <div class="form-group col-md-12">
					      <label>Gender</label>
					       <select id="inputState" class="form-control">
					        <option selected>Please Confirm</option>
					        
					      </select>
					    </div>
					      <div class="form-group col-md-12">
					      <label>Date of Birth</label>
					      <input type="text" class="form-control" placeholder="Please Enter" />
					    </div>
					  </div>
					
 
		</form>
	</div>
	    	</div>
	    	
	    </div>
	     <div class="confirms">
					  	 <button type="submit" class="btn btn-primary con-btn">Save</button>
					  </div>
	</div>
	</div>

    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default SpecialRequirement;
