import { Accordion } from 'semantic-ui-react'
import React,{useEffect,useState}  from 'react';

const level1Panels = [
  { key: 'panel-1a', title: 'Level 1A', content: 'Level 1A Contents' },
  { key: 'panel-ba', title: 'Level 1B', content: 'Level 1B Contents' },
]

const Level1Content = (
  <div>
    <Accordion.Accordion panels={level1Panels} />
  </div>
)

const level2Panels = [
  { key: 'panel-2a', title: 'Level 2A', content: 'Level 2A Contents' },
  { key: 'panel-2b', title: 'Level 2B', content: 'Level 2B Contents' },
]

const Level2Content = (
  <div>
    <Accordion.Accordion panels={level2Panels} />
  </div>
)

const rootPanels = [
  { key: 'panel-1', title: 'Level 1', content: { content: Level1Content } },
  { key: 'panel-2', title: 'Level 2', content: { content: Level2Content } },
]
const AccordionExampleNested = ({main_data,getAnserById,sub_data}) => {

    // if(data && data.length > 0){
    //     console.log(data)
    //     data.map((item)=>{
    //         // let DataChecl  = getAnserById(item.id)
    //         // console.log(DataChecl)
    //         rootPanels.push(
    //             { key: 'panel-'+item.id, title: item.name, content: { content: Level1Content } }
    //         )    
    //     })
    // }   
    return(
    <>
        <Accordion defaultActiveIndex={0} panels={main_data} styled />
    </>
  )
}

export default AccordionExampleNested