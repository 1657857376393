import React from 'react';
import { Route, Redirect} from 'react-router-dom'
import HomeHeader from '../pages/HomeHeader';
import HomeFooter from '../pages/HomeFooter';
import ProfileService from '../../services/ProfileService'
import Helmet from 'react-helmet';
import { APP_BASE_URL} from '../../constants';
import { IMAGE_BASE_URL} from '../../constants';

class Home extends React.Component {
  constructor(props) {
    super(props);
	this.handleSubmitSubs = this.handleSubmitSubs.bind(this);
    this.state = {
    subscribeEmail : '',
    modal: false,
	subscribeEmailSuccess : false,
	topDestinations:[],
	popularActivities : [],
	travelInspirationActivities : [],
	recommendedActivities:[]
  };
  
}


  componentDidMount() {
      ProfileService.retrieveTopDestinations().then(response=>{
		  console.log(response.data);
		  
            this.setState({"topDestinations":response.data})
	  });

	  ProfileService.retrievePopularActivities().then(response=>{
		console.log(response.data);
		this.setState({"popularActivities":response.data})
		
	});
	ProfileService.retrieveTravelInspirationActivities().then(response=>{
		console.log(response.data);
		  this.setState({"travelInspirationActivities":response.data})
	});
	ProfileService.retrieveRecommendedActivities().then(response=>{
		console.log(response.data);
		  this.setState({"recommendedActivities":response.data})
	});

	ProfileService.getBlogList().then(response=>{
		
		if(response.data.length>=3){
			this.setState({"blog1":response.data[0]})
			this.setState({"blog2":response.data[1]})
			this.setState({"blog3":response.data[2]})
		}
		
	  })


  }
  

  handleClickDestination(e,id){
	this.props.history.push(`/destination/${id}`)
	window.location.reload();
  }

  handleClickActitvity(e, id){
	e.preventDefault();
	//alert(value);
	if(id!=null){
		
		ProfileService.getActivityByUrl(id).then(response=>{
			var activityId = response.data.id;
			if(response.data.activityCategory_id=='611b452a4fdde75b279725eb'){
				window.location.href=`${APP_BASE_URL}/staycation/${id}`;
			}else if(response.data.activityCategory_id=='611b45344fdde75b279725ec'){
				window.location.href=`${APP_BASE_URL}/tours/${id}`;
			}else if(response.data.activityCategory_id=='611ba6cd14e97e569e41eaac'){
				window.location.href=`${APP_BASE_URL}/activities/${id}`;
			}else if(response.data.activityCategory_id=='61275c3ceaf59f69875e2abd'){
				window.location.href=`${APP_BASE_URL}/transport/${id}`;
			}else if(response.data.activityCategory_id=='61275c55eaf59f69875e2abe'){
				window.location.href=`${APP_BASE_URL}/food/${id}`;
			}else {
				window.location.href=`${APP_BASE_URL}/activity/${id}`;
			}
	    })
		
	}   
	this.props.history.push(`/activity/${id}`)
	window.location.reload();
  }

  handleChangeSubEmail(e){
	  this.setState({"subscribeEmail":e.target.value})
  }

  handleSubmitSubs(e){
    e.preventDefault();
	ProfileService.subscribeEmail(this.state.subscribeEmail).then(response=>{
		
		this.setState({"subscribeEmail":"","subscribeEmailSuccess":true})

	})
  }
  handleClickBlog(e,id){
    this.props.history.push(`/blog/${id}`)
  }

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
	
	
   
  return (
	<div class="main_all">
		<Helmet>
    
		<title>Renok Adventures - Best Trekking Companies in India</title>
  <meta name="description" content="We are Govt Approved and Best Trekking Companies in India, We organize trekking tours in India and Trekking Packages in India. Explore Himalayas with Us"/>
	</Helmet>
	<HomeHeader></HomeHeader>

	

		   {/* <section class="salebannerside common_block padding_leftang_right secpaddingT banner-middle">
			  <div class="salebanner_inner">
				 <img src="images/sale-banner.jpg" alt="" />
				 <a href="#" class="onlink"></a>
			  </div>
		   </section> */}

	   <section class="same-section padding_top topdetinations bg-gray destin-cnt">
		   <div class="container">
			   <h2 class="section_main_title text-align">Top Destinations</h2>
			   <p class="section_main_subtitle text-align">Discover adventures, activities, experiences and much more for your next trip</p>
			   <div class="destiimagessec_perent">
				   <div class="row">
				   {(this.state.topDestinations != null && this.state.topDestinations.length> 0 )? this.state.topDestinations.map(
                                    influencer =>
									<div class="col-md-3 col-sm-4">
									<div class="destination_img">
										<img src={IMAGE_BASE_URL+``+JSON.parse(influencer.gridImage).imageUrl}  loading="lazy" height="" width=""  alt={JSON.parse(influencer.gridImage).altText} />
										<h6 class="onimgD_title">{influencer.name}</h6>
										<a href={`${APP_BASE_URL}/destination/${influencer.url}`}  class="onlink"></a>
									</div>
								</div>
									)
                                    
						: ""}
					  
{/* 
					   <div class="col-md-3 col-sm-4">
						   <div class="destination_img">
							   <img src="images/top-destination02.jpg" alt=""/>
							   <h6 class="onimgD_title">Jammu</h6>
							   <a href="destination-attractions.html" class="onlink"></a>
						   </div>
					   </div>

					   <div class="col-md-3 col-sm-4">
						   <div class="destination_img">
							   <img src="images/top-destination03.jpg" alt=""/>
							   <h6 class="onimgD_title">Uttar Pradesh</h6>
							   <a href="destination-attractions.html" class="onlink"></a>
						   </div>
					   </div>

					   <div class="col-md-3 col-sm-4">
						   <div class="destination_img">
							   <img src="images/top-destination04.jpg" alt=""/>
							   <h6 class="onimgD_title">Chennai</h6>
							   <a href="destination-attractions.html" class="onlink"></a>
						   </div>
					   </div>

					   <div class="col-md-3 col-sm-4">
						   <div class="destination_img">
							   <img src="images/top-destination05.jpg" alt=""/>
							   <h6 class="onimgD_title">Chennai</h6>
							   <a href="destination-attractions.html" class="onlink"></a>
						   </div>
					   </div>

					   <div class="col-md-3 col-sm-4">
						   <div class="destination_img">
							   <img src="images/top-destination06.jpg" alt=""/>
							   <h6 class="onimgD_title">Goa</h6>
							   <a href="destination-attractions.html" class="onlink"></a>
						   </div>
					   </div>

					   <div class="col-md-3 col-sm-4">
						   <div class="destination_img">
							   <img src="images/top-destination07.jpg" alt=""/>
							   <h6 class="onimgD_title">Uttrakhand</h6>
							   <a href="destination-attractions.html" class="onlink"></a>
						   </div>
					   </div>

					   <div class="col-md-3 col-sm-4">
						   <div class="destination_img">
							   <img src="images/top-destination08.jpg" alt=""/>
							   <h6 class="onimgD_title">Maharastra</h6>
							   <a href="destination-attractions.html" class="onlink"></a>
						   </div>
					   </div> */}
				   </div>
				   <div class="explore_more_perent">
				   <a data-toggle="modal" data-target="#homesearch--model">
					   <a  class="common_readmore">Explore More</a>
					   </a>
				   </div>
			   </div>
		   </div>
	   </section>

	   <section class="same-section popular_activities padding_top  bg-gray">
		   <div class="container">
			   <h2 class="section_main_title text-align">Popular Activities</h2>
			   <p class="section_main_subtitle text-align">Discover adventures, activities, experiences and much more for your next trip</p>
			   <div class="popularacti_bx_perent">
				   <div class="row">
					   <div id="renok_carousel_02" class="owl-carousel common_col--width common_arrow--slider">
					   {(this.state.popularActivities != null && this.state.popularActivities.length> 0 )? this.state.popularActivities.map(
                                    influencer =>
						   <div class="item">
							   <div class="col-md-3">
								   <div class="popular_activities_box">
										<div class="imagesof_box">
										   <img loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(influencer.gridImage).imageUrl}  height="" width=""  alt={JSON.parse(influencer.gridImage).altText}/>
										   <div class="activities_palcetitle">
											  <img loading="lazy" src="images/map.svg"  height="" width=""  alt="map"/> {influencer.ad[0].name}
										   </div>
										</div>
										<div class="popular_activities_content">
										<div class="discount_div">
									 		

										{Math.ceil(influencer.pk.discountPercantage)!==0 && <>
											<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />
										<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}
										</div>
										   <h3 class="palcebox_title">{influencer.name}</h3>
										   <div class="box_review_side">
											  <div class="review_side_cell">
												 <i class="fas fa-star"></i>
												 <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												 
											  </div>
											 
											  <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img loading="lazy" src="images/lightning.svg" height="17px" width="10px" alt="instant-confirmation"/></span></>):(<></>))}
												</p>
												
											  </div>
										   </div>
										</div>


							
										{( influencer.activityCategory_id=='611b452a4fdde75b279725eb' ?  (<a  href={`${APP_BASE_URL}/staycation/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611b45344fdde75b279725ec' ?  (<a  href={`${APP_BASE_URL}/tours/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611ba6cd14e97e569e41eaac' ?  (<a  href={`${APP_BASE_URL}/activities/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c3ceaf59f69875e2abd' ?  (<a  href={`${APP_BASE_URL}/transport/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c55eaf59f69875e2abe' ?  (<a  href={`${APP_BASE_URL}/food/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{/* <a  href="" onClick={(e)=> this.handleClickActitvity(e, influencer.url)} class="onlink"></a> */}
								   </div>
							   </div>
						   </div>
                                	)
                                    
	                        : ""}
						   

					   </div>
				   </div>
				     <div class="explore_more_perent">
					   	<a href="/subactivities/All/activities"  class="common_readmore">Explore More</a>
				   </div>
			   </div>
		   </div>
	   </section>

	   <section class="same-section travel_inspiration padding_top  bg-gray">
		   <div class="container">
			   <h2 class="section_main_title text-align">Travel Inspiration</h2>
			   <p class="section_main_subtitle text-align">Handpicked trips based on seasons, festivals and interests</p>
			   <div class="travel_inspiration_perent">
				   <div class="row">
					   <div class="col-md-12">
						   <div class="gentle_touch_box">
							   <img loading="lazy" src="images/gentle-touch.webp"  height="100%" width="100%"  alt="banner"/>
							   <div class="gentle_touch_content text-align">
								   <h3 class="earn_friend_title">Local, traditional, and a gentle touch of Nature </h3>
								   <p class="earn_friend_subtitle">Spend the perfect vacation with loved ones with a mix of cozy, thrilling and enjoyable activities in store this season </p>
								   <a href="/destination" class="explor_more_butt">Explore More</a>
							   </div>
						   </div>
					   </div>
                      </div>    
					  <div class="row">
					  <div id="renok_carousel_10" class="owl-carousel common_col--width common_arrow--slider">
					   {(this.state.travelInspirationActivities != null && this.state.travelInspirationActivities.length> 0 )? this.state.travelInspirationActivities.map(
                                    influencer =>
									<div class="item">
					   <div class="col-md-12">
						   <div class="popular_activities_box">
								<div class="imagesof_box">
								   <img loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(influencer.gridImage).imageUrl} alt={JSON.parse(influencer.gridImage).altText}/>
								   <div class="activities_palcetitle">
									  <img loading="lazy" src="images/map.svg" alt="map"/> {influencer.ad[0].name}
								   </div>
								</div>
								<div class="popular_activities_content">
								<div class="discount_div">
								{Math.ceil(influencer.pk.discountPercantage)!==0 && <>
									 	<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />	
										<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}
										</div>
								   <h3 class="palcebox_title">{influencer.name}</h3>
								   <div class="box_review_side">
									  <div class="review_side_cell">
										 <i class="fas fa-star"></i>
										 <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												
									  </div>
									 
									  <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img loading="lazy" src="images/lightning.svg" height="17px" width="10px"  alt="instant-confirmation"/></span></>):(<></>))}
												</p>
												
											  </div>
								   </div>
								</div>
								{( influencer.activityCategory_id=='611b452a4fdde75b279725eb' ?  (<a  href={`${APP_BASE_URL}/staycation/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611b45344fdde75b279725ec' ?  (<a  href={`${APP_BASE_URL}/tours/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611ba6cd14e97e569e41eaac' ?  (<a  href={`${APP_BASE_URL}/activities/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c3ceaf59f69875e2abd' ?  (<a  href={`${APP_BASE_URL}/transport/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c55eaf59f69875e2abe' ?  (<a  href={`${APP_BASE_URL}/food/${influencer.url}`}  class="onlink"></a>):(<></>) )}
								{/* <a onClick={(e)=> this.handleClickActitvity(e, influencer.url)} class="onlink"></a> */}
						   </div>
					   </div>
					   </div>)
                                    
						: ""}
                      </div>
					   

				   </div>
			   </div>
		   </div>
	   </section>




	   <section class="same-section renok_recommended padding_top  bg-gray">
		   <div class="container">
			   <h2 class="section_main_title text-align">Renok Recommended</h2>
			   <p class="section_main_subtitle text-align">Handpicked trips based on seasons, festivals and interests</p>
			   <div class="renok_recommended_perent">
				   <div class="row">
				   <div id="renok_carousel_05" class="owl-carousel common_col--width common_arrow--slider">
				   {(this.state.recommendedActivities != null && this.state.recommendedActivities.length> 0 )? this.state.recommendedActivities.map(
                                    influencer =>
					  
						   <div class="item">
							   <div class="col-md-3">
								   <div class="popular_activities_box">
										<div class="imagesof_box">
										   <img loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(influencer.gridImage).imageUrl} alt={JSON.parse(influencer.gridImage).altText}/>
										   <div class="activities_palcetitle">
											  <img loading="lazy" src="images/map.svg" alt="map"/> {influencer.ad[0].name}
										   </div>
										</div>
										<div class="popular_activities_content">
										<div class="discount_div">
										{Math.ceil(influencer.pk.discountPercantage)!==0 && <>
													<img loading="lazy" src={APP_BASE_URL + '/images/discount.png'} alt="discount" />
													<span class="discount"> {Math.ceil(influencer.pk.discountPercantage)}<sup>%</sup> <sub>OFF</sub></span>
										</>}	
											 
										
										</div>
										   <h3 class="palcebox_title">{influencer.name}</h3>
										   <div class="box_review_side">
											  <div class="review_side_cell">
												 <i class="fas fa-star"></i>
												 <span class="rateeside">{influencer.pk.review}</span>
												 <span class="totalreview">({influencer.pk.total_reviews} reviews)</span>
												 <span class="totalblocked">{influencer.pk.booked} Booked</span>
												
											  </div>
											  <h6 class="brocken_price"><strike>₹ {influencer.pk.sellingPrice}</strike></h6>
					                           <div class="popular_activities_price">
					                            <p class="pricesid">₹ {influencer.pk.basePrice}
												{(influencer.instantConfirmation == true ? (<><span><img  loading="lazy" src="images/lightning.svg" height="17px" width="10px"  alt="instant"/></span></>):(<></>))}
												</p>
												
											  </div>
										   </div>
										</div>
										{( influencer.activityCategory_id=='611b452a4fdde75b279725eb' ?  (<a  href={`${APP_BASE_URL}/staycation/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611b45344fdde75b279725ec' ?  (<a  href={`${APP_BASE_URL}/tours/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='611ba6cd14e97e569e41eaac' ?  (<a  href={`${APP_BASE_URL}/activities/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c3ceaf59f69875e2abd' ?  (<a  href={`${APP_BASE_URL}/transport/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{( influencer.activityCategory_id=='61275c55eaf59f69875e2abe' ?  (<a  href={`${APP_BASE_URL}/food/${influencer.url}`}  class="onlink"></a>):(<></>) )}
										{/* <a onClick={(e)=> this.handleClickActitvity(e, influencer.url)} class="onlink"></a> */}
								   </div>
							   </div>
						   </div>)
						   : ""}

						
						   
					   </div>

				   </div>
			   </div>
		   </div>
	   </section>

	   {/* <section class="same-section new_renok_video">
		   <div class="container">
			   <h2 class="top_main_Bheding text-align">New to Renok?</h2>
			   <p class="top_main_Sheding text-align">Book your next adventure with Renok</p>
			   <div class="videoplay_ic">
				   <a data-toggle="modal" data-target="#homevideo--model">
					   <img src="images/video-playicon.svg" alt=""/>
				   </a>
			   </div>
		   </div>
	   </section> */}

	   <section class="same-section earn_friend padding_top padding_bottom bg-gray">
		   <div class="container">
			   <div class="row">
				   <div class="col-md-3 col-sm-6">
					   <div class="earn_friend_bx">
						   <img loading="lazy" src="images/renok-plans01.webp" alt="banner"/>
						   <div class="earn_friend_content">
							   <h2 class="earn_friend_title">Check out Renok blog</h2>
							   <p class="earn_friend_subtitle">Follow the team’s musings on trends in travel, itinerary ideas and travel tips </p>
						   </div>
						   <a href="/blog" class="onlink"></a>
					   </div>
				   </div>
                {(this.state.blog1!=null ? (<>
					<div class="col-md-3 col-sm-6">
					   <div class="earn_friend_bx">
						   <img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(this.state.blog1.gridImage).imageUrl} alt={JSON.parse(this.state.blog1.gridImage).altText} />
						   <div class="earn_friend_content">
							   <h2 class="earn_friend_title">{this.state.blog1.name}</h2>
							   <p class="earn_friend_subtitle">{(JSON.parse(this.state.blog1.tags) != null && JSON.parse(this.state.blog1.tags).length> 0 )? JSON.parse(this.state.blog1.tags).map(
                                    tg =><>
		    					{tg.valueValue} &nbsp;
		    					</>
						   ):""}</p>
						   </div>
						   <a href={`${APP_BASE_URL}/blog/${this.state.blog1.url}`}   class="onlink"></a>
					   </div>
				   </div>
				</>) :( <></>) )}
				{(this.state.blog2!=null ? (<>
					<div class="col-md-3 col-sm-6">
					   <div class="earn_friend_bx">
						   <img loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(this.state.blog2.gridImage).imageUrl} alt={JSON.parse(this.state.blog2.gridImage).altText} />
						   <div class="earn_friend_content">
							   <h2 class="earn_friend_title">{this.state.blog2.name}</h2>
							   <p class="earn_friend_subtitle">{(JSON.parse(this.state.blog2.tags) != null && JSON.parse(this.state.blog2.tags).length> 0 )? JSON.parse(this.state.blog2.tags).map(
                                    tg =><>
		    					{tg.valueValue} &nbsp;
		    					</>
						   ):""}</p>
						   </div>
						   <a href={`${APP_BASE_URL}/blog/${this.state.blog2.url}`}class="onlink"></a>
					   </div>
				   </div>
				</>) :( <></>))}
				{(this.state.blog3!=null ? (<>
					<div class="col-md-3 col-sm-6">
					   <div class="earn_friend_bx">
						   <img loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(this.state.blog3.gridImage).imageUrl} alt={JSON.parse(this.state.blog3.gridImage).altText} />
						   <div class="earn_friend_content">
							   <h2 class="earn_friend_title">{this.state.blog3.name}</h2>
							   <p class="earn_friend_subtitle">
							   {(JSON.parse(this.state.blog3.tags) != null && JSON.parse(this.state.blog3.tags).length> 0 )? JSON.parse(this.state.blog3.tags).map(
                                    tg =><>
		    					{tg.valueValue} &nbsp;
		    					</>
						   ):""}
							   </p>
						   </div>
						   <a href={`${APP_BASE_URL}/blog/${this.state.blog3.url}`} class="onlink"></a>
					   </div>
				   </div>
				</>) :( <></>))}
				   

				  


				   {/* <div class="col-md-3 col-sm-6">
					   <div class="earn_friend_bx">
						   <img src="images/renok-plans02.jpg" alt=""/>
						   <div class="earn_friend_content">
							   <h2 class="earn_friend_title">Earn credit for inviting friends</h2>
							   <p class="earn_friend_subtitle">After your friend signs up and completes an activity, you’ll get a ₹100 reward! </p>
						   </div>
						   <a href="/invite-your-friends" class="onlink"></a>
					   </div>
				   </div>

				   <div class="col-md-3 col-sm-6">
					   <div class="earn_friend_bx">
						   <img src="images/renok-plans03.jpg" alt=""/>
						   <div class="earn_friend_content">
							   <h2 class="earn_friend_title">Get free credits when you complete activity</h2>
							   <p class="earn_friend_subtitle">Find out how to get extra discounts on your next booking by completing Renok activities and leaving reviews </p>
						   </div>
						   <a href="/get-free-credits" class="onlink"></a>
					   </div>
				   </div>

				   <div class="col-md-3 col-sm-6">
					   <div class="earn_friend_bx">
						   <img src="images/renok-plans04.jpg" alt=""/>
						   <div class="earn_friend_content"> 
							   <h2 class="earn_friend_title">Download Renok’s Editor’s Choice App</h2>
							   <p class="earn_friend_subtitle">Enjoy ₹50 off on your first Mobile App booking using promo code ‘RenokApp’</p>
						   </div>
						   <a href="#" class="onlink"></a>
					   </div>
				   </div> */}
			   </div>
		   </div>
	   </section>

	   {/* <section class="same-section subsand_socials">
		   <div class="container">
			   <div class="row">
				   <div class="col-md-3">
					   <ul class="footer_sociallist">
						   <li><a href="#"><span><i class="fab fa-facebook-f"></i></span></a></li>
						   <li><a href="#"><span><i class="fab fa-twitter"></i></span></a></li>
						   <li><a href="#"><span><i class="fab fa-whatsapp"></i></span></a></li>
						   <li><a href="#"><span><i class="fab fa-instagram"></i></span></a></li>	    					
					   </ul>
				   </div>
				   <div class="col-md-9">
					   <div class="footer_subs_bx">
						   <div class="contentsubs_side">
							   <p>Travel discounts up to 60% off sent straight to your inbox</p>
							   <p>By clicking Subscribe, you have agreed to our Terms & Conditions and Privacy Policy</p>
						   </div>
						   <div class="subs_fuildside">
						   <form onSubmit={this.handleSubmitSubs} >
							   <input type="email" class="subsfuil" required name="subscribeEmail" onChange={(event) => this.handleChangeSubEmail(event)} value={this.state.subscribeEmail} placeholder="Enter your email address" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Enter your email address'" />
							   <button class="subs_but" type="submit">Subscribe</button>
							   {( this.state.subscribeEmailSuccess == true ?  (<div class="contentsubs_side"><p>Thank you for Subscription</p></div>):(<></>) )}
							   </form>
						   </div>
					   </div>
				   </div>
			   </div>
		   </div>
	   </section> */}

	   

	   <HomeFooter></HomeFooter> 
	   </div>
  );
  }
}

export default Home;
