import React from 'react';
import CommonHeader from '../pages/Header';
import AuthService from '../../services/AuthService'
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import SocialButton from './SocialButton'
import { GoogleLogin } from 'react-google-login';
import qs from 'qs';
import {GOOGLE_CLIENT_ID, FB_APP_ID} from '../../constants'
import Helmet from 'react-helmet';

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogin = this.handleLogin.bind(this);
    this.handleLoginP = this.handleLoginP.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onChangeCheckbox =  this.onChangeCheckbox.bind(this);
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handleFBLogin = this.handleFBLogin.bind(this)
    this.getOtpClicked = this.getOtpClicked.bind(this)
    this.state = {
      userLoggedIn :  AuthService.isUserLoggedIn(),
      email: localStorage.email,
      phone: localStorage.phone,
      password: localStorage.password,
      isChecked : false,
      isCheckedEmail : localStorage.isCheckedEmail,
      isCheckedMobile:localStorage.isCheckedMobile,
    modal: false,
  };
  
}


  componentDidMount() {
    
  }

  onChangeCheckbox = event => {
    this.setState({
        isChecked: event.target.checked
    })
}

  handleGoogleLogin(response){
    //console.log(response)
    if(response!=null){
    const signInRequest = {
      email : response.profileObj.email,
      accessToken : response.accessToken,
      lastName : response.profileObj.familyName,
      firstName : response.profileObj.givenName,
      imageUrl : response.profileObj.imageUrl,
      googleId : response.profileObj.googleId
    }
    AuthService.gmailSignIn(signInRequest).then(
      (data) => {
        
       
          if(data.status==undefined || data.status === 'ACTIVE'){
           
            const user = JSON.parse(localStorage.getItem('userRenokWeb'));
            this.props.history.push("/myProfile");
            window.location.reload();
          }else{
            localStorage.removeItem('userRenokWeb')
            this.setState({
              loading: false,
              message: "Login Blocked By Administrator."
            });
          }
       


      },
      error => {
        const resMessage =
          (error.response &&
          error.response.data &&
          error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      }
    )
  }
  }

  handleFBLogin(response){

    //console.log(response)
    const signInRequest = {
      fbId : response.profile.id,
      email : response.profile.email,
      firstName : response.profile.firstName,
      lastName : response.profile.lastName,
      imageUrl : response.profile.profilePicURL,
      accessToken : response._token.accessToken
    }
    AuthService.faceBookSignIn(signInRequest).then(
      (data) => {
        if(data.status==undefined || data.status === 'ACTIVE'){
           
          const user = JSON.parse(localStorage.getItem('userRenokWeb'));
          this.props.history.push("/myProfile");
          window.location.reload();
        }else{
          localStorage.removeItem('userRenokWeb')
          this.setState({
            loading: false,
            message: "Login Blocked By Administrator."
          });
        }
      },
      error => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        this.setState({
          loading: false,
          message: resMessage
        });
      }
    )

  }


  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleLogin(e) {
    e.preventDefault();

    this.setState({
      message: "",
      loading: true
    });

    this.form.validateAll();
    if (document.getElementById("checkEmail").checked) {
      //alert("checkedEmail")
      localStorage.email = this.state.email
      localStorage.password = this.state.password
      localStorage.isCheckedEmail = this.state.isCheckedEmail
    }
             if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)){
              var password = this.state.password;
              const regPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
               // alert(x);
               if (password.match(regPass)==null || password.match(regPass) === false) {
                this.setState({ message: "The password should be min 8 characters long , and should include one Capital alphabet, one Small alphabet, one special character and numbers." });
              }else{
                const loginRequest = {
                  email : this.state.email,
                  password : this.state.password
                }
                AuthService.userSignupEmail(loginRequest).then(
                  response => {
                    if(response.data.message=="Registration Successful!"){
                      // localStorage.setItem("userRenokWeb", JSON.stringify(response.data.data));
                      // this.props.history.push("/myProfile");
                      this.setState({
                        successful : true,
                        loading: false,
                        message: "Registration Successfull , we've sent verification link to your email."
                      });
                    }else{
                      this.setState({
                        loading: false,
                        message: response.data.message
                      });
                    }
                   
                  }
                );
              }

            }else{
              this.setState({
                loading: false,
                message: "Invalid Email Address"
              });
            }

  }

  handleLoginP(e) {
    e.preventDefault();

    this.setState({
      message: "",
      disable:false,
      loading: true
    });

    this.form.validateAll();
    if (document.getElementById("checkMobile").checked) {
      // alert("checkedMobile")
       localStorage.phone = this.state.phone
       localStorage.password = this.state.password
       localStorage.isCheckedMobile = this.state.isCheckedMobile
     }
    
    var phoneno = /^\d{10}$/;
    if(this.state.phone!=null && this.state.phone.length==10 && this.state.phone.match(phoneno)){
      let dto = {
        mobile : this.state.phone,
        otp : this.state.otp
     }
    AuthService.verifyOtp(dto).then(response=>{
      if(response.data.message=="Mobile verified successfully"){

        const regPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
               // alert(x);
               if (this.state.password.match(regPass)==null || this.state.password.match(regPass) === false) {
                this.setState({ message: "The password should be min 8 characters long , and should include one Capital alphabet, one Small alphabet, one special character and numbers." });
              }else{
                const loginRequest = {
                  phone : this.state.phone,
                  password : this.state.password
                }
                AuthService.userSignupPhone(loginRequest).then(
                  response => {
                    if(response.data.message=="Registration Successful!"){
                      localStorage.setItem("user", JSON.stringify(response.data.data));
                      this.props.history.push("/myBookings");
                    }else{
                      this.setState({
                        loading: false,
                        message: response.data.message
                      });
                    }
                   
                  }
                );
              }

        
      }else{
        this.setState({
          loading: false,
          message: response.data.message
        });
      }
    })
    }else{
      this.setState({
        loading: false,
        message: 'Please enter 10 digit mobile no.'
      });
    }
     
      
    
  }

  getOtpClicked(e){
    e.preventDefault();
    this.setState({"disable":true})
    var phoneno = /^\d{10}$/;
    if(this.state.phone.length==10 && this.state.phone.match(phoneno)){
      AuthService.sendOtp(this.state.phone).then(response=>{
        if(response.data.message=="Otp send Successfully"){
          this.setState({"disable":false})
          this.setState({
            loading: false,
            message: "Please Enter Otp"
          });
        }else{
          this.setState({
            loading: false,
            message: "Failed to send otp"
          });
        }
      })
    }else{
      this.setState({"disable":false})
      this.setState({
        loading: false,
        message: "Please Enter 10 digit Mobile No."
      });
    }
   
    
  }

  render() {
    const responseGoogle = (response) => {
      this.handleGoogleLogin(response)
    }
    const handleSocialLogin = (user) => {
      this.handleFBLogin(user)
    }

    const handleSocialLoginFailure = (err) => {
      console.error(err)
    }
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    
	<body class="darkHeader_inner">
    <Helmet>
    <script src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v12.0&appId=1206614489817558&autoLogAppEvents=1" 
    type="text/javascript" />
    </Helmet>
    <div class="main_all">
    <CommonHeader></CommonHeader>
	  <section class="login_main_perent">
          <div class="login_main_perent_overflow">
          	<div class="user_login_page_bx">
          		<div class="user_login_page_bx_inner">
          			<div class="login_title_fuild_bx">
          				<h2 class="login_side_ttile">Sign Up</h2>
                  {this.state.message && (
              <div className="form-group">
                <div className={
                    this.state.successful
                      ? "alert alert-success"
                      : "alert alert-danger"
                  } role="alert">
                  {this.state.message}
                </div>
              </div>
            )}
          				<ul class="listtabs_click nav nav-tabs" role="tablist">
          					<li class="nav-item">
                        <a class="nav-link active" data-toggle="tab" href="#home">Email address</a>
                      </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#menu1">Phone Number</a>
                    </li>
          				</ul>                
                </div>
                  <div class="tab-content">
                    <div id="home" class="tab-pane active">
                      <div class="login_tab_content">
                        <div class="formside_perent">
                          <Form class="login-form" onSubmit={this.handleLogin} ref={c => {this.form = c;}}>
                          <div class="fuild_form_row">                 
                            <div class="classform_fuild_icon">
                                <input type="email" name="email"  onChange={this.onChange} value={this.state.email} required class="form_inputfuild" placeholder="Email" />
                            </div>
                          </div>                  
                          <div class="fuild_form_row">
                            <div class="classform_fuild_icon ">
                              <input id="password-field" type="password" name="password"  onChange={this.onChange} value={this.state.password}  required class="form_inputfuild" placeholder="Password" />
                              <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
                            </div>
                          </div>


                          <div class="fuild_form_row">
                            <div class="rememberlist_chck">
                            <div class="log_formfuild"> 
                              <label class="containerRememberMe">Remember Me<input type="checkbox" id="checkEmail" value="checkedEmailValue" name="checkEmail"  />
                              <span class="checkmark"></span>
                               </label>           
                                
                              </div>                  
                              <div class="log_formfuild align-right">
                                <a href="/forgot-password" class="forgotpass_link">Forgot Password?</a>
                              </div>                    
                            </div>
                          </div>

                          <div class="fuild_form_row">
                          <button type="submit" class="green_button login-page" >Sign up</button>
                            
                          </div>

                          
                          </Form>
                          <div class="fuild_form_row">
                            <div class="orlog_with text-align">
                              <span>or sign up with</span>
                            </div>
                          </div>

                          <div class="fuild_form_row">
                          <GoogleLogin 
                                        clientId={GOOGLE_CLIENT_ID}
                                        render={renderProps => (
                                          <a class="green_button logs_google" style={{"color":"white !important"}} onClick={renderProps.onClick} disabled={renderProps.disabled} >
                                          <i class="fab fa-google"></i>&nbsp; &nbsp;&nbsp; Login with Google</a>
                                        )}
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                   />
                          </div>
                          <div class="fuild_form_row forno_padd_bottom">
                            
                          <SocialButton
                                        provider='facebook'
                                        appId={FB_APP_ID}
                                        onLoginSuccess={handleSocialLogin}
                                        onLoginFailure={handleSocialLoginFailure}
                                      >
                                        Login with Facebook
                                      </SocialButton> 	

                          </div>
                        </div>

                        <div class="fuild_form_row">
                          <div class="noaccount_yetside">
                            <div class="noaccount_yetside_left">
                              Already have a Renok Account
                            </div>
                            <div class="noaccount_yetside_right">
                              <a href="/login" class="signuo_butS">Log In</a>
                            </div>
                          </div>
                        </div>
                        <div class="fuild_form_row">
                          <div class="lastlog_trens_condition">
                            <p>By joining Renok through Facebook, Google, Phone Number or Email Sign up, you agree to <a href="/privacy-policy">Renok’s General Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a></p>
                          </div>
                        </div>
                      </div>                      
                    </div>

                    <div id="menu1" class="tab-pane">
                      <div class="formside_perent">
          				      <div class="login_tab_content">
                        <Form class="login-form" onSubmit={this.handleLoginP} ref={c => {this.form = c;}}>
                          <div class="fuild_form_row formob_num_row">
                           
                            <div class="log_formfuild">
                              <div class="classform_fuild_icon">
                                <select class="form_inputfuild">
                                  <option>+91</option>
                                </select>
                                <span class="formon_icon"><i class="fas fa-angle-down"></i></span>
                              </div>
                            </div>                  
                            <div class="log_formfuild">
                              <div class="classform_fuild_icon">
                                <input type="number" name="phone"   onChange={this.onChange} value={this.state.phone} required  class="form_inputfuild" placeholder="Phone Number" />
                              </div>
                            </div>
                          </div> 

                          <div class="fuild_form_row">
                          <button onClick={(e)=> this.getOtpClicked(e)} type="button" class="green_button bggray_button" >Send Verification Code</button>
                         
                          </div>
                          <div class="fuild_form_row">
                            <div class="classform_fuild_icon">
                              <input type="text" name="otp" disabled={this.state.disable}  onChange={this.onChange} value={this.state.otp}  required  class="form_inputfuild " placeholder="Otp" />
                           

                            </div>
                          </div>
                          <div class="fuild_form_row">
                            <div class="classform_fuild_icon">
                              <input id="password-field2" type="password" name="password"  onChange={this.onChange} value={this.state.password}  required  class="form_inputfuild " placeholder="Password" />
                              <span toggle="#password-field2" class="fa fa-fw fa-eye field-icon toggle-password"></span>

                            </div>
                          </div>

                          <div class="fuild_form_row">
                            <div class="rememberlist_chck">
                            <div class="log_formfuild"> 
                              <label class="containerRememberMe">Remember Me<input type="checkbox" id="checkMobile" value="checkedMobileValue" name="checkMobile"  />
                              <span class="checkmark"></span>
                               </label>           
                                
                              </div>                  
                              <div class="log_formfuild align-right">
                                <a href="/forgot-password" class="forgotpass_link">Forgot Password?</a>
                              </div>                    
                            </div>
                          </div>

                          <div class="fuild_form_row">
                          <button type="submit" class="green_button login-page" >Sign up</button>
                            
                          </div>

                          
                          </Form>
                          <div class="fuild_form_row">
                            <div class="orlog_with text-align">
                              <span>or sign up with</span>
                            </div>
                          </div>

                          <div class="fuild_form_row">
                          <GoogleLogin 
                                        clientId={GOOGLE_CLIENT_ID}
                                        render={renderProps => (
                                          <a class="green_button logs_google" style={{"color":"white !important"}} onClick={renderProps.onClick} disabled={renderProps.disabled} >
                                          <i class="fab fa-google"></i>&nbsp; &nbsp;&nbsp; Login with Google</a>
                                        )}
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                   />
                          </div>
                          <div class="fuild_form_row forno_padd_bottom">
                          <SocialButton
                                        provider='facebook'
                                        appId={FB_APP_ID}
                                        onLoginSuccess={handleSocialLogin}
                                        onLoginFailure={handleSocialLoginFailure}
                                      >
                                        Login with Facebook
                                      </SocialButton> 	
                          </div>

                        </div> 

                        </div>                    
                          <div class="fuild_form_row">
                            <div class="noaccount_yetside">
                              <div class="noaccount_yetside_left">
                               Already have a Renok Account
                              </div>
                              <div class="noaccount_yetside_right">
                                <a href="/login" class="signuo_butS">Log In</a>
                              </div>
                            </div>
                          </div>
                          <div class="fuild_form_row">
                            <div class="lastlog_trens_condition">
                              <p>By joining Renok through Facebook, Google, Phone Number or Email Sign up, you agree to <a href="/privacy-policy">Renok’s General Terms of Use</a> and <a href="/privacy-policy">Privacy Policy</a></p>
                            </div>
                          </div>
                		</div>                
                  </div>
          	 </div>
            </div>
          </div>
        </section>

               <div class="footerpart common_block padding_leftang_right secpaddingT secmarginT">
              
               <div class="askrenok_button">
                  <a href="/ask-renok" class="renokbutton">Ask Renok</a>
               </div>
               <div class="footermenu_links secpaddingT">
                  <ul class="footer_linklist">
                     <li><a href="/about-us">About us</a></li>
                     <li><a href="/career">Careers</a></li>
                     <li><a href="/privacy-policy">Terms of Use</a></li>
                     
                  </ul>
               </div>
               <div class="footersocials secpaddingT">                  
                  <ul class="footer_soclist">
                  <li><a href="https://www.facebook.com/renokadventures/"><i class="fab fa-facebook-f"></i></a></li>
                     <li><a href="https://www.instagram.com/renok.adventures/?hl=en"><i class="fab fa-instagram"></i></a></li>
                     <li><a href="https://twitter.com/renokadventures?lang=en"><i class="fab fa-twitter"></i></a></li>
                     <li><a href="https://www.youtube.com/channel/UCkuiirm9sBWaLt11OYsgDQg"><i class="fab fa-youtube"></i></a></li>
                  </ul>
               </div>
              
               <div class="footerbootom_bar secpaddingT">
                  © 2011 - Present | Renok Adventures. All Rights Reserved.
               </div>
            </div>
         

           <div class="side_toggle_menu">
          <div class="sidenav">
            <a class="menu_toggle_green">
                <i class="fas fa-times"></i>
                 </a>
                 <ul class="leftmenu_list">
                 {(this.state.userLoggedIn === true ? 
							(<>
							<li class="line_link"><a href="/destination"><span>Search by Destination</span></a></li>
							<li class="line_link"><a href="/subactivities/All/activities"><span>Browse Activities</span></a></li>
							<li class="line_link"><a href="/subactivities/All/tours"><span>Browse Tours</span></a></li>
							<li class="line_link"><a href="/subactivities/All/staycation"><span>Browse Staycations</span></a></li>
							<li class="line_link"><a href="/subactivities/All/transport_&_travel_services"><span>Browse Transport</span></a></li>
							<li class="line_link"><a href="/privacy-policy#side_tab1"><span>Terms And Conditions</span></a></li>
							<li class="line_link"><a href="/privacy-policy#side_tab2"><span>Privacy Policy</span></a></li>

							 <li class="line_link"><a onClick={this.logout}><span>Log Out</span></a></li></>):( <>
							 <li class="line_link"><a href="/destination"><span>Search by Destination</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/activities"><span>Browse Activities</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/tours"><span>Browse Tours</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/staycation"><span>Browse Staycations</span></a></li>
							 <li class="line_link"><a href="/subactivities/All/transport_&_travel_services"><span>Browse Transport</span></a></li>
							 <li class="line_link"><a href="/privacy-policy#side_tab1"><span>Terms And Conditions</span></a></li>
							 <li class="line_link"><a href="/privacy-policy#side_tab2"><span>Privacy Policy</span></a></li>
		                     <li class="line_link"><a href="/slogin"><span>Log In</span></a></li>
		                     </>) )}
         		</ul>
         
      </div>
         </div>


    </div>
	</body>
  );
  }
}

export default SignUp;
