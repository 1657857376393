import React from 'react';
import CommonHeader from '../pages/Header';
import HomeFooter from '../pages/HomeFooter';

class Terms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    modal: false,
  };
  
}


  componentDidMount() {
    
  }


  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <body class="darkHeader_inner">
    <div class="main_all">
    <CommonHeader></CommonHeader>
<section class="same-section privacy-policy-staticP innerpages_padding_top">
	    	<div class="container">
<article class="tabbed-content tabs-side">
  <nav class="tabs">
    <ul class="listtabs_staticpage">
      <li><a href="#side_tab1" data-toggle="tab"  class="termstoggle active">Terms of Use</a></li>
      <li><a href="#side_tab2" data-toggle="tab"  class="termstoggle">Privacy Policy</a></li>
      <li><a href="#side_tab3" data-toggle="tab"  class="termstoggle">Cookie Policy</a></li>
    </ul>
  </nav>
  <div id="side_tab1" class="item tab-pane active" data-title="Terms And Conditions">
    <div class="item-content pp_staticP_contents">
      <div id="home" class="tab-pane">
		                    	<h1 class="static_contentP_title">Terms And Conditions</h1>
		                    	<p>These Booking Conditions contain important information. It is essential that you carefully read and understand them. The Booking Conditions constitute a legally binding contract between Renok Adventures and you for the services provided by Renok Adventures and set out the basis of your legal relationship with Renok Adventures.</p>
		                    	<p>By booking with us you acknowledge that you have read, understood and agree to be legally bound by these Terms and Conditions. Acceptance of your booking will be confirmed in writing or by confirmation email sent by us to you and a contract comes into existence on the date shown on your confirmation letter or email.</p>
		                    	<p>If you have any queries regarding these Conditions or do not understand them, please raise your query with a member of our staff.</p>

		                    	<h3 class="static_numof_title">
		                    		<span class="numside_stai">1)</span><span class="numside_content">PRICES AND VALIDITY</span>
		                    	</h3>
		                    	<p>Balance payments (if any) shall be paid at the start of the tour. If the balance payment is not made then the registration shall be deemed to be cancelled; cancellation charges as per cancellation rules will be applicable.<br></br>
		                    	Any incidental / additional expenses incurred during the tour shall be borne by the participants.<br></br>
		                    	Please note that a levy of up to 2.35% may be applied to all purchases made by credit card.</p>

		                    	<h3 class="static_numof_title">
		                    		<span class="numside_stai">2)</span><span class="numside_content">YOUR TRIP AND ASSUMPTION OF RISK </span>
		                    	</h3>
		                    	<p>Renok Adventures operates trips in regions where standards of accommodation, transport, safety, hygiene, medical facilities and other infrastructure may, at times, not be of the standard that you are used to at home.</p>
		                    	<p>By booking with us you acknowledge that participation in all Renok Adventures trips involves inherent risks that may not be present in the case of conventional or less demanding holidays. These risks include, without limitation, the possibility of injury or death, psychological trauma, disease, loss or damage to property, inconvenience and discomfort.</p>

		                    	<h3 class="static_numof_title">
		                    		<span class="numside_stai">3)</span><span class="numside_content">EXCLUSION OF LIABILITY </span>
		                    	</h3>
		                    	<p>Where Renok Adventures provides services it will do so with due care and skill. Renok Adventures is not responsible for the acts and omissions of others, including tour guides, accommodation providers or for any loss, damage, death, injury or expense (including loss of money paid in advance), which you may incur as a consequence of the acts and omissions of others.</p>
		                    	<p>Renok Adventures will not accept responsibility or liability if you contravene any law or regulation of any country or region visited. Any independent arrangements that you make that are not part of the Renok Adventures trip (including pre- and post accommodation) are entirely at your own risk and Renok Adventures cannot and does not give you any assurance, representation or warranty in connection with any such arrangements.</p>
		                    	<p>To the fullest extent permitted by law and subject to any  exception set out below, Renok Adventures does not accept liability for any loss, death, injury or damage, however caused (including through negligence), which you may directly or indirectly suffer in connection with or arising from this contract or your participation in a tour/trek, or in respect of a failure or omission on the part of Renok Adventures to comply with its obligations under this contract, or in respect of any other conduct that Renok Adventures undertakes in connection with a trip.  To the fullest extent permitted by law and subject to the exception set out below, you also agree to release Renok Adventures and its employees and representatives (“Representatives”) from all costs, liability, loss and damage incurred by you in connection with your participation in any trip, and waive any associated claims that you may have against Renok Adventures or its Representatives as a result of your participation in any Tour/Trek</p>

		                    	<h3 class="static_numof_title">
		                    		<span class="numside_stai">4)</span><span class="numside_content"> CANCELLATION BY RENOK ADVENTURES </span>
		                    	</h3>
		                    	<p>Renok Adventures reserves the right to cancel a departure and will advise you of such cancellations no later than 7 days before the tour departure date. If due to ‘Force Majeure’, Renok Adventures reserves the right to cancel a departure at any time. With any form of cancellation, Renok Adventures will offer you alternative Treks/tours, and if the price of your alternative booking is of lower value than the original booking we will refund the difference to you. If you do not accept alternative arrangements we will refund all payments after deducting our cost incurred in advance booking. Renok Adventures will not be liable for any additional costs incurred by you.</p>
		                    	<p>Renok Adventures will not be liable for any delay in, change to or cancellation of trips due to ‘Force Majeure’. ‘Force Majeure’ means a circumstance beyond the reasonable control of Renok Adventures and includes, but is not limited to, war or threat of war, riot, civil strife, terrorist activity, industrial dispute, disease, industrial or nuclear disaster, adverse weather conditions, fire and strikes.</p>

		                    	<h3 class="static_numof_title">
		                    		<span class="numside_stai">5)</span><span class="numside_content"> CANCELLATION BY YOU</span>
		                    	</h3>
		                    	<p>If you wish to cancel all or any part of your booking, notification of cancellation must be made to Renok Adventures in writing/email. The date of the cancellation is the date on which Renok Adventures receives  notification by email or in writing. Days before departure charge applicable as follows, per person:</p>
		                    	<p>Up to 24 Days :- 95% of Trek/Tour cost will be refunded.</p>
		                    	<p>Between 14-24 Da :- 70% of Trek/Tour cost will be refunded.</p>
		                    	<p>Between 14-08 Days :- 50% of Trek/Tour cost will be refunded.</p>
		                    	<p>Less than 7 Days :- No refund</p>
		                    	<p>Please Note: GST, Payment Gateway Charges etc will not be refunded in any case.</p>

		                    	<h3 class="static_numof_title">
		                    		<span class="numside_stai">6)</span><span class="numside_content"> CHANGES TO YOUR HOLIDAY</span>
		                    	</h3>
		                    	<p>You acknowledge that travelling with Renok Adventures requires a degree of flexibility, good humour, and an understanding that the itinerary, accommodation, and/or modes of transport may change, even after a tour’s commencement, without prior notice due to local circumstances. Changes may occur because of ‘Force Majeure’, poor road conditions, weather, the availability of tickets, vehicle breakdowns, changes in transport schedules, or other circumstances beyond Renok’s control or which simply cannot be foreseen. Renok Adventures does not accept any responsibility for loss of enjoyment, delays or compensation resulting from circumstances beyond Renok’s control. You acknowledge that group sizes may also vary during the duration of your tour.</p>
		                    	<p>The information about trips are subject to change. It is your responsibility to review the up-to-date Trip Notes and Pre-Departure Information. These can be obtained from Renok Adventures website. The information and conditions in the Trip Notes and Pre-Departure Information are deemed to be part of the contract. Where changes to your trip occur after you have received your final documentation we will, where practical, advise you of such changes. In case of evacuation the cost of the evacuation will be borne by the participant not by Renok Adventures. Any extra boarding,lodging etc due to the evacuation will be borne by the participant.</p>

		                    	<h3 class="static_numof_title">
		                    		<span class="numside_stai">7)</span><span class="numside_content">  AUTHORITY OF THE LEADER</span>
		                    	</h3>
		                    	<p>At all times the decision of the Renok Tour/Trek leader or representative will be final on all matters likely to affect the safety and well-being of the trip. This includes any decision that the Renok Leader makes about your on-going participation in the trip or certain activities that comprise part of the trip.  If you fail to comply with a decision made by a Renok Leader, or interfere with the well-being of the group, then the Renok Leader reserves the right to terminate this contract and order you to leave the tour immediately, with no right of refund.</p>
		                    </div>

		                   
    </div>
  </div>
  <div id="side_tab2" class="item tab-pane " data-title="Privacy Policy">
    <div class="item-content pp_staticP_contents">
      <h1 class="static_contentP_title">Privacy Policy</h1>
    	<p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
    	tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
    	quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
    	consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
    	cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
    	proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\</p>
    </div>
  </div>
  <div id="side_tab3" class="item tab-pane " data-title="Cookie Policy">
    <div class="item-content pp_staticP_contents">
      <h1 class="static_contentP_title">Cookie Policy</h1>
		<p> Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
		tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
		quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
		consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
		cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
		proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\</p>

		
    </div>
  </div>
</article>

	
	    	</div>
	    </section>

<HomeFooter></HomeFooter>
</div>
    </body>
  );
  }
}

export default Terms;
