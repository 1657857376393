import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';

class FreeCredits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    modal: false,
  };
  
}


  componentDidMount() {
    
  }


  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>

    <section class="same-section getfree-creaditsB innerpage_banner">
	    	<div class="container">
	    		<div class="banner-img-cnt">
	    		<div class="innpage_banner_inn_side">
		    		<h1 class="inner_banner_heding text-sm-align">Get  free credits when you complete an experience</h1>
		    		<p class="top_main_Sheding text-align">Find out how to get extra discount on your next booking by completing Renok activities and leaving reviews</p>
		    		<div class="check_bannerbutton text-align">
		    			<a href="#" class="fill_green_btn btn btn-primary">Check My Credits</a>
		    		</div>
	    		</div>
	    		<div class="get_free_bx ">
                <img src="images/free-credits01.svg" alt="" />
              </div>
          </div>
	    	</div>
	    </section>

	    <section class="same-section what_are_renok text-align padding_top">
	    	<div class="container">
	    		<h2 class="inner_banner_heding small_padding_bottom">What are Renok Credits?</h2>
		    	<p class="innerpage_subhead text-sm-align small_padding_bottom">Credits Credits can be earned by Renok customers for activities they book on RenokAdventures.com or via the Renok App. After you collect enough credits, you can use these credits to get a discount on your next booking with Renok</p>
		    	<strong class="tencredit_side"><span>10 Credits = 1 Rupee</span></strong>
		    	<p>The credits value in other currencies is based on the exchange rate at the time of booking</p>
	    	</div>
	    </section>
	    <section class="same-section how_doget_cradit padding_top credits-cnt">
	    	<div class="container">
	    		<h3 class="inner_banner_heding text-align small_padding_bottom">How do I get credits?</h3>
	    		<div class="how_docreadit_sec_bxperent">
		    		<div class="same-section how_docreadit_sec_bx">
		    			<div class="how_docreadit_sec_bx_inner text-align">
		    				<img src="images/how-do-get01.png" alt="Image-Icon" class="how-get" />
		    				<img src="images/get-credits01.svg" alt="Image-Icon" class="how-gets1" />
		    				<div class="activity-cnt">
		    				<h4 class="innpage_bx_title">Complete an Activity</h4>
		    				<p class="align-left">When you complete an activity, you will receive 1%-5% of the activity price in Renok credits. The credits will appear in your Renok account 2 days after you complete the activity</p>
		    			</div>
		    			</div>	    			
		    			<div class="how_docreadit_sec_bx_inner text-align">
		    				<img src="images/how-do-get02.png" alt="Image-Icon" class="how-get"/>
		    				<img src="images/get-credits02.svg" alt="Image-Icon" class="how-gets1" />
		    				<div class="activity-cnt">
		    				<h4 class="innpage_bx_title">Leave a Review</h4>
		    				<p class="align-left">After completing any Renok activity, you are invited to leave a review. Each review earns you 80 credits which will appear in your account immediately</p>
		    			</div>
		    			</div>
		    		</div>
	    		</div>
	    	</div>
	    </section>
	    <section class="same-section how_doUse_cradit padding_top padding_bottom">
	    	<div class="container">
	    		<h3 class="inner_banner_heding text-align small_padding_bottom">How do I use credits?</h3>
	    		<p> When you have collected <strong>at least 10</strong> credits, you can choose to use these credits to get a discount on your next booking with Renok</p>
	    	</div>
	    </section>


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default FreeCredits;
