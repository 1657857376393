import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import { APP_BASE_URL,IMAGE_BASE_URL} from '../../constants';
import { API_BASE_URL} from '../../constants';
import AuthService from '../../services/AuthService'
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Form from "react-validation/build/form";

class PackageOptions extends React.Component {
  constructor(props) {

    super(props);
	this.handleClickActitvity = this.handleClickActitvity.bind(this);
	this.handleClickAddtoWishlist = this.handleClickAddtoWishlist.bind(this);
	this.handleDayClick = this.handleDayClick.bind(this);
	this.bookNow = this.bookNow.bind(this);
	this.addReview = this.addReview.bind(this);
	this.onChange = this.onChange.bind(this);
    this.state = {
	userLoggedIn :  AuthService.isUserLoggedIn(),
	userId : AuthService.getCurrentUserId(),
	initialMonth : new Date(),
	reviewMessage : false,
    modal: false,
  };
  
}


  componentDidMount() {

    var packageId = this.props.match.params.id;
	this.setState({"packageId":packageId})
	ProfileService.getPackageDetailsById(packageId).then(response=>{
		
		//alert(response.data[0].id)
	ProfileService.getVendorById(response.data.vendor_id).then(response=>{
		this.setState({"vendorName":response.data.name })
		this.setState({"vendorCompanyName":response.data.companyName })
		this.setState({"vendorTagLine":response.data.tagLine })
		
	})

	
		if(response.data.message){
			this.props.history.push(`/pageNotFound`)
			window.location.reload();
		}
		var activitId = response.data.activity;
		ProfileService.getNonDefaultPackageListByActivityId(activitId).then(response=>{
			//alert(response.data.length);
			this.setState({"otherPackageCount":response.data.length})
			this.setState({"otherPackageList":response.data})
		})
		this.setState({"activitId":activitId})
		ProfileService.similarActivities(activitId).then(response=>{
		
			this.setState({"similarActivities":response.data})
		})
		

		ProfileService.getActivityReviews(activitId).then(response=>{
		  this.setState({"activityReviews":response.data})
		})
		ProfileService.getActivity(activitId).then(response=>{
			this.setState({
				"name":response.data.name,
				"coverImage":JSON.parse(response.data.coverImage).imageUrl,
				"coverImageAltText":JSON.parse(response.data.coverImage).altText,
				"gridImage":response.data.gridImage,
				"activityCategory":response.data.activityCategory,
				"destination" :response.data.destination,
			  })
			  ProfileService.getStateById(response.data.state).then(response=>{
				  this.setState({"state":response.data.name })
			  })
			  ProfileService.getDestination(response.data.destination).then(response=>{
				this.setState({"destination":response.data.name })
				this.setState({"destinationUrl":response.data.url })
			  })
			  this.setState({ activityDescription: EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.activityDescription))) })
	  })

	  this.setState({"packageId":response.data.id})
	  this.setState({"packageName":response.data.name})
	  this.setState({"booked":response.data.booked})
	  this.setState({"total_reviews":response.data.total_reviews})
	  this.setState({"review":response.data.review})
	  if(response.data.dateRangeTypeFlag!=null && response.data.dateRangeTypeFlag === 'EXCLUDE'){
		//alert("dateExcludeFlag "+true)
	   this.setState({"dateExcludeFlag":true })
	   let excludeDateRange = JSON.parse(response.data.excludeDateRange);
	   var datesToFormat = [];
	   for(var i =0;i<excludeDateRange.length;i++){
		var st = excludeDateRange[i].keyValue;
		var pattern = /(\d{4})\-(\d{2})\-(\d{2})/;
		var dt = new Date(st.replace(pattern,'$1-$2-$3'));
		datesToFormat.push(dt);
	   }
	   this.setState({"dateExclude":datesToFormat}) 
	   datesToFormat.push({before: new Date()})
	   this.setState({disabledDays:datesToFormat});
	  } else{
	  // alert("dateExcludeFlag "+false)
	   this.setState({"dateExcludeFlag":false })
	   this.setState({"dateRange":JSON.parse(response.data.dateRange) })
	   this.setState({"dateSelected":JSON.parse(response.data.dateRange)[0].keyValue})
	  }   
	 
      

	  this.setState({"activityId":response.data.activity})
	  this.setState({"noOfAdults":1 })
	  this.setState({"noOfChilds":0 })
	  this.setState({"basePrice":response.data.basePrice })
	  this.setState({"pkreview":response.data.review })
	  this.setState({"childPrice":response.data.childPrice })
	  //alert(response.data.childPrice)
	  var totalPriceAdult =  response.data.basePrice * 1;
	  var totalPriceChild =  response.data.childPrice * 0;
	  var totalPaymentAmount = totalPriceAdult + totalPriceChild;
	  this.setState({"totalPaymentAmount":totalPaymentAmount})
	  this.setState({"updatedPrice":response.data.basePrice })
	  this.setState({"updatedChildPrice":response.data.childPrice })
	  this.setState({"unitprice":response.data.basePrice })
	  this.setState({"unitpriceChild":response.data.childPrice })
	  this.setState({"totalPrice":response.data.basePrice })
	  this.setState({"optionLable1":response.data.optionLable1 })
	  this.setState({"oV1":JSON.parse(response.data.optionValue1)})
	  this.setState({"oV2":JSON.parse(response.data.optionValue2)})
	  this.setState({"oV3":JSON.parse(response.data.optionValue3)})
	  var optionValue1 = JSON.parse(response.data.optionValue1);
	  for(var i=0;i<optionValue1.length;i++){
		  var value = optionValue1[i];
		  if(value.valueValue==0){
			 value.selectedValue = true;
		  }
	  }
	  this.setState({"optionValue1":optionValue1 })
	  this.setState({"optionLable2":response.data.optionLable2 })
	  var optionValue2 = JSON.parse(response.data.optionValue2);
	  for(var i=0;i<optionValue2.length;i++){
		  var value = optionValue2[i];
		  if(value.valueValue==0){
			 value.selectedValue = true;
		  }
	  }
	  this.setState({"optionValue2":optionValue2 })
	  this.setState({"optionLable3":response.data.optionLable3 })
	  var optionValue3 = JSON.parse(response.data.optionValue3);
	  for(var i=0;i<optionValue3.length;i++){
		  var value = optionValue3[i];
		  if(value.valueValue==0){
			 value.selectedValue = true;
		  }
	  }
	  this.setState({"optionValue3":optionValue3 })
	  
	  this.setState({"packageDescription":EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.description))) })
	  this.setState({"packageTerms":EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.terms))) })
	})

	

	// ProfileService.getDefaultPackageByActivity(this.props.match.params.id).then(response=>{
		         
	// 			 this.setState({"packageId":response.data[0].id})
	// 			 this.setState({"packageName":response.data[0].name})
				 
	// 			 this.setState({"activityId":response.data[0].activity})
	// 	         this.setState({"noOfAdults":1 })
	// 			 this.setState({"noOfChilds":0 })
	// 	         this.setState({"basePrice":response.data[0].basePrice })
	// 			 var totalPriceAdult =  response.data[0].basePrice * 1;
	// 			 var totalPriceChild =  response.data[0].basePrice * 0;
	// 			 var totalPaymentAmount = totalPriceAdult + totalPriceChild;
	// 			 this.setState({"totalPaymentAmount":totalPaymentAmount})
	// 			 this.setState({"updatedPrice":response.data[0].basePrice })
	// 			 this.setState({"totalPrice":response.data[0].basePrice })
	// 			 this.setState({"optionLable1":response.data[0].optionLable1 })
	// 			 this.setState({"oV1":JSON.parse(response.data[0].optionValue1)})
	// 			 this.setState({"oV2":JSON.parse(response.data[0].optionValue2)})
	// 			 this.setState({"oV3":JSON.parse(response.data[0].optionValue3)})
    //              var optionValue1 = JSON.parse(response.data[0].optionValue1);
	// 			 for(var i=0;i<optionValue1.length;i++){
	// 				 var value = optionValue1[i];
	// 				 if(value.valueValue==0){
	// 					value.selectedValue = true;
	// 				 }
	// 			 }
	// 			 this.setState({"optionValue1":optionValue1 })
	// 			 this.setState({"optionLable2":response.data[0].optionLable2 })
	// 			 var optionValue2 = JSON.parse(response.data[0].optionValue2);
	// 			 for(var i=0;i<optionValue2.length;i++){
	// 				 var value = optionValue2[i];
	// 				 if(value.valueValue==0){
	// 					value.selectedValue = true;
	// 				 }
	// 			 }
	// 			 this.setState({"optionValue2":optionValue2 })
	// 			 this.setState({"optionLable3":response.data[0].optionLable3 })
	// 			 var optionValue3 = JSON.parse(response.data[0].optionValue3);
	// 			 for(var i=0;i<optionValue3.length;i++){
	// 				 var value = optionValue3[i];
	// 				 if(value.valueValue==0){
	// 					value.selectedValue = true;
	// 				 }
	// 			 }
	// 			 this.setState({"optionValue3":optionValue3 })
				 
    //              this.setState({"packageDescription":EditorState.createWithContent(convertFromRaw(JSON.parse(response.data[0].description))) })
	// 			 this.setState({"packageTerms":EditorState.createWithContent(convertFromRaw(JSON.parse(response.data[0].terms))) })
	// });
	
   

  }
  handleClickActitvity(e, id){
	 // alert(id);
	this.props.history.push(`/activity/${id}`)
  }

  handleClickAddtoWishlist(e,id){
    if(this.state.userLoggedIn){
		let dto = {
			userid : this.state.userId,
			activityId : id
		}
		ProfileService.addToWishlist(dto).then(response=>{
			alert("Activity Added to wishlist")
		})
	}else{
       alert("Please login first.");
	}
  }

  addReview(e){
	e.preventDefault();
	if(this.state.rating==0){
		  this.setState({"ratingError":true})
	}else{
	   this.setState({"ratingError":false})
		if(this.state.review==''){
		   this.setState({"reviewError":true})
		}else{
		   this.setState({"reviewError":false})
		   var userId = null;
		   if(this.state.userLoggedIn){
			   userId = this.state.userId;
		   }
		   var packageId = this.state.reviewPackageId;
		   if(this.state.reviewPackageId==''){
			   packageId = this.state.packageId;
		   }
		   let dto = {
			   userId : userId,
			   packageId : packageId,
			   activityId : this.state.activityId,
			   noOfStar : this.state.rating,
			   review : this.state.review
		   }
		   ProfileService.addReview(dto).then(response=>{
			   this.setState({"rating":0,"review":'',"reviewMessage":true});
		   })
		}
	}
   
 }

 onChange(e){
   this.setState({
	   [e.target.name]: e.target.value
	 });
 }

  handleClickPackageOptions(e, id){
	if(this.state.userLoggedIn){
		this.props.history.push(`/packageOptions/${id}`)
		window.location.reload();
	}else{
       alert("Please login to make booking.");
	}
  }

  handleClickClearOptionValue(){
	var optionValue1 = this.state.oV1;
	for(var i=0;i<optionValue1.length;i++){
		var value = optionValue1[i];
		if(value.valueValue==0){
		   value.selectedValue = true;
		}else{
			value.selectedValue = false;
		}
	}
	this.setState({"optionValue1":optionValue1 })
	var optionValue2 = this.state.oV2;
	for(var i=0;i<optionValue2.length;i++){
		var value = optionValue2[i];
		if(value.valueValue==0){
		   value.selectedValue = true;
		}else{
			value.selectedValue = false;
		}
	}
	this.setState({"optionValue2":optionValue2 })
	var optionValue3 = this.state.oV3;
	for(var i=0;i<optionValue3.length;i++){
		var value = optionValue3[i];
		if(value.valueValue==0){
		   value.selectedValue = true;
		}else{
			value.selectedValue = false;
		}
	}
	this.setState({"optionValue3":optionValue3 })
	this.setState({"noOfAdults":1 })
	this.setState({"noOfChilds":0 })
	this.updatePrice(1,0);
  }

  handleClickOptionValue(valueId,keyId){
  // alert(valueId);
	if(valueId==1){
		var optionValue = this.state.optionValue1;
		for(var i=0;i<optionValue.length;i++){
			var value = optionValue[i];
			if(value.id==keyId){
				value.selectedValue = true;
			}else{
				value.selectedValue = false;
			}
		}
		this.setState({"optionValue1":optionValue })
	}else if(valueId==2){
		var optionValue = this.state.optionValue2;
		for(var i=0;i<optionValue.length;i++){
			var value = optionValue[i];
			if(value.id==keyId){
				value.selectedValue = true;
			}else{
				value.selectedValue = false;
			}
		}
		this.setState({"optionValue2":optionValue })
	}else if(valueId==3){
		var optionValue = this.state.optionValue3;
		for(var i=0;i<optionValue.length;i++){
			var value = optionValue[i];
			if(value.id==keyId){
				value.selectedValue = true;
			}else{
				value.selectedValue = false;
			}
		}
		this.setState({"optionValue3":optionValue })
	}
	this.updatePrice(this.state.noOfAdults,this.state.noOfChilds);
//	alert(keyId);
  }
  
  decrementAdult(){
	  var adult = this.state.noOfAdults;
	  if(adult>1){
		  adult = adult-1;
		  this.setState({"noOfAdults":adult});
		  this.updatePrice(adult,this.state.noOfChilds);
	  }
  }

  decrementChild(){
	var child = this.state.noOfChilds;
	if(child>0){
		child = child-1;
		this.setState({"noOfChilds":child});
		this.updatePrice(this.state.noOfAdults,child);
	}
}

incrementAdult(){
	    var adult = this.state.noOfAdults;
		 adult = adult+1;
		this.setState({"noOfAdults":adult});
		this.updatePrice(adult,this.state.noOfChilds);
	
}

incrementChild(){
      var child = this.state.noOfChilds;
	   child = child+1;
	  this.setState({"noOfChilds":child});
	  this.updatePrice(this.state.noOfAdults,child);

}

  updatePrice(adult,child){
	var basePrice = this.state.basePrice; 
	var childPrice = this.state.childPrice; 
	var addOnOv1 = 0;
	var addOnOv2 = 0;
	var addOnOv3 = 0;
	var optionValue = this.state.optionValue1;
	for(var i=0;i<optionValue.length;i++){
		var value = optionValue[i];
		if(value.selectedValue==true){
			addOnOv1 = parseInt(value.valueValue);
		}
	}
	var optionValue2 = this.state.optionValue2;
	for(var i=0;i<optionValue2.length;i++){
		var value = optionValue2[i];
		if(value.selectedValue==true){
			addOnOv2 = parseInt(value.valueValue);
		}
	}
	var optionValue3 = this.state.optionValue3;
	for(var i=0;i<optionValue3.length;i++){
		var value = optionValue3[i];
		if(value.selectedValue==true){
			addOnOv3 = parseInt(value.valueValue);
		}
	}

	var totalAddOn = addOnOv1 + addOnOv2 + addOnOv3;

	var unitprice = parseInt(basePrice) + totalAddOn;

	var unitpriceChild = parseInt(childPrice) + totalAddOn;
	
    var totalPriceAdult =  unitprice * adult;
	
	var totalPriceChild =  unitpriceChild * child;

	var totalPaymentAmount = totalPriceAdult + totalPriceChild;
	this.setState({"totalAddOn":totalAddOn})
	this.setState({"unitprice":unitprice})
	this.setState({"unitpriceChild":unitpriceChild})
	this.setState({"totalPriceAdult":totalPriceAdult})
	this.setState({"totalPriceChild":totalPriceChild})

	this.setState({"totalPaymentAmount":totalPaymentAmount})
  }

  bookNow(){
	
	var packageOptions=[];
	packageOptions.push(this.state.optionValue1);
	packageOptions.push(this.state.optionValue2);
	packageOptions.push(this.state.optionValue3);
	var sd = this.state.dateSelected;
	//alert(sd);
	if(this.state.dateExcludeFlag){
		sd = this.state.selectedDay;
	}
	if(sd!= undefined){
	//alert(sd);
	let bookingDetails = {
		// userId : this.state.userId,
         packageId : this.state.packageId,
		 packageName : this.state.packageName,
		 dateSelected : sd,
		 dateExcludeFlag : this.state.dateExcludeFlag,
		 activityId : this.state.activityId,
		 totalPaymentAmount : this.state.totalPaymentAmount,
		 noOfAdults:this.state.noOfAdults,
         noOfChilds:this.state.noOfChilds,
         packageOptions:packageOptions,
		 basePrice : this.state.basePrice,
		 childPrice : this.state.childPrice,
		 totalAddOn : this.state.totalAddOn,
		 unitprice : this.state.unitprice,
		 unitpriceChild : this.state.unitpriceChild,
		 totalPriceAdult : this.state.totalPriceAdult,
		 totalPriceChild : this.state.totalPriceChild,
         payementStatus:'PENDING',
         bookingStatus:'PENDING'
	}
	localStorage.setItem("bkd"+this.state.packageId, JSON.stringify(bookingDetails));
	var id = this.state.packageId;
	this.props.history.push(`/travelInfo/${id}`)
     }else{
		 alert("Please select Date.")
	 }
    

  }

  handleDayClick(day, { selected }) {
	//alert("inn"+day)
    this.setState({
      selectedDay: selected ? undefined : day,
    });

  }

  handleClickDestination(e,id){
	this.props.history.push(`/destination/${id}`)
	window.location.reload();
  }

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>
   
	<section class="same-section destinationpage_slider">
		  	<div class="same-section topside_banner--slider">
		  		<div class="activity_banner" >
		  			<div class="item"><img src={IMAGE_BASE_URL+``+this.state.coverImage} alt={this.state.coverImageAltText}/></div>
		  			
		  		</div>
		  	</div>
		</section>

		<section class="same-section desination_perent--details">
			<div class="container">
				<div class="row onlydetail_main_row">
					<div class="col-md-8 desti_detail_mainleft">
						<div class="desination_perent--details--Linn">
					        <div class="bredcrumb_custom same-section small_padding_top">
					            <ul class="custbread">
					              <li><a href="/">Home</a></li>
					              <li><i class="fas fa-chevron-right"></i></li>
					              <li><a href="/destination">Destinations</a></li>
								  <li><i class="fas fa-chevron-right"></i></li>
								  <li><a onClick={(e)=> this.handleClickDestination(e, this.state.destinationUrl)}>{this.state.destination}</a></li>
								  <li><i class="fas fa-chevron-right"></i></li>
								  <li class="active">{this.state.name}</li>
					            </ul>
					        </div>

							<div class="same-section heading_side">
								<h3 class="palcebox_title get-sky">{this.state.name}</h3>
								<h2 class="detail_in_title forecast">{this.state.name}</h2>
								<div class="rating_detail_bx">
									<div class="rating_detail_bx--left">
										<span><i class="fas fa-star"></i>&nbsp;{this.state.review}</span><span>({this.state.total_reviews} reviews)</span><span>{this.state.booked} Booked</span>
									</div>
									<div class="rating_detail_bx--right">
										<a onClick={(e)=> this.handleClickAddtoWishlist(e, this.state.activitId)}><i class="far fa-heart"></i> Wishlist</a>
									</div>
								</div>
								{( this.state.otherPackageCount == 0 ? (<><p style={{"margin-top":"15px;"}}>Sold by <a href="#" data-toggle="modal" data-target="#myModal01">{this.state.vendorName}</a></p></>):(<><p style={{"margin-top":"15px;"}}>Sold by <a href="#" data-toggle="modal" data-target="#myModal01">{this.state.vendorName}</a> and {this.state.otherPackageCount} similar <a href="#" data-toggle="modal" data-target="#myModal">offers</a> Starting from {this.state.basePrice}</p></>) )}
								<div class="popular_activities_price">
                           			<p class="pricesid">₹ {this.state.basePrice} </p>
                        		</div>
								<p class="available_service_title secmarginB"><i class="far fa-clock"></i> &nbsp; Available Today</p>
								<div class="service_list_wIcon">
									<ul class="listwith_icon">
										<li><span class="service_typeic"><img loading="lazy"  height="16px" width="16px" src="/images/ruees.svg" alt="rupee" /></span><span class="service_type">Conditional Cancelation*</span></li>
										<li><span class="service_typeic"><img  loading="lazy"  height="16px" width="16px" src="/images/printer.svg" alt="print" /></span><span class="service_type">Print Voucher</span></li>
										<li><span class="service_typeic"><img  loading="lazy"   height="16px" width="16px"src="/images/calender.svg" alt="calender" /></span><span class="service_type">Collect Physical Ticket</span></li>
										<li><span class="service_typeic"><img  loading="lazy"   height="16px" width="16px"src="/images/ticket.svg"  alt="ticket"  /></span><span class="service_type">Open Date Ticket</span></li>
									</ul>
								</div>
							
 

								<div class="simple_list">
								<Editor toolbarHidden={true} editorState={this.state.activityDescription} readOnly={true} />
								
								</div>
							</div>

						
								


							<div class="same-section package_optionD--details padding_top">
								<h3 class="title--leftborder">Package Options</h3>
								<div class="detail_packag--descs">
									<div class="pack_ttile--clearall">
										<div class="row">
											<div class="col-md-8">
												{/* <span class="package_subsT">Select date and package options</span> */}
											</div>
											<div class="col-md-4">
												<a class="clear_butD" onClick={() => this.handleClickClearOptionValue()}><i class="fas fa-redo"></i>&nbsp; Clear All</a>
											</div>
										</div>
									</div>
									<div class="label--withdetail">
										<h6 class="label--titleS">Please select date of departure </h6>
										<div class="label-desc--bx">
										{(this.state.dateExcludeFlag == false ? (<><select class="filter_fulid_date" required name="dateSelected" value={this.state.dateSelected}   onChange={this.onChange} >
			                    						

														{( this.state.dateRange != null && this.state.dateRange.length> 0 )? this.state.dateRange.map(
													hl =><>
													<option value={hl.keyValue}>{hl.keyValue}</option>
										 
										   </>
										   ):""}
														</select>	</>):(<>
													
															<DayPicker style={{"background-color": "white"}} selectedDays={this.state.selectedDay}
															
      disabledDays={this.state.disabledDays}
	  onDayClick={this.handleDayClick}
    />
														</>) )}
										</div>
									</div>	

									<div class="label--withdetail">
										<h6 class="label--titleS">{this.state.optionLable1}</h6>
										<div class="label-desc--bx">
											<ul class="label_typeL">
											{(this.state.optionValue1 != null && this.state.optionValue1.length> 0 )? this.state.optionValue1.map(
												 hl =><>
                                              {( hl.selectedValue === true  ? (<><li class="active" onClick={() => this.handleClickOptionValue(1,hl.id)}><h6 style={{"font-weight":"bolder"}}>{hl.keyValue}</h6></li></>) :(<><li onClick={() => this.handleClickOptionValue(1,hl.id)}><h6 style={{"font-weight":"bolder"}}>{hl.keyValue}</h6></li></>) )}
												
												
												</>):""}
											</ul>
										</div>
									</div>							
									<div class="label--withdetail">
										<h6 class="label--titleS">{this.state.optionLable2}</h6>
										<div class="label-desc--bx">
											<ul class="label_typeL">
											{(this.state.optionValue2 != null && this.state.optionValue2.length> 0 )? this.state.optionValue2.map(
												 hl =><>
                                              {( hl.selectedValue === true ? (<><li class="active" onClick={() => this.handleClickOptionValue(2,hl.id)}><h6 style={{"font-weight":"bolder"}}>{hl.keyValue}</h6></li></>) :(<><li onClick={() => this.handleClickOptionValue(2,hl.id)}><h6 style={{"font-weight":"bolder"}}>{hl.keyValue}</h6></li></>) )}
												
												
												</>):""}
											</ul>
										</div>
									</div>							
									<div class="label--withdetail">
										<h6 class="label--titleS">{this.state.optionLable3}</h6>
										<div class="label-desc--bx">
											<ul class="label_typeL non_inline">
											{(this.state.optionValue3 != null && this.state.optionValue3.length> 0 )? this.state.optionValue3.map(
												 hl =><>
                                              {( hl.selectedValue === true  ? (<><li class="active" onClick={() => this.handleClickOptionValue(3,hl.id)}><h6 style={{"font-weight":"bolder"}}>{hl.keyValue}</h6></li></>) :(<><li onClick={() => this.handleClickOptionValue(3,hl.id)}><h6 style={{"font-weight":"bolder"}}>{hl.keyValue}</h6></li></>) )}
												
												
												</>):""}
											</ul>
										</div>
									</div>	
									<div class="label--withdetail">
									<div class="manage_row_flex padding_leftang_right">
                                      <div class="manage_row_bx">
                                       <div class="titile_content_log_method">
                                       <span class="qty_print">Adult</span>
									   <span class="qty_print">₹ {this.state.unitprice}</span>
                                      </div>
                                      </div>
									  
                                          <div class="manage_edit_row_bx">
                     	                    <div class="add_lase_bx" >
						                  
											 <span class="qty_print">{this.state.noOfAdults}</span>
	                                             <a  onClick={() => this.incrementAdult()} class="tabplus_minus"><i class="fas fa-plus"></i></a>
	                                             <a onClick={() => this.decrementAdult()}  class="tabplus_minus"><i class="fas fa-minus"></i></a>
                    	                    </div>
                                          </div>
                                     </div>		
									 </div>	
									 <div class="label--withdetail">
									<div class="manage_row_flex padding_leftang_right">
                                      <div class="manage_row_bx">
                                       <div class="titile_content_log_method">
                                       <span class="qty_print">Child(6-11)</span>
									   <span class="qty_print">₹ {this.state.unitpriceChild}</span>
                                      </div>
                                      </div>
									  
                                          <div class="manage_edit_row_bx">
                     	                    <div class="add_lase_bx" >
						                  
	                     	                 <span class="qty_print">{this.state.noOfChilds}</span>
	                                             <a onClick={() => this.incrementChild()} class="tabplus_minus"><i class="fas fa-plus"></i></a>
	                                             <a onClick={() => this.decrementChild()}  class="tabplus_minus"><i class="fas fa-minus"></i></a>
                    	                    </div>
                                          </div>
                                     </div>		
									 </div>						
								</div>
							</div>
							
							
							<div class="same-section what_expect padding_top what-exp-type">
							<Editor toolbarHidden={true} editorState={this.state.packageDescription} readOnly={true} />
                            </div> 
 
							<div class="same-section what_expect padding_top what-exp-type">
								{/* <h3 class="title--leftborder">What to Expect</h3>
								<p>There is no doubt that there has been a sudden surge in the popularity of Kedarkantha. If you’re unfamiliar with Kedarkantha trek best time, here are a few important pieces of information that may be of interest to you. Kedarkantha is a 10 km trek (one way) from the village Sankri. Located around 200 kilometers from the state capital Dehradun, Sankri is the starting point of the Kedarkantha trek.</p>

								<p>In order to trek to Kedarkantha peak, you have to go through Govind Wildlife Sanctuary. Sankri falls right outside the sanctuary and offers magnificent views of the surrounding Himalayas. After travelling from Dehradun for a period of 7-8 hours, you will arrive in the village. The trek begins and ends in Sankri.</p>

								<img src="/images/what-except.jpg" alt="Image"/>

								<p>There are multiple things that you have to consider before travelling to Kedarkantha.  One of them is the weather which directly impacts the trek difficulty level. If you are an experienced trekker, you should plan Kedarkantha trek during the months of winter. With snow all around, trekking to the peak offers an ethereal experience. Snow begins to fall in December and lasts until late April. The temperature during these months ranges between 0 to -7 degree Celsius.</p> */}
							<Editor toolbarHidden={true} editorState={this.state.packageTerms} readOnly={true} />
							</div>
							

							
							<div class="same-section guest_review padding_top">
								<h3 class="title--leftborder small_margin_bottom">Guest Review : {(this.state.activityReviews != null  ? (<>{this.state.activityReviews.length}</>):(<>0</>))}</h3>
								{(this.state.activityReviews != null && this.state.activityReviews.length> 0 )? this.state.activityReviews.map(
												 hl =><>
								<div class="reviews_flex_bx">
									<div class="reviews_user_img">
										<div class="user_imgbx">
											<img src="/images/guidetip-user.jpg" alt="Image-Icon"/>
										</div>
									</div>
									<div class="user_name_rate">
										<h4>{hl.userName}</h4>
										<ul class="starrate_sides">
										{( hl.noOfStar == 5?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( hl.noOfStar == 4?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( hl.noOfStar == 3?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( hl.noOfStar == 2?  (<><li><i class="fas fa-star"></i></li>
				    									<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{( hl.noOfStar == 1?  (<><li><i class="fas fa-star"></i></li></>):(<></>) )}
										
										</ul>
									</div>
								</div>
								<div class="rate_desc_conmtent">
									<p>{hl.review}</p>
								</div>
								<br></br>
								</>):""}
								
								
							</div>

						
<div class="add_review">
							<h3 class="small_margin_bottom">Add Review</h3>
							<Form onSubmit={this.addReview} ref={c => {this.form = c;}}>
							<fieldset class="rating">
    <input type="radio" id="star5" name="rating" value="5" onChange={this.onChange} /><label class = "full" for="star5" title="Awesome - 5 stars"></label>
    {/* <input type="radio" id="star4half" name="rating" value="4 and a half" onChange={this.onChange}  /><label class="half" for="star4half" title="Pretty good - 4.5 stars"></label> */}
    <input type="radio" id="star4" name="rating" value="4" onChange={this.onChange}  /><label class = "full" for="star4" title="Pretty good - 4 stars"></label>
    {/* <input type="radio" id="star3half" name="rating" value="3 and a half" onChange={this.onChange}  /><label class="half" for="star3half" title="Meh - 3.5 stars"></label> */}
    <input type="radio" id="star3" name="rating" value="3" /><label class = "full" for="star3" title="Meh - 3 stars"></label>
    {/* <input type="radio" id="star2half" name="rating" value="2 and a half" onChange={this.onChange}  /><label class="half" for="star2half" title="Kinda bad - 2.5 stars"></label> */}
    <input type="radio" id="star2" name="rating" value="2" onChange={this.onChange}  /><label class = "full" for="star2" title="Kinda bad - 2 stars"></label>
   {/*  <input type="radio" id="star1half" name="rating" value="1 and a half" onChange={this.onChange}  /><label class="half" for="star1half" title="Meh - 1.5 stars"></label> */}
    <input type="radio" id="star1" name="rating" value="1" onChange={this.onChange}  /><label class = "full" for="star1" title="Sucks big time - 1 star"></label>
   {/*  <input type="radio" id="starhalf" name="rating" value="half" onChange={this.onChange}  /><label class="half" for="starhalf" title="Sucks big time - 0.5 stars"></label> */}
</fieldset>
{( this.state.ratingError == true ?  (<div class="contentsubs_side"><p>Please provide rating.</p></div>):(<></>) )}
<select style={{"margin-top":"10px","margin-bottom":"10px"}} class="filter_fulid" required name="reviewPackageId" value={this.state.reviewPackageId}  onChange={this.onChange}>
{(this.state.otherPackageList != null && this.state.otherPackageList.length> 0 )? this.state.otherPackageList.map(
												 hl =><>			
<option value={hl._id}>{hl.vd[0].name} ( {hl.name} )	</option>								
													
  </>):""}
  </select>
  <textarea id="w3review" name="review" rows="4" cols="50" value={this.state.review} onChange={this.onChange}>
</textarea>
{( this.state.reviewError == true ?  (<div class="contentsubs_side"><p>Please enter your review.</p></div>):(<></>) )}
<button type="submit" class="btn btn-primary" >Save</button>
{( this.state.reviewMessage == true ?  (<div class="contentsubs_side"><p>Thank you for Review Submission. It will be displayed after approval.</p></div>):(<></>) )}
</Form>
							</div>


							<div class="same-section import_faqs--details padding_top padding_bottom">
								{/* <h3 class="title--leftborder">Other similar experiences</h3>
								<div class="same-section other_similar--details small_padding_top">
									<div class="row">
									{(this.state.similarActivities != null && this.state.similarActivities.length> 0 )? this.state.similarActivities.map(
                                    influencer =>
						    			<div class="col-md-4">
							    			<div class="popular_activities_box">
							                     <div class="imagesof_box">
							                        <img src="/images/renok-recommend03.jpg" alt=""/>
							                        <div class="activities_palcetitle">
							                          
							                        </div>
							                        <div class="desti_km">
						                         
						                        </div>
							                     </div>
							                     <div class="popular_activities_content">
							                        <h3 class="palcebox_title">{influencer.name}</h3>
							                        <div class="box_review_side">
							                           <div class="review_side_cell">
							                              <i class="fas fa-star"></i>
							                              <span class="rateeside">4.9</span>
							                              <span class="totalreview">(3,489 reviews)</span>
							                              <span class="totalblocked">12k Booked</span>
							                           </div>
							                        
							                           <div class="popular_activities_price">
							                              <p class="pricesid">₹ 20,000 <span><img src="/images/lightning.svg" alt=""/></span></p>
							                           </div>

							                           <div class="popular_activities_price available_place">
							                              <p class="available_from">Available Today</p>
							                           </div>
							                        </div>
							                     </div>
							                     <a onClick={(e)=> this.handleClickActitvity(e, influencer.id)} class="onlink"></a>
							                </div>
						    			</div>):""}

						    			
					    			</div>
								</div> */}
							</div>

						</div>
					</div>

					{/* <div class="col-md-4 desti_detail_mainright">
						<div class="select_option--details small_padding_top">
							<div class="select_option--price">
								<h3 class="totlaprice--details">₹ {this.state.basePrice}</h3>
								<ul class="listside_icon small_padding_top small_margin_bottom">
									<li>
										<span class="iconside--det"><img src="/images/time.svg" alt="Images-Icon"/></span>
										<span class="title_icon--desc">Earliest available date: 1 Oct 2019</span>
									</li>
									<li>
										<span class="iconside--det"><img src="/images/lightning-samll.svg" alt="Images-Icon"/></span>
										<span class="title_icon--desc">Instant Confirmation</span>
									</li>
								</ul>
								<div class="select_op_button">
									<a href="#" class="green_button" data-toggle="modal" data-target="#myModal">Select Options</a>
								</div>
							</div>
							<div class="same-section getfree_pernt">								
								<div class="getfree--details">
									<a href="#">Get free credits when you complete an experience <span><i class="fas fa-angle-right"></i></span></a>
								</div>
							</div>
							<div class="same-section downloadapp--details small_margin_top">
								<div class="download_flex">
									<div class="icondwnload">
										<img src="/images/download-mobile.svg" align="Image-Icon"/>
									</div>
									<div class="contentdownload">
										Get ₹100 off on your first Mobile App booking with promo code <span>mob10</span>
									</div>
								</div>
								<div class="downloadlick--app">
									<a href="#"><img src="/images/download-app.svg" alt="Image-Icon"/> Download App</a>
								</div>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</section>


		<section class="bottobfix--booknow">
			<div class="container">
				<div class="bottobfix--booknow_flex">
				  
					<div class="complate_allrequerd">
					<div class="rupeeqts">
            			₹ {this.state.totalPaymentAmount}
            		</div>
						Complete all required fields to proceed to the next step
					</div>
					<div class="bottom_side--fixbutton">
						<a onClick={(e)=> this.handleClickAddtoWishlist(e, this.state.activitId)} class="wishlist_button"><i class="far fa-heart"></i></a>
					</div>
					<div class="bottom_side--fixbutton">
						<a onClick={() => this.bookNow()}   class="green_button">Book Now</a>
					</div>
					{/* <div class="editside_bookfix">
						<a href="#"><i class="far fa-edit"></i> &nbsp;Edit Selected Options</a>
					</div> */}
				</div>
			</div>
			</section>

			<div class="modal" id="myModal">
    <div class="modal-dialog modal-lg mycustom_model_postion">
      <div class="modal-content">        
        
        <div class="modal-body seller_info--model">
          <div class="seller_info_inner">
          	<div class="seller_model_head">
          		<div class="seller_head_bx align-left">
          			<h5>Price + Delivery</h5>
          		</div>
          		<div class="seller_head_bx align-left">
          			<h5>Type</h5>
          		</div>
          		<div class="seller_head_bx align-left">
          			<h5>Seller Information</h5>
          		</div>
          	</div>
          	<div class="seller_info_flex_perent">
			  {(this.state.otherPackageList != null && this.state.otherPackageList.length> 0 )? this.state.otherPackageList.map(
												 hl =><>
	          	<div class="seller_info_flex">
	          		<div class="seller_model_bx align-left">
	          		<a onClick={(e)=> this.handleClickPackageOptions(e, hl._id)}  >	<h5>₹  {hl.basePrice}</h5> <p>{hl.packageUSP}</p></a>
	          			
	          		</div>
	          		<div class="seller_model_bx align-left">
	          		<a onClick={(e)=> this.handleClickPackageOptions(e, hl._id)}  >		<h4>{hl.type}</h4></a>
	          		</div>
	          		<div class="seller_model_bx align-left">
	          		<a onClick={(e)=> this.handleClickPackageOptions(e, hl._id)}  >		<h4>{hl.vd[0].name}</h4></a>
	          			<div class="sallerstar_rate">
	          				<ul>

							  {( Math.ceil(hl.review) == 5?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(hl.review) == 4?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(hl.review) == 3?  (<><li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(hl.review) == 2?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(hl.review) == 1?  (<><li class="active"><i class="fas fa-star"></i></li>
														<li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
								  {(  Math.ceil(hl.review) == 0?  (<><li><i class="fas fa-star"></i></li>
														<li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
							
	          					
	          					<li><strong>{hl.review} Rating</strong></li>
	          				</ul>
	          			</div>
	          			<p>in the last 12 months ({hl.total_reviews} total ratings)</p>
	          		</div>
	          	</div>
				  </>):""}

	          	
	          
	          	
          	</div>
          </div>
        </div>   
      </div>
      <button type="button" class="close closecustom--css" data-dismiss="modal">&times;</button>
    </div>
    </div>
     


			<div class="modal" id="myModal01">
    <div class="modal-dialog modal-lg mycustom_model_postion">
      <div class="modal-content">        
      
        <div class="modal-body seller_info--model">
          <div class="seller_info_inner">
          	<div class="seller_info_flex_perent  small_padding_top small_margin_bottom">
	          	<div class="seller_namedetail_flex">
	          		<div class="seller_namedetail_left">
	          			<h2>{this.state.vendorName}</h2>
	          			<h3>{this.state.vendorCompanyName}</h3>
	          		</div>
	          		<div class="seller_namedetail_right">
		          		<div class="seller_model_bx align-left">
		          			<div class="sallerstar_rate">
		          				<ul>
								  {( Math.ceil(this.state.pkreview) == 5?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(this.state.pkreview) == 4?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(this.state.pkreview) == 3?  (<><li class="active"><i class="fas fa-star"></i></li>
								  <li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(this.state.pkreview) == 2?  (<><li class="active"><i class="fas fa-star"></i></li>
	          					<li class="active"><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
														{(  Math.ceil(this.state.pkreview) == 1?  (<><li class="active"><i class="fas fa-star"></i></li>
														<li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
								  {(  Math.ceil(this.state.pkreview) == 0?  (<><li><i class="fas fa-star"></i></li>
														<li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li>
								  <li><i class="fas fa-star"></i></li>
	          					<li><i class="fas fa-star"></i></li></>):(<></>) )}
		          					<li><strong>{this.state.pkreview} Rating</strong></li>
		          				</ul>
		          			</div>
		          			<p>in the last 12 months</p>
		          		</div>
	          		</div>
	          	</div>
	          	<p class="content_of_saller_name_datils">{this.state.vendorTagLine}</p>
          	</div>
          </div>
        </div>   
      </div>
      <button type="button" class="close closecustom--css" data-dismiss="modal">&times;</button>
    </div>
     </div>
    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default PackageOptions;
