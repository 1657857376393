import React from 'react'
import SocialLogin from 'react-social-login'

class SocialButton extends React.Component {
 
    render() {
        return (
            <a class="green_button logs_fb" style={{"color":"white !important"}}  onClick={this.props.triggerLogin} {...this.props} >
                                   <i class="fab fa-facebook-f"></i>&nbsp; &nbsp;&nbsp; Login with Facebook
            </a>
           
        );
    }
}
 
export default SocialLogin(SocialButton);