import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import AuthService from '../../services/AuthService'
import ProfileService from '../../services/ProfileService';
import Form from "react-validation/build/form";
import SocialButtonVerify from './SocialButtonVerify'
import { GoogleLogin } from 'react-google-login';
import qs from 'qs';
import {GOOGLE_CLIENT_ID, FB_APP_ID,IMAGE_BASE_URL,PROFILE_IMAGE_BASE_URL} from '../../constants'

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
	this.handleRemoveWishList = this.handleRemoveWishList.bind(this);
	this.updateDetails = this.updateDetails.bind(this);
	this.updatePassword  = this.updatePassword.bind(this);
	this.onChange = this.onChange.bind(this);
	this.onChangeProfilePic = this.onChangeProfilePic.bind(this);
    this.state = {
	profileImageUrl : "images/user-account.jpg",	
	userLoggedIn :  AuthService.isUserLoggedIn(),
	userName : AuthService.getCurrentUserName(),
	userId : AuthService.getCurrentUserId(),
	noBookings : false,
	noWishList : false,
	noReview : false,
	gmailVerified : false,
	facebookVerified : false,
	isEmailVerified : false,
	isPhoneVerified : false,
	passwordExist : false,
	updated : false,
	updatedPassword : false,
    modal: false,
  };
  
}


  componentDidMount() {
    if(this.state.userLoggedIn){
		//alert("inn"+this.state.userId)
		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			//alert(response.data.profileImageUrl)
			if(response.data.profileImageUrl!=null && response.data.profileImageUrl!==''){
				this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
			}else{
				if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
					this.setState({"profileImageUrl":response.data.gmailImageUrl});
				}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
					this.setState({"profileImageUrl":response.data.facebookImageUrl});
				}
			}
		
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			if(response.data.firstName!=='User'){
				this.setState({"firstName":response.data.firstName});
			}
			this.setState({"title":response.data.title,
			
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
		//	console.log(response.data)
		  })
	}else{
		this.props.history.push(`/login`)
		window.location.reload();
	}
  }

  handleRemoveWishList(e, id){
	e.preventDefault();
	ProfileService.removeFromWishlist(id,this.state.userId).then(response=>{
		ProfileService.getWishList(this.state.userId).then(response=>{
		    this.setState({"wishList":response.data.data})
			if(response.data.data.length==0){
				this.setState({"noWishList":true})
			  }else{
				this.setState({"noWishList":false})
			  }
		//	console.log(response.data.data)
		  })
	})
  }
  onChange(e){
	if(e.target.name==='country'){
		var letters = /^[A-Za-z\s]+$/;
		if(e.target.value.match(letters) || e.target.value==='' ){
		 this.setState({
			 [e.target.name]: e.target.value
		   });
		 }
	 }else if(e.target.name==='firstName'){
		var letters = /^[A-Za-z\s]+$/;
		if(e.target.value.match(letters) || e.target.value==='' ){
		 this.setState({
			 [e.target.name]: e.target.value
		   });
		 }
	 }else if(e.target.name==='lastName'){
		var letters = /^[A-Za-z\s]+$/;
		if(e.target.value.match(letters) || e.target.value==='' ){
		 this.setState({
			 [e.target.name]: e.target.value
		   });
		 }
	 }else{
		this.setState({
			[e.target.name]: e.target.value
		  });
	 }
  }

  updateDetails(e){
	e.preventDefault();
	var phoneno = /^\d{10}$/;
	if(this.state.phone.length==10 && this.state.phone.match(phoneno)){
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)){
		
		
				let dto = {
				   id : this.state.userId,
				   title : this.state.title, 
				   firstName : this.state.firstName,
				   lastName : this.state.lastName,
				   code : this.state.code,
				   country : this.state.country,
				   email :  this.state.email,
				   phone : this.state.phone
				}
				ProfileService.updateUserDetails(dto).then(response=>{
					//alert(response.data)
					let user = JSON.parse(localStorage.getItem('userRenokWeb'));
					user.firstName = this.state.firstName;

					localStorage.setItem("userRenokWeb", JSON.stringify(user));
					alert("Account Details Updated")
					window.location.reload();
				    this.setState({"updated":true,"userName":this.state.firstName});

				})
			
		}else{
                alert("Invalid Email Address"); 
		}
	}else{
		alert("Mobile Number Should be 10 Digits");
	}
     
  }

  updatePassword(e){
	e.preventDefault();
	const regPass = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
               // alert(x);
               if (this.state.newPassword.match(regPass)==null || this.state.newPassword.match(regPass) === false) {
                this.setState({ message: "The password should be min 8 characters long , and should include one Capital alphabet, one Small alphabet, one special character and numbers." });
              }else{
				if(this.state.newPassword == this.state.repeatPassword){
					let dto = {
						id : this.state.userId,
						email :  this.state.email,
						phone : this.state.phone,
						passwordExist : this.state.passwordExist,
						currentPassword : this.state.currentPassword,
						newPassword : this.state.newPassword
					 }
					ProfileService.updatePassword(dto).then(response=>{
						if(response.data.message=='User Password updated'){
							this.setState({"updatedPassword":true,"currentPassword":"","newPassword":"","repeatPassword":""});
						}else{
							alert(response.data.message);
						}
						
					})
				}else{
				  alert("Password and Confirm Password are not same.")
				}
			  }
	
	
  }

  handleGoogleLogin(response){
	//console.log(response)
	if(response!=null){
	const signInRequest = {
	  email : this.state.email,
	  accessToken : response.accessToken,
	  lastName : response.profileObj.familyName,
	  firstName : response.profileObj.givenName,
	  imageUrl : response.profileObj.imageUrl,
	  googleId : response.profileObj.googleId
	}
	AuthService.gmailSignIn(signInRequest).then(
	  () => {
		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			if(response.data.profileImageUrl !=null && response.data.profileImageUrl!==''){
				this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
			}else{
				if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
					this.setState({"profileImageUrl":response.data.gmailImageUrl});
				}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
					this.setState({"profileImageUrl":response.data.facebookImageUrl});
				}
			}
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			this.setState({"title":response.data.title,
			"firstName":response.data.firstName,
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
		//	console.log(response.data)
		  })
	  },
	  error => {
		const resMessage =
		  (error.response &&
		  error.response.data &&
		  error.response.data.message) ||
		  error.message ||
		  error.toString();

		this.setState({
		  loading: false,
		  message: resMessage
		});
	  }
	)
  }
  }

  handleFBLogin(response){

	//console.log(response)
	const signInRequest = {
	  fbId : response.profile.id,
	  email : this.state.email,
	  firstName : response.profile.firstName,
	  lastName : response.profile.lastName,
	  imageUrl : response.profile.profilePicURL,
	  accessToken : response._token.accessToken
	}
	AuthService.faceBookSignIn(signInRequest).then(
	  () => {
		ProfileService.getUserDetails(this.state.userId).then(response=>{
			//alert(response.data.email)
			if(response.data.profileImageUrl !=null && response.data.profileImageUrl!==''){
				this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
			}else{
				if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
					this.setState({"profileImageUrl":response.data.gmailImageUrl});
				}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
					this.setState({"profileImageUrl":response.data.facebookImageUrl});
				}
			}
			
			if(response.data.googleId !=null){
				this.setState({"gmailVerified":true});
			}
			if(response.data.facebookId !=null){
				this.setState({"facebookVerified":true});
			}
			if(response.data.isEmailVerified){
				this.setState({"isEmailVerified":true});
			}
			if(response.data.isPhoneVerified){
				this.setState({"isPhoneVerified":true});
			}
			if(response.data.password !=null && response.data.password!==''){
				this.setState({"passwordExist":true});
			}
			this.setState({"title":response.data.title,
			"firstName":response.data.firstName,
			"lastName":response.data.lastName,
			"code":response.data.code,
			"country":response.data.country,
			"email":response.data.email,
			"phone":response.data.phone});
		//	console.log(response.data)
		  })
	  },
	  error => {
		const resMessage =
		  (error.response &&
			error.response.data &&
			error.response.data.message) ||
		  error.message ||
		  error.toString();

		this.setState({
		  loading: false,
		  message: resMessage
		});
	  }
	)

  }

  onChangeProfilePic(event){
	 this.setState({"profileUploading":true})
	  const formData = new FormData();
      formData.append('id',this.state.userId);
	  formData.append('profileImage',event.target.files[0]);
	  formData.append('profileImageName',event.target.files[0].name);
	  ProfileService.updateProfile(formData).then(
        response => {
			if (response.data.responseCode == 'SUCCESS') {
				alert("Profile Image Updated.");
				ProfileService.getUserDetails(this.state.userId).then(response=>{
					//alert(response.data.email)
					if(response.data.profileImageUrl !=null && response.data.profileImageUrl!==''){
						this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
					}
					this.setState({"profileUploading":false})
				});
			
			}else{
				alert(response.data.message);
			}

        });
  }

  render() {
	const responseGoogle = (response) => {
        this.handleGoogleLogin(response)
      }
      const handleSocialLogin = (user) => {
        this.handleFBLogin(user)
      }

      const handleSocialLoginFailure = (err) => {
        console.error(err)
      }
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>

	<section class="same-section user_accountP padding_top padding_bottom">
	    	<div class="container">
	    		<div class="user_accountP_perent">
	    			<div class="user_accountP_left">
	    				<div class="userac_tabsimage_bx_main">
		    				<div class="user_image_side">
		    					<div class="user_image_account">
		    						<img src={this.state.profileImageUrl} style={{"border-radius":"50%"}}  alt="Image_Icon"/>
									<div class="profilePicEdit">
									           <i class="fas fa-camera"></i>
									</div>
									<input type="file" id="imgupload" name="profilePic" style={{"display":"none"}} onChange={this.onChangeProfilePic}  /> 
									{( this.state.profileUploading == true ?  (<div style={{"color":"#55aeb3"}}><p>Uploading....</p></div>):(<></>) )}
									
		    					</div>
		    					<h5>{this.state.userName}</h5>
								<h6><a class="nav-link"  href="/myProfile" className="greenlink_text">Edit User</a></h6> 
		    				</div>
		    				
		    				<div class="user_account_table_menu">
		    					<ul class="userac_tabs nav nav-tabs" role="tablist">
		          					<li class="nav-item">
				                        <a class="nav-link" href="/myBookings">
				                        	<img src="images/usericon01.svg" alt="Image-Icon"/><span class="boktitle"> Bookings</span> 
				                        	
				                        </a>
				                    </li>
				                    <li class="nav-item">
				                      <a class="nav-link" href="/myReviews">
				                      	<img src="images/usericon02.svg" alt="Image-Icon"/><span class="boktitle"> Reviews</span> 
				                      
				                      </a>
				                  	</li>
				                    <li class="nav-item">
				                      <a class="nav-link" href="/myWishlist">
				                      	<img src="images/usericon03.svg" alt="Image-Icon"/><span class="boktitle"> Wishlist</span> 
				                      	
				                      </a>
				                  </li>
				                    <li class="nav-item">
				                      <a class="nav-link active"  href="/myProfile">
				                      	<img src="images/usericon04.svg" alt="Image-Icon"/><span class="boktitle"> Settings</span> 
				                      	<i class="fas fa-chevron-right"></i>
				                      </a>
				                  	</li>
		    					</ul>
		    				</div>
	    				</div>
	    				{/* <div class="bestfood_account">	    					
				    		<div class="planning_four_bx">
		    					<div class="planning_inner box_side_one background_grees">
		    						<h6>Best Food & Must Eats</h6>
		    						<p>Discover Local Cuisines</p>
		    						<a href="#" class="white_text_border explor_more_butt">View More</a>
		    					</div>
	    					</div>
	    				</div> */}
	    			</div>
	    			<div class="user_accountP_right">
		                <div class="tab-content">
		                 

		                   



<div id="menu3" class="tab-pane active">
<div class="ac_info_userside">
<div class="panel-group accordion_Ppolicy" id="accordion_Ppolicy_one" role="tablist" aria-multiselectable="true">

<div class="panel panel-default">
<div class="panel-heading" role="tab" id="heading-2">
<h4 class="panel-title">
<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_Ppolicy_one" href="#collapse-2" aria-controls="collapse-2">							                     
<h2 class="userac_right_title">Account Information</h2>
</a>
</h4>
</div>
<Form class="login-form" onSubmit={this.updateDetails} ref={c => {this.form = c;}}>
<div id="collapse-2" class="panel-collapse collapse show in" role="tabpanel" aria-labelledby="heading-2">
<div class="panel-body">
<div class="account_info_form_main">
<div class="acdetail_title">
<p>This information is used to autofill your details to make it quicker for you to book. Your details will be stored securely and won’t be shared publicly.</p>
<div class="formfuild_perent small_padding_top">
	
<div class="form_fulidrow">
<div class="row">
<div class="col-lg-2 pb-4">
<label class="form_fuild_label">Title</label>
<div class="form_icon_postion_fuild">
<select class="filter_fulid" required name="title" value={this.state.title}  onChange={this.onChange}>
			                    						<option value="">select</option>
														<option value="Mr">Mr</option>
														<option value="Mrs">Mrs</option>
														<option value="Miss">Miss</option>
</select>
<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
</div>
</div>

<div class="col-lg-5 pb-4">
<label class="form_fuild_label">First Name (as on Travel Document)</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" required name="firstName" value={this.state.firstName}  onChange={this.onChange} placeholder="First Name"/>
</div>
</div>

<div class="col-lg-5 pb-4">
<label class="form_fuild_label">Family Name (as on Travel Document)</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" required name="lastName" value={this.state.lastName}  onChange={this.onChange} placeholder="Family Name"/>
</div>
</div>
</div>
</div>

<div class="form_fulidrow">
<div class="row">
<div class="col-lg-12 pb-4">
<label class="form_fuild_label">Country/Region of Travel Document</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" required name="country" value={this.state.country}  onChange={this.onChange} placeholder="Country Name"/>

</div>
</div>
</div>
</div>

<div class="form_fulidrow">
<div class="row">
<div class="col-lg-2 pb-4">
<label class="form_fuild_label">Country Code</label>
<div class="form_icon_postion_fuild">
<input type="number" class="filter_fulid" min="1" required name="code" value={this.state.code}  onChange={this.onChange} placeholder="Code"/>

</div>
</div>

<div class="col-lg-6 pb-4">
<label class="form_fuild_label">Phone Number (Incase of Emergency)</label>
<div class="form_icon_postion_fuild">
<input type="number" class="filter_fulid" min="1" required name="phone" value={this.state.phone}  onChange={this.onChange} placeholder="Phone Number"/>

</div>
</div>
</div>
</div>

<div class="form_fulidrow">
<div class="row">								                    				
<div class="col-lg-12 pb-4">
<label class="form_fuild_label">Email (To Receive Coupon)</label>
<div class="form_icon_postion_fuild">
{( this.state.email == null || '' ?  (<input type="email" class="filter_fulid" required name="email" value={this.state.email}  onChange={this.onChange} placeholder="Email Address"/>):(<input type="email" class="filter_fulid" required name="email" value={this.state.email} disabled  onChange={this.onChange} placeholder="Email Address"/>) )}

</div>
</div>
</div>
</div>

<div class="form_fulidrow">
<div class="row">								                    				
<div class="col-lg-12 pb-4">
<div class="ac_savebutton">
<button type="submit" class="btn btn-primary" >Save</button>
{( this.state.updated == true ?  (<div style={{"color":"#55aeb3"}}><p>Account Details Updated</p></div>):(<></>) )}
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</Form>
</div>

<Form class="login-form" onSubmit={this.updatePassword} ref={c => {this.form = c;}}>
<div class="panel panel-default">
<div class="panel-heading" role="tab" id="heading-3">
<h4 class="panel-title">
<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_Ppolicy_one" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
<h2 class="userac_right_title">Change Password</h2>
</a>
</h4>
</div>
<div id="collapse-3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-3">
<div class="panel-body">							                    
<div class="account_info_form_main">
<div class="acdetail_title">
<p>Password must be 8-20 characters long and contain at least 1 letter and 1 number</p>
<div class="formfuild_perent small_padding_top">

{(this.state.passwordExist == false ? (<></>):(
<>	
<div class="form_fulidrow">
<div class="row">
<div class="col-lg-5 pb-4">
<label class="form_fuild_label">Current Password</label>
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" name="currentPassword" value={this.state.currentPassword} onChange={this.onChange} placeholder="Enter your current password"/>
</div>
</div>
</div>
</div>
</>) )}




<div class="form_fulidrow">
<div class="row">
<div class="col-lg-12">
<label class="form_fuild_label">New Password (password must be 8 - 20 characters long and contain at least 1 letter and 1 number)</label>
</div>
<div class="col-lg-5 pb-4">
<div class="form_icon_postion_fuild">
<input type="password" class="filter_fulid" name="newPassword" value={this.state.newPassword} onChange={this.onChange} placeholder="Enter new password"/>
</div>
</div>								                    				
<div class="col-lg-5 pb-4">
<div class="form_icon_postion_fuild">
<input type="text" class="filter_fulid" name="repeatPassword" value={this.state.repeatPassword} onChange={this.onChange} placeholder="Confirm password"/>
</div>
</div>
</div>
</div>
<div class="form_fulidrow small_padding_bottom">
<div class="row">								                    				
<div class="col-md-2">
<div class="ac_savebutton">
<button type="submit" class="btn btn-primary" >Save</button>
{( this.state.updatedPassword == true ?  (<div style={{"color":"#55aeb3"}}><p>Password Updated</p></div>):(<></>) )}
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>
</Form>
<div class="panel panel-default">
<div class="panel-heading" role="tab" id="heading-4">
<h4 class="panel-title">
<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_Ppolicy_one" href="#collapse-4" aria-expanded="false" aria-controls="collapse-3">
<h2 class="userac_right_title">Manage Login Methods</h2>
</a>
</h4>
</div>
<div id="collapse-4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="heading-4">
<div class="panel-body">						                  								                    
<div class="account_info_form_main">
<div class="acdetail_title">
<p>Log into Renok with your email address, phone number, or social media accounts</p>
<div class="formfuild_perent small_padding_top">
<div class="form_fulidrow">
<div class="row">
<div class="col-lg-6 pb-4">
<label class="form_fuild_label">Email</label>
<div class="form_icon_postion_fuild_flex">
<div class="form_icon_postion_flex_left">
<span class="email_ad_title">Email address</span>
<span class="email_ad_address">{this.state.email}</span>
</div>


{(this.state.isEmailVerified == true ? (<><div class="form_icon_postion_flex_right align-right" style={{"color":"green"}}>Verified</div></>):(<><div class="form_icon_postion_flex_right align-right" >
	{/* <a href="#" class="greenlink_text">Verify</a> */}
	</div></>) )}	

</div>
</div>
<div class="col-lg-6 pb-4">
<label class="form_fuild_label">Phone Number</label>
<div class="form_icon_postion_fuild_flex span_not_padding">
<div class="form_icon_postion_flex_left">
<span class="email_ad_title">Phone Number</span>
<span class="email_ad_address">{this.state.phone}</span>
</div>

{(this.state.isPhoneVerified == true ? (<><div class="form_icon_postion_flex_right align-right" style={{"color":"green"}}>Verified</div></>):(<><div class="form_icon_postion_flex_right align-right" >
	{/* <a href="#" class="greenlink_text">Verify</a> */}
	
	</div></>) )}	

</div>
</div>
</div>
</div>
<div class="form_fulidrow">
<div class="row">
<div class="col-lg-6 pb-4">
<label class="form_fuild_label">Social Media</label>
<div class="form_icon_postion_fuild_flex span_not_padding">
<div class="form_icon_postion_flex_left">
<span class="email_ad_title">Google</span>
</div>


{(this.state.gmailVerified == true ? (<><div class="form_icon_postion_flex_right align-right" style={{"color":"green"}}>Verified</div></>):(<><div class="form_icon_postion_flex_right align-right" >
<GoogleLogin 
                                        clientId={GOOGLE_CLIENT_ID}
                                        render={renderProps => (
											<a href="#" class="greenlink_text" onClick={renderProps.onClick} disabled={renderProps.disabled} >Verify</a>
    
                                        )}
                                        buttonText="Login"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                   />
	
	</div></>) )}	


</div>
</div>
<div class="col-lg-6 pb-4">
<label class="form_fuild_label"></label>
<div class="form_icon_postion_fuild_flex span_not_padding">
<div class="form_icon_postion_flex_left">
<span class="email_ad_title">Facebook</span>
</div>


{(this.state.facebookVerified == true ? (<><div class="form_icon_postion_flex_right align-right" style={{"color":"green"}}>Verified</div></>):(<><div class="form_icon_postion_flex_right align-right" ><SocialButtonVerify
                                        provider='facebook'
                                        appId={FB_APP_ID}
                                        onLoginSuccess={handleSocialLogin}
                                        onLoginFailure={handleSocialLoginFailure}
                                      >
                                        Verify
                                      </SocialButtonVerify> 	
                                  </div></>) )}	

</div>
</div>

</div>
</div>
</div>
</div>
</div>
</div>
</div>
</div>

</div> 
</div>
</div>
			    		</div>
	    			</div>
	    		</div>
	    	</div>
	    </section>


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default MyProfile;
