import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import { APP_BASE_URL} from '../../constants';
import { API_BASE_URL} from '../../constants';
import AuthService from '../../services/AuthService'
import Form from "react-validation/build/form";
import Helmet from 'react-helmet';

class TravelInfo extends React.Component {
  constructor(props) {

    super(props);
	this.onChange = this.onChange.bind(this);
	this.handleSaveBooking = this.handleSaveBooking.bind(this);
    this.state = {
	userId : AuthService.getCurrentUserId(),
	packageId : this.props.match.params.id,
    modal: false,
  };
  
}


  componentDidMount() {

	
	var bookingDetails = localStorage.getItem("bkd"+this.props.match.params.id);
    if(bookingDetails!=null){
		this.setState({"bookingDetails":JSON.parse(bookingDetails)});
		var bkd = JSON.parse(bookingDetails);
		//alert(bkd.dateExcludeFlag)
		var sd = bkd.dateSelected;
		if(bkd.dateExcludeFlag){
			var sdr = bkd.dateSelected.split("T");
			sd = sdr[0];
			var sd3 = sd.split("-");
			var day = sd3[2];
			var month = sd3[1];
			if(month==1){
               month ="January";
			}else if(month==2){
				month ="February";
			}
			else if(month==3){
				month ="March";
			}
			else if(month==4){
				month ="April";
			}else if(month==5){
				month ="May";
			}else if(month==6){
				month ="June";
			}else if(month==7){
				month ="July";
			}else if(month==8){
				month ="Aug";
			}else if(month==9){
				month ="September";
			}else if(month==10){
				month ="October";
			}else if(month==11){
				month ="November";
			}else if(month==12){
				month ="December";
			}
			var year = sd3[0]
			//alert("Date:"+day+" "+month+" "+year)
			sd =  day+" "+month+" "+year;
		}
		
		
		this.setState({
			"packageId" : bkd.packageId,
			"packageName" : bkd.packageName,
			"participationDate":sd,
			"activityId" : bkd.activityId,
			"totalPaymentAmount" : bkd.totalPaymentAmount,
			"noOfAdults":bkd.noOfAdults,
			"noOfChilds":bkd.noOfChilds,
			"packageOptions":bkd.packageOptions,
			"basePrice" : bkd.basePrice,
			"childPrice" : bkd.childPrice,
			"totalAddOn" : bkd.totalAddOn,
			"unitprice" : bkd.unitprice,
			"unitpriceChild" : bkd.unitpriceChild,
			"totalPriceAdult" : bkd.totalPriceAdult,
			"totalPriceChild" : bkd.totalPriceChild,
			"totalPaymentAmount" :  bkd.totalPaymentAmount,
			"payementStatus":bkd.payementStatus,
			"bookingStatus":bkd.bookingStatus
		});
	}
   

  }
  onChange(e) {
	if(e.target.name==='country'){
		var letters = /^[A-Za-z\s]+$/;
		if(letters.test(e.target.value) || e.target.value==='' ){
		 this.setState({
			 [e.target.name]: e.target.value
		   });
		 }
	 }else if(e.target.name==='firstName'){
		var letters = /^[A-Za-z\s]+$/;
		if(letters.test(e.target.value) || e.target.value==='' ){
		 this.setState({
			 [e.target.name]: e.target.value
		   });
		 }
	 }else if(e.target.name==='lastName'){
		var letters = /^[A-Za-z\s]+$/;
		if(letters.test(e.target.value) || e.target.value==='' ){
		 this.setState({
			 [e.target.name]: e.target.value
		   });
		 }
	 }else{
		this.setState({
			[e.target.name]: e.target.value
		  });
	 }
	
  }

  
 
  

  handleSaveBooking(e){
	 // alert("inn");
	 e.preventDefault();
	 var phoneno = /^\d{10}$/;
	 if(this.state.phone.length==10 && this.state.phone.match(phoneno)){
		 if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)){
			let travellerDetail = {
				title: this.state.title,
				firstName: this.state.firstName,
				lastName : this.state.lastName,
				country : this.state.country,
				code : this.state.code,
				phone : this.state.phone,
				email : this.state.email
			}
			//TODO
			var password     = '';
			var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
			var charactersLength = characters.length;
			for ( var i = 0; i < 8; i++ ) {
			  password += characters.charAt(Math.floor(Math.random() * 
				   charactersLength));
			}
			const userCheck = {
			  email : this.state.email,
			  password : password
			}
			AuthService.checkOrCreatUserByEmail(userCheck).then(
			  response => {
				if(response.data.message=="Success"){
				//   localStorage.setItem("userRenokWeb", JSON.stringify(response.data.data));
				  let dto ={
					  userId:response.data.data.id,
					  activityId:this.state.activityId,
					  packageId:this.state.packageId,
					  noOfAdults:this.state.noOfAdults,
					  noOfChilds:this.state.noOfChilds,
					  participationDate:this.state.participationDate,
					  packageOptions:this.state.packageOptions,
					  travellerDetail:travellerDetail,
					  payementStatus:this.state.payementStatus,
					  bookingStatus:this.state.bookingStatus,
					  amount:this.state.totalPaymentAmount
					  }
					  this.createBooking(dto);
				}
			  }
			);
		 }else{
			 alert("Invalid Email Address");
		 }
		}else{
			alert("Phone Number Should be of 10 Digits")
		}		 
	 
	  

	 

  }

  async createBooking(dto){
	const data = await fetch(`${API_BASE_URL}api/createRazorOrder/`, { method: 'POST',headers: { 'Content-Type': 'application/json' },body:JSON.stringify(dto) }).then((t) =>
	t.json());
	//console.log(data)
		const options = {
			key: 'rzp_live_1BCwHDySeu7Vvy',
			currency: data.currency,
			amount: data.amount.toString(),
			order_id: data.id,
			name: 'Renok Adventure',
			description: 'Payment for Renok Adventure Booking',
			image: `${APP_BASE_URL}/images/logo.svg`,
			handler: function (response) {
				//alert(JSON.stringify(response));
				if(response.razorpay_payment_id!=null){
					//localStorage.removeItem("bkd"+this.props.match.params.id);
					    let dto = {
							id : data.bookingOrderId,
							orderId : data.orderId,
							transactionId : response.razorpay_order_id,
							paymentType : 'Razorpay',
							payedAmount : data.amount,
							paymentStatus : 'Paid',
							razorpayPaymentId : response.razorpay_payment_id,
							razorpayOrderId : response.razorpay_order_id,
							razorpaySignature : response.razorpay_signature
						}
                         ProfileService.updateBooking(dto).then(response=>{

						
									   window.location.href=`${APP_BASE_URL}/myBookings`;
					
							//this.props.history.push("/myBookings");
							//localStorage.removeItem("bkd"+this.props.match.params.id);
						 })
				}
			}
		}
		const paymentObject = new window.Razorpay(options)
		paymentObject.open()
  }

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
	<Helmet>
    <script src="https://checkout.razorpay.com/v1/checkout.js" 
    type="text/javascript" />
    </Helmet>
    <div class="main_all">
    <Header></Header>
	<Form class="login-form" onSubmit={this.handleSaveBooking} ref={c => {this.form = c;}}>
	<section class="same-section paymentpage_top_main bg-gray padding_top padding_bottom">
	    	<div class="container">
	    		<div class="payment_page_main_flex" style={{"margin-top":"30px"}}>
				
	    			<div class="payment_page_left_main">
	    				<div class="payment_box_main travellerinfo_payment">
	    					<div class="payment_bx_title">
	    						<h3 class="smal_white_bold_title"><span>1</span>Traveler’s Information</h3>
	    					</div>
    						<div class="payment_bx_inner">	    							
	                    		<div class="formfuild_perent">
	                    			<div class="form_fulidrow small_padding_bottom">
	                    				<div class="row">
		                    				<div class="col- col-md-2">
			                    				<label class="form_fuild_label">Title</label>
			                    				<div class="form_icon_postion_fuild">
			                    					<select class="filter_fulid" required name="title" value={this.state.title}  onChange={this.onChange}>
			                    						<option value="">Please select</option>
														<option value="Mr">Mr</option>
														<option value="Mrs">Miss</option>
			                    					</select>
			                    					<span class="down_arrow_filter"><i class="fas fa-chevron-down"></i></span>
			                    				</div>
		                    				</div>

		                    				<div class="col- col-md-5">
			                    				<label class="form_fuild_label">First Name (as on Travel Document)</label>
			                    				<div class="form_icon_postion_fuild">
			                    					<input type="text" class="filter_fulid" required name="firstName" value={this.state?.firstName}  onChange={this.onChange} 
													placeholder="Please Enter"/>
			                    				</div>
		                    				</div>

		                    				<div class="col- col-md-5">
			                    				<label class="form_fuild_label">Family Name (as on Travel Document)</label>
			                    				<div class="form_icon_postion_fuild">
			                    					<input type="text" class="filter_fulid" required name="lastName" value={this.state.lastName}  onChange={this.onChange} placeholder="Please Enter"/>
			                    				</div>
		                    				</div>
	                    				</div>
	                    			</div>

	                    			<div class="form_fulidrow small_padding_bottom">
	                    				<div class="row">
		                    				<div class="col- col-md-6">
			                    				<label class="form_fuild_label">Country/Region of Travel Document</label>
			                    				<div class="form_icon_postion_fuild">
												<input type="text" class="filter_fulid" required name="country" value={this.state.country}  onChange={this.onChange} placeholder="Please Enter"/>
			                    				</div>
		                    				</div>
	                    				</div>
	                    			</div>

	                    			<div class="form_fulidrow small_padding_bottom">
	                    				<div class="row">
		                    				<div class="col- col-md-4">
			                    				<label class="form_fuild_label">Country Code</label>
			                    				<div class="form_icon_postion_fuild">
												<input type="number" min="1" class="filter_fulid" required name="code" value={this.state.code}  onChange={this.onChange} placeholder="Please Enter"/>
			                    				</div>
		                    				</div>

		                    				<div class="col- col-md-6">
			                    				<label class="form_fuild_label">Phone Number (Incase of Emergency)</label>
			                    				<div class="form_icon_postion_fuild">
												<input type="number" min="1" class="filter_fulid" required name="phone" value={this.state.phone}  onChange={this.onChange} placeholder="Please Enter"/>
			                    				</div>
		                    				</div>
	                    				</div>
	                    			</div>

	                    			<div class="form_fulidrow small_padding_bottom">
	                    				<div class="row">								                    				
		                    				<div class="col- col-md-6">
			                    				<label class="form_fuild_label">Email (To Receive Coupon)</label>
			                    				<div class="form_icon_postion_fuild">
			                    					<input type="email" class="filter_fulid" required name="email" value={this.state.email}  onChange={this.onChange} placeholder="mopasa@king.com"/>
			                    				</div>
		                    				</div>
	                    				</div>
	                    			</div>

	                    			{/* <div class="form_fulidrow">
	                    				<div class="row">								                    				
		                    				<div class="col-md-2">
		                    					<div class="ac_savebutton">
		                    						<a href="#" class="acsave_btn btn btn-primary">Save</a>
		                    					</div>
		                    				</div>
	                    				</div>
	                    			</div> */}
	                    		</div>
    						</div>
	    				</div>

	    				

	    				
	    			</div>

               
	    			<div class="payment_page_right_main">
	    				<div class="payment_right_prices">
	    					<h4>{this.state.packageName}</h4>
							<h4>{this.state.participationDate}</h4>
	    					<div class="row dateand_units">
	    						<div class="package_child">Adults</div>
	    						<div class="package_child_number align-right">{this.state.noOfAdults} x Person</div>
	    						
	    					</div>
							<div class="row dateand_units">
	    						
	    						<div class="package_child">Childs</div>
	    						<div class="package_child_number align-right">{this.state.noOfChilds} x Person</div>
	    					</div>
	    					{/* <div class="viewpackage_detail">
	    						<a href="#">View Package Details <span><i class="fas fa-angle-down"></i></span></a>
	    					</div> */}
	    				</div>

	    				<div class="payment_page_right_main_inn">
		    				<div class="totalpaymet_mini_bx_perent">	    					
		    					<div class="totalpaymet_mini_bx">
		    						<div class="row dateand_units">
		    							<div class="package_child">Total</div>
		    							<div class="package_child_number align-right">₹ {this.state.totalPaymentAmount}</div>
		    							
		    						</div>
									<div class="row dateand_units">
		    							
		    							<div class="package_child">Payment Amount</div>
		    							<div class="package_child_number align-right">₹ {this.state.totalPaymentAmount}</div>
		    						</div>
		    					</div>
		    				</div>
							{/* <div class="orign_caret_with_img">
								<div class="row">
	    							<div class="col-md-9">
	    								Get <span>24</span> credits for this booking
	    							</div>
	    							<div class="col-md-3">
	    								<img src={APP_BASE_URL + '/images/payment_icon.svg'}  alt="Image-Icon"/>
	    							</div>
								</div>								
		    				</div> */}
						</div>
	    			</div>

					
	    		</div>

				<div class="payment_box_main pay_box">
    						<div class="payment_bx_inner">
    							<div class="payment_privecypolicy">
    								<div class="row">
    									<div class="col-md-6">
    										
    										<p class="agrree">By clicking Pay Now, you agree that you have read and understood our <a href="/privacy-policy"> Terms & Conditions </a> and <a href="/privacy-policy"> Cancellation Policy</a></p>
    									</div>
    									<div class="col-md-6 align-right">
										<button type="submit" class="btn btn-primary" >Pay Now</button>
    									
    									</div>
    								</div>
    							</div>
    						</div>
    					</div>
	    	</div>
	    </section>


		</Form>
    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default TravelInfo;
