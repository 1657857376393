import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';

class InviteFriend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    modal: false,
  };
  
}


  componentDidMount() {
    
  }


  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    <div class="main_all">
    <Header></Header>

    <section class="same-section invite_your_friendsB innerpage_banner">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
		    		<h1 class="inner_banner_heding text-align">Invite your friends to Renok</h1>
		    		<p class="top_main_Sheding text-align reward">After your friend signs up and completes an activity, you’ll get a ₹250 reward!</p>
	    		</div>
	    	</div>
	    </section>

	    <section class="same-section what_are_renok text-align padding_top padding_bottom bg-gray referal-code">
	    	<div class="container">
	    		
	    		<div class="share_social_friends">
	    			<p>Your Referral Code</p>
	    			<div class="copylinkside_fuild">
	    				<input class="headersearh" type="text" name="" placeholder="" />
	    				<button class="searchhead_but">Copy Link</button>
	    			</div>
	    			<div class="common_block secmarginT">
                  <a href="#" class="green_main_button">Invite Now</a>
               </div>
	    			<div class="copylinkside_fuild social_icside">
	    				<div class="socialsd_bx">
	    					<span>Share</span>
	    				</div>
	    				<div class="socialsd_bx">
	    					<button type="button" class="social_friend_common socialare_facebook"><i class="fab fa-facebook-f"></i>&nbsp; Facebook</button>
	    				</div>
	    				<div class="socialsd_bx">
	    					<button type="button" class="social_friend_common socialare_messanger"><i class="fab fa-facebook-messenger"></i>&nbsp; Messenger</button>
	    				</div>
	    				<div class="socialsd_bx">
	    					<button type="button" class="social_friend_common socialare_twitter"><i class="fab fa-twitter"></i>&nbsp; Twitter</button>
	    				</div>
	    			</div>
	    		</div>
	    		<div class="same-section small_padding_top search_social_friends">
		    		<div class="searchsocial_friens_inner">	    			
		    				<input class="headersearh" type="text" name="" placeholder="Please enter email address" onfocus="this.placeholder = ''" onblur="this.placeholder = 'Please enter email address'" />
		    				<button class="searchhead_but">Send</button>
		    		</div>
	    		</div>
	    	</div>
	    </section>

	    <section class="same-section how_get_reward text-align padding_top padding_bottom">
	    	<div class="container">
	    		<h2 class="inner_banner_heding small_padding_bottom">How To Get Rewards</h2>
	    		<p class="small_padding_bottom unique-code">Invite friends via email or your unique code</p>
	    		<div class="how_get_reward_flex">
	    			<div class="get_reward_bx">
	    				<div class="imageside_reward">
	    					<img  loading="lazy" src="images/reward-enwalop.svg" alt="Image-Icon"/>
	    				</div>	    				
	    				<div class="contside_reward">
	    					<p>Gift each friend <strong>₹250</strong> as soon as they sign up</p>
	    				</div>
	    			</div>
	    			<div class="get_reward_bx">
	    				<div class="imageside_reward">
	    					<img  loading="lazy" src="images/gift.svg" alt="Image-Icon"/>
	    				</div>	    				
	    				<div class="contside_reward">
	    					<p>After your friend completes an activity, you’ll receive a <strong>₹250</strong> reward!</p>
	    				</div>
	    			</div>
	    		</div>
	    	</div>
	    </section>
	    <section class="same-section yourthe_reward text-align padding_bottom">
	    	<div class="container">
	    		<h2 class="inner_banner_heding small_padding_bottom">Your Rewards</h2>
	    		<div class="yourrewards_side">
	    			<div class="your_rewoard_bx border-cnt">
	    				<span>₹ <span>0</span></span>
	    				<p>Total Earnings</p>
	    			</div>
	    			<div class="your_rewoard_bx border-cnt">
	    				<span>₹ <span>0</span></span>
	    				<p>Still To Use</p>
	    			</div>
	    			<div class="your_rewoard_bx">
	    				<span>₹ <span>0</span></span>
	    				<p>Successful Invites</p>
	    			</div>
	    		</div>
	    	</div>
	    </section>


    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default InviteFriend;
