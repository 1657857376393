import axios from "axios";
import authHeader from './AuthHeader'
import { API_BASE_URL} from '../constants';
import { WEATHER_API_KEY } from '../constants';
const ALLINDUSTRY_API_URL =  `${API_BASE_URL}/getIndustries/`
const GET_OTP_API_URL =  `${API_BASE_URL}/getOtp/`
const ADD_PROFILE_API_URL =  `${API_BASE_URL}/addProfile/`
const GET_INF_API_URL =  `${API_BASE_URL}/getInfluencerById/`
const UPDATE_ACCOUNT_API_URL = `${API_BASE_URL}/updateAccountDetails/`
const UPDATE_BANK_API_URL = `${API_BASE_URL}/updateBankDetails/`
const GETASSIGNEDCAMPAIGNS_API_URL =  `${API_BASE_URL}/getAssignedCampaigns/`
const GETASSIGNEDCAMPAIGNDETAILS_API_URL =  `${API_BASE_URL}/getAssignedCampaignById/`
const UPLOAD_VIDEO_API_URL = `${API_BASE_URL}/uploadVideo/`
const DOWNLOAD_VIDEO_API_URL = `${API_BASE_URL}/downloadVideo/`
const REMOVE_CHANNEL_URL = `${API_BASE_URL}/removeChannel/`
const UPDATE_ASSIGNED_CAMP_STATUS_API_URL =`${API_BASE_URL}/updateAssignedCampaignStatus/`

const TOP_DESTINATIONS = `${API_BASE_URL}/api/destinationlist`
const ACTIVITIES_BY_TYPE =  `${API_BASE_URL}/api/activitylist`
const GET_DESTINATION = `${API_BASE_URL}/api/destination/`
const GET_ACTIVITY = `${API_BASE_URL}/api/activity/`
const ACTIVITIES_BY_DESTINATION_AND_CATEGORY =  `${API_BASE_URL}/api/activitybydestandcategory`
const CATEGORY_LIST = `${API_BASE_URL}/api/activitycategory/`
const NEAREST_DESTINATION_BYID  = `${API_BASE_URL}/api/nearbydestbydestid/`
const STATE_BY_ID =`${API_BASE_URL}/api/stateswithpagination/`
const DEFAULT_PACKAGE_BY_ACTIVITY = `${API_BASE_URL}/api/defaultpackagesbyactivity/?activityId=`
const SIMILAR_ACTIVITIES = `${API_BASE_URL}/api/simailaractivitybyactivityId/?id=`
const GET_BLOG_LIST =`${API_BASE_URL}/api/bloglist`
const GET_BLOG = `${API_BASE_URL}/api/blog/`
const RELATED_BLOG = `${API_BASE_URL}/api/relatedblog/?id=`
const CREATE_RAZOR_ORDER_URL = `${API_BASE_URL}/api/createRazorOrder/`
const ADD_TO_WISHLIST = `${API_BASE_URL}/api/addwishlist/`
const UPDATE_ORDER_URL = `${API_BASE_URL}/api/updateRazorOrder/`
const GET_BOOKINGS_BY_USER = `${API_BASE_URL}/api/bookingorderbyuser?userId=`
const SUBCATEGORY_API =  `${API_BASE_URL}/api/subcategories/?category=`
const ACTIVITIES_SEARCH = `${API_BASE_URL}/api/activitysearch/`
const ALL_ACTIVITIES = `${API_BASE_URL}/api/allactivity/`
const ALL_FAQS = `${API_BASE_URL}/api/faqbytype`
const FAQ_BY_TYPE = `${API_BASE_URL}/api/faqbytype?type=`
const FAQ_TYPE_LIST = `${API_BASE_URL}/api/faqtypes`
const JOB_LIST = `${API_BASE_URL}/api/joblist`
const ALL_DESTINATION = `${API_BASE_URL}/api/nearestdestination`
const GET_DESTINATION_BY_URL = `${API_BASE_URL}/api/destinationbyurl/?url=`
const GET_ACTIVITY_BY_URL = `${API_BASE_URL}/api/activitybyurl/?url=`
const GET_ACTIVITY_URL = `${API_BASE_URL}/api/activityurl/?url=`
const GET_VENDOR_BY_ID =  `${API_BASE_URL}/api/vendor/`
const GET_NON_DEFAULT_PACKAGE_LIST_BY_ACTIVITY = `${API_BASE_URL}/api/nondefaultpackagesbyactivity/`
const GET_PACKAGE_BY_ID = `${API_BASE_URL}/api/package/`
const GET_ALL_STATE_WITH_DESTINATIONS = `${API_BASE_URL}/api/states/findAllWithDestinationsByRegion/?region=`
const GET_BLOG_BY_URL = `${API_BASE_URL}/api/blogbyurl/?url=`
const GET_WISHLIST = `${API_BASE_URL}/api/findbyuserid?userid=`
const REMOVE_FROM_WISHLIST =  `${API_BASE_URL}/api/wishlist/?id=`
const ADD_SUBSCRIPTION = `${API_BASE_URL}/api/addsubscription/`
const UPDATE_USER_DETAILS = `${API_BASE_URL}/api/user/adduser` 
const GET_USER_DETAILS = `${API_BASE_URL}/api/user/` 
const SUB_CAT_BY_NAME = `${API_BASE_URL}/api/subcatbyname/` 
const UPDATE_PASSWORD = `${API_BASE_URL}/api/user/updatePassword` 
const ADD_REVIEW = `${API_BASE_URL}/api/review`
const GET_USER_REVIEWLIST = `${API_BASE_URL}/api/reviewbyuserid?userId=`
const GET_ACTIVITY_REVIEWLIST  = `${API_BASE_URL}/api/reviewbyActivity?activityId=`
const GET_BLOG_COMMENTLIST = `${API_BASE_URL}/api/commentbyBlog?blogId=`
const ADD_COMMENT = `${API_BASE_URL}/api/comment`
const GET_BLOGS_PAGINATION =  `${API_BASE_URL}/api/activeblog`
const GET_BLOGSSEARCH_PAGINATION = `${API_BASE_URL}/api/searchBlog`
const FORGOT_PASSWORD = `${API_BASE_URL}/api/forgotpassword/?email=`
const RESET_PASSWORD = `${API_BASE_URL}/api/resetpassword/?code=`
const VERIFY_EMAIL = `${API_BASE_URL}/api/verifyemail/?code=`
const UNSUB_EMAIL = `${API_BASE_URL}/api/unsubscribe/?email=`
const DESTINATION_BY_STATE = `${API_BASE_URL}/api/destinationByStateId?id=`
const STATEID_BY_NAME  = `${API_BASE_URL}/api/states/findByName/?name=`
const UPDATEPROFILE_API_URL = `${API_BASE_URL}/api/user/uploadprofile`

class ProfileService{

   getDestination(id){
    
    return axios.get(`${GET_DESTINATION}${id}`);

   }

   getDestinationByState(id){
    return axios.get(`${DESTINATION_BY_STATE}${id}`);
   }

   getAllDestinations(){
    return axios.get(`${ALL_DESTINATION}`);
   }
   getActivity(id){
    return axios.get(`${GET_ACTIVITY}${id}`);
   }

    retrieveTopDestinations() {
        return axios.get(`${TOP_DESTINATIONS}?topDestinations=true`);
    }   

    retrievePopularActivities() {
        return axios.get(`${ACTIVITIES_BY_TYPE}`);
    }   

    retrieveTravelInspirationActivities() {
        return axios.get(`${ACTIVITIES_BY_TYPE}?type=inspiration`);
    } 
    retrieveRecommendedActivities() {
        return axios.get(`${ACTIVITIES_BY_TYPE}?type=recommended`);
    }  

    activityByDestination(destination){
        return axios.get(`${ACTIVITIES_BY_DESTINATION_AND_CATEGORY}?destination=${destination}`);
    }

    activitybydestandcategory(destination,categoryId){
        return axios.get(`${ACTIVITIES_BY_DESTINATION_AND_CATEGORY}?destination=${destination}&activityCategory=${categoryId}`);
    }

    getCategoryList(){
        return axios.get(`${CATEGORY_LIST}`);
    }

    nearbydestbydestid(destination){
        return axios.get(`${NEAREST_DESTINATION_BYID}?id=${destination}`);
    }

    getStateById(id){
        return axios.get(`${STATE_BY_ID}${id}`); 
    }

    getDefaultPackageByActivity(id){
        return axios.get(`${DEFAULT_PACKAGE_BY_ACTIVITY}${id}`); 
    }

    similarActivities(id){
        return axios.get(`${SIMILAR_ACTIVITIES}${id}`); 
    }

    getBlogList(){
        return axios.get(`${GET_BLOG_LIST}`); 
    }

    getBlog(id){
        return axios.get(`${GET_BLOG}${id}`); 
    }

    getRelatedBlog(id){
        return axios.get(`${RELATED_BLOG}${id}`); 
    }

retrieveAllIndustries() {
    return axios.get(`${ALLINDUSTRY_API_URL}`,{headers : authHeader()});
}

getOtp(otp){
    return axios.post(`${GET_OTP_API_URL}`,otp,{headers : authHeader()});
}

addProfile(profile){
    //return axios.post(`${ADD_PROFILE_API_URL}`,profile,{headers : authHeader()});
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        'Authorization' : "Bearer "+user.accessToken
      }
    return axios({
        method: 'post',
        url: `${ADD_PROFILE_API_URL}`,
        data: profile,
        headers: headers
    })
}

uploadVideo(upload){
    const user = JSON.parse(localStorage.getItem('user'));
    const headers = {
        'Content-Type': 'application/json',
        'Authorization' : "Bearer "+user.accessToken
      }
    return axios({
        method: 'post',
        url: `${UPLOAD_VIDEO_API_URL}`,
        data: upload,
        headers: headers
    })
}

getInfluencerProfile(id){
    return axios.get(`${GET_INF_API_URL}${id}`,{headers : authHeader()});
}


updateAccountDetails(account){
    return axios.post(`${UPDATE_ACCOUNT_API_URL}`,account,{headers : authHeader()});
}

updateBankDetails(bank){
    return axios.post(`${UPDATE_BANK_API_URL}`,bank,{headers : authHeader()});
}

updateStatus(update){
    return axios.post(`${UPDATE_ASSIGNED_CAMP_STATUS_API_URL}`,update,{headers : authHeader()});
}

getAssignedCampaigns(page, size, infId){
    return axios.get(`${GETASSIGNEDCAMPAIGNS_API_URL}?page=${page}&size=${size}&infId=${infId}`,{headers : authHeader()});
}

getAssignedCampaignDetails(id){
    return axios.get(`${GETASSIGNEDCAMPAIGNDETAILS_API_URL}${id}`,{headers : authHeader()});
}
downloadVideo(id){
    return axios.get(`${DOWNLOAD_VIDEO_API_URL}${id}`,{headers : authHeader()});
}
removeChannel(id){

    return axios.post(`${REMOVE_CHANNEL_URL}`,id, {headers : authHeader()})
}
createRazorOrder(dto){
    return axios.post(`${CREATE_RAZOR_ORDER_URL}`,dto, {headers : authHeader()})
}
addToWishlist(dto){
      return axios.post(`${ADD_TO_WISHLIST}`,dto, {headers : authHeader()})
}
updateBooking(dto){
    return axios.post(`${UPDATE_ORDER_URL}`,dto, {headers : authHeader()})
}
getBookingList(id){
    return axios.get(`${GET_BOOKINGS_BY_USER}${id}`)
}

getSubCategoryListByCategory(id){
   // alert(`${SUBCATEGORY_API}${id}`)
    return axios.get(`${SUBCATEGORY_API}${id}`);
}

searchOnActivities(state,destination,activityCategory,name,subCategory,priceRange,type,instantConfirmation,page,size){
    return axios.get(`${ACTIVITIES_SEARCH}?state=${state}&destination=${destination}&activityCategory=${activityCategory}&name=${name}&subCategory=${subCategory}&priceRange=${priceRange}&type=${type}&instantConfirmation=${instantConfirmation}&page=${page}&size=${size}`);
}

getAllActivities(){
    return axios.get(`${ALL_ACTIVITIES}`);
}
getAllfaqs(){
    return axios.get(`${ALL_FAQS}`);
}

getFaqByType(id){
    
    return axios.get(`${FAQ_BY_TYPE}${id}`);

}

getFaqTypeList(){
    return axios.get(`${FAQ_TYPE_LIST}`);
}

getJobList(){
    return axios.get(`${JOB_LIST}`);
}

getDestinationByUrl(url){
    return axios.get(`${GET_DESTINATION_BY_URL}${url}`);
}
getActivityByUrl(url){
    return axios.get(`${GET_ACTIVITY_BY_URL}${url}`);
}
getActivityUrl(url){
    return axios.get(`${GET_ACTIVITY_URL}${url}`);
}
getVendorById(id){
    return axios.get(`${GET_VENDOR_BY_ID}${id}`);
}
getNonDefaultPackageListByActivityId(activityId){
    return axios.get(`${GET_NON_DEFAULT_PACKAGE_LIST_BY_ACTIVITY}?activityId=${activityId}`);
}
getPackageDetailsById(id){
    return axios.get(`${GET_PACKAGE_BY_ID}${id}`);
}
getStatesListWithDestinations(region){
    return axios.get(`${GET_ALL_STATE_WITH_DESTINATIONS}${region}`);
}

getWeatherReport(lat,lon){
    //https://api.openweathermap.org/data/2.5/onecall?lat=31.1048&lon=77.1734&appid=32dba427fb32444692d714b9b1a89ede&exclude=current,hourly,minutely,currently
    return axios.get(`https://api.openweathermap.org/data/2.5/onecall?lat=${lat}&lon=${lon}&exclude=current,hourly,minutely,currently&units=metric&appid=${WEATHER_API_KEY}`);
}

getBlogByUrl(url){
    return axios.get(`${GET_BLOG_BY_URL}${url}`); 
}

getWishList(id){
    return axios.get(`${GET_WISHLIST}${id}`);
}
removeFromWishlist(id){
    return axios.delete(`${REMOVE_FROM_WISHLIST}${id}`);
}
subscribeEmail(email){
    let dto = { 
        email: email
    }
    return axios.post(`${ADD_SUBSCRIPTION}`,dto);
}

updateUserDetails(dto){
    return axios.post(`${UPDATE_USER_DETAILS}`,dto);
}

getUserDetails(id){
    return axios.get(`${GET_USER_DETAILS}${id}`);
}

getSubCategoryIdByName(id){
    let dto = {
        name : id
    }
    return axios.post(`${SUB_CAT_BY_NAME}`,dto);
}
updatePassword(dto){
    return axios.post(`${UPDATE_PASSWORD}`,dto);
}
addReview(dto){
    return axios.post(`${ADD_REVIEW}`,dto);
}
getReviewList(id){
    return axios.get(`${GET_USER_REVIEWLIST}${id}`); 
}
getActivityReviews(id){
    return axios.get(`${GET_ACTIVITY_REVIEWLIST}${id}`); 
}
getCommentList(id){
    return axios.get(`${GET_BLOG_COMMENTLIST}${id}`); 
}
addComment(dto){
    return axios.post(`${ADD_COMMENT}`,dto);
}
retrieveAllBlog(page,size){
    return axios.get(`${GET_BLOGS_PAGINATION}?page=${page}&size=${size}&status=ACTIVE`);
}

retrieveAllBlogSearch(search,page,size){
    return axios.get(`${GET_BLOGSSEARCH_PAGINATION}?page=${page}&size=${size}&search=${search}`);
}

submitForgotPasswordRequest(email){
    return axios.get(`${FORGOT_PASSWORD}${email}`);
}
resetPasswordRequest(code,password){
    return axios.get(`${RESET_PASSWORD}${code}&password=${password}`);
}
verifyEmail(code){
    return axios.get(`${VERIFY_EMAIL}${code}`);
}

unsubscribe(email){
    return axios.get(`${UNSUB_EMAIL}${email}`);
}
getStateIdByName(name){
    return axios.get(`${STATEID_BY_NAME}${name}`);
}

checkOrCreatUserByEmail(dto){
    
}

updateProfile(data){
    const headers = {
        'Content-Type': 'multipart/form-data',
        }
    return axios({
        method: 'post',
        url: `${UPDATEPROFILE_API_URL}`,
        data: data,
        headers: headers
     })
}

}
export default new ProfileService()