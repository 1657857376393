
import React from 'react';
import { Route, Redirect} from 'react-router-dom'
import { APP_BASE_URL} from '../../constants';
import AuthService from '../../services/AuthService'
import Select from 'react-select'
import ProfileService from '../../services/ProfileService'
import {GOOGLE_CLIENT_ID, FB_APP_ID,IMAGE_BASE_URL,PROFILE_IMAGE_BASE_URL} from '../../constants'

class Header extends React.Component {
  constructor(props) {
    super(props);
	this.searchActivity = this.searchActivity.bind(this);
	this.handleClickSearch = this.handleClickSearch.bind(this);
	this.handleClickSearchPage = this.handleClickSearchPage.bind(this);
    this.state = {
	userLoggedIn :  AuthService.isUserLoggedIn(),
	userName : AuthService.getCurrentUserName(),
	userId : AuthService.getCurrentUserId(),
	profileImageUrl : `${APP_BASE_URL}/images/user-account.jpg`,	
    modal: false,
	isClearable: true,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
  };
  
}


  componentDidMount() {
    ProfileService.getAllActivities().then(response=>{
		//console.log(response.data);
		
		let popularActivities = [];
		for(let i = 0; i < response.data.length; i++){
			let value = response.data[i].url;
			let label = response.data[i].name;
			let skillS = { value : value ,label : label }
			//alert(value);
			//alert(label);
			popularActivities.push(skillS);
			
		  }
		  this.setState({"popularActivities":popularActivities})

	});

	
		 
	if(this.state.userLoggedIn){
	ProfileService.getUserDetails(this.state.userId).then(response=>{
	  // alert(response.data.email)
	  if(response.data.profileImageUrl !=null && response.data.profileImageUrl!==''){
		this.setState({"profileImageUrl":`${PROFILE_IMAGE_BASE_URL}`+response.data.profileImageUrl});
	}else{
		if(response.data.gmailImageUrl !=null && response.data.gmailImageUrl!==''){
			this.setState({"profileImageUrl":response.data.gmailImageUrl});
		}else if(response.data.facebookImageUrl !=null && response.data.facebookImageUrl!==''){
			this.setState({"profileImageUrl":response.data.facebookImageUrl});
		}
	}
	
		if(response.data.googleId !=null){
			this.setState({"gmailVerified":true});
		}
		if(response.data.facebookId !=null){
			this.setState({"facebookVerified":true});
		}
		if(response.data.isEmailVerified){
			this.setState({"isEmailVerified":true});
		}
		if(response.data.isPhoneVerified){
			this.setState({"isPhoneVerified":true});
		}
		if(response.data.password !=null && response.data.password!==''){
			this.setState({"passwordExist":true});
		}
		this.setState({"title":response.data.title,
		"firstName":response.data.firstName,
		"lastName":response.data.lastName,
		"code":response.data.code,
		"country":response.data.country,
		"email":response.data.email,
		"phone":response.data.phone});
	//	console.log(response.data)
	  })
	}
  }

  handleClickSearchPage(e) {
	e.preventDefault();
	var searchText = this.state.searchText;
	if(searchText.length>0){
		window.location.href=`${APP_BASE_URL}/search/${searchText}`;
	}
}

searchActivity(text){
	let matchers = [];
	var popularActivities = this.state.popularActivities;
	if(text.length>0){
		matchers = popularActivities.filter(act => {
			const regex = new RegExp(`${text}`,"gi");
			return act.label.match(regex);
		})
	}
    //console.log(matchers);
	this.setState({"searchText":text,"suggestions":matchers});
}

clearSuggestions(){
	this.setState({"suggestions": []});
}

handleClickSearch(e,value){
	e.preventDefault();
	//alert(value);
	if(value!=null){
		var id = value;
		ProfileService.getActivityByUrl(id).then(response=>{
			var activityId = response.data.id;
			if(response.data.activityCategory_id=='611b452a4fdde75b279725eb'){
				window.location.href=`${APP_BASE_URL}/staycation/${id}`;
			}else if(response.data.activityCategory_id=='611b45344fdde75b279725ec'){
				window.location.href=`${APP_BASE_URL}/tours/${id}`;
			}else if(response.data.activityCategory_id=='611ba6cd14e97e569e41eaac'){
				window.location.href=`${APP_BASE_URL}/activities/${id}`;
			}else if(response.data.activityCategory_id=='61275c3ceaf59f69875e2abd'){
				window.location.href=`${APP_BASE_URL}/transport/${id}`;
			}else if(response.data.activityCategory_id=='61275c55eaf59f69875e2abe'){
				window.location.href=`${APP_BASE_URL}/food/${id}`;
			}else {
				window.location.href=`${APP_BASE_URL}/activity/${id}`;
			}
	    })
		
	}
}


  handleChangeSearch(value){
	if(value!=null){
		var id = value.value;
		ProfileService.getActivityByUrl(id).then(response=>{
			var activityId = response.data.id;
			if(response.data.activityCategory_id=='611b452a4fdde75b279725eb'){
				window.location.href=`${APP_BASE_URL}/staycation/${id}`;
			}else if(response.data.activityCategory_id=='611b45344fdde75b279725ec'){
				window.location.href=`${APP_BASE_URL}/tours/${id}`;
			}else if(response.data.activityCategory_id=='611ba6cd14e97e569e41eaac'){
				window.location.href=`${APP_BASE_URL}/activities/${id}`;
			}else if(response.data.activityCategory_id=='61275c3ceaf59f69875e2abd'){
				window.location.href=`${APP_BASE_URL}/transport/${id}`;
			}else if(response.data.activityCategory_id=='61275c55eaf59f69875e2abe'){
				window.location.href=`${APP_BASE_URL}/food/${id}`;
			}else {
				window.location.href=`${APP_BASE_URL}/activity/${id}`;
			}
	    })
		
	}
}


  logout = () => {
    AuthService.logout();
    window.location.href=`${APP_BASE_URL}/login`;
  }

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
    {/* <header>
	        <div class="container">
	            <div class="logo-bx float-left">
	            	<div class="hder_menu_box">
                  <a class="header_toggle"> <i class="fas fa-bars"></i></a>
                   
               </div>
	               <a href="/">
	               <img src={APP_BASE_URL + '/images/logo.svg'} alt="Logo"/>
	               </a>
	            </div>
	            <div class="menu-bx float-right">
	               <div class="menu-list float-left">
	               
		                <ul class="onhover_line">
		                    <li><a href="#">
		                    		<div class="headerdot_toggle"></div>
		                    		<div class="headerdot_toggle"></div>
		                    		<div class="headerdot_toggle"></div> 
		                    	</a>
		                    </li>
		                    
								 {(this.state.userLoggedIn === true ? 
							(<><li class="line_link"><a href="/myBookings"><span>{this.state.userName}</span></a></li>
							 <li class="line_link"><a onClick={this.logout}><span>Log Out</span></a></li></>):( <>
		                     <li class="line_link"><a href="/login"><span>Log In</span></a></li>
		                     <li class="line_link"><a href="/signup"><span>Sign Up</span></a></li> </>) )}
		                </ul>
	               </div>
	            </div>
	            <div class="clearfix"></div>
	        </div>
        </header> */}

<header class="with-login">
	        <div class="container">
	            <div class="logo-bx float-left">
	            	<a href="/">
					<img src={APP_BASE_URL + '/images/logo.svg'}  height="100%" width="100%" alt="Logo"/>
	               </a>
	            	<div class="hder_menu_box">
                  <a class="header_toggle"> <img src={APP_BASE_URL + '/images/menu-toggle.svg'}   height="100%" width="100%" alt="toggle"/></a>
               	</div>
	            </div>

				<div class="mobile_header_button"><img src={APP_BASE_URL + '/images/search-icon01.svg'}  height="100%" width="100%" alt="search"/>  </div>
	            <div class="search-bar header_search_bar">
				<form  class="searchbar" onSubmit={this.handleClickSearchPage}>  
			   
				<div class="mobile_header_search"> <input  type="text" value={this.searchText} onBlur={()=>{
							  setTimeout(()=>{
                                this.clearSuggestions();
							  },1000)
						     }}  onChange={e=> this.searchActivity(e.target.value)} placeholder='Search your activity' />
						
						{( this.state.suggestions && this.state.suggestions.length != 0 ? 
						      (<div class="suggestions">
						           {this.state.suggestions && this.state.suggestions.map((suggestion,i)=>
						          <div class="suggestions-item" onClick={e =>this.handleClickSearch(e,suggestion.value)}   key={i} >{suggestion.label}</div>
						        )}
						      </div>) : (<></>)
                          )}
						 <button type="submit" class=""><img src={APP_BASE_URL + '/images/search-icon01.svg'}  height="" width="" alt="search"/></button> 	 
				</div>
						 
						   </form>
	          
	               </div>

	              

					{(this.state.userLoggedIn === true ? 
							(<>

                    <div class="login-dp">
	              	<span>{this.state.userName}</span>

	              	<div class="user-dp">
					  <img src={this.state.profileImageUrl}  height="" width="" alt="profile" />

	            	<div class="user-option">
	            		<ul>
						<li><a href="/myBookings"><img src={APP_BASE_URL + '/images/usericon01.svg'} height="" width=""  alt="bookings" /> Bookings</a></li>
	            		<li><a href="/myReviews"><img src={APP_BASE_URL + '/images/usericon02.svg'}  height="" width="" alt="reviews" />Reviews</a></li>
	            		<li><a href="/myWishlist"><img src={APP_BASE_URL + '/images/usericon03.svg'}  height="" width="" alt="whishlist" />Wishlist</a></li>
	            		<li><a href="/myProfile"><img src={APP_BASE_URL + '/images/usericon04.svg'}  height="" width="" alt="settings" />Settings</a></li>
	            		<li><a href="#" onClick={this.logout} ><i class="fa fa-power-off" aria-hidden="true" style={{"color":"#00acb2", "width": "36px","font-size":"20px"}} ></i>Log out</a></li>
	            		</ul>
	            	</div>
	            	</div>
	            	
	            	</div>
							 
							</>):( <>

								
								<div class="login-dp ">
								<ul class="onhover_line">
								<li class="line_link"><a href="/login" style={{"color":"white","margin-top": "-8px"}} class="signup-btn"  ><span>Log In</span></a></li>
		                   
							 </ul>
	                         </div>
		                    </>) )}

	            <div class="clearfix"></div>
	        </div>
        </header>	




    </>
  );
  }
}

export default Header;
