import React from 'react';
import Header from './Header';
import HomeFooter from './HomeFooter';
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertFromRaw } from 'draft-js';
import ProfileService from '../../services/ProfileService'
import { IMAGE_BASE_URL } from '../../constants';
import Helmet from 'react-helmet';
import Form from "react-validation/build/form";
import AuthService from '../../services/AuthService';

class Blog extends React.Component {
  constructor(props) {
    super(props);
	this.addComment =  this.addComment.bind(this);
	this.onChange = this.onChange.bind(this);
	this.generateCaptcha = this.generateCaptcha.bind(this);
	this.removeSpaces = this.removeSpaces.bind(this);
	this.ValidCaptcha = this.ValidCaptcha.bind(this);
    this.state = {
		userLoggedIn :  AuthService.isUserLoggedIn(),
		userId : AuthService.getCurrentUserId(),
	comment : '',
	commentMessage : false,
    modal: false,
  };
  
}


  componentDidMount() {
	this.generateCaptcha();
    ProfileService.getBlogByUrl(this.props.match.params.id).then(response=>{
	  var blogId = 	response.data.id;
	  if(response.data.id==undefined){
		 this.props.history.push(`/pageNotFound`)
		 window.location.reload();
	}
	//  alert(blogId);
	 // alert(response.data.userName);
	  this.setState({"blogId":blogId})
      this.setState({"coverImage":JSON.parse(response.data.coverImage).imageUrl})
	  this.setState({"coverImageAltText":JSON.parse(response.data.coverImage).altText})
      this.setState({"name":response.data.name})
      this.setState({"tags":JSON.parse(response.data.tags) })
	  this.setState({"blogDate":response.data.blogDate})
     // this.setState({"profileImage":JSON.parse(response.data.profileImage).imageUrl})
	 // this.setState({"profileImageAltText":JSON.parse(response.data.profileImage).altText})
	  this.setState({"userName":response.data.userName,"title":response.data.title,
	  "metaDesc":response.data.metaDesc})
      this.setState({ blogDescription: EditorState.createWithContent(convertFromRaw(JSON.parse(response.data.blogDescription))) })
      ProfileService.getRelatedBlog(blogId).then(response=>{
		this.setState({"relatedBlogs":response.data})
	  })
	  ProfileService.getCommentList(blogId).then(response=>{
		this.setState({"commentList":response.data})
        this.setState({"commentListSize":response.data.length})
	  })
    })
    
	ProfileService.retrieveAllBlog(0,10)
	.then(response => { 
		  this.setState({blogList:response.data.content})
		}
	);

	
  }

  handleClickBlog(e,id){
    this.props.history.push(`/blog/${id}`)
	window.location.reload();
  }

  generateCaptcha() {  
	
	var chr1 = Math.ceil(Math.random() * 10) + '';  
	var chr2 = Math.ceil(Math.random() * 10) + '';  
	var chr3 = Math.ceil(Math.random() * 10) + '';  

	var str = new Array(4).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); });  
	var captchaCode = str + chr1 + ' ' + chr2 + ' ' + chr3;  
	document.getElementById("txtCaptcha").value = captchaCode  
}  

/* Validating Captcha Function */  
 ValidCaptcha() {  
	var str1 = this.removeSpaces(document.getElementById('txtCaptcha').value);  
	var str2 = this.removeSpaces(document.getElementById('txtCompare').value);  

	if (str1 == str2) return true;  
	return false;  
}  

/* Remove spaces from Captcha Code */  
 removeSpaces(string) {  
	return string.split(' ').join('');  
}

  addComment(e){
	e.preventDefault();

	  
		if(this.state.comment==''){


		   this.setState({"errorComment":true})
		}else{
		   this.setState({"errorComment":false})
		   if(this.ValidCaptcha()){
			var userId = null;
			if(this.state.userLoggedIn){
				userId = this.state.userId;
			}
			let dto = {
			 comment: this.state.comment,
			 blogId: this.state.blogId,
			 userId: userId
			}
			ProfileService.addComment(dto).then(response=>{
				this.setState({"comment":'',"captchatext":'',"commentMessage":true});
			})
			this.generateCaptcha();
			this.setState({"captchError":false})
		    }else{
			this.setState({"captchError":true})
		   }
		   
		}
	
  }

  onChange(e){
	this.setState({
		[e.target.name]: e.target.value
	  });
  }

  render() {
    let myMessage = null;
    if(this.state.message!=null){
        myMessage = <div className="alert alert-success fade show" role="alert">{this.state.message}</div>
    }
   
  return (
    <>
	<Helmet>
    <title>{this.state.title}</title>
    <meta name='description' content={this.state.metaDesc} />
	<script src="https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v12.0&appId=1206614489817558&autoLogAppEvents=1" 
    type="text/javascript" />
    </Helmet>
    <div class="main_all">
    <Header></Header>
	<img  loading="lazy" class="blog-bg" src={IMAGE_BASE_URL+``+this.state.coverImage} alt={this.state.coverImageAltText} />
    <section class="same-section blog_detailsB innerpage_banner">
	    	<div class="container">
	    		<div class="innpage_banner_inn_side">
    				<ul class="italic_service_label">
            {(this.state.tags != null && this.state.tags.length> 0 )? this.state.tags.map(
                                    hl =><>   
		    					<li>{hl.valueValue}</li>
		    				
                  </>
						   ):""}
    				</ul>
		    		<h1 class="inner_banner_heding aligh-left" style={{"font-size":"28px"}}>{this.state.name}</h1>
		    		<div class="blog_write_byB small_padding_top">	
		    			{/* <div class="userwrite_img">
		    				<img  loading="lazy" src={this.state.profileImage} alt={this.state.profileImageAltText} />
		    			</div> */}
		    			 <span class="wrte_username">Author: {this.state.userName}</span> 
		    		</div>
	    		</div>
	    	</div>
	    </section>



   		<section class="same-section blog_page_blogs padding_top">
   				<div class="travelby_side_flex secpaddingB">
                  <div class="travelby_side_logo">
                     <img  loading="lazy" src="/images/logo.svg" alt="" />
                  </div>
                  <div class="travelby_side_logo">
                     <span>A Travel blog by</span>
                     <h6>Renok Adventures</h6>
                  </div>
               </div>
               <div class="button_bbx">
                  <a href="#" class="btn_blog_bx">Itineraries</a>
                  <a href="#" class="btn_blog_bx">Trip planning</a>
               </div>
	    	<div class="container">
	    		<div class="blog_pagemain_flex">
	    			<div class="blog_leftbx_side">
	    				{/* <h2 class="common_main_headin secmarginT aughst">Overview Of Leh Ladakh Trip In August  </h2>
	    				<section class="bredcrumb_custom common_block secpaddingT">
		               <ul class="custbread">
		                  <li><a href="#">Home</a></li>
		                  <li><i class="fas fa-chevron-right"></i></li>
		                  <li><a href="#">Trip Planning</a></li>
		                  <li><i class="fas fa-chevron-right"></i></li>
		               </ul>
		            </section> */}
			    		<div class="blogDetail_bx_Bpage" style={{"font-family":" 'Mont'"}}>
              				<Editor toolbarHidden={true} editorState={this.state.blogDescription}  readOnly={true} editorStyle={{lineHeight: '150%', letterSpacing: '0.5px', fontWeight: '800'}} />
			    			{/* <div class="blog_userimg_bx new-blog-user">
			                  <div class="blog_usercont">
			                     <div class="image_bbx_user">
			                        <img src="images/guidetip-user.jpg" alt="" />
			                     </div>
			                  </div>
			                  <div class="blog_usercont">
			                     <span>Dinkar Kamat</span><span>SEPTEMBER 12, 2019</span>
			                  </div>
			               </div>
				    		<p>I’d dreamed of travelling to India all my life.</p>			    			
				    		<p>I’d wake up from fitful sleep on flights to Asia whilst flying over the subcontinent.  Not once, or twice—but every single time</p>			    			
				    		<p>In 2015, back when I was on sabbatical from my corporate job in tourism (and pre-blogging days), the only country I knew I absolutely had to go to was India.  It took me months after my first trip to India to write about it – not for want of enthusiasm – but for the daunting task of trying to put my first trip to India into words. </p>			    			
				    			    			
		    				<img src="images/blogD01.jpg" alt="Image-Story"/>
		    				<p>The sub-continent to me stood for a land of energy, mystery, spirituality, colour and love.  I fantasised about riding around on trains and smelling the heat and energy of the place.  Of seeing elephants and tigers, colourful saris, prayer ceremonies on the banks of the ganges, chaotic streets blocked by slow moving cows and so much more.</p>
		    				<p>But beyond that, Incredible India has just always held an inexplicable draw for me.  It has tugged at my heart strings. And I know I’m not the only one.</p>
		    				<p>Nearly three years on from that first time visit to India – and many subsequent solo trips to India, this is what I’ve learned. Over the last three years I’ve spent a total of 10 months travelling and staying in India. India has even become a part of me – and the other half of this blog is, after all now… Indian! I’ve come to learn a little of what to expect travelling India and how to prepare for travel in India – whether it’s for the first or tenth time.</p>
		    				<h3 class="blackblog_title">Expectations</h3>
		    				<p>The Average travel advice for India is full of cliches such as “expect the unexpected”, or even “prepare to lose yourself”. Unhelpful as they might sound, there’s a reason for these cliches. Over my journeys to India I’ve come to realise that life somehow seems much “larger” there – there’s the chaos, the bustle, the beauty, but also the sheer number of people somehow ensure, that whatever your opinions on what to expect from your first trip to India, you’ll never be quite right</p>
		    				<p>So here are my own versions of the cliches, based on this traveller’s experiences:</p>
		    				<p>Boarding my flight from Bangkok to Delhi, I posted on my Facebook page “Ready for India… I think” one of my friends wrote back “You are never ready for India. But the good thing is, India is always ready for you!”. I can’t think of anything more true.  Nothing prepares you for the reality of India. It really is like no other place on earth.</p>

		    				<img src="images/blogD02.jpg" alt="Image-Story"/>
		    				<p>Leave your expectations on the plane. It’s one thing to read about India in books and see pictures.  It’s another to be there. Prime example: the Taj Mahal (a must for any visitor, despite its overburdening popularity). No matter how many pictures of the Taj Mahal we’ve all seen, there is nothing that prepares you for the actual beauty of the place. Glistening white in the morning sunshine, rising from the misty river banks behind.  Just like the Taj, first time India is an experience best enjoyed without expectations and pre-judgements.</p>
		    				<p>India will teach you patience. This is one of the few things you can be certain of.  Getting frustrated when things don’t go to plan or you discovered you’ve been ripped off in some transaction does not serve us well.  Understanding up front that ‘staring’ is not considered particularly rude by many, and that you may attract quite a few stares during your trip, will help keep your patience in tact too.  In short—in a country where the lateness of trains is measured not by hours late but by quarter, half or full day late—adapting an open, go with the flow attitude makes travel in India a lot more enjoyable.  Which brings me to my next point… To be continued</p> */}

				    	</div>
	    			</div>	    			
	    			<div class="blog_rightbx_side">
	    				<div class="HRblack_bold"></div>
	    				<h3 class="blackblog_title small_padding_top">RECENT POSTS</h3>
	    				<ul class="recent_postlist small_padding_top">
	    				{(this.state.blogList != null && this.state.blogList.length> 0 )? this.state.blogList.map(
                                    hl =><>
	    					<li><a style={{"cursor":"pointer"}}  href={`/blog/${hl.url}`} >{hl.name}</a></li>
	    				
                </>
						   ):""}
	    				</ul>
	    				<div class="HRblack_bold"></div>
	    				<h3 class="blackblog_title small_padding_top">ABOUT RENOK</h3>
	    				<p class="small_padding_top">Renok is an adventure company established in 2011 which specializes in trekking, adventure activities and outbound training. As nature and adventure aficionados we strive to give you an experience where the destination is never a place, but a new way of seeing things.</p>
						<div style={{"width":"100%","margin-top":"20px"}}>

<div class="fb-page" data-href="https://www.facebook.com/renokadventures/" data-width="420"></div>
</div>
	    			</div>
	    		</div>
	    	</div>
	    </section>

	    
	    <section class="same-section postconnent_sec small_padding_top small_margin_top padding_bottom">
	    	<div class="container">	    		
				<div class="blog_pagemain_flex">
					<div class="blog_leftbx_side">
						<div class="title_for_commit">
							{/* <h3 class="blackblog_title">Travel to Leh now</h3>
							<p>Planning a vacation this summer, get on with Renok for amazing offers and deals a one stop solution for all of your adventure quests. Book your next vacation on Renok and experience the best vacation’s yet!</p> */}
							<div class="tags-cnt">
							<h3 class="tags_oftitle small_padding_top">TAGS</h3>							
		    				<ul class="italic_service_label">
                {(this.state.tags != null && this.state.tags.length> 0 )? this.state.tags.map(
                                    hl =><>   
		    					<li>{hl.valueValue}</li>
		    				
                  </>
						   ):""}
		    				</ul>
		    			</div>
						</div>

			            <div class="travelof_palcer ">
			               <div class="takeme_topalce">
			               
			                  <h4 class="secmarginB">{(this.state.commentList != null  ? (<>{this.state.commentList.length}</>):(<>0</>))} Comments</h4>
			               </div>
						   <Form onSubmit={this.addComment} ref={c => {this.form = c;}}>
			               <textarea class="blog_detail_textara" name="comment" value={this.state.comment} onChange={this.onChange} placeholder="Add Comments …"></textarea>
						   {( this.state.errorComment == true ?  (<div style={{"color":"#55aeb3"}}><p>Please enter your comment.</p></div>):(<></>) )}
						   <div class="btnrefresh"> <input  class="filter_fulid captchaMy" type="text"  id="txtCaptcha"  />  
<img id="btnrefresh"  onClick={e =>this.generateCaptcha()} loading="lazy" src="/images/refresh-icon.png" height="30px" width="30px"   alt="refresh"/> </div>
<input class="filter_fulid row" style={{"width":"170px","margin-left":"0px"}} type="text" name="captchatext" id="txtCompare" value={this.state.captchatext} placeholder="Enter Captcha"  onChange={this.onChange} />  
{( this.state.captchError == true ?  (<div  style={{"color":"#55aeb3"}}><p>Invalid Captcha.</p></div>):(<></>) )}
						   <button type="submit" style={{"margin-top":"10px"}} class="btn btn-primary" >Save</button>
						   </Form>
{( this.state.commentMessage == true ?  (<div style={{"color":"#55aeb3"}}><p>Thank you for adding comment. It will be displayed after approval.</p></div>):(<></>) )}
			            </div>

			            <div class="blog_comment_Ulists padding_leftang_right">  

						{(this.state.commentList != null && this.state.commentList.length> 0 )? this.state.commentList.map(
                                    hl =><>  
			               <div class="common_block likeside_link_perent">            
			                  <div class="blog_userimg_bx review-blog">
			                     <div class="blog_usercont">
			                        <div class="image_bbx_user">
			                           <img  loading="lazy" src="/images/user.jpg" alt="user"/>
			                        </div>
			                     </div>
			                     <div class="blog_usercont">
			                        <span>{hl.userName}</span>
			                        <p>{hl.comment}</p>
			                     </div>
			                  </div>
			                  {/* <div class="likeside_link common_block">
			                     <a href="#" class="links_user_likes">Like</a>
			                     <a href="#" class="links_user_likes">Reply</a>
			                     <a href="#" class="links_user_likes">Mark as spam</a>
			                     <a href="#" class="links_user_likes">1d</a>
			                  </div> */}
			               </div>
						   </>
						   ):""}


			             
			            </div>
			            {/* <div class="tags-links">
							<h3 class="tags_oftitle small_padding_top">TAGS :</h3>							
		    				<ul class="italic_service_label">
                {(this.state.tags != null && this.state.tags.length> 0 )? this.state.tags.map(
                                    hl =><>   
		    					<li>{hl.valueValue}</li>
		    				
                  </>
						   ):""}
		    				</ul>
		    			</div> */}

		    			<section class="aboutauthor_details padding_leftang_right common_block secpaddingT secpaddingB secmarginT">
		               <div class="aboutauthor_flex">
		                  <div class="about_author_bx">
		                     
		                  </div>
		                  <div class="about_author_bx">
		                     <h2>About the Author</h2>
		                     <h3>{this.state.userName}</h3>
		                  
		                  </div>
		               </div>
		            </section>


			            <div class="same-section related_postsec_side">
			            	<h3 class="blackblog_title padding_top">Related posts</h3>
                 {(this.state.relatedBlogs != null && this.state.relatedBlogs.length> 0 )? this.state.relatedBlogs.map(
                                    hl =><>   
				    		<div class="blog_bx_Bpage">			    			
			    				<img  loading="lazy" src={IMAGE_BASE_URL+``+JSON.parse(hl.coverImage).imageUrl} alt={JSON.parse(hl.coverImage).altText} />
			    				<ul class="italic_service_label">
			    				{(JSON.parse(hl.tags) != null && JSON.parse(hl.tags).length> 0 )? JSON.parse(hl.tags).map(
                                    tg =><>
		    					<li><a href="#" class="brn-itiner">{tg.valueValue}</a></li>
		    					</>
						   ):""}
			    				</ul>
			    				<h3 class="blackblog_title">{hl.name}</h3>
								<a href={`/blog/${hl.url}`}  class="onlink"></a>
					    	</div>
                </>
						   ):""}


				    		
				    		
			            </div>
		        	</div>
		            <div class="blog_rightbx_side">
		            </div>
			    </div>
	    	</div>
	    </section>



    <HomeFooter></HomeFooter>
    </div>
    </>
  );
  }
}

export default Blog;
